import { Component, OnInit } from '@angular/core';
import { AssignJeevesDefaultsComponent, JeevesDeliveryAddressesService } from 'gung-standard-jeeves';
import {
  SelectedCustomerService,
  CustomerService,
  CustomerContactService,
  GungFlowService,
  AuthService,
  CartService,
  AvailabilityService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { forkJoin, of } from 'rxjs';
import { first, mergeMap } from 'rxjs';
import { OttoOlsenFreightCostService } from '../../../../services/otto-olsen-freight-cost.service';

@Component({
  selector: 'otto-olsen-assign-jeeves-default',
  templateUrl: './otto-olsen-assign-jeeves-default.component.html',
  styleUrls: ['./otto-olsen-assign-jeeves-default.component.scss']
})
export class OttoOlsenAssignJeevesDefaultComponent extends AssignJeevesDefaultsComponent implements OnInit {
  freightCostProductId = this.freightCostService.freightCostProductId;

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected customerContactService: CustomerContactService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected availabilityService: AvailabilityService,
    protected dateUtilService: DateUtilService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    private freightCostService: OttoOlsenFreightCostService
  ) {
    super(
      selectedCustomerService,
      customerService,
      customerContactService,
      gungFlowService,
      authService,
      cartService,
      availabilityService,
      dateUtilService,
      jeevesDeliveryAddressesService
    );
  }

  ngOnInit(): void {
    // super.ngOnInit();

    this.onNextBtnClicked.subscribe(() => {
      forkJoin([
        this.selectedCustomerService.getSelectedCustomer().pipe(first()),
        this.gungFlowService.getSelectedFlow().pipe(first()),
        this.authService.getCurrentUser().pipe(first()),
        this.cartService.getCurrentCart().pipe(first())
      ])
        .pipe(
          mergeMap(([customer, flow, user, cartRows]) => {
            const productIds = cartRows.map(row => row.productId);
            if (productIds.includes(this.freightCostProductId)) {
              const freight = cartRows.find(r => r.productId === this.freightCostProductId);
              if (freight.extra._calculateFreight) {
                this.cartService.removeRow(freight);
              }
            }
            return forkJoin([
              of(customer),
              this.jeevesDeliveryAddressesService.getOrderedDeliveryAddressesForCustomer(customer.id),
              of(flow),
              of(user),
              of(cartRows)
            ]);
          }),
          mergeMap(([customer, deliveryAddressList, flow, user, cartRows]) => {
            return forkJoin([
              of(customer),
              of(deliveryAddressList),
              of(flow),
              of(user),
              of(cartRows),
              this.customerContactService.getCustomerContacts(customer.id).pipe(first()),
              of(undefined)
            ]);
          })
        )
        .subscribe(([customer, deliveryAddressList, flow, user, cartRows, customerContacts, billingCustomer]) => {
          this.setValues(customer, flow, user, customerContacts, billingCustomer, cartRows);
          this.setDeliveryAddress(deliveryAddressList[0]);
          this.checkout.extra.oh.slutkundnamn2 = user.username;
          this.stepDone.emit(this.checkout);
        });
    });
  }
}
