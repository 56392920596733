<div *ngIf="loginRequired || (!isLoading && !errorOccured); else noApp">
  <mat-sidenav-container [ngStyle]="containerStyles" [hasBackdrop]="true">
    <mat-sidenav
      #sidenav
      mode="over"
      [fixedInViewport]="true"
      [fixedTopGap]="marginTop"
      position="end"
      (opened)="sideMenuChange(true)"
      (closed)="sideMenuChange(false)"
    >
    <ul *ngIf="mainMenu" class="no-style p-2 main-menu">
      <li class="col-12 link-block" *ngFor="let linkGroup of mainMenu.links">
        
        <div *ngIf="linkGroup.link || linkGroup.action; else noLink">
          <div [ngSwitch]="linkGroup.heading">
            <div *ngSwitchCase="'HIDE_PRICE'">
              <div class="gung-toggle-switch">
                <label class="switch">
                  <input
                    id="hidePrice"
                    name="hidePrice"
                    type="checkbox"
                    #hidePriceInput
                    (change)="linkGroup.action(hidePriceInput)"
                  />
                  <span class="slider round bg-primary"></span>
                </label>
                <label for="hidePrice" class="ml-1" translate>{{ linkGroup.heading }}</label>
              </div>
            </div>
            <div *ngSwitchCase="'HIDE_EDIT_PIM_LINKS'">
              <div class="gung-toggle-switch">
                <label class="switch">
                  <input
                    id="hideEditPim"
                    name="hideEditPim"
                    type="checkbox"
                    #hideEditPimInput
                    (change)="linkGroup.action(hideEditPimInput)"
                  />
                  <span class="slider round bg-primary"></span>
                </label>
                <label for="hideEditPim" class="ml-1" translate>{{ linkGroup.heading }}</label>
              </div>
            </div>
            <div *ngSwitchDefault>
              <a
                [routerLink]="linkGroup.link ? linkGroup.link : undefined"
                [routerLinkActive]="linkGroup.link ? 'active' : ''"
                (click)="linkGroup.action ? linkGroup.action() : undefined"
              >
                <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
                <span translate>{{ linkGroup.heading }}</span>
              </a>
            </div>
          </div>
        </div>

        <ng-template #noLink>
          <h6 class="text-uppercase font-weight-bold">
            <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
            <span translate>{{ linkGroup.heading }}</span>
          </h6>
        </ng-template>

        <ul class="pl-0 no-style">
          <li *ngFor="let childLink of linkGroup.links" >
            <a
              [routerLink]="childLink.link ? childLink.link : undefined"
              [routerLinkActive]="childLink.link ? 'active' : ''"
              (click)="childLink.action ? childLink.action() : undefined"
              class="d-flex align-items-center link-withicon"
            >
              <i *ngIf="childLink.icon" class="fa {{ childLink.icon }} mr-2"></i>
              <span translate>{{ childLink.heading }}</span>
            </a>
            <ul>
              <li *ngFor="let subLink of childLink.links">
                <a
                  [routerLink]="subLink.link ? subLink.link : undefined"
                  [routerLinkActive]="subLink.link ? 'active' : ''"
                  (click)="subLink.action ? subLink.action() : undefined"
                >
                  <i *ngIf="subLink.icon" class="fa {{ subLink.icon }} mr-2"></i>
                  <span translate>{{ subLink.heading }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    </mat-sidenav>

    <mat-sidenav-content>
      <otto-olsen-navbar
        #navbar
        *ngIf="!hideNavbar"
        [class.d-none]="hideNavbar"
        [hideMenuRoutes]="hideMenuRoutes"
        (navbarHeight)="setContainerMarginTop($event)"
        (sidenavToggle)="sidenav.toggle()"
        (topMenuExpanded)="topMenuChange($event)"
      ></otto-olsen-navbar>
      <div id="app-wrapper-content-container" class="wrapper-content-container" (click)="collapseTopMenu()">
        <div class="container main" [class.pt-5]="!hideMenuRoutes.includes(router.url.split('/')[1])">
          <router-outlet></router-outlet>
        </div>
        <otto-olsen-footer [hideMenuRoutes]="hideMenuRoutes"></otto-olsen-footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- <div class="barcode-footer" *ngIf="isScannerOnly">
    <div class="d-flex">
   

      <button type="button" class="btn btn-primary" (click)="quickCheckout()">
        <i class="far fa-shopping-cart"></i>
      </button>
      <button type="button" class="btn btn-primary" (click)="clearCart()"><i class="far fa-eraser"></i></button>
      <button type="button" class="btn btn-primary" [routerLink]="'/mobile'"><i class="fas fa-bars"></i></button>
      <button type="button" class="btn btn-primary" [routerLink]="'/barcode-scanner'">
        <i class="fas fa-barcode"></i>
      </button>
    </div>
  </div> -->
</div>
<ng-template #noApp>
  <div class="wrapper d-flex align-items-center justify-content-center m-5">
    <div *ngIf="isLoading && !errorOccured">
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </div>
    <div *ngIf="errorOccured">
      <h1 class="text-center" *ngIf="!errorSelectedCustomer; else noAppErrorCustomer">An error occured, please log out and contact your supplier</h1>
      <ng-template #noAppErrorCustomer>
        <h1><span translate>YOU_WERE_NOT_LOGGED_IN</span>!</h1>
        <p>{{'YOUR_USER_IS_NOT_CONNECTED_TO_A_CUSTOMER' | translate: {user: username} }}</p>
        <p class="mb-3" translate>PLEAS_CONTACT_OTTO_OLSEN_AT</p>
      </ng-template>

      <div class="d-flex justify-content-center">
        <button class="btn btn-primary" [class.px-5]="errorSelectedCustomer" (click)="logout()" translate>{{ !errorSelectedCustomer ? 'LOGOUT' : 'OK'}}</button>
      </div>
    </div>
  </div>
</ng-template>
