<div class="card product-card">
  <div class="card-body text-center">
    <div>
      <span class="favourite-icon-wrapper">
        <otto-olsen-favourite-product-icon-only [id]="data.id"></otto-olsen-favourite-product-icon-only>
      </span>
    </div>
    <div class="image-wrapper" (click)="clickProduct(data.id)">
      <!-- routerLink="/product/{{data.id}}" -->
      <img
        class="img-fluid m-auto"
        src="https://cdn1.gung.io/fit-in/250x250/filters:etag({{ data.extra.images[0].s3ETag }})/{{
          data.extra.images[0].s3Uri
        }}"
        alt=""
      />
    </div>
    <div class="product-text">
      <otto-olsen-availability
        [availability]="availability"
        *ngIf="!isAnonymous && availability"
        [unit]="data.extra.ar?.enhetskod"
        [decimals]="data?.extra?.ar?.antdec"
        [productionDays]="data?.extra.ar?.anskaffningssatt && (data?.extra.ar?.anskaffningssatt * 1) === 12 && data?.extra.ar?.artgenomloppstid"
      ></otto-olsen-availability>
      <div class="text-truncate" translate>{{ data.name }}</div>
      <small translate
        >Art no: <span translate>{{ data.id }}</span></small
      >
      <b
        *ngIf="
          selectedCustomer &&
          data.extra._customerCrossReferences &&
          data.extra._customerCrossReferences[selectedCustomer.id]
        "
        class="d-block small"
      >
        {{ data.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
      </b>
      <div translate *ngIf="price">
        <lib-price [price]="price" type="NET"></lib-price>
      </div>
    </div>
    <div *ngIf="isExport; else buyBtn">
      <otto-olsen-item-selection-button [item]="data"></otto-olsen-item-selection-button>
    </div>
    <ng-template #buyBtn>
      <div
        *ngIf="
          isAnonymous ||
            (!(
              data.product.extra.pim &&
              data.product.extra.pim.pricerequest &&
              data.product.extra.pim.pricerequest !== 'false'
            ) &&
              !(data.product.extra.ar.itemtypecd1 === 0 || data.product.extra.ar.itemtypecd1 === 7));
          else priceRequest
        "
      >
        <otto-olsen-buy-btn
          name="buy-btn-package-size"
          [id]="data.id"
          [unit]="data?.product?.extra?.ar?.enhetskod"
          [minimumOrderQuantity]="data.firstStepAmount || data.packageSize"
          [stepAmount]="data.packageSize"
          [decimals]="data.extra.ar.antdec"
          [activeInB2B]="data?.product?.extra?.pim?.activeInB2B"
        >
        </otto-olsen-buy-btn>
      </div>
      <ng-template #priceRequest>
        <a
          href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ data.id }}"
          target="_blank"
          class="request-price-email"
        >
          <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
        </a>
      </ng-template>
    </ng-template>
  </div>
</div>
