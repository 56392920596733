import { Component, OnInit } from '@angular/core';
import { FavouriteProductIconComponent, FavouriteProductsService, AuthService } from 'gung-standard';
import { filter, first } from 'rxjs';

@Component({
  selector: 'otto-olsen-favourite-product-icon-only',
  templateUrl: './otto-olsen-favourite-product-icon-only.component.html',
  styleUrls: ['./otto-olsen-favourite-product-icon-only.component.scss']
})
export class OttoOlsenFavouriteProductIconOnlyComponent extends FavouriteProductIconComponent {
  isAnonymous = true;

  constructor(protected favouritesService: FavouriteProductsService, protected authService: AuthService) {
    super(favouritesService);
    this.authService
      .getRoles()
      .pipe(
        filter(roles => !!roles),
        first()
      )
      .subscribe(roles => {
        if (roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }
      });
  }
}
