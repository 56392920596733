import { Component, OnInit } from '@angular/core';
import { ProductFastSearchListConfigService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-product-fast-search-list',
  template: '<lib-fastsearch #productList [configService]="configService"></lib-fastsearch>',
  styleUrls: ['./otto-olsen-product-fast-search-list.component.scss']
})
export class OttoOlsenProductFastSearchListComponent implements OnInit {
  constructor(public configService: ProductFastSearchListConfigService) {}

  ngOnInit() {}
}
