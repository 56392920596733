import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JeevesOrderActionConfigService } from 'gung-standard-jeeves';
import {
  Order,
  BackendRerouteService,
  SelectedCustomerService,
  CustomerService,
  OrderToBasketService,
  PdfDownloadIndicatorService,
  BackendInterceptor
} from 'gung-standard';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenOrderActionConfigService extends JeevesOrderActionConfigService {
  base = [
    { title: 'XLSX', action: (item: Order) => this.backendRerouteService.reroute('download/order-excel/' + item.id) },
    { title: 'PDF', action: (item: Order) => this.backendRerouteService.reroute('download/order-pdf/' + item.id) },
    // { title: 'IMAGES', action: (item: Order) => this.backendRerouteService.reroute('download/order-zip/' + item.id) },
    { title: 'COPY_TO_CART', action: (item: Order) => this.addOrderToCart(item) }
  ];

  constructor(
    protected backendRerouteService: BackendRerouteService,
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected orderToBasketService: OrderToBasketService,
    protected modalService: NgbModal,
    protected router: Router,
    protected pdfDownloadIndicatorService: PdfDownloadIndicatorService,
    protected http: HttpClient,
    protected backendInterceptor: BackendInterceptor
  ) {
    super(
      backendRerouteService,
      selectedCustomerService,
      customerService,
      orderToBasketService,
      modalService,
      router,
      pdfDownloadIndicatorService,
      http,
      backendInterceptor
    );
  }
}
