import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';

@Component({
  selector: 'otto-olsen-footer',
  templateUrl: './otto-olsen-footer.component.html',
  styleUrls: ['./otto-olsen-footer.component.scss']
})
export class OttoOlsenFooterComponent implements OnInit {

  @Input() hideMenuRoutes;
  constructor(public router: Router) {}

  ngOnInit(): void {
   
  }

}
