import { Component, OnInit } from '@angular/core';
import { ProductPaginationListComponent, ProductPaginationListConfigService } from 'gung-standard';
import { OttoOlsenProductPaginationListConfigService } from '../../services/otto-olsen-product-pagination-list-config.service';

@Component({
  selector: 'otto-olsen-quick-search',
  template: '<otto-olsen-pagination-list #productList [configService]="configService"></otto-olsen-pagination-list>',
  styles: []
})
export class OttoOlsenQuickSearchComponent extends ProductPaginationListComponent {
  constructor(public configService: OttoOlsenProductPaginationListConfigService) {
    super(configService);
    this.configService.globalSearch = 'sINTERNAL_SEARCH';
  }
}
