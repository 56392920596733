<div class="container" *ngIf="processing && !error">
  <div
    class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
    role="progressbar"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
    style="width: 75%"
  ></div>
  <h2 translate>SUBMITTING_ORDER</h2>
</div>

<div class="container" *ngIf="!!context && !error">
  <div class="mb-4">
    <h2 class="text-center">
      <span translate>THANK_YOU_HEADER</span>
    </h2>
    <p class="text-center">
      <span translate>ORDER_ID</span>:
      <a [href]="'orders/' + item" *ngFor="let item of context.erpOrderIds; last as isLast"
        >{{ item }}<span *ngIf="!isLast">, </span></a
      >
    </p>
    <p class="text-center" translate>THANK_YOU_EMAIL_CONFIRMATION</p>
  </div>
</div>
<div class="container" *ngIf="error">
  <div class="mb-4">
    <p class="text-center">ERROR_OCCURED</p>
  </div>
</div>
<otto-olsen-scanner-footer-menu *ngIf="showScannerMenu"></otto-olsen-scanner-footer-menu>