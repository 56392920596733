import { Injectable } from '@angular/core';
import { Order} from 'gung-standard';
import { JeevesOpenOrdersListConfigService } from 'gung-standard-jeeves';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { OttoOlsenOpenOrderTableComponent } from '../components/otto-olsen-open-order-table/otto-olsen-open-order-table.component';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenOpenOrdersListConfigService extends JeevesOpenOrdersListConfigService{
  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenOpenOrderTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getItems() {
    return super.getItems().pipe(
      map(orders => orders.filter(order => order?.extra?.oh?.salestype !== '1' && order?.extra?.status !== 'ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS'))
    )
  }
}
