import { Component, OnInit } from '@angular/core';
import { JeevesInvoiceActionConfigService, JeevesInvoiceCardListComponent } from 'gung-standard-jeeves';

@Component({
  selector: 'otto-olsen-invoice-card-list',
  templateUrl: './otto-olsen-invoice-card-list.component.html',
  styleUrls: ['./otto-olsen-invoice-card-list.component.scss']
})
export class OttoOlsenInvoiceCardListComponent extends JeevesInvoiceCardListComponent {
  constructor(public configService: JeevesInvoiceActionConfigService) {
    super(configService);
  }
}
