import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OpenOrdersTableCardComponent } from 'gung-dashboard';
import { OrderService, LocationConfigService, SelectedCustomerService, MetadataService } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { forkJoin, of } from 'rxjs';
import { first, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'otto-olsen-last-ten-orders-card',
  templateUrl: './otto-olsen-last-ten-orders-card.component.html',
  styleUrls: ['./otto-olsen-last-ten-orders-card.component.scss']
})
export class OttoOlsenLastTenOrdersCardComponent extends OpenOrdersTableCardComponent implements OnInit {
  currentLang = this.translateService.currentLang || 'se';

  constructor(
    protected orderService: OrderService,
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    public dateUtilService: DateUtilService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected translateService: TranslateService
  ) {
    super(orderService, locationConfigService, router, dateUtilService);
  }

  ngOnInit(): void {
    // This is not correct. Only fetches for selected customer and the request is way to slow.
    // It will be fine until a new endpoint has been created in the backend that works similarly to
    // how open orders endpoint work. (Faster, for all the users customers).
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(customer => forkJoin([this.orderService.getOrders(customer.id, false, 10)])),
        map(([orders]) => {
          return orders;
        })
      )
      .subscribe(res => {
        this.orders = res;
        this.loaded = true;
      });
  }

  metadataLookup(table: string, field: string, value: string, prefix = '', suffix = '', translate?: boolean) {
    if (translate) {
      return this.metadataService.getMetaValueWithLang(table, value, this.currentLang);
    }
    return this.metadataService.getMetadataValue(table, field, prefix + value + suffix);
  }
}
