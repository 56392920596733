<div class="modal-header px-5 border-0 justify-content-center pb-0">
  <h4 class="modal-title mt-0 text-primary" id="modal-basic-title"><span translate>DELETE</span>&nbsp;</h4>
  <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div class="modal-body px-5 border-0">
  <div class="row mb-2">
    <div class="col-12 font-weight-bold">
      <span translate>CONFIRM_DELETE_SHOPPING_CART</span>?
    </div>
  </div>
  <div class="row">
    <div class="col-3 font-weight-bold">
      <span translate>DATE</span>:
    </div>
    <div class="col-9">
      <span>{{ data.date | date: dateUtilService.dateFormat }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-3 font-weight-bold">
      <span translate>SHOPPING_CART</span>:
    </div>
    <div class="col-9">
      <span>{{ data.name }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-3 font-weight-bold">
      <span translate>CUSTOMER_NAME</span>:
    </div>
    <div class="col-9">
      <span>{{ data.customer }}</span>
    </div>
  </div>
</div>

<div class="modal-footer d-block px-5 border-0">
  <div class="row">
    <div class="col-12 text-center">
      <button class="btn btn-sm btn-primary btn-default px-4 mr-2" (click)="activeModal.close('add')" translate>
        YES
      </button>
      <button class="btn btn-sm btn-primary btn-default px-4 ml-2" (click)="activeModal.close(false)" translate>
        NO
      </button>
    </div>
  </div>
</div>
