import { Injectable } from '@angular/core';
import { BackendInterceptor } from 'gung-standard';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenBackendInterceptorService extends BackendInterceptor {
  getBaseUrl(): string {
    return environment.backendUrl;
  }
}
