<h3 translate class="page-title text-center">FAVOURITES</h3>

<div class="d-flex flex-row justify-content-start">
  <button
    *ngIf="favouriteExportAction"
    class="btn btn-primary"
    (click)="exportFavourites()"
    translate
  >{{ favouriteExportAction.label }}</button>
</div>

<lib-filter-list #productList [configService]="favouriteProductListConfig"></lib-filter-list>
