import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationListComponent, FilterListService } from 'gung-list';

@Component({
  selector: 'otto-olsen-pagination-list',
  templateUrl: './otto-olsen-pagination-list.component.html',
  styleUrls: ['./otto-olsen-pagination-list.component.scss']
})
export class OttoOlsenPaginationListComponent<T> extends PaginationListComponent<T> {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    componentFactoryResolver: ComponentFactoryResolver,
    public changeDetectorRef: ChangeDetectorRef,
    filterListService: FilterListService<T>
  ) {
    super(route, router, componentFactoryResolver, changeDetectorRef, filterListService);
  }

  setSearch(searchString: string) {
    const stringArray = [searchString];
    this.terms = stringArray;
    this.refreshRouterQuery();
    this.searchTerms.next(stringArray);
  }
}
