import { Injectable } from '@angular/core';
import { ListLayoutMultipleComponent, ListLayout } from 'gung-list';
import { CartConfigService, CartService, CartRow } from 'gung-standard';
import { OttoOlsenJeevesSalesCartListComponent } from '../components/checkout/otto-olsen-jeeves-sales-cart-list/otto-olsen-jeeves-sales-cart-list.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCartConfigService extends CartConfigService {
  getLayouts(): ListLayout<CartRow>[] {
    return [
      {
        getIconClass: () => '',
        getName: () => 'Cart grid',
        getListItemComponent: () => OttoOlsenJeevesSalesCartListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent
      }
    ];
  }
}
