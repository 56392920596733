import { Component, Input } from '@angular/core';

@Component({
  selector: 'otto-olsen-price-list-display',
  templateUrl: './otto-olsen-price-list-display.component.html',
  styleUrls: ['./otto-olsen-price-list-display.component.scss']
})
export class OttoOlsenPriceListDisplayComponent {

  @Input() price;
  @Input() product;

  public Number = (n) => Number(n);
}
