<ng-template #htmlContent>
  <img *ngIf="selectedImageUrl" [src]="selectedImageUrl" class="tooltip-img" />
</ng-template>

<div class="table-responsive-md gung-responsive" *ngIf="mappedData; else loading">
  <table class="table table-striped" *ngIf="mappedData && mappedData.length > 0">
    <thead class="thead-sticky">
      <tr>
        <th
          class="pointer text-nowrap text-left"
          translate
          (click)="sortColumn(dynamicColumns[dynamicColumns.length - 1])"
        >
          PRODUCT
          <i
            *ngIf="dynamicColumns[dynamicColumns.length - 1].sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': dynamicColumns[dynamicColumns.length - 1].sort === 'asc',
              'fa-sort-amount-up': dynamicColumns[dynamicColumns.length - 1].sort === 'desc'
            }"
          ></i>
        </th>
        <th
          *ngFor="let headerKey of dynamicColumns"
          class="pointer text-center"
          (click)="sortColumn(headerKey)"
          [class.d-none]="!headerKey.isDisplay && !headerKey.isDisplayList && !headerKey.isDisplayGrid"
        >
          {{ headerKey.translationKey | translate }}
          <i
            *ngIf="headerKey.sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': headerKey.sort === 'asc',
              'fa-sort-amount-up': headerKey.sort === 'desc'
            }"
          ></i>
        </th>
        <!-- <th
          *ngIf="!isAnonymous"
          class="text-nowrap text-right pointer"
          (click)="sortColumn(dynamicColumns[dynamicColumns.length - 2])"
          translate
        >
          AVAILABILITY
          <i
            *ngIf="dynamicColumns[dynamicColumns.length - 2].sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': dynamicColumns[dynamicColumns.length - 2].sort === 'asc',
              'fa-sort-amount-up': dynamicColumns[dynamicColumns.length - 2].sort === 'desc'
            }"
          ></i>
        </th> -->
        <th class="text-center text-nowrap pointer"
          (click)="sortColumn(dynamicColumns[dynamicColumns.length - 3])" translate>
          PRICE
          <i
            *ngIf="dynamicColumns[dynamicColumns.length - 3].sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': dynamicColumns[dynamicColumns.length - 3].sort === 'asc',
              'fa-sort-amount-up': dynamicColumns[dynamicColumns.length - 3].sort === 'desc'
            }"
          ></i>
        </th>
        <th class="text-center text-nowrap pointer"
          (click)="sortColumn(dynamicColumns[dynamicColumns.length - 2])" translate>
          AVAILABILITY
          <i
            *ngIf="dynamicColumns[dynamicColumns.length - 2].sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': dynamicColumns[dynamicColumns.length - 2].sort === 'asc',
              'fa-sort-amount-up': dynamicColumns[dynamicColumns.length - 2].sort === 'desc'
            }"
          ></i>
        </th>
        <th *ngIf="!isAnonymous"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData; index as i">
        <td [attr.data-label]="'PRODUCT' | translate">
          <a [routerLink]="['./', item.product?.id]" class="product-link text-nowrap d-inline-block">
            <span class="d-block font-weight-bold text-nowrap">
              {{ item.product?.extra.ar?.artnr || item.product?.id }}
            </span>
            <b
              *ngIf="
                selectedCustomer &&
                item.product.extra._customerCrossReferences &&
                item.product.extra._customerCrossReferences[selectedCustomer.id]
              "
              class="d-block small"
            >
              {{ item.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
            </b>
          </a>
          <a *ngIf="isProductManager && !pimEditUrlHide" class="pim-edit" [href]="productPimUrl + encodeURIComponent(item.product?.id)" target="_blank" (click)="$event.stopPropagation()">
            <i class="fa-regular fa-pen-to-square"></i>
          </a>
          <a [routerLink]="['./', item.product?.id]" class="product-link text-nowrap">
            {{ item.product?.extra.ar?.artbeskr || item.product?.name }}
          </a>
        </td>
        <td
          *ngFor="let value of item.product.dynamicCollumnsValues; let i = index"
          [attr.data-label]="dynamicColumns[i].translationKey | translate"
          class="text-md-center text-nowrap"
          [class.d-none]="
            !dynamicColumns[i].isDisplay && !dynamicColumns[i].isDisplayList && !dynamicColumns[i].isDisplayGrid
          "
        >
          <span *ngIf="tooltipEnabled" placement="right" [ngbTooltip]="htmlContent" tooltipClass="tooltip-container">{{
            value
          }}</span>
          <span *ngIf="!tooltipEnabled">
            <span *ngIf="value?.description">
              {{ value.description }}
            </span>
            <span *ngIf="!value?.description">
              <span *ngIf="dynamicColumns[i].path === 'pim.packageSize'">
                {{ value | number }}
              </span>
              <span *ngIf="dynamicColumns[i].path !== 'pim.packageSize'">
                {{ value }}
              </span>
            </span>
          </span>
        </td>
        <!-- <td *ngIf="!isAnonymous" class="text-right text-nowrap" [attr.data-label]="'AVAILABILITY' | translate">
          <otto-olsen-availability
            [availability]="item.availability"
            [unit]="item.product.extra.ar?.enhetskod"
            [decimals]="item.product?.extra.ar.antdec"
            [productionDays]="
              item.product?.extra.ar?.anskaffningssatt &&
              item.product?.extra.ar?.anskaffningssatt * 1 === 12 &&
              item.product?.extra.ar?.artgenomloppstid
            "
          ></otto-olsen-availability>
        </td> -->
        <td class="" width="1%" [attr.data-label]="'PRICE' | translate">
          <div
            [class.d-none]="!!item.product.extra?.ar?.konfigartnr"
            *ngIf="
              isAnonymous ||
                (!(
                  item.product.extra.pim &&
                  item.product.extra.pim.pricerequest &&
                  item.product.extra.pim.pricerequest !== 'false'
                ) &&
                  !(item.product.extra.ar.itemtypecd1 === 0 || item.product.extra.ar.itemtypecd1 === 7));
            "
          >
            <div *ngIf="!isAnonymous && item.price" class="text-center">
              <otto-olsen-price-list-display [price]="item.price" [product]="item.product"></otto-olsen-price-list-display>
            </div>
          </div>
        </td>
        <td class="" width="1%" [attr.data-label]="'' | translate">
          <div
            *ngIf="
              isAnonymous ||
                (!(
                  item.product.extra.pim &&
                  item.product.extra.pim.pricerequest &&
                  item.product.extra.pim.pricerequest !== 'false'
                ) &&
                  !(item.product.extra.ar.itemtypecd1 === 0 || item.product.extra.ar.itemtypecd1 === 7));
              else priceRequest
            "
          >
            <!-- <span *ngIf="!isAnonymous && item.price" class="d-block text-center">
              <lib-price [price]="item.price" type="GROSS"></lib-price>
              <span *ngIf="item.price.customerDiscountPercent !== 0" [class.blur]="hidePrice">(-{{ item.price.customerDiscountPercent }}%)</span>
              <span
                *ngIf="
                  item.price.customerDiscountPercent === 0 &&
                  Number(item.product.extra.ar.artlistpris) !== Number(item.price.backendPriceLevel.price)
                "
                [class.blur]="hidePrice"
                >({{ 'NET_PRICE' | translate }})</span
              >
            </span> -->
            <otto-olsen-buy-btn
              name="buy-btn-package-size"
              class="height-30 ml-auto"
              style="width: 150px"
              [id]="item.product?.id"
              [minimumOrderQuantity]="item.product?.firstStepAmount || item.product?.packageSize"
              [stepAmount]="item.product?.packageSize"
              [decimals]="item.product?.extra.ar.antdec"
              [originalSize]="item.product?.originalSize"
              [unit]="item.product?.extra.ar?.enhetskod"
              [activeInB2B]="item.product?.extra?.pim?.activeInB2B"
              [product]="item.product"
              [availability]="item.availability"
            ></otto-olsen-buy-btn>
            <div class="text-center" *ngIf="!isAnonymous">
              <otto-olsen-availability
                [availability]="item.availability"
                [unit]="item.product.extra.ar?.enhetskod"
                [decimals]="item.product?.extra.ar.antdec"
                [productionDays]="
                  item.product?.extra.ar?.anskaffningssatt &&
                  item.product?.extra.ar?.anskaffningssatt * 1 === 12 &&
                  item.product?.extra.ar?.artgenomloppstid"
              ></otto-olsen-availability>
            </div>
          </div>
          <ng-template #priceRequest>
            <a
              href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ item.product.id }}"
              target="_blank"
              class="request-price-email"
            >
              <button type="button" class="btn btn-block btn-sm btn-primary ml-auto" style="width: 150px" translate>
                ASK_PRICE
              </button>
            </a>
            <!-- <otto-olsen-favourite-product-icon-only class="favourite-element" (click)="$event.preventDefault(); $event.stopPropagation();" [id]="item.product.id"></otto-olsen-favourite-product-icon-only> -->
          </ng-template>
        </td>
        <td *ngIf="!isAnonymous" width="1%" [attr.data-label]="'FAVOURITES' | translate">
          <otto-olsen-favourite-product-icon-only
            class="favourite-element"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            [id]="item.product.id"
          >
          </otto-olsen-favourite-product-icon-only>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
