import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
  AuthService,
  Customer,
  CustomerService,
  MetadataService,
  Product,
  Supplier,
  SupplierService,
  TableRecord,
  gungTypeaheadSearchStreamFn
} from 'gung-standard';
import { User } from 'gung-standard/lib/state/auth/types';
import { FormUtilService } from 'gung-common';
import { forkJoin, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs';
import { OttoOlsenCreateProductService } from '../../services/otto-olsen-create-product.service';
import { OttoOlsenSupplierArticleService } from '../../services/otto-olsen-supplier-article.service';

type AutoCompleteType = Supplier | Customer | TableRecord;

const roundLeveled = (val: number) =>
  val <= 10 ? val : val <= 500 ? Math.round(val * 10) / 10 : val <= 1000 ? Math.round(val) : Math.round(val / 10) * 10;

@Component({
  selector: 'otto-olsen-create-product',
  templateUrl: './otto-olsen-create-product.component.html',
  styleUrls: ['./otto-olsen-create-product.component.scss']
})
export class OttoOlsenCreateProductComponent implements OnInit {
  @ViewChild('countryElement') countryElement;
  @ViewChild('varugruppKod') varugruppKodElement;
  @ViewChild('artprodklass') artprodklassElement;
  @ViewChild('artrabklass') artrabklassElement;
  @ViewChild('hsKodeElement') hsKodeElement;
  @ViewChildren('searchInput') searchInput;

  constructor(
    protected formBuilder: FormBuilder,
    protected createProductService: OttoOlsenCreateProductService,
    protected authService: AuthService,
    protected supplierService: SupplierService,
    protected customerService: CustomerService,
    protected metadataService: MetadataService,
    protected formUtilService: FormUtilService,
    protected ottoOlsenSupplierArticleService: OttoOlsenSupplierArticleService
  ) {}

  loading = true;
  allowEditListPrice = false;
  isCreatingProduct: boolean;
  productIdAlreadyExists = false;

  listPrice = 0;
  purchasePriceNet = 0;
  markupFactor = 0;
  error: any;
  createdProduct: Product;
  product: Product;
  form: FormGroup;
  public filesImages: File[] = [];
  public filesImagesPreview: any[] = [];

  protected suppliers: Supplier[] = [];
  protected customers: Customer[] = [];
  protected user: User | undefined;
  protected arsn: TableRecord[] = [];
  protected countrys: TableRecord[] = [];
  protected varegruppkods: TableRecord[] = [];
  protected discountGroups: TableRecord[] = [];
  protected xp: TableRecord[] = [];

  public modelSearches: Record<any, any> = {};

  public inPriceFieldChanged: Subject<void> = new Subject();

  public recalcListPris: Subject<void> = new Subject();

  getField = (item: AutoCompleteType) => (item?.id && item?.name ? item?.id + ' - ' + item?.name : (item as any));

  search: Record<string, OperatorFunction<string, readonly AutoCompleteType[]>> = {
    AL_LEVNR: (text$: Observable<string>) => gungTypeaheadSearchStreamFn(text$, this.suppliers.filter(s => s.extra?.le?.leverantorstyp !== 1), this.getField),
      // text$.pipe(
      //   debounceTime(200),
      //   distinctUntilChanged(),
      //   filter((term: string) => term.length >= 2),
      //   map(term => this.suppliers.filter(d => new RegExp(term.replace(/\+/g, "\\+"), 'mi').test(this.getField(d))).slice(0, 10))
      // ),
    AR_FTGNR: (text$: Observable<string>) => gungTypeaheadSearchStreamFn(text$, this.customers, this.getField),
      // text$.pipe(
      //   debounceTime(200),
      //   distinctUntilChanged(),
      //   filter((term: string) => term.length >= 2),
      //   map(term => this.customers.filter(d => new RegExp(term, 'mi').test(this.getField(d))).slice(0, 10))
      // ),
    AR_ARTSTATNR: (text$: Observable<string>) => gungTypeaheadSearchStreamFn(text$, this.arsn, [(d) => d.artstatnr, (d) => d.artstatbeskr]),
      // text$.pipe(
      //   debounceTime(200),
      //   distinctUntilChanged(),
      //   filter((term: string) => term.length >= 2 || term.length == 0),
      //   map(term =>
      //     this.arsn
      //       .filter(d => new RegExp(term, 'mi').test(d.artstatnr) || new RegExp(term, 'mi').test(d.artstatbeskr))
      //       .slice(0, 10)
      //   )
      // ),
    AR_URSPRUNGSLAND: (text$: Observable<string>) => gungTypeaheadSearchStreamFn(text$, this.countrys, [(d) => d.land, (d) => d.landskod]),
      // text$.pipe(
      //   debounceTime(200),
      //   distinctUntilChanged(),
      //   filter((term: string) => term.length >= 2 || term.length == 0),
      //   map(term =>
      //     this.countrys
      //       .filter(d => new RegExp(term, 'mi').test(d.land) || new RegExp(term, 'mi').test(d.landskod))
      //       .slice(0, 10)
      //   )
      // ),
    AR_VARUGRUPPKOD: (text$: Observable<string>) => gungTypeaheadSearchStreamFn(text$, this.varegruppkods.filter(d => d.sprakkod == '7'), [(d) => d.varugruppbeskr, (d) => d.varugruppkod], 0),
      // text$.pipe(
      //   debounceTime(200),
      //   distinctUntilChanged(),
      //   filter((term: string) => term.length >= 2 || term.length == 0),
      //   map(term =>
      //     this.varegruppkods
      //       .filter(d => d.sprakkod == '7')
      //       .filter(d => new RegExp(term, 'mi').test(d.varugruppbeskr) || new RegExp(term, 'mi').test(d.varugruppkod))
      //       .slice(0, 10)
      //   )
      // ),
    AR_ARTRABKLASS: (text$: Observable<string>) => gungTypeaheadSearchStreamFnArArtrabklass(
        text$,
        this.discountGroups.filter(
          d =>
            this.filterArtrabklassOptions(this.form.get(['ar', 'varugruppkod']).value, d) ||
            d.artrabklassbeskr == '998  Nettopriser'
        ),
        [(d) => d.artrabklass, (d) => d.artrabklassbeskr],
        0
      ),
      // text$.pipe(
      //   debounceTime(200),
      //   distinctUntilChanged(),
      //   filter((term: string) => term.length >= 2 || term.length == 0),
      //   map(term =>
      //     this.discountGroups
      //       .filter(
      //         d =>
      //           this.filterArtrabklassOptions(this.form.get(['ar', 'varugruppkod']).value, d) ||
      //           d.artrabklassbeskr == '998  Nettopriser'
      //       )
      //       .filter(d => new RegExp(term, 'mi').test(d.artrabklass) || new RegExp(term, 'mi').test(d.artrabklassbeskr))
      //       .slice(0, 10)
      //   )
      // ),
    AR_ARTPRODKLASS: (text$: Observable<string>) => gungTypeaheadSearchStreamFn(text$, this.xp, [(d) => d.artprodklass, (d) => d.artprodklbeskr], 0),
      // text$.pipe(
      //   debounceTime(200),
      //   distinctUntilChanged(),
      //   filter((term: string) => term.length >= 2 || term.length == 0),
      //   map(term =>
      //     this.xp
      //       .filter(d => new RegExp(term, 'mi').test(d.artprodklass) || new RegExp(term, 'mi').test(d.artprodklbeskr))
      //       .slice(0, 10)
      //   )
      // )
  };

  hsKodeIdFormatter = (item: TableRecord) => (item != null ? item.artstatnr : '');
  hsKodeDisplay = (item: TableRecord) => (item != null ? item.artstatnr + ' - ' + item.artstatbeskr : '');
  hsKodeResultFormatter = (item: TableRecord) => item.artstatnr + ' - ' + item.artstatbeskr;

  idFormatter = (item: AutoCompleteType) => (item != null ? item.id : '');
  display = (item: AutoCompleteType) => (item != null ? this.getField(item) : '');
  resultFormatter = (item: AutoCompleteType) => this.getField(item);

  countryIdFormatter = (item: TableRecord) => (item != null ? item.landskod : '');
  countryDisplay = (item: TableRecord) => (item != null ? item.landskod + ' - ' + item.land : '');
  countryResultFormatter = (item: TableRecord) => item.landskod + ' - ' + item.land;

  varugruppkodIdFormatter = (item: TableRecord) => (item != null ? item.varugruppkod : '');
  varugruppkodDisplay = (item: TableRecord) => (item != null ? item.varugruppkod + ' - ' + item.varugruppbeskr : '');
  varugruppkodResultFormatter = (item: TableRecord) => item.varugruppkod + ' - ' + item.varugruppbeskr;

  artrabklassIdFormatter = (item: TableRecord) => (item != null ? item.artrabklass : '');
  artrabklassDisplay = (item: TableRecord) => (item != null ? item.artrabklass + ' - ' + item.artrabklassbeskr : '');
  artrabklassResultFormatter = (item: TableRecord) => item.artrabklass + ' - ' + item.artrabklassbeskr;

  artprodklassIdFormatter = (item: TableRecord) => (item != null ? item.artprodklass : '');
  artprodklassDisplay = (item: TableRecord) => (item != null ? item.artprodklass + ' - ' + item.artprodklbeskr : '');
  artprodklassResultFormatter = (item: TableRecord) => item.artprodklass + ' - ' + item.artprodklbeskr;

  filterArtrabklassOptions(selectedVg: string, option: TableRecord): boolean {
    if (!selectedVg || !option) {
      return true;
    }
    const artprodkonto = this.metadataService.getMetadataValue('vg', 'artprodkonto', selectedVg);
    return option.q_jis_avd_code == artprodkonto;
  }

  public fieldFormatter(fieldName: string) {
    const formatted = fieldName.toUpperCase().split('.').join('_');
    return formatted;
  }

  ngOnInit(): void {
    forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.supplierService.getSuppliers().pipe(first()),
      this.customerService.getCustomers(),
      of(this.metadataService.getMetadataTableList('arsn')),
      of(this.metadataService.getMetadataTableList('xr')),
      of(this.metadataService.getMetadataTableList('vg')),
      of(this.metadataService.getMetadataTableList('xarb')),
      of(this.metadataService.getMetadataTableList('xp'))
    ]).subscribe(([currentUser, suppliers, customers, arsn, countrys, varegruppkods, discountGroups, xp]) => {
      this.user = currentUser;
      this.suppliers = suppliers;
      this.customers = customers;
      this.arsn = arsn;
      this.countrys = countrys;
      this.varegruppkods = varegruppkods;
      this.discountGroups = discountGroups;
      this.xp = xp;
      this.initForm();
      this.loading = false;
    });
    this.inPriceFieldChanged
      .pipe(
        debounceTime(200),
        map(v => {
          const vbInPrisNetto = this.form.get('al.vb_inpris_netto').value;
          const rabatt1 = this.form.get('al.rabatt1').value;
          const valkod = this.form.get('al.valkod').value;
          const inkprisper = this.form.get('al.inkprisper').value;
          return { vbInPrisNetto, rabatt1, valkod, inkprisper };
        }),
        filter(d => {
          return d.inkprisper && (d.rabatt1 === 0 || d.rabatt1) && d.valkod && d.vbInPrisNetto;
        }),
        map(v => {
          const valkurs = this.metadataService.getMetadataValue('xx', 'valkurs', v.valkod);
          return {
            ...v,
            valkurs
          };
        })
      )
      .subscribe(({ vbInPrisNetto, rabatt1, valkod, inkprisper, valkurs }) => {
        const discountFactor = 1 - rabatt1 / 100;
        const valkursFactor = Number(valkurs);

        const calculatedValue = (vbInPrisNetto * discountFactor * valkursFactor) / inkprisper;
        const roundedValue = calculatedValue.toFixed(2);
        this.form.get('al.artinpris').setValue(roundedValue);
      });
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      description: this.formBuilder.control(''),
      productNotes: this.formBuilder.control(''), // notes
      ar: this.formBuilder.group({
        artnr: this.formBuilder.control(''),
        artbeskr: this.formBuilder.control('', [Validators.maxLength(40), Validators.required, prohibitedCharactersValidator()]),
        extra1: this.formBuilder.control('', [Validators.maxLength(30), Validators.required]),
        varugruppkod: this.formBuilder.control('', [Validators.required]),
        artrabklass: this.formBuilder.control('', [Validators.required]),
        enhetskod: this.formBuilder.control('', [Validators.required]),
        itemtypecd1: this.formBuilder.control('', [Validators.required]),
        ftgnr: this.formBuilder.control(
          { value: '', disabled: true },
          this.formUtilService.validatorRequiredUponCondition(() =>
            ['0', '1', '2', '3'].includes(this.form.get('ar').get('itemtypecd1').value)
          )
        ),
        artstatnr: this.formBuilder.control('', [Validators.required]),
        ursprungsland: this.formBuilder.control('', [Validators.required]),
        artvikt: this.formBuilder.control(undefined, [Validators.required]), // number
        artfsgforp: this.formBuilder.control('', [numberAndCommaValidator()]),
        antdec: this.formBuilder.control(undefined, [Validators.required]), // number
        artprodklass: this.formBuilder.control(''),
        artbeskr2: this.formBuilder.control('', [Validators.maxLength(255)]),
        artbeskrspec: this.formBuilder.control('', [Validators.maxLength(30), prohibitedCharactersValidator()]),
        lagtyp: this.formBuilder.control('0', [Validators.required]),
        qshelflifeindays: this.formBuilder.control(''),
        q_jis_classid: this.formBuilder.control('')
      }),
      al: this.formBuilder.group({
        vb_inpris_netto: this.formBuilder.control(undefined, [Validators.required]), // number
        valkod: this.formBuilder.control({value: '', disabled: true}, [Validators.required]),
        ledtidenllev: this.formBuilder.control('', [Validators.required]),
        transporttid: this.formBuilder.control(''),
        artinpris: this.formBuilder.control(undefined), // number
        inkprisper: this.formBuilder.control('', [Validators.required]),
        rabatt1: this.formBuilder.control(undefined, [Validators.max(100), Validators.required]),
        levsartbeskr: this.formBuilder.control('', [Validators.maxLength(75)]),
        levvarespesifikasjon: this.formBuilder.control('', [Validators.maxLength(255)]),
        artnrlev: this.formBuilder.control('', [Validators.maxLength(30)]),
        levnr: this.formBuilder.control('', [Validators.required]),
        multipelextqty: this.formBuilder.control(undefined, [Validators.required, numberAndCommaValidator()]),
        artlistpris: this.formBuilder.control(undefined, [Validators.required]), // number
        markupFactor: this.formBuilder.control('0', [Validators.required]) // number, not put into jeeves
      }),
      username: ['']
    });
    this.form.get('username').setValue(this.user.username);

    this.form
      .get('ar')
      .get('itemtypecd1')
      .valueChanges.subscribe(value => {
        this.form.get('ar').get('ftgnr').disable();
        if (['0', '1', '2', '3'].includes(value)) {
          this.form.get('ar').get('ftgnr').enable();
        } else {
          this.form.get('ar').get('ftgnr').setValue('');
        }
      });
    this.form
      .get('ar')
      .get('varugruppkod')
      .valueChanges.subscribe(_ => {
        this.form.get('ar').get('artrabklass').setValue('');
        this.modelSearches[this.fieldFormatter('ar.artrabklass')] = null;
      });

    this.form
      .get('ar')
      .get('artrabklass')
      .valueChanges.subscribe(value => {
        let markup;
        if (!!value) {
          markup = this.metadataService.getMetadataValue('xarb', 'q_oo_markupf', value) || '0';
          this.markupFactor = markup;
        } else {
          // Wierd, 0 does not display if not string
          markup = '0';
        }
        this.form.get('al').get('markupFactor').setValue(markup);
        this.recalcListPris.next();
      });

    this.form
      .get('al')
      .get('levnr')
      .valueChanges.subscribe(value => {
        const supplier = this.suppliers.find(s => s.id === value);
        if (!supplier) {
          return;
        }

        this.form.get('al.valkod').setValue(supplier.extra.le.valkod);
      });

    this.form.get('al.artinpris').valueChanges.subscribe(v => {
      this.purchasePriceNet = v;
      this.recalcListPris.next();
    });
    this.form.get('al.artlistpris').valueChanges.subscribe(v => {
      this.listPrice = v;
    });

    this.recalcListPris
      .pipe(
        debounceTime(200),
        map(_ => [this.form.get('al.markupFactor').value, this.form.get('al.artinpris').value])
      )
      .subscribe(([markup, inpris]) => {
        const inprisEmpty = inpris == 0 || !inpris;
        const markupEmpty = markup == 0 || !markup;
        if (inprisEmpty && markupEmpty) {
          this.form.get('al.artlistpris').setValue(undefined);
          return;
        } else if (markupEmpty) {
          this.form.get('al.artlistpris').setValue(roundLeveled(inpris).toFixed(2));
          return;
        }
        const listPris = inpris * markup;

        this.form.get('al.artlistpris').setValue(roundLeveled(listPris).toFixed(2));
      });
  }

  submitForm() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      // FILL_ALL_REQUIRED_FIELDS
      return;
    }
    const formRaw = this.form.getRawValue();

    if (formRaw.ar && formRaw.ar.artfsgforp) {
      formRaw.ar.artfsgforp = formRaw.ar.artfsgforp.replace(/,/g, '.');
    }
    if (formRaw.al && formRaw.al.multipelextqty) {
      formRaw.al.multipelextqty = formRaw.al.multipelextqty.replace(/,/g, '.');
    }

    const postBody = { extra: formRaw } as Product;
    if (!this.isCreatingProduct) {
      this.isCreatingProduct = true;
      this.createProductService.postCreateProduct(postBody).subscribe(
        res => {
          this.isCreatingProduct = false;
          delete this.error;
          this.createdProduct = res;
          this.formUtilService.resetForm(this.form);
          this.allowEditListPrice = false;
          this.productIdAlreadyExists = false;

          this.artrabklassElement.nativeElement.value = '';
          this.countryElement.nativeElement.value = '';
          this.varugruppKodElement.nativeElement.value = '';
          this.artprodklassElement.nativeElement.value = '';
          this.hsKodeElement.nativeElement.value = '';
          this.searchInput.forEach(c => (c.nativeElement.value = ''));
          this.modelSearches = {};
        },
        err => {
          this.isCreatingProduct = false;
          delete this.createdProduct;
          this.error = err;
        }
      );
    }
  }

  isAllowedLagtyp(input: TableRecord): boolean {
    const allowed = new Set<number>([5, 6, 0]);
    return allowed.has(Number(input.lagtyp));
  }

  checkIfSupplierArticleNumberAlreadyExists(): void {
    const supplier = this.form.get(['al', 'levnr']).value;
    const supplierArticleId = this.form.get(['al', 'artnrlev']).value;
    if (!supplier || !supplierArticleId) {
      this.productIdAlreadyExists = false;
      return;
    }

    this.ottoOlsenSupplierArticleService
      .isExistingArticleNumber(supplierArticleId, supplier)
      .pipe(first())
      .subscribe(isExisting => {
        this.productIdAlreadyExists = isExisting;
      });
  }

  selectItem({ item: itemObject, preventDefault }, target, fieldName: string) {
    const item: AutoCompleteType = itemObject;
    const ctrl = this.form.get(fieldName.split('.'));
    const alreadySelected = ctrl.value === this.idFormatter(item);
    if (alreadySelected) {
      return;
    }
    ctrl.setValue(this.idFormatter(item));
  }

  selectHrKodeItem({ item: itemObject, preventDefault }, fieldName: string) {
    const item: TableRecord = itemObject;
    const ctrl = this.form.get(fieldName.split('.'));
    const alreadySelected = ctrl.value === this.hsKodeIdFormatter(item);
    if (alreadySelected) {
      return;
    }
    ctrl.setValue(this.hsKodeIdFormatter(item));
  }

  selectVarugruppkodItem({ item: itemObject, preventDefault }, fieldName: string) {
    const item: TableRecord = itemObject;
    const ctrl = this.form.get(fieldName.split('.'));
    const alreadySelected = ctrl.value === this.varugruppkodIdFormatter(item);
    if (alreadySelected) {
      return;
    }
    ctrl.setValue(this.varugruppkodIdFormatter(item));
  }

  selectArtprodklassItem({ item: itemObject, preventDefault }, fieldName: string) {
    const item: TableRecord = itemObject;
    const ctrl = this.form.get(fieldName.split('.'));
    const alreadySelected = ctrl.value === this.artprodklassIdFormatter(item);
    if (alreadySelected) {
      return;
    }
    ctrl.setValue(this.artprodklassIdFormatter(item));
  }

  selectArtrabklassItem({ item: itemObject, preventDefault }, fieldName: string) {
    const item: TableRecord = itemObject;
    const ctrl = this.form.get(fieldName.split('.'));
    const alreadySelected = ctrl.value === this.artrabklassIdFormatter(item);
    if (alreadySelected) {
      return;
    }
    ctrl.setValue(this.artrabklassIdFormatter(item));
  }

  ngModelChange(itemObject, fieldName: string) {
    const item: AutoCompleteType = itemObject;
    if (!item) {
      const ctrl = this.form.get(fieldName.split('.'));
      ctrl.patchValue(this.idFormatter(item));
    }
  }

  ngModelChangeHrKode(itemObject, fieldName: string) {
    const item: TableRecord = itemObject;
    if (!item) {
      const ctrl = this.form.get(fieldName.split('.'));
      ctrl.patchValue(this.hsKodeIdFormatter(item));
    }
  }

  ngModelChangeVarugruppkod(itemObject, fieldName: string) {
    const item: TableRecord = itemObject;
    if (!item) {
      const ctrl = this.form.get(fieldName.split('.'));
      ctrl.patchValue(this.varugruppkodIdFormatter(item));
    }
  }

  ngModelChangeArtprodklass(itemObject, fieldName: string) {
    const item: TableRecord = itemObject;
    if (!item) {
      const ctrl = this.form.get(fieldName.split('.'));
      ctrl.patchValue(this.artprodklassIdFormatter(item));
    }
  }

  ngModelChangeArtrabklass(itemObject, fieldName: string) {
    const item: TableRecord = itemObject;
    if (!item) {
      const ctrl = this.form.get(fieldName.split('.'));
      ctrl.patchValue(this.artrabklassIdFormatter(item));
    }
  }

  focusout({ target }, modelSearch: AutoCompleteType) {
    if (!modelSearch) {
      target.value = '';
      return;
    }
  }

  openOptions(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  checkIfEmpty(value: string, fieldName: string) {
    const ctrl = this.form.get(fieldName.split('.'));
    if (value.localeCompare('') === 0 && ctrl.value) {
      ctrl.setValue('');
    }
  }

  isDisabled(fieldName: string) {
    const ctrl = this.form.get(fieldName.split('.'));
    return ctrl.disabled;
  }

  selectCountryItem({ item: itemObject, preventDefault }, fieldName: string) {
    const item: TableRecord = itemObject;
    const ctrl = this.form.get(fieldName.split('.'));
    const alreadySelected = ctrl.value === this.countryIdFormatter(item);
    if (alreadySelected) {
      return;
    }
    ctrl.setValue(this.countryIdFormatter(item));
  }

  ngModelChangeCountry(itemObject, fieldName: string) {
    const item: TableRecord = itemObject;
    if (!item) {
      const ctrl = this.form.get(fieldName.split('.'));
      ctrl.patchValue(this.countryIdFormatter(item));
    }
  }

  onKeyUp(keyEvent: any, formField: AbstractControl, maxCharacters: number) {
    if (!maxCharacters) {
      return;
    }
    const input = keyEvent.target;
    const value = input.value as string;
    const text = value.split(/\r?\n/);
    for (let index = 0; index < text.length; index++) {
      const line = text[index];
      if (line.length > 75) {
        text[index] = `${line.substring(0, 75)}`;
        if (text.length > index + 1) {
          text[index + 1] = text[index + 1] + line.substring(75);
        } else {
          text.push(line.substring(75));
        }
      }
    }
    formField.setValue(text.join('\n'));
  }

  public testRegex(regex: any, value: string): boolean {
    return !(new RegExp(/^\S*$/)).test(value);
    return regex?.test(value);
  }
}

export function prohibitedCharactersValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
          return null;
      }

      /*
      Following characters needs to be prohibited:
      ‒ dash, unicode U+2012
      ’ right single quotation mark, unicode U+2019
      ” right double quotation mark, unicode U+201D
      ¼ one quarter fraction, unicode U+00BC
      ½ one half fraction, unicode U+00BD
      ¾ three quarter fraction, unicode U+00BE
      – en dash, unicode U+2013
      — em dash, unicode U+2014
      */
      const hasDash = new RegExp('\u2012').test(value);
      const hasRightSingleQuotationMark = new RegExp('\u2019').test(value);
      const hasRightDoubleQuotationMark = new RegExp('\u201D').test(value);
      const hasOneQuarterFraction = new RegExp('\u00BC').test(value);
      const hasOneHalfFraction = new RegExp('\u00BD').test(value);
      const hasThreeQuarterFraction = new RegExp('\u00BE').test(value);
      const hasEmDash = new RegExp('\u2014').test(value);
      const hasEnDash = new RegExp('\u2013').test(value);

      const prohibitedCharactersValidator = hasDash
        || hasRightSingleQuotationMark
        || hasRightDoubleQuotationMark
        || hasOneQuarterFraction
        || hasOneHalfFraction
        || hasThreeQuarterFraction
        || hasEmDash
        || hasEnDash;

      return prohibitedCharactersValidator ? {
        prohibitedCharactersValidator: {
          hasDash,
          hasRightSingleQuotationMark,
          hasRightDoubleQuotationMark,
          hasOneQuarterFraction,
          hasOneHalfFraction,
          hasThreeQuarterFraction,
          hasEmDash,
          hasEnDash
        }
      }: null;
  }
}

export function numberAndCommaValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const forbidden = /[^0-9,]/.test(control.value);
    if (forbidden) {
      return { numberAndCommaValidator: true };
    }

    const commaCount = (control.value.match(/,/g) || []).length;
    if (commaCount > 1) {
      return { numberAndCommaValidator: true };
    }

    return null;
  };
}

function gungTypeaheadSearchStreamFnArArtrabklass(
  text$: Observable<string>,
  inputList: any[],
  getField: ((a: any) => string) | ((a: any) => string)[] = (s: any) => { return s; },
  minLength = 2,
  maxOutput = 10,
  debounceTimeValue = 200
): Observable<any[]> {

  const fixRegex = (s: string) => {
    return s.replace(/\+/g, "\\+");
  };

  return text$.pipe(
    debounceTime(debounceTimeValue),
    distinctUntilChanged(),
    filter((term: string) => term.length >= minLength),
    map((term: string) => {
      const termRegex = new RegExp(fixRegex(term), 'mi');
     
      const sortByMatch = (a, b) => {
        const aIdMatch = termRegex.test(a.artrabklass.toString());
        const bIdMatch = termRegex.test(b.artrabklass.toString());
        const aNameMatch = termRegex.test(a.artrabklassbeskr);
        const bNameMatch = termRegex.test(b.artrabklassbeskr);

        if (aIdMatch && !bIdMatch) {
          return -1;
        } else if (!aIdMatch && bIdMatch) {
          return 1;
        } else if (aNameMatch && !bNameMatch) {
          return -1;
        } else if (!aNameMatch && bNameMatch) {
          return 1;
        } else {
          return 0;
        }
      };

      const filterFunction = d => {
        if (Array.isArray(getField)) {
          return getField.some(fn => termRegex.test(fn(d)));
        }
        return termRegex.test(getField(d));
      };
    
      const resultList = inputList
        .filter(filterFunction)
        .sort(sortByMatch)
        .slice(0, maxOutput);
    
      return resultList;
    })
  );
}
