<div class="filter-list-item" [hidden]="renderFilter.valueList.length == 0">
  <div
    class="filter-header px-4 py-3"
    [ngClass]="{ isActive: hasSelection, isOpen: renderFilter.active }"
    (click)="renderFilter.active = !renderFilter.active"
    translate
  >
    <i
      class="fa pr-2"
      [ngClass]="{ 'fa-chevron-down': !renderFilter.active, 'fa-chevron-up': renderFilter.active }"
    ></i>
    {{ renderFilter.name }} <span *ngIf="amountSelected > 0">({{ amountSelected }})</span>
  </div>
  <div class="filter-body" [ngbCollapse]="!renderFilter.active" *ngIf="renderFilter.type !== 'ColorConfigBaseFilter'">
    <div class="px-4 pt-3">
      <ul *ngIf="renderFilter.type !== 'RangeConfigBaseFilter' && renderFilter.type !== 'dateRangeFilter'">
        <div class="pb-2" *ngIf="renderFilter.valueList.length > 0 && !renderFilter.disableSearch">
          <lib-filter-list-search (searchUpdated)="setSearch($event)"></lib-filter-list-search>
        </div>
        <div *ngIf="renderFilterValueList == 0">
          <span translate>NO_RESULTS_FOUND</span>
        </div>
        <li
          class="filter-option mb-1 p-0"
          [ngClass]="{ selected: option.selected, 'd-none': !option.valueId || option.hide }"
          *ngFor="let option of renderFilterValueList"
          translate
        >
          <span (click)="option.selected ? deselectValue.emit(option.valueId) : selectValue.emit(option.valueId)">
            <i class="far" [ngClass]="{ 'fa-square': !option.selected, 'fa-check-square': option.selected }"></i>
            {{ option.valueName | translate}} <span *ngIf="!renderFilter.hideItemCounter" class="float-right">{{ option.itemCountAfterFilter }}</span>
            <i *ngIf="renderFilter.type === 'SubSimpleConfigBaseFilter'" class="fa-solid ml-1" [ngClass]="{ 'fa-chevron-down': !option.selected, 'fa-chevron-up': option.selected }"></i>
          </span>
          <ul [ngbCollapse]="!option.selected" (ngbCollapseChange)="option.selected = $event" *ngIf="renderFilter.type === 'SubSimpleConfigBaseFilter'" class="no-bullets" #collapse="ngbCollapse">
            <li *ngFor="let subOption of option.subValueList" class="filter-option" [ngClass]="{ 'd-none': !option.valueId || option.hide }">
              <button
                class="btn top-filter-item w-100 text-left p-0 pr-1"
                (click)="$event.preventDefault(); $event.stopPropagation(); subOption.selected ? deselectSubValue.emit({option: option.valueId, subOption: subOption.valueId}) : selectSubValue.emit({option: option.valueId, subOption: subOption.valueId})"
                translate
              >
                <i class="fa-circle" [ngClass]="{ 'fa-regular': !subOption.selected, 'fa-solid': subOption.selected }"></i>
                {{ subOption.valueName }} <span class="float-right">{{ subOption.itemCountAfterFilter }}</span>
              </button>
            </li>
          </ul>
        </li>
      </ul>
      <!-- date range type filter-->
      <div *ngIf="renderFilter.type === 'dateRangeFilter'">
        <lib-filter-date-range
          [renderFilter]="renderFilter"
          (selectDateRange)="setRangeValues($event)"
          [changeMax]="renderFilter.maxValue"
          [changeMin]="renderFilter.minValue"
        ></lib-filter-date-range>
      </div>
      <div *ngIf="renderFilter.type === 'RangeConfigBaseFilter' && maxRange">
        <div class="row">
          <div class="col-12">
            <lib-range-slider
              (selectedRangeOutput)="selectedRangeChanged($event)"
              [step]="0.01"
              [maxRange]="maxRange"
              [selectedRange]="maxValue && minValue ? { max: maxValue, min: minValue } : undefined"
            ></lib-range-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-body" [ngbCollapse]="!renderFilter.active" *ngIf="renderFilter.type === 'ColorConfigBaseFilter'">
    <div class="px-4 pt-3">
      <ul class="filter-colors">
        <li
          class="filter-option mb-1 p-0"
          [ngClass]="{ selected: option.selected, 'd-none': !option.valueId || option.hide }"
          [attr.color]="option.valueId"
          *ngFor="let option of renderFilterValueList"
          title="{{ option.valueName }}"
          [ngStyle]="{ 'background-color': option.valueId | lowercase }"
          (click)="option.selected ? deselectValue.emit(option.valueId) : selectValue.emit(option.valueId)"
          translate
        ></li>
      </ul>
    </div>
  </div>
</div>
