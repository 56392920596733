import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from 'gung-standard';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCreateProductService {
  private baseUrl = 'json/otto-olsen/create-product'; // not confirmed

  constructor(protected http: HttpClient) {}

  postCreateProduct(product: Product): Observable<Product> {
    const url = `${this.baseUrl}`;

    return this.http.post<Product>(url, product);
  }
}
