import { Component } from '@angular/core';
import { ProductTreeListComponent, ProductTreeListConfigService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-product-tree-list',
  template: '<otto-olsen-filter-list #productList [configService]="configService"></otto-olsen-filter-list>',
  styleUrls: ['./otto-olsen-product-tree-list.component.scss']
})
export class OttoOlsenProductTreeListComponent extends ProductTreeListComponent {
  constructor(public configService: ProductTreeListConfigService) {
    super(configService);
  }
}
