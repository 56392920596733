<!-- <div class="modal-header" *ngIf="!!availability">
  <h4 class="modal-title" id="modal-basic-title">
    <span translate>FUTURE_AVAILABILITY</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->

<div class="modal-body">
  <h4 class="pl-4" style="text-transform: none;">Detaljert lagerstatus hovedlager for:</h4>
  <h6 class="pl-4 font-weight-normal mt-0">{{product?.id}} - {{product?.name}}</h6>
  <p class="h6 pl-5">
    <span translate>STOCK_STATUS_LABEL</span>&nbsp;<span class="font-weight-normal">(<span translate>CHANGES_MAY_OCCUR</span>)</span>
  </p>
  <table class="table table-borderless">
    <thead class="">
      <tr>
        <th scope="col" width="150px"></th>
        <th scope="col" width="51px"></th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let availability of availabilities; let isFirst = first; let isLast = last">
        <td class="text-right">
          <span [class.font-weight-bold]="isFirst" translate>
            {{ availability.info }}
          </span>
        </td>
        <td class="td-circle">
          <div class="container-cicrle">
            @if(availabilities.length > 1){
              <div class="line" [class.line-first]="isFirst" [class.line-last]="isLast"></div>
            }
            <div class="text-center h-100 cicle">
              <i class="fa-solid fa-circle {{availability.class}}"></i>
            </div>
          </div>
        </td>
        <td>
          <div class="row">
            <div class="col-auto pr-0">
              {{ availability.date | date }}
            </div>
            <div class="col-auto p-0">
              &nbsp;-&nbsp;
            </div>
            <div class="col pl-0">
              <ng-container *ngIf="availability.stock > 0 && availability.stock < 100000; else otherStock">
                <b>{{availability.stock}} <span translate>PCS</span></b>&nbsp;<span *ngIf="availability.info !== 'TODAY'" translate>ON_STOCK</span>
              </ng-container>
              
              <ng-template #otherStock>
                {{ availability.stock === 100000 ? ('STOCK_REPLENISHMENT' | translate) : ('NO_AVAILABILITY' | translate) }}
                @if(isLast) {
                  <span class="d-block small" translate>AVAILABILITY_CONTACT_US</span>
                }
              </ng-template>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer"></div>
