import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthService,
  PriceService,
  AvailabilityService,
  SelectedCustomerService,
  MetadataService,
  DeliveryDateService,
  SalesCartConfigService,
  CartConfigService,
  CartService
} from 'gung-standard';
import { OttoCheckoutStateServiceService } from '../../../../services/utils/otto-checkout-state-service.service';
import { OttoOlsenFreightCostService } from '../../../../services/otto-olsen-freight-cost.service';
import {
  getMinDate,
  OttoOlsenOrderTermsJeevesComponent
} from '../otto-olsen-order-terms-jeeves/otto-olsen-order-terms-jeeves.component';
import { first, map } from 'rxjs';
import { parse } from 'date-fns';

@Component({
  selector: 'otto-olsen-order-terms-jeeves-with-cart-list',
  templateUrl: './otto-olsen-order-terms-jeeves-with-cart-list.component.html',
  styleUrls: ['./otto-olsen-order-terms-jeeves-with-cart-list.component.scss']
})
export class OttoOlsenOrderTermsJeevesWithCartListComponent
  extends OttoOlsenOrderTermsJeevesComponent
  implements OnInit
{
  constructor(
    protected cartService: CartService,
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected datePipe: DatePipe,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    selectedCustomerService: SelectedCustomerService,
    metadataService: MetadataService,
    protected deliveryDateService: DeliveryDateService,
    freightCostService: OttoOlsenFreightCostService,
    public salesCartConfigService: SalesCartConfigService,
    public cartConfigService: CartConfigService,
    protected ottoCheckoutState: OttoCheckoutStateServiceService
  ) {
    super(
      cartService,
      authService,
      formBuilder,
      datePipe,
      priceService,
      availabilityService,
      selectedCustomerService,
      metadataService,
      deliveryDateService,
      freightCostService,
      ottoCheckoutState
    );
  }

  ngOnInit() {
    this.removeExtraProductsFromCart();
    super.ngOnInit();
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      dellevtillaten: this.formBuilder.control(this.checkout.extra.oh.dellevtillaten || '0'),
      levsattkod: this.formBuilder.control('' + this.checkout.extra.procargs.levsattkod || '', [Validators.required]),
      levvillkkod: this.formBuilder.control({
        value: '' + this.checkout.extra.procargs.levvillkkod || '',
        disabled: this.isUser
      }),
      betkod: this.formBuilder.control(this.checkout.extra.oh.betkod || ''),
      saljare: this.formBuilder.control(this.checkout.extra.procargs.saljare || ''),
      ordstat: this.formBuilder.control(this.checkout.extra.oh.ordstat || 13)
    });

    if (this.form.get('levsattkod').value) {
      // this.onDeliveryMethodChanged(this.checkout.extra.procargs.levsattkod);
      this.ottoCheckoutState.selectedDeliveryMethod = this.form.get('levsattkod').value;
      this.initDeliveryDatesBasedOnDeliveryMethod();
    }
  }

  protected assignFormValues(formGroup: FormGroup) {
    super.assignFormValues(formGroup);
    const rawValues = formGroup.getRawValue();
    this.checkout.extra = {
      ...this.checkout.extra,
      oh: {
        ...this.checkout.extra.oh,
        ordstat: rawValues.ordstat
      }
    };

    if (this.checkout.extra.oh.ordstat + '' === '0') {
      this.checkout.extra.procargs.ordtyp = '301';
    } else {
      this.checkout.extra.procargs.ordtyp = '1';
    }
  }


assignRowDates(): void {
    this.availabilityService
      .getAvailabilities(
        this.cartRows.map(r => r.productId),
        undefined,
        true
      )
      .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
      .subscribe(avs => {
        for (const row of this.cartRows) {
          const av = avs.find(a => a.productId === row.productId);
          // delete row.extra._availability_reference;
          // row.extra._availability_reference = Object.assign([], row.extra._availability_reference);
          // row.extra = Object.assign([], row.extra);
          // row.extra._availability_reference = Object.assign({}, av);
          row.extra._availability_reference = av;
          let mindate = null;
          if (av) {
            const tempDate = getMinDate(av, row.qty);
            const nowDate = new Date();
            let minDate = nowDate;
            if (minDate < tempDate) {
              minDate = tempDate;
            }

            for (const testDate of this.dates) {
              const dString11: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
              const dString22: string = this.datePipe.transform(testDate.date, 'yyyy-MM-dd');

              // We cant include time when comparing
              if (dString22 >= dString11) {
                if (testDate.valid) {
                  minDate = testDate.date;
                  break;
                }
              }
            }

            // If now date is today and q_jis_cutoff_hour of delivery method is already past
            const dString1: string = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
            const dString2: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');

            if (this.pushDeliveryDate && dString1 === dString2) {
              const dStringBefore: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
              minDate.setDate(minDate.getDate() + 1);
              const dStringAfter: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
              // minDate = this.setMinDate();
            }

            const dString: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
            mindate = dString;
          }

          row.extra._calc_min_date = mindate;
          row.extra.orp = row.extra.orp || {};
          row.extra.orp.ordberlevdat = row.extra._calc_min_date;
          if (row.extra._user_selected_ordberlevdat) {
            if (new Date(row.extra._user_selected_ordberlevdat) > new Date(row.extra._calc_min_date)) {
              // row.extra.orp.ordberlevdat = row.extra._user_selected_ordberlevdat;
              // TODO: Check if the selected date is in the available dates in this delivery method
              // this.deliveryDateService.checkIfDateInDeliveryDates(new Date(row.extra._user_selected_ordberlevdat));
            }
          }

          const deliveryPlan = {};
          const dates = Object.keys(av.availabilities);
          let quantityRemaining = row.qty;
          for (const date of dates) {
            const qty = av.availabilities[date];
            if (qty > 0 && quantityRemaining > 0) {
              const parsedDate = parse(date, 'yyMMdd', new Date());
              const formattedDate = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');

              let assignedDate = formattedDate;
              if (new Date(row.extra._user_selected_ordberlevdat) > new Date(assignedDate)) {
                assignedDate = row.extra._user_selected_ordberlevdat;
              }
              const currentOnDate = deliveryPlan[assignedDate] || 0;
              const currentAllocation = Math.min(qty, quantityRemaining);

              deliveryPlan[assignedDate] = currentOnDate + currentAllocation;

              quantityRemaining = quantityRemaining - currentAllocation;
            }
          }
          if (quantityRemaining > 0) {
            // Handle edge case of last quantities of expiring product
            deliveryPlan['2099-12-31'] = quantityRemaining;
          }
          row.extra._preliminaryDeliveryPlan = deliveryPlan;
        }

        for (const row of this.cartRows) {
          this.cartService.removeExtraField(
            row.productId,
            'dummy_element_just_to_trigger',
            row.targetStockId,
            row.productPartialId
          );
          break;
        }

        this.loadingComponent = false;
      });
  }

  removeExtraProductsFromCart() {
    if (!this.checkout.extra._extraRows) {
      return;
    }

    this.cartService
      .getCurrentCart()
      .pipe(first())
      .subscribe(cartRows => {
        for (const row of this.checkout.extra._extraRows) {
          const cartRow = cartRows.find(cr => cr.productId === row.id);
          if (cartRow) {
            this.cartService.removeRow(cartRow);
          }
        }
        delete this.checkout.extra._extraRows;
      });
  }}
