<div class="barcode-wrapper">
  <div class="component-barcode-scanner">
    <ngx-scanner-qrcode #action="scanner" [config]="scannerConfig" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
  </div>
  <div class="scanner-toolbar">
    <i *ngFor="let item of toolbarItems" [ngClass]="item.icon" (click)="item.itemAction()"></i>
  </div>
  <div *ngIf="action.isLoading" class="loading-icon-wrapper">
    <i class="fa fa-spinner fa-spin fa-5x"></i>
  </div>
</div>
