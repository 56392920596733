import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JeevesOrderListConfigService } from 'gung-standard-jeeves';
import {
  OrderService,
  SelectedCustomerService,
  MetadataService,
  CustomerService,
  Order,
  AuthService
} from 'gung-standard';
import { first, map } from 'rxjs';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { OttoOlsenOrderCardListComponent } from '../components/otto-olsen-order-card-list/otto-olsen-order-card-list.component';
import { OttoOlsenOrderMobileCardListComponent } from '../components/otto-olsen-order-mobile-card-list/otto-olsen-order-mobile-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenOrderListConfigService extends JeevesOrderListConfigService {
  isScannerOnly = false;

  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    private authService: AuthService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService);
    this.authService
      .getHasRoles('SCANNER')
      .pipe(first())
      .subscribe(isScannerOnly => (this.isScannerOnly = isScannerOnly));
  }

  getItems() {
    return super.getItems().pipe(
      map(orders => {
        return orders.filter(o => o.extra.oh.ordstat !== 90 && o.extra.oh.ordstat !== 0); // Hide Canceled orders
      }),
      map(orders => orders.filter(order => order?.extra?.oh?.salestype !== '1' && order?.extra?.status !== 'ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS'))
    );
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        ...super.getLayouts()[0],
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getListItemComponent: () =>
          this.isScannerOnly ? OttoOlsenOrderMobileCardListComponent : OttoOlsenOrderCardListComponent
      }
    ];
  }

  getSearchTerms(item: Order): string[] {
    const terms = super.getSearchTerms(item);

    if (item?.extra?.oh?.kundbestnr  ) {
      terms.push(item.extra.oh.kundbestnr + '');
    }
    if (item?.extra?.oh?.kundbetalarenr  ) {
      terms.push(item.extra.oh.kundbetalarenr);
    }
    if (item?.extra?.oh?.kundref2 ) {
      terms.push(item.extra.oh.kundref2);
    }
    
    return terms;
  }
  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }
}
