import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssortmentTreeListViewComponent, AuthService } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { Subject, first, takeUntil } from 'rxjs';
import { OttoOlsenPimEditConfigService } from '../../services/otto-olsen-pim-edit-config.service';

@Component({
  selector: 'otto-olsen-assortment-tree-list-view',
  templateUrl: './otto-olsen-assortment-tree-list-view.component.html',
  styleUrls: ['./otto-olsen-assortment-tree-list-view.component.scss']
})
export class OttoOlsenAssortmentTreeListViewComponent extends AssortmentTreeListViewComponent implements OnInit, OnChanges, OnDestroy {
  assortmentPimUrl: string = environment.externalPimUrl + 'assortment/';
  isAssortmentManager: boolean = false;
  isProductManager: boolean = false;
  pimEditUrlHide: boolean = false;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private authService: AuthService,
    protected translateService: TranslateService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService
  ) {
    super(translateService);
  }

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(userRoles => {
      this.isAssortmentManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'assortments');
      this.isProductManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'products-concepts');
    });
    this.ottoOlsenPimEditConfigService.pimEditUrlHide.pipe(takeUntil(this.unsubscribe)).subscribe(pimEditUrlHide => (this.pimEditUrlHide = pimEditUrlHide));
    super.ngOnInit();
  }

  ngOnChanges() {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        let seeAllAssortments = false;
        if (user.roles.includes('SALES') || user.roles.includes('ADMIN')) {
          seeAllAssortments = true;
        }
        if (!seeAllAssortments) {
          const temp = this.data.filter(assortment => assortment.extra?.pim?.activeInB2B === 'true');
          this.data = [...temp];
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
