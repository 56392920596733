import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent, SearchRequest, SearchResult } from 'gung-list';
import {
  ProductPaginationListConfigService,
  ProductService,
  AuthService,
  Product,
  AssortmentService,
  SelectedCustomerService
} from 'gung-standard';
import { forkJoin, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs';
import { OttoOlsenProductPaginationListCardComponent } from '../components/otto-olsen-product-pagination-list-card/otto-olsen-product-pagination-list-card.component';

interface OttoOlsenSearchRequest extends SearchRequest {
  additionalAssortments?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductPaginationListConfigService extends ProductPaginationListConfigService {
  public globalSearch; // = 'sINTERNAL_SEARCH';

  constructor(
    protected productService: ProductService,
    authService: AuthService,
    private assortmentService: AssortmentService,
    private selectedCustomerService: SelectedCustomerService
  ) {
    super(productService, authService);
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenProductPaginationListCardComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getPagedItems(request: SearchRequest): Observable<SearchResult<Product>> {
    const terms = [...request.terms];
    // request.terms.forEach(term => {
    //   terms.push(term.trim().replace(/[^\w]/gi, ''));
    // });
    request.terms = terms;
    return forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(
        filter(result => !!result),
        first()
      ),
      this.authService.getCurrentUser().pipe(
        filter(result => !!result),
        first()
      )
    ]).pipe(
      map(([customer, user]) => {
        const searchRequest: OttoOlsenSearchRequest = {
          ...request,
          assortment: this.globalSearch || user.assortment || 's0'
        };
        if (customer.extra._customerSpecAssortment) {
          searchRequest.additionalAssortments = [customer.extra._customerSpecAssortment];
        }
        (searchRequest as any).customerId = customer.id;
        return searchRequest;
      }),
      switchMap(searchRequest => this.productService.getPagedProducts(searchRequest))
    );
  }

  getSearchButtonEnabled(): boolean {
    return true;
  }

  getSearchButtonText(): string {
    return 'SEARCH';
  }
}
