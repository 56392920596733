import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CartKeyService, CartService, CheckoutConfigService, CheckoutWrapperComponent, GungModalService, OrderSubmitService } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'otto-olsen-checkout-wrapper',
  templateUrl: './otto-olsen-checkout-wrapper.component.html',
  styleUrls: ['./otto-olsen-checkout-wrapper.component.scss']
})
export class OttoOlsenCheckoutWrapperComponent extends CheckoutWrapperComponent {

  isDemoAccount: boolean = false;

  constructor(
    protected orderSubmitService: OrderSubmitService,
    protected checkoutConfig: CheckoutConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cartService: CartService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected gungModalService: GungModalService,
    protected cartKeyService: CartKeyService,
    protected authService: AuthService,
    protected router: Router

  ) {
    super(
      orderSubmitService,
      checkoutConfig,
      componentFactoryResolver,
      cartService,
      changeDetectorRef,
      gungModalService,
      cartKeyService
    );

    this.authService.getRoles().subscribe(roles => {
      this.isDemoAccount = roles.indexOf('DEMO') > -1;
    })
  }

  createOffer(){
    if (this.isDemoAccount) {
      this.demoSubmitOrder();
      return;
    }
    super.createOffer();
  }

  protected submitOrder() {
    if (this.isDemoAccount) {
      this.demoSubmitOrder();
      return;
    }
    super.submitOrder();
  }

  demoSubmitOrder() {
    this.cartKeyService.getCurrentCartKey().pipe(first()).subscribe(key => {
      localStorage.removeItem(key);
      this.cartService.clearCart();
      this.router.navigate(['/demo/checkout/']);
    });
  }
}
