import { Injectable } from '@angular/core';
import { GungImageOption, GungImageUrlService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenImageUrlService extends GungImageUrlService {
  defaultHighDefinitionOptions: GungImageOption = {
    format: this.defaultFormat,
    size: '1000x1000',
    sizePortrait: '1000x1330',
    quality: '95',
    fill:'white',
    background_color:'white'
  }
}
