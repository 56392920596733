import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  BeepService,
  ProductService,
  AuthService,
  GungFlowService,
  CartService,
  EanNumbersService,
  BarcodeScannerConfigService,
  BarcodeScannerComponent,
  ProductInputQuantityConfigService,
  BaseViewConfigService,
  SelectedCustomerService,
  Customer,
  Product
} from 'gung-standard';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { first } from 'rxjs';
import { OttoOlsenScannerOptionsModalComponent } from './otto-olsen-scanner-options-modal/otto-olsen-scanner-options-modal.component';
import { OttoOlsenBaseViewConfigService } from '../../services/otto-olsen-base-view-config.service';
import {
  OttoOlsenBarcodeSearchResult,
  OttoOlsenBarcodeSearchService
} from '../../services/otto-olsen-barcode-search.service';

@Component({
  selector: 'otto-olsen-barcode-scanner',
  templateUrl: './otto-olsen-barcode-scanner.component.html',
  styleUrls: ['./otto-olsen-barcode-scanner.component.scss']
})
export class OttoOlsenBarcodeScannerComponent extends BarcodeScannerComponent implements OnInit, OnDestroy {
  barcodePicker;
  public modalRef: NgbModalRef;
  customer: Customer;

  timeReadAgain = 1500;
  addSameProduct = false;
  cartProductsIds: string[] = [];
  unsubscribe: Subject<void> = new Subject();

  selectedFreeScanner: boolean = false;
  freeScannerComponent = this.baseViewConfigService.getFreeScannerComponent();
  isAdmin: boolean = false;
  isScannerBetaGroup: boolean = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    beepService: BeepService,
    protected productsService: ProductService,
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected cartService: CartService,
    protected eanNumbersService: EanNumbersService,
    protected barcodeScannerConfigService: BarcodeScannerConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected router: Router,
    protected baseViewConfigService: OttoOlsenBaseViewConfigService,
    protected modalService: NgbModal,
    protected selectedCustomerService: SelectedCustomerService,
    protected ottoOlsenBarcodeSearchService: OttoOlsenBarcodeSearchService
  ) {
    super(
      changeDetectorRef,
      beepService,
      productsService,
      authService,
      gungFlowService,
      cartService,
      eanNumbersService,
      componentFactoryResolver,
      productInputQuantityConfigService,
      baseViewConfigService
    );
  }

  ngOnInit() {
    const scansettings = JSON.parse(localStorage.getItem('scannerSettings'));
    if (scansettings) {
      this.timeReadAgain = Number(scansettings.timeReadAgain);
      this.addSameProduct = scansettings.addSameProduct;
    }
    forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first()),
      this.authService.getRoles().pipe(first())
    ]).subscribe(([user, flow, roles]) => {
      this.stockIds = user.managedMultistockIds;
      this.initProductsInCart = true;

      this.isAdmin = roles.findIndex(r => r === 'ADMIN') > -1;
      this.isScannerBetaGroup = !!user.activeGroups['SCANNER-BETA'];
    });
    this.cartService
      .getCurrentCart()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(cartProducts => {
        this.cartProductsIds = cartProducts.map(p => p.productId);
      });

    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(customer => (this.customer = customer));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  findProductByEan(code, now) {
    if (!this.gettingEan) {
      this.gettingEan = true;

      forkJoin([
        this.ottoOlsenBarcodeSearchService.search(code).pipe(first()),
        this.eanNumbersService.getEanNumber(code).pipe(first())
      ]).subscribe(([ottoBarcodeResults, eanRepositoryResults]) => {
        let productId = undefined;
        if (!!ottoBarcodeResults) {
          productId = ottoBarcodeResults.productId;
        } else if (!!eanRepositoryResults && !!eanRepositoryResults[0]) {
          productId = eanRepositoryResults[0].sku;
        }

        if (productId && !this.addSameProduct && this.cartProductsIds.includes(productId)) {
          this.notFound = false;
          this.findResult = false;
        } else if (productId) {
          this.productsService
            .getProduct(productId)
            .pipe(first())
            .subscribe(productToAdd => {
              if (productToAdd) {
                this.notFound = false;
                this.findResult = true;
                setTimeout(() => {
                  this.findResult = false;
                }, 1000);

                this.beepService.beep();

                const qtyToAdd = this.getQuantityToAdd(productToAdd, ottoBarcodeResults);
                let extra;
                if (ottoBarcodeResults?.productId && ottoBarcodeResults.data.q_oo_customer_location ) {
                  extra = {
                    orp: {
                      editext: ottoBarcodeResults.data.q_oo_customer_location // customer location code
                    }
                  }
                }
                this.cartService.addToCart(productToAdd.id, qtyToAdd, undefined, undefined, undefined, extra, undefined, productToAdd.name);
              }
            });
        } else {
          this.notFound = true;
          this.notFoundCode = code;
        }
        this.barcodeScannerConfigService.pauseScanning(this.barcodePicker);
        this.gettingEan = false;
      });
    }
  }

  private getQuantityToAdd(product: Product, barcodeSearchResult: OttoOlsenBarcodeSearchResult): number {
    // Default step quantity
    let qty = this.productInputQuantityConfigService.getStepAmount(product.id, product);

    // If we have a kuar
    const customer_kuar = product.extra.kuar.filter(kuar => kuar?.ftgnr === this.customer.extra?.fr?.ftgnr)[0] || null;
    if (customer_kuar !== null && customer_kuar.q_oo_normordant && Number(customer_kuar.q_oo_normordant) !== 0) {
      qty = Number(customer_kuar.q_oo_normordant);
    }

    // If we have a result from barcodeSearch
    if (
      !!barcodeSearchResult &&
      barcodeSearchResult.data.q_oo_normordant &&
      Number(barcodeSearchResult.data.q_oo_normordant) !== 0
    ) {
      qty = Number(barcodeSearchResult.data.q_oo_normordant);
    }

    return qty;
  }

  onBarcodeScanned(code) {
    this.lastTenCodes.push(code);
    if (this.lastTenCodes.length < 2) {
      return;
    } else {
      this.lastTenCodes.shift();
    }
    // ignore duplicates for an interval of 1.5 seconds
    const now = new Date().getTime();
    if (!this.allAreEqual(this.lastTenCodes)) {
      return;
    }
    if (
      code === this.lastScannedCode &&
      this.lastScannedCodeDate !== undefined &&
      now < this.lastScannedCodeDate + this.timeReadAgain
    ) {
      return;
    }
    this.lastScannedCode = code;
    this.lastScannedCodeDate = now;
    this.findProductByEan(code, now);
    this.changeDetectorRef.detectChanges();
  }

  openScannerConfigs() {
    this.modalRef = this.modalService.open(OttoOlsenScannerOptionsModalComponent, {
      size: 'md',
      backdrop: 'static'
    });

    this.modalRef.componentInstance.timeReadAgain = this.timeReadAgain;
    this.modalRef.componentInstance.addSameProduct = this.addSameProduct;
    this.modalRef.componentInstance.delegate = this;
    this.modalRef.result.then(result => {
      localStorage.setItem('scannerSettings', JSON.stringify(result));
      this.timeReadAgain = Number(result.timeReadAgain);
      this.addSameProduct = result.addSameProduct;
    });
  }

  switchScanner(input: boolean) {
    this.selectedFreeScanner = input;
  }
}
