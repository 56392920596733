import { Component } from '@angular/core';

@Component({
  selector: 'otto-olsen-scanner-footer-menu',
  templateUrl: './otto-olsen-scanner-footer-menu.component.html',
  styleUrls: ['./otto-olsen-scanner-footer-menu.component.scss']
})
export class OttoOlsenScannerFooterMenuComponent {

}
