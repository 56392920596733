import { Injectable } from '@angular/core';
import { ConfigBaseFilter } from 'gung-list/public-api';
import { Cart, CartsService, CreationDateFilterService, CustomerService, SavedCart } from 'gung-standard';
import { BehaviorSubject, first, forkJoin, map, mergeMap, Observable, of, Subject, switchMap } from 'rxjs';
import { OttoOlsenSavedCartConfigService } from './otto-olsen-saved-cart-config.service';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenSavedCartDetailConfigService extends OttoOlsenSavedCartConfigService {

  searchDisabled = true;
  cart: Subject<string> = new BehaviorSubject<string>('');

  constructor(
    protected cartsService: CartsService,
    protected creationDateFilterService: CreationDateFilterService,
    protected customerService: CustomerService
  ) {
    super(cartsService, creationDateFilterService, customerService);
  }

  getItems(): Observable<SavedCart[]> {
    return this.cart.pipe(
      switchMap((cart) => {
        return this.cartsService.getCart(cart).pipe(first())
      }),
      map((cart) => {
        const carts = [];
        carts.push(cart);
        return carts;
      }),
      mergeMap(carts => {
        const customers = {carts: of(carts)};
        for (const cart of carts) {
          if (cart.customerId) {
            customers[cart.customerId] = this.customerService.getCustomer(cart.customerId).pipe(first())
          }
        }
        return forkJoin(customers);
      }),
      switchMap(({carts, ...customers}) => {
        for (const cart of carts) {
          if (cart.customerId && customers[cart.customerId]) {
            cart.extra.customer = customers[cart.customerId];
          }
        }
        return of(carts);
      })
    )
  }

  getFilters(): ConfigBaseFilter<Cart>[] {
    return [];
  }


}
