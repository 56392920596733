<nav class="navbar gung-navbar navbar-expand-md d-block" *ngIf="!isScannerOnly || currentMode === 'B2B'; else mobileMenu">
  <div class="container d-block">
    <div class="d-flex justify-content-center align-items-center navbar-top">
      <div class="logo d-flex flex-1">
        <div class="nav-brand-wrapper d-flex justify-content-center">
          <a class="navbar-brand" routerLink="/">
            <img src="./assets/logo.png" width="auto" height="28px" alt="Logotype" />
          </a>
        </div>
      </div>
      <div class="d-flex contacts">
        <div>
          <a href="https://oo.no/" target="_blank">
            <i class="d-none d-lg-inline fas fa-home fa-2x"></i>
            <i class="d-none d-sm-inline d-lg-none fas fa-home"></i>
            <span>Otto Olsen</span>
          </a>
        </div>
        <div>
          <i class="d-none d-lg-inline fas fa-headset fa-2x"></i>
          <i class="d-none d-sm-inline d-lg-none fas fa-headset"></i>
          <span>{{ contactMobile }}</span>
        </div>
        <div>
          <a href="mailto:{{ contactEmail }}">
            <i class="d-none d-lg-inline fas fa-envelope fa-2x"></i>
            <i class="d-none d-sm-inline d-lg-none fas fa-envelope"></i>
            <span>{{ contactEmail }}</span>
          </a>
        </div>
      </div>
      <div *ngIf="!isAnonymous" class="d-flex justify-content-end flex-1">
        <div *ngIf="authenticated" class="cart-menu-wrapper d-flex">
          <a class="px-0 side-menu-icon" (click)="sidenavToggleEvent()">
            <i class="fas fa-bars"></i>
          </a>
          <otto-olsen-navbarcart *ngIf="isCartVisible && !isViewOnly"></otto-olsen-navbarcart>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center menu-links" *ngIf="!hideMenuRoutes.includes(router.url.split('/')[1])">
      <div class="left-menu d-flex mr-auto justify-content-center align-items-center">
        <div class="mr-auto">
          <ul class="navbar-nav" *ngIf="leftMenus">
            <li class="nav-item" *ngFor="let leftMenuLink of leftMenus">
              <div class="nav-item nav-link">
                <a
                  class="px-0 menu-link"
                  (click)="leftMenuLink.links ? toggleTopMenu() : undefined"
                  [routerLink]="!leftMenuLink.links && leftMenuLink.link ? leftMenuLink.link : undefined"
                >
                  <i *ngIf="leftMenuLink.icon" class="fa {{ leftMenuLink.icon }} mr-2"></i>
                  <span translate>{{ leftMenuLink?.heading }}</span>
                  <i *ngIf="leftMenuLink.links && !isTopMenuExpanded" class="fas fa-angle-down pl-2"></i>
                  <i *ngIf="leftMenuLink.links && isTopMenuExpanded" class="fas fa-angle-up pl-2"></i>
                </a>
                <div
                  id="gung-mega-top-menu"
                  *ngIf="leftMenuLink.links"
                  class="gung-mega-menu gung-mega-top-menu-collapsed"
                >
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-12 col-sm-6 col-md-3 col-lg-2 link-block mb-2"
                        *ngFor="let linkGroup of leftMenuLink.links; index as topMainMenuIndex"
                      >
                        <h6 class="text-uppercase">
                          <a
                            [routerLink]="linkGroup.link ? linkGroup.link : undefined"
                            [routerLinkActive]="linkGroup.link ? 'active' : ''"
                            (click)="
                              linkGroup.action ? linkGroup.action() : undefined;
                              selectedTopMainMenuIndex = topMainMenuIndex
                            "
                          >
                            <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
                            <span translate>{{ linkGroup.heading }}</span></a
                          >
                        </h6>

                        <ul
                          *ngIf="linkGroup.links"
                          [ngClass]="{
                            'gung-mega-top-main-menu-expanded':
                              isSmallScreen && selectedTopMainMenuIndex === topMainMenuIndex,
                            'gung-mega-top-main-menu-collapsed':
                              isSmallScreen && selectedTopMainMenuIndex !== topMainMenuIndex
                          }"
                        >
                          <li *ngFor="let childLink of linkGroup.links.slice(0, 7)">
                            <a
                              [routerLink]="childLink.link ? childLink.link : undefined"
                              [routerLinkActive]="childLink.link ? 'active' : ''"
                              (click)="childLink.action ? childLink.action() : undefined"
                            >
                              <i *ngIf="childLink.icon" class="fa {{ childLink.icon }} mr-2"></i>
                              <span translate>{{ childLink.heading }}</span>
                            </a>
                          </li>
                          <li *ngIf="linkGroup.links.length > 7">
                            <a [routerLink]="linkGroup.link"> &darr; <span translate>SHOW_MORE</span> &darr; </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="icons-top-wrapper align-items-center justify-content-end middle-search-menu mr-0 pr-5 h-100 background-red flex-1"
      >
        <div class="product-inline-search h-100 w-100">
          <div
            class="d-flex align-items-center justify-content-center w-100 h-100"
            [class.invisible]="!showInlineSearch"
          >
            <otto-olsen-inline-search-navbar
              [configService]="configService"
              class="w-90"
            ></otto-olsen-inline-search-navbar>
          </div>
        </div>
      </div>
      <div class="d-flex navbar-customer-info justify-content-center right-link align-items-center">
        <div class="">
          <div class="">
            <a *ngIf="topInformation" class="small" routerLink="{{ topInformation.link }}">
              <span [innerHTML]="topInformation.heading"></span>
            </a>
            <a
              *ngIf="isAnonymous"
              class="p-0 text-center"
              [routerLink]="'/login'"
              [queryParams]="{ redirectUrl: router.url }"
            >
              <div class="p-0">
                <i class="fal fa-user"></i>
              </div>
              <div class="p-0 nav-bar-icon-title small">
                <span>{{ 'LOGIN' | translate }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<ng-template #mobileMenu class="">
  <div class="navbar gung-navbar navbar-expand-md d-block">
    <div class="container d-block">
      <div [class.scanner-user]="isScannerOnly" class="navbar-top">
        <div class="logo">
          <div class="nav-brand-wrapper d-flex justify-content-center">
            <a class="navbar-brand" routerLink="/">
              <img src="./assets/logo.png" width="auto" height="28px" alt="Logotype" />
            </a>
          </div>
        </div>
        <!-- <div class="customer-info" style="font-size: 0.8rem">
          <span class="d-block font-weight-bold" translate>LOGIN:</span>
          <div [innerHtml]="topInformation.heading | safeHtml"></div>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>
