import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JeevesProductDetailsComponent } from 'gung-standard-jeeves';
import {
  ProductService,
  AvailabilityService,
  AuthService,
  PriceService,
  MetadataService,
  Product,
  BaseDetailData,
  AssortmentRecursive,
  GungFlowService,
  AssortmentService,
  BreadcrumbItem,
  SelectedCustomerService,
  Customer,
  BaseViewConfigService,
  gungGetPropertyOfProduct,
  PriceConfigService,
  CartService,
  CartRow,
  ProductConcept,
  CoverImage
} from 'gung-standard';
import { Observable, Subject, takeUntil, filter, first, mergeMap } from 'rxjs';
import { OttoOlsenProductAssortmentParentService } from '../../services/otto-olsen-product-assortment-parent.service';
import { environment } from './../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { PimTemplateProperties } from 'gung-list';
import { getDocuments } from '../otto-olsen-assortment-tree-navigation/otto-olsen-assortment-tree-navigation.component';
import { OttoOlsenPimEditConfigService } from '../../services/otto-olsen-pim-edit-config.service';
import { countCharactersIgnoringHTML, gungAddRemoveSpinner, shortenCharactersIgnoringHTML } from 'gung-common';
import { OttoOlsenProductService } from '../../services/otto-olsen-product.service';

export interface ProductExtended extends Product {
  documents?: any[];
  extra: {
    [s: string]: any;
    productTemplateProperties?: PimTemplateProperties[];
  };
  packageSize?: number;
  originalSize?: number;
  firstStepAmount?: number;
}

export interface ProductError extends Product {
  status?: number;
}

interface AssortmentRecursiveExtended extends AssortmentRecursive {
  documents?: any[];
  description?: string;
}

@Component({
  selector: 'otto-olsen-product-details',
  templateUrl: './otto-olsen-product-details.component.html',
  styleUrls: ['./otto-olsen-product-details.component.scss']
})
export class OttoOlsenProductDetailsComponent
  extends JeevesProductDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  unsubscribe: Subject<boolean> = new Subject<boolean>();

  public product: ProductExtended;
  today;
  currentLang = this.translateService.currentLang || 'se';
  public hidePrice: boolean;

  productDetailsTable: { title: string; value: string }[] = [];
  breadcrumbMenu: BreadcrumbItem[];

  public requestPriceEmail = environment.requestPriceEmail;
  public Number = Number;

  models3d: String[];
  showPriceDetail = false;
  productInCart: CartRow;
  packageSize: Number;
  totalBuyThisProduct: { total: string; totalRec: string } = { total: null, totalRec: null };
  cuttingFeePrice: Number;

  isProductAccessForbidden: boolean = false;

  @Input() set setProductId(value: string) {
    if (value === undefined || value === null) {
      return;
    }
    this.setId(value);
  }

  @Input()
  currentAssortment?: AssortmentRecursiveExtended;

  selectedCustomer: Customer;

  sortDetailTable = ['ar.artstatnr', 'ar.ursprungsland', 'ar.artnrean', 'ar.artvikt'];
  detailTable: PimTemplateProperties[] = [
    {
      path: 'ar.artstatnr',
      translationKey: 'AR_ARTSTATNR',
      type: 'text',
      metaReference: null,
      metadata: null,
      isDisplay: true,
      isDisplayDetails: true,
      isDisplayGrid: false,
      isDisplayList: false,
      isFilter: false
    },
    {
      path: 'ar.ursprungsland',
      translationKey: 'AR_URSPRUNGSLAND',
      type: 'metadata',
      metaReference: null,
      metadata: 'xr.land',
      isDisplay: true,
      isDisplayDetails: true,
      isDisplayGrid: false,
      isDisplayList: false,
      isFilter: false
    },
    {
      path: 'ar.artnrean',
      translationKey: 'AR_ARTNREAN',
      type: 'text',
      metaReference: null,
      metadata: null,
      isDisplay: true,
      isDisplayDetails: true,
      isDisplayGrid: false,
      isDisplayList: false,
      isFilter: false
    },
    {
      path: 'ar.artvikt',
      translationKey: 'AR_ARTVIKT',
      type: 'text',
      metaReference: null,
      metadata: null,
      isDisplay: true,
      isDisplayDetails: true,
      isDisplayGrid: false,
      isDisplayList: false,
      isFilter: false
    }
  ];
  currentProductPimUrl: string = '';
  isAssortmentManager: boolean = false;
  isProductManager: boolean = false;
  pimEditUrlHide: boolean = false;

  productImages: CoverImage[];

  configurableLengths: { length: number; quantity: number; itemno?: string; itemprice?: number };
  showLenghtWarning: boolean = false;

  showProductAddedToCart = false;

  constructor(
    protected route: ActivatedRoute,
    protected productService: OttoOlsenProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: OttoOlsenProductAssortmentParentService,
    protected titleService: Title,
    protected selectedCustomerService: SelectedCustomerService,
    protected priceConfigService: PriceConfigService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected cartService: CartService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      translateService,
      gungFlowService,
      metadataService,
      assortmentService
    );
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
    this.priceConfigService.hidePrice
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(hidePrice => (this.hidePrice = hidePrice));
  }

  ngOnInit() {
    super.ngOnInit();
    const today = new Date();
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const dd = String(today.getDate()).padStart(2, '0');
    const yyyy = today.getFullYear();
    const seconds = today.getSeconds();
    const minutes = today.getMinutes();
    const hour = today.getHours();

    this.today =
      dd +
      ' ' +
      this.translateService.instant(monthNames[today.getMonth()]) +
      ' ' +
      yyyy +
      ' (' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds +
      ')';

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(userRoles => {
        this.isAssortmentManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'assortments');
        this.isProductManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'products-concepts');
      });
    this.ottoOlsenPimEditConfigService.pimEditUrlHide
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(pimUrlHide => (this.pimEditUrlHide = pimUrlHide));
  }

  ngOnChanges() {
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }

  ngOnDestroy() {
    this.titleService.setTitle('Otto Olsen B2B');
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }

  calculateTotals() {
    this.packageSize = Number(Number(this.product.extra.pim?.packageSize).toFixed(3)) || 1;
    this.cartService
      .getCurrentCart()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(cart => {
        this.productInCart = cart.find(p => p.productId === this.product.id);
        if (this.productInCart) {
          this.totalBuyThisProduct.total = (this.price.customerNetPrice.value * this.productInCart.qty).toFixed(2);
          this.totalBuyThisProduct.totalRec = (this.price.customerGrossPrice.value * this.productInCart.qty).toFixed(2);
        }
      });
  }

  protected handleObservable(observable: Observable<BaseDetailData>): void {
    observable.subscribe(d => {
      const productErrorCheck: ProductError = d.product;
      if (productErrorCheck?.status === 403) {
        this.isProductAccessForbidden = true;
        this.titleService.setTitle('Otto Olsen B2B' + ' - ' + this.translateService.instant('NO_ACCESS'));
      }
      const { product: productAux, availability, price, user } = d;
      this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
      const product: ProductExtended = productAux;
      this.productImages = product.extra.images;
      this.getImagesToDisplay(product);
      // this.titleService.setTitle(product.id + ' ' + product.name + ' - ' + 'Otto Olsen B2B');
      if (product.extra.artv) {
        this.addonIds = product.extra.artv.map(r => r.artnrtillvalart);
      }
      if (product.extra.pim && product.extra.pim.relatedProducts) {
        this.pimRelatedProductsIds = product.extra.pim.relatedProducts.split(',');
      }
      // Video from parent if not in product
      if (product.extra.pim?.videolink) {
        product.extra.pim.videolink = product.extra?.pim?.videolink || this.currentAssortment?.extra?.pim?.videolink;
      }
      // Documents from assortment parent and products
      product.documents = [];
      product.documents.push(...getDocuments(product, this.currentLang, user.roles));
      if (product.extra.pim?.model3d && product.extra.pim.model3d.length > 0 && product.extra.pim.model3d != '[]') {
        this.models3d = [];
        for (let model of product.extra.pim.model3d.replace(/\[|\]/g, '').split(', ')) {
          this.models3d.push('https://cdn2.gung.io/' + model);
        }
      }

      /* product.extra.pim.model3dFile = ['assets/test.igs', 'assets/7804922.stp' ,' assets/3d.STEP' ]; */
      if (this.currentAssortment) {
        getDocuments(this.currentAssortment, this.currentLang, user.roles).forEach(document => {
          const existing = product.documents.find(e => {
            return e.url === document.url;
          });
          if (!existing) {
            product.documents.push(document);
          }
        });
      }
      // Bullets
      product.extra.bullets = [];
      ['bullet1', 'bullet2', 'bullet3'].forEach(key => {
        const fromProduct = product.extra.pim?.[key];
        if (fromProduct) {
          product.extra.bullets.push(fromProduct);
        }
      });
      // Product description
      if (product.extra.i18n && product.extra.i18n[this.currentLang]) {
        product.description = product.extra.i18n[this.currentLang].description || '';
      }
      if (!product.description && product.extra.pim && product.extra.pim.description) {
        product.description = product.extra.pim.description || '';
      }
      if (product.extra.bullets.length === 0 && !product.description) {
        if (product.extra.bullets.length === 0) {
          // Bullets
          ['bullet1', 'bullet2', 'bullet3'].forEach(key => {
            const fromAssortment = this.currentAssortment && this.currentAssortment.extra.pim[key];
            if (fromAssortment) {
              product.extra.bullets.push(fromAssortment);
            }
          });
        }
        if (
          this.currentAssortment &&
          this.currentAssortment.extra.pim &&
          this.currentAssortment.extra.pim.description
        ) {
          product.description = this.currentAssortment.extra.pim.description || '';
        }
        if (
          this.currentAssortment &&
          this.currentAssortment.extra.i18n &&
          this.currentAssortment.extra.i18n[this.currentLang] &&
          this.currentAssortment.extra.i18n[this.currentLang].assortmentDescription
        ) {
          product.description = this.currentAssortment.extra.i18n[this.currentLang].assortmentDescription || '';
        }
      }

      if (product.description && countCharactersIgnoringHTML(product.description) > 550) {
        product.extra.descriptionShort = shortenCharactersIgnoringHTML(product.description, 450);
        const descriptionLeft = product.description.slice(product.extra.descriptionShort.length);
        product.extra.descriptionShort += `<div class="d-none hidden">${descriptionLeft || product.description}</div>`;
        product.extra.descriptionShort += '...';
      }

      this.product = product;
      this.availability = availability;
      this.price = price;

      this.currentProductPimUrl = environment.externalPimUrl + 'pim-item/' + this.product.id;

      this.cuttingFee();

      // assortmentParentPath
      if (!this.currentAssortment) {
        this.authService
          .getCurrentUser()
          .pipe(
            filter(u => !!u),
            first(),
            mergeMap(u => {
              return this.productAssortmentParentService.getProductAssortmentParentByProductIds(
                this.product.id,
                u.assortment
              );
            })
          )
          .subscribe(productAssortmentParent => {
            if (productAssortmentParent) {
              const currentProductAssortmentParent = productAssortmentParent[this.product.id];
              if (currentProductAssortmentParent) {
                this.calculateAssortmentPath(currentProductAssortmentParent.map(path => path.id));
              }
            }
          });
      }

      // Sort TemplateProperties by the sortDetailTable
      const sortByTable = (a, b) => {
        if (this.sortDetailTable.includes(a.path) && this.sortDetailTable.includes(b.path)) {
          const aIdx = this.sortDetailTable.indexOf(a.path);
          const bIdx = this.sortDetailTable.indexOf(b.path);
          return aIdx - bIdx;
        }
        if (this.sortDetailTable.includes(a.path)) {
          return 1;
        }
        if (this.sortDetailTable.includes(b.path)) {
          return -1;
        }
        return 0;
      };

      // Product table details
      this.productDetailsTable = [];
      if (product.extra.productTemplateProperties) {
        product.extra.productTemplateProperties.sort(sortByTable).forEach(templateProp => {
          if (templateProp.isDisplayDetails) {
            const value = gungGetPropertyOfProduct(templateProp, this.product, this.currentLang, this.metadataService);
            if (!!value) {
              this.productDetailsTable.push({ title: templateProp.translationKey, value });
            }
          }
        });
      } else if (this.currentAssortment && this.currentAssortment.extra.itemProperties) {
        this.currentAssortment.extra.itemProperties.sort(sortByTable).forEach(templateProp => {
          if (
            templateProp.isDisplayDetails &&
            this.productDetailsTable.findIndex(prop => prop.title === templateProp.translationKey) === -1
          ) {
            const value = gungGetPropertyOfProduct(templateProp, this.product, this.currentLang, this.metadataService);
            if (!!value) {
              this.productDetailsTable.push({ title: templateProp.translationKey, value });
            }
          }
        });
      }
      this.detailTable.forEach(detail => {
        let value = gungGetPropertyOfProduct(detail, this.product, this.currentLang, this.metadataService);
        if (!!value && this.productDetailsTable.findIndex(table => table.title === detail.translationKey) === -1) {
          if (detail.path === 'ar.artvikt') {
            value = Number(value).toString().replace('.', ',');
          }

          if ((detail.path === 'ar.artvikt' && Number(value) !== 0) || detail.path !== 'ar.artvikt') {
            this.productDetailsTable.push({ title: detail.translationKey, value });
          }
        }
      });
      this.productDetailsTable = this.productDetailsTable.filter(
        d => d.title !== 'Varegruppe' && d.title !== 'Rabatt V.Gr'
      );
      // Product package size
      if (
        product.extra.ar?.q_jis_fast_pakke_strl &&
        product.extra.ar.artfsgforp &&
        Number(product.extra.ar.artfsgforp) > 0
      ) {
        product.packageSize = Number(product.extra.ar.artfsgforp);
      }
      // Product original size without cutting
      if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
        product.originalSize = Number(product.extra.ar.artfsgforp);
      }
      if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
        product.packageSize = Number(product.extra.ar.q_salesbatchsize);
      }
      if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
        product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
      }
      this.calculateTotals();

      this.configurableLengths = undefined;

      if (
        product.extra.ar?.konfigartnr &&
        (product.extra.ar.konfigartnr !== '' || product.extra.ar.konfigartnr !== null)
      ) {
        this.configurableLengths = { length: 0, quantity: 0 };
      }
    });
  }

  calculateAssortmentPath(assortmentPath: string[]) {
    /*
      Possible to get the full path and use it to create the full route (articles/...).
    */
    this.assortmentService.getAssortmentRecursive('s0').subscribe(assortment => {
      if (assortment.id === assortmentPath[0]) {
        assortmentPath.shift();
        let urlParts: string[] = [];
        let url = '/articles';
        urlParts = [url + '/'];
        this.breadcrumbMenu = [
          {
            label: assortment.name,
            url,
            urlParts
          }
        ];

        let assortmentChildren = assortment.children;
        for (const path of assortmentPath) {
          const assortmentChild = assortmentChildren.find(a => a.id === path);
          if (assortmentChild) {
            urlParts = [url + '/', assortmentChild.id];
            url += '/' + assortmentChild.id;
            this.breadcrumbMenu.push({
              label: assortmentChild.name,
              url,
              urlParts
            });
            assortmentChildren = assortmentChild.children;
          } else {
            break;
          }
        }
        // url += '/' + this.product.id;
        this.breadcrumbMenu.push({
          label: this.product.name,
          url,
          urlParts: [url + '/', this.product.id]
        });
      }
    });
  }

  cuttingFee() {
    if (this.product.extra.ar.q_jis_kaltillegg_artnr) {
      this.priceService
        .getCurrentCustomerPrice(this.product.extra.ar.q_jis_kaltillegg_artnr)
        .pipe(first())
        .subscribe(price => {
          this.cuttingFeePrice = price.customerNetPrice.value;
        });
    }
  }

  getPropertyValue(item: any, itemProperty: any): string {
    const path: string = itemProperty.path;
    const splitPath = path.split('.');

    let currentValue = item.extra;
    while (splitPath.length > 0) {
      const currentPath = splitPath[0];
      splitPath.splice(0, 1);
      currentValue = currentValue[currentPath];
    }

    return currentValue;
  }

  private getProductPropertiesValues(product: Product): any[] {
    const values: any[] = [];

    // Get field form object if exist
    const getMetadata = (skuProperty, value) => {
      let metaTable = skuProperty.metaReference ? skuProperty.metaReference.table : '';
      let metaField = skuProperty.metaReference ? skuProperty.metaReference.field : '';
      if (!metaTable) {
        if (skuProperty.metadata) {
          const splitmeta = skuProperty.metadata.split('.');
          if (splitmeta.length === 2) {
            metaTable = splitmeta[0];
            metaField = splitmeta[1];
          }
        }
      }
      return this.metadataService.getMetadataValue(metaTable, metaField, value);
    };

    // skuProperties
    for (const skuProperty of product.extra.skuProperties) {
      if (skuProperty.isDisplay) {
        const paths: string[] = skuProperty.path.split('.');
        const value = product.extra[paths[0]][paths[1]];
        if (skuProperty.type === 'metadata') {
          values.push(getMetadata(skuProperty, value));
        } else {
          values.push(value);
        }
      }
    }

    // itemProperties
    for (const itemProperties of product.extra.itemProperties || []) {
      if (itemProperties.isDisplay) {
        const paths: string[] = itemProperties.path.split('.');
        const value = product.extra[paths[0]][paths[1]];
        if (itemProperties.type === 'metadata') {
          if (itemProperties.path.indexOf('pim') >= 0) {
            values.push(this.getPropertyValue(product, itemProperties));
          } else {
            values.push(getMetadata(itemProperties, value));
          }
        } else {
          values.push(value);
        }
      }
    }

    return values;
  }

  protected getImagesToDisplay(product: ProductExtended) {
    const conceptId: string = product?.extra?.conceptId || '';
    this.productService
      .getProduct(conceptId)
      .pipe(first())
      .subscribe(concept => {
        const productImages: CoverImage[] = product?.extra?.images;
        const conceptImages: CoverImage[] = concept?.extra?.images;
        if (productImages?.length > 0 && productImages?.[0]?.s3Uri !== 'images/no-image.jpg') {
          // THIS IF WILL NEVER RUN IF THEY  DON'T HAVE CONCEPT! YOU COULD DONE THIS BEFORE REQUESTING THE CONCEPT! SO YOU ONLY WOULG GET THE CONCEPT IF YOU NEED IT
          this.productImages = productImages;
        } else {
          this.productImages = conceptImages;
        }
      });
  }

  public addConfigurableLengths(length: number, quantity: number) {
    if (this.configurableLengths.itemno) {
      const { itemno, itemprice } = this.configurableLengths;
      this.cartService.addToCart(itemno, quantity, undefined, `${itemno}_${new Date().getTime()}`, undefined, {
        _productId: this.productId,
        orp: {
          vb_pris: itemprice,
          rabatt1: 0,
          rabatt2: 0
        }
      }, undefined, this.product.name);
      this.configurableLengths = {
        length: 0,
        quantity: 0
      };
      this.showProductAddedToCart = true;
    }
  }

  public calculatePrice(length: number, quantity: number, { target }) {
    gungAddRemoveSpinner(target);
    this.showLenghtWarning = false;
    if (this.product?.extra.ar?.artfsgforp && length > Number(this.product?.extra.ar?.artfsgforp)) {
      this.configurableLengths.length = Number(this.product?.extra.ar?.artfsgforp);
      this.showLenghtWarning = true;
    }
    this.productService
      .getProductByLength(this.productId, quantity, length, this.product.extra.ar.konfigartnr)
      .subscribe({
        next: ({ itemno, itemprice }) => {
          this.configurableLengths.itemprice = itemprice;
          this.configurableLengths.itemno = itemno;
          gungAddRemoveSpinner(target);
        },
        error: e => gungAddRemoveSpinner(target),
        complete: () => {}
      });
  }

  onLengthChange(ele) {
    this.showLenghtWarning = false;
    if (
      this.product?.extra.ar?.artfsgforp &&
      this.configurableLengths.length > Number(this.product?.extra.ar?.artfsgforp)
    ) {
      this.configurableLengths.length = Number(this.product?.extra.ar?.artfsgforp);
      ele.value = this.configurableLengths.length.toFixed(
        this.product.extra.ar?.q_salesbatchsize?.toString().split('.')?.[1].length || 0
      );
      this.showLenghtWarning = true;
    }
    this.configurableLengths.itemno = undefined;
    this.configurableLengths.itemprice = undefined;
  }

  public formatValue(value: number, decimals = 0): string {
    if (value == null) {
      return '';
    }
    return value.toFixed(decimals);
  }

  onQuantityChange(qtyRef: HTMLInputElement) {
    const maxQuantity = Number(this.product.extra.ar?.artfsgforp);
    if (this.configurableLengths.quantity > maxQuantity) {
      this.configurableLengths.quantity = maxQuantity;
      qtyRef.value = maxQuantity as any;
    }
    this.configurableLengths.itemno = undefined;
    this.configurableLengths.itemprice = undefined;
  }
}
