<div class="row" *ngIf="!loadingComponent && minDate; else loading">
  <div class="col-12 col-md-6">
    <div [formGroup]="form">
      <lib-meta-select-input
        formControlName="levsattkod"
        table="x2f"
        metaId="levsattkod"
        metaDisplay="levsattbeskr"
        [optionsFilterFunction]="optionsFilterDeliveryMethod.bind(this, currentCustomer)"
        (ngModelChange)="onDeliveryMethodChanged($event)"
      >
      </lib-meta-select-input>
    </div>
    <div [formGroup]="form" *ngIf="!isUser && !checkout.extra.isExportCustomer">
      <lib-meta-select-input formControlName="levvillkkod" table="x2e" metaId="levvillkkod" metaDisplay="levvillkbeskr">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div [formGroup]="form" *ngIf="!isUser">
      <lib-meta-select-input formControlName="betkod" table="x2" metaId="betkod" metaDisplay="betvillbeskr">
      </lib-meta-select-input>
    </div>
    <div [formGroup]="form" *ngIf="!isUser">
      <lib-meta-select-input formControlName="saljare" table="salj" metaId="saljare" metaDisplay="saljarenamn">
      </lib-meta-select-input>
    </div>
    <div class="form-group" [formGroup]="form">
      <label for="partDelivery" class="control-label" translate>PART_DELIVERY</label>
      <select
        name="partDelivery"
        id="partDelivery"
        class="form-control"
        formControlName="dellevtillaten"
        (change)="handlePartDeliveryChange()"
      >
        <option [value]="entry.id" *ngFor="let entry of partDeliveryEntries">
          {{ entry.name | translate }}
        </option>
      </select>
    </div>
    <div *ngIf="checkout.extra.oh.dellevtillaten == '10'">
      <lib-delivery-date-picker
        *ngIf="minDate"
        (selectedDate)="dateSelected($event)"
        [initialDate]="checkout.extra.oh.ordberlevdat"
        [deliveryMethod]="checkout.extra.procargs.levsattkod"
        [minInitialDate]="minDate"
      ></lib-delivery-date-picker>
    </div>
    <div *ngIf="form.get('dellevtillaten').value == '0'" translate>PART_DELIVERY_MESSAGE</div>
  </div>
  <div *ngIf="isFreight && !noFreight" class="col-12 mt-2">
    <div class="alert alert-warning text-center" role="alert">
      <span translate>OTTO_FREIGHT_COST</span>
    </div>
  </div>
  <div *ngIf="deliveryMethod22" class="col-12 mt-2">
    <div class="alert alert-warning text-center" role="alert">
      <span translate>OTTO_OLSEN_PICK_TWO_HOURS_CONFIRMATION</span>
    </div>
  </div>
</div>

<ng-template #loading translate> LOADING </ng-template>
