import { Injectable } from '@angular/core';
import { SelectionAction } from 'gung-list';
import { Product, ProductExportActionConfigService } from 'gung-standard';
import { Observable, first, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductExportActionConfigService extends ProductExportActionConfigService {
  getSelectionActionByTemplateId(templateId: string): Observable<SelectionAction<Product>> {
    return this.documentTemplatesService.getTemplates().pipe(
      first(),
      switchMap(templates => {
        const filteredTemplates = templates.filter(t => t.id === templateId);
        if (filteredTemplates.length === 1) {
          return of(this.getExportActionFromTemplate(filteredTemplates[0]))
        } else {
          return of(undefined);
        }
      })
    );
  }
}
