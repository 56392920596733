import { Injectable } from '@angular/core';
import { ListLayoutMultipleComponent, ListLayout } from 'gung-list';
import { CartService, CartRow } from 'gung-standard';
import { OttoOlsenSalesCartListComponent } from '../components/otto-olsen-sales-cart-list/otto-olsen-sales-cart-list.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { JeevesSalesCartConfigService } from 'gung-standard-jeeves';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenSalesCartConfigService extends JeevesSalesCartConfigService {
  getLayouts(): ListLayout<CartRow>[] {
    return [
      {
        getIconClass: () => '',
        getName: () => 'Cart table',
        getListItemComponent: () => OttoOlsenSalesCartListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent
      }
    ];
  }
}
