<div [ngSwitch]="renderFilter.type">
  <div *ngSwitchCase="'dateRangeFilter'">
    <div ngbDropdown [class]="class" [autoClose]="'outside'" placement="bottom-left" (openChange)="handleDropdownOpen($event)">
      <button class="btn btn-block text-left top-filter-dropdown" ngbDropdownToggle>
        <div class="d-flex align-items-center">
          <span translate>
            {{ renderFilter.name }}
          </span>
          <i
            class="fa float-right ml-2"
            [ngClass]="{ 'fa-chevron-down': !dropdownIsOpen, 'fa-chevron-up': dropdownIsOpen }"
          ></i>
        </div>
      </button>
      <ul class="top-filter-dropdown-list no-overflow" ngbDropdownMenu>
        <div class="dropdown-date-padding" *ngIf="renderFilter.type === 'dateRangeFilter'">
          <lib-filter-date-range
            [renderFilter]="renderFilter"
            (selectDateRange)="setRangeValues($event)"
            [changeMax]="renderFilter.maxValue"
            [changeMin]="renderFilter.minValue"
          ></lib-filter-date-range>
        </div>
      </ul>
    </div>
  </div>

  <div [ngSwitch]="renderFilter.type">
    <div *ngSwitchCase="'RangeConfigBaseFilter'">
      <div ngbDropdown [class]="class" [autoClose]="'outside'" placement="bottom-left" (openChange)="handleDropdownOpen($event)">
        <button class="btn btn-block text-left top-filter-dropdown" ngbDropdownToggle>
          <div class="d-flex align-items-center">
            <span translate>
              {{ renderFilter.name }}
            </span>
            <i
              class="fa float-right ml-2"
              [ngClass]="{ 'fa-chevron-down': !dropdownIsOpen, 'fa-chevron-up': dropdownIsOpen }"
            ></i>
          </div>
        </button>
        <ul class="top-filter-dropdown-list no-overflow" ngbDropdownMenu>
          <div class="dropdown-date-padding">
            <lib-range-slider
              (selectedRangeOutput)="selectedRangeChanged($event)"
              [maxRange]="maxRange"
              [selectedRange]="maxValue && minValue ? { max: maxValue, min: minValue } : undefined"
            ></lib-range-slider>
          </div>
        </ul>
      </div>
    </div>

  <div *ngSwitchDefault>
    <div ngbDropdown [class]="class" [autoClose]="'outside'" placement="bottom-left" (openChange)="handleDropdownOpen($event)">
      <button class="btn btn-block text-left top-filter-dropdown" [ngClass]="{ 'has-selection': hasSelection }" ngbDropdownToggle>
        <div class="d-flex align-items-center">
          <span translate>
            {{ renderFilter.name }}
          </span>
          <i
            class="fa float-right ml-2"
            [ngClass]="{ 'fa-chevron-down': !dropdownIsOpen, 'fa-chevron-up': dropdownIsOpen }"
          ></i>
        </div>
      </button>
      <ul class="top-filter-dropdown-list" ngbDropdownMenu>
        <li *ngIf="renderFilter.valueList.length > 0">
          <lib-filter-list-search (searchUpdated)="setSearch($event)"></lib-filter-list-search>
        </li>
        <li *ngIf="renderFilterValueList == 0">
          <span translate>NO_RESULTS_FOUND</span>
        </li>
        <li *ngFor="let option of renderFilterValueList" [ngClass]="{ 'd-none': !option.valueId || option.hide }">
          <button
            class="btn top-filter-item w-100 text-left"
            (click)="option.selected ? deselectValue.emit(option.valueId) : selectValue.emit(option.valueId); updateHasSelection()"
            translate
          >
            <i class="far" [ngClass]="{ 'fa-square': !option.selected, 'fa-check-square': option.selected }"></i>
            <img *ngIf="renderFilter.type === 'IconConfigBaseFilter' && option.icon?.includes('http')" src="{{option.icon}}" alt="{{ option.valueName }}" class="filter-image mx-1">
            <span class="value-name"> {{ option.valueName | translate }} </span> <span class="float-right">{{ option.itemCountAfterFilter }}</span>
            <i *ngIf="renderFilter.type === 'SubSimpleConfigBaseFilter'" 
              class="fa-solid ml-1" [ngClass]="{ 'fa-chevron-down': !option.selected, 'fa-chevron-up': option.selected }"></i>
          </button>
          <ul [ngbCollapse]="!option.selected" (ngbCollapseChange)="option.selected = $event" *ngIf="renderFilter.type === 'SubSimpleConfigBaseFilter'" class="no-bullets" #collapse="ngbCollapse">
            <li *ngFor="let subOption of option.subValueList" [ngClass]="{ 'd-none': !option.valueId || option.hide }">
              <button
                class="btn top-filter-item w-100 text-left pr-1"
                (click)="subOption.selected ? deselectSubValue.emit({option: option.valueId, subOption: subOption.valueId}) : selectSubValue.emit({option: option.valueId, subOption: subOption.valueId})"
                translate
              >
                <i class="fa-circle" [ngClass]="{ 'fa-regular': !subOption.selected, 'fa-solid': subOption.selected }"></i>
                {{ subOption.valueName }} <span class="float-right">{{ subOption.itemCountAfterFilter }}</span>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
