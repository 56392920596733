{
  "EXPORT_XLSX": "Exportera XLSX",
  "EXPORT_STOCKLIST": "Exportera Stocklist",
  "EXPORT_PRODUCT_INFO": "Exportera Product Info",
  "EXPORT_IMG": "Exportera Image",
  "EXPORT_PRICAT": "Exportera PRICAT",
  "PRODUCT_EXPORT_NAV_BAR": "Produkt Export",
  "PRODUCT_FAST_SEARCH": "Produkter snabb sökning",
  "READ_MORE_AND_ORDER": "Läs mer & Beställ",
  "ACCESSORIES": "Tillbehör",
  "ACTIONS": "Alternativ",
  "ACTIVE_IN_B2B": "Aktiv i B2B",
  "ACTIVE_IN_B2C": "Aktiv i B2C",
  "ADD_NEW_CONDITION": "Lägg till villkor",
  "ADD_NEW_FIELD": "Lägg till nytt fält",
  "ADD_QUERY": "Lägg till villkor",
  "ADD_QUERY_PART": "Lägg till delvillkor",
  "ADMIN": "Admin",
  "AL_ARTNRLEV": "Lev. Varenummer",
  "AL_FTGNR": "Leverantør",
  "AL_LEVSARTBESKR": "Lev. Varespesifikasjon",
  "ALL_FIELDS": "Alla fält",
  "ALL_INFO": "All info",
  "AND": "och ",
  "AR_ARTBESKR": "Artikelbeskrivning",
  "AR_ARTBESKRSPEC": "Artikelbeskrivning 2",
  "AR_ARTRABKLASS": "Rabatt V.Gr.",
  "AR_ARTSTATNR": "Tullnummer",
  "AR_EDIT": "Noteringar",
  "AR_URSPRUNGSLAND": "Ursprungsland",
  "AR_VARUGRUPPKOD": "Varugrupp",
  "AREA_OF_USE": "Användningsområde",
  "ARTICLE_INFORMATION": "Produktinformation",
  "ASSORTMENT": "Sortiment",
  "ASSORTMENT_BUILDER": "Sortimentsbyggare",
  "ASSORTMENT_DESCRIPTION": "Sortimentsbeskrivning",
  "ASSORTMENT_DETAILS": "Sortimentsdetaljer",
  "ASSORTMENT_NAME": "Sortimentnamn",
  "ASSORTMENT_TEMPLATE": "Sortimentmall",
  "ASSORTMENT_TEMPLATES": "Sortimentmallar",
  "ASSORTMENTS": "Sortiment",
  "ATTRIBUTE": "Egenskap",
  "ATTRIBUTES_MATCH_ALL_CONDITIONS": "Vars egenskaper matchar ALLA villkor nedan",
  "AXIS": "Axel",
  "BEARING_TYPE": "Lagertyp",
  "BENDING_RADIUS": "Bøyeradius",
  "BRAKE": "Brems",
  "BUILD_HEIGHT": "Byggehøyde mm",
  "BURST_PRESSURE": "Sprengtrykk Bar",
  "CANCEL": "Avbryt",
  "CLEAR_ALL": "Rensa",
  "CLOSE": "Stäng",
  "CODE": "Kod",
  "COLOR": "Färg",
  "COMPLIANCES": "Compound",
  "COMPOUND": "Compound",
  "CONCEPT": "Koncept",
  "CONCEPT_TEMPLATE": "Konceptmall",
  "CONCEPT_TEMPLATES": "Konceptmallar",
  "CONCEPTS": "Koncept",
  "CONDITIONAL_ON_FIELD": "Villkorsstyrt fält",
  "CONFIRM": "Bekräfta",
  "CONFIRM_DELETE_MESSAGE": "Detta är en permanent handling. Bekräfta att du vill fortsätta ta bort.",
  "CONFIRM_PASSWORD": "Bekräfta lösenord",
  "CONTINUE": "Fortsätt",
  "COOLING": "Kjøling",
  "COUNTRY_OF_ORIGIN": "Ursprungsland",
  "CREATE_ASSORTMENT_TEMPLATE": "Skapa sortimentmall",
  "CREATE_CONCEPT": "Skapa koncept",
  "CREATE_CONCEPT_TEMPLATE": "Skapa konceptmall",
  "CREATE_FIELD_DISPLAY_TEMPLATE": "Skapa mall",
  "CREATE_METADATA": "Skapa ny metadata",
  "CREATE_METADATA_COLLECTION": "Skapa ny metadata-kategori",
  "CREATE_NEW_FIELD": "Skapa fält",
  "CREATE_ROLE": "Skapa roll",
  "CREATE_USER": "Skapa användare",
  "CURRENT": "Ström ",
  "CURRENT_230": "Ström 230V",
  "CURRENT_400": "Ström 400V",
  "CURRENT_690": "Ström 690V",
  "DB_LEVEL": "Bullernivå dB",
  "DELETE": "Ta bort",
  "DESCRIPTION": "Beskrivning",
  "DESELECT_ALL": "Avmarkera allt",
  "DESELECT_ALL_FILTERED_ITEMS": "Avmarkera urval",
  "DESELECT_ALL_ITEMS": "Avmarkera alla",
  "DIMENSIONS": "Dimension",
  "DISPLAY_AS_FILTER": "Visa som filter",
  "DISPLAY_BATCH_PREFIX": "Visa prefix",
  "DISPLAY_IN_LIST": "Visa i listan",
  "DOCUMENT": "Dokument",
  "DOCUMENT_TYPE": "Dokumenttyp",
  "DOCUMENTS": "Dokument",
  "EDIT": "Ändra",
  "EFFECT": "kW",
  "EFFECT_PERCENT": "Eff. %",
  "EMAIL": "Email",
  "EMPTY": "Tom",
  "ERROR_MESSAGE": "Felmeddelande",
  "EXPORT": "Export",
  "EXPORT_EXCEL": "Exportera excel",
  "EXPORT_INCLUDED_ITEMS": "Exportera inkluderade produkter",
  "FASTENING_BOLT": "Fästbult",
  "FIELD": "Fält",
  "FIELD_DEFINITIONS": "Fältdefinitioner",
  "FIELD_DISPLAY_TEMPLATE": "Visningsmall",
  "FIELD_DISPLAY_TEMPLATES": "Visningsmallar",
  "FIELD_NAME": "Fältnamn",
  "FIELDS": "Fält",
  "FILE_COULD_NOT_BE_UPLOADED": "Filen kunde inte laddas upp",
  "FILE_NAME": "Filnamn",
  "FILENAME": "Filnamn",
  "FILTERS": "Filter",
  "FLANGE_DIAMETER": "Flensdiameter",
  "FREQUENCY": "Hz",
  "GENERATE_EXCEL": "Exportera excel",
  "GROSS_WEIGHT": "Bruttovikt",
  "HARD": "Hård",
  "HARDNESS": "Hardhet",
  "HAS_IMAGE": "Har bild",
  "HAS_ONE_OF_VALUES": "Har ett av följande värden:",
  "HELP": "Hjälp",
  "HOLE_DIAMETER": "Hjuldiameter",
  "HOLE_PATTERN": "Hjulbild",
  "HOME": "Start",
  "HUB_DIAMETER": "Nav diameter",
  "IE_CLASS": "IEC",
  "IEC": "IE klasse",
  "IMAGES": "Bilder",
  "IMPORT_EXCEL": "Importera excel",
  "IMPORT_EXPORT": "Import / export",
  "IMPORT_FAILED": "Importen misslyckades",
  "IMPORT_FROM_EXCEL": "Importera från Excel",
  "IMPORT_IMAGE(S)": "Importera bilder",
  "IMPORT_SUCCESSFUL": "Importen lyckades",
  "IMPORTS": "Importer",
  "INCLUDE_PRODUCTS_CONCEPTS": "Inkludera: ",
  "INCLUDED_ITEMS": "Inkluderade produkter",
  "INCLUDED_PRODUCTS": "Inkluderade produkter",
  "INIT_DEFAULT_FIELDS": "Initiera default fält",
  "INPUT_TYPE": "Inmatningsfält",
  "INSERT_MATERIAL": "Invändigt material",
  "INSIDE_DIAMETER": "Invändig diameter",
  "INSIDE_MATERIAL": "Invändigt material",
  "IP_RATING": "Kapslingsgrad",
  "ITEM_ID": "Produkt ID",
  "ITEM_NAME": "Produktnamn",
  "ITEM_UNIQUE_METADATA": "Artikelunik metadata",
  "LABEL": "Titel",
  "LABEL_TRANSLATE_KEY": "Översättningsnyckel",
  "LANGUAGE_EN": "Engelska",
  "LANGUAGE_SE": "Svenska",
  "LIGHT_SOURCE_INFORMATION": "Ljuskälla",
  "LOAD_CAPACITY": "Lastkapacitet (kg)",
  "LOAD_MORE": "Visa ytterligare {{batchSize}}",
  "LOGOUT": "Logga ut",
  "MAIN_CATEGORY": "Type",
  "MASTER_CATEGORY": "Huvudkategori",
  "MATCHES_PRODUCT_IDS": "Matchande produkter",
  "MATERIAL": "Materiale",
  "MAX_CABLE_DIAMETER": "Max Kabeldiamter",
  "METADATA": "Metadata",
  "METADATA_COLLECTION": "Metadata kategori",
  "METADATA_OPTIONS": "Metadata alternativ",
  "MULTI_SELECT": "Flerval",
  "NAME": "Namn",
  "NET_WEIGHT": "Nettovikt",
  "NEXT": "Nästa",
  "NO_CUSTOM_FELDS_EXIST_FOR_EXPORT": "Det finns inga anpassade fält för vald importtyp, endast basfälten kommer exporteras",
  "NO_RESULTS_FOUND": "Inga träffar kunde visas",
  "OFFSET": "Offset",
  "OUTSIDE_DIAMETER": "Utvändig diameter",
  "OUTSIDE_MATERIAL": "Utvendigt material",
  "PASSWORD": "Lösenord",
  "PASSWORD_HAS_BEEN_RESET": "Lösenordet har återställts",
  "PASSWORD_MISMATCH": "Lösenorden matchar ej",
  "PIM_COLLECTION": "Pim databas",
  "PIM_ITEM": "PIM produkt",
  "PIM_ITEM_CATEGORY": "Artikelkategori",
  "PIM_ITEM_CONCEPT_SAVED": "Koncept sparat",
  "PIM_ITEM_COUNTRY_OF_ORIGIN": "Ursprungsland",
  "PIM_ITEM_METADATA": "Artikelunik metadata",
  "PIM_ITEM_SAVED": "Produkt sparad",
  "PLATE_SIZE": "Platestørrelse",
  "POLES": "Poler",
  "POWER_FACTOR": "cosφ",
  "PRESSURE": "Tryck",
  "PREVIOUS": "Föregående",
  "PRODUCT_FIELDS": "Produktfält",
  "PRODUCT_LIST_FILTER_TOTAL": "Totalt filtrerade",
  "PRODUCT_LIST_TOTAL": "Totalt",
  "PRODUCTS": "Produkter",
  "QUERY_FIELD": "Sökfält",
  "READ": "Läs",
  "READ_ONLY": "Enbart läsning",
  "RELATED_PRODUCTS": "Relaterade produkter",
  "REMOVE_ASSORTMENT": "Ta bort sortiment",
  "RESET_PASSWORD": "Återställ lösenord",
  "RESPONSIBLE": "Ansvarig",
  "ROLE": "Roll",
  "ROLES": "Roller",
  "RPM": "Hastighet",
  "SAFETY_CLASS": "Säkerhetsklass",
  "SALES_INFORMATION": "Säljinformation",
  "SAVE": "Spara",
  "SEARCH": "Sök",
  "SEARCH_CONCEPT": "Sök för att välja koncept",
  "SEARCH_FOR_ITEMS": "Sök artiklar",
  "SEARCH_PRODUCT": "Sök produkt",
  "SELECT": "Markera",
  "SELECT_ALL": "Markera allt",
  "SELECT_ALL_FILTERED_ITEMS": "Markera urval",
  "SELECT_ALL_ITEMS": "Markera alla",
  "SELECT_FIELD_ORDER": "Välj fältordning",
  "SELECT_FIELDS_TO_EXPORT": "Välj fält att exportera",
  "SELECT_FILE": "Välj fil",
  "SELECT_IMPORT_TYPE": "Välj import-typ",
  "SELECTED_FILTERS": "Valda filter",
  "SELECTED_ITEM_COUNT": "Markerade artiklar",
  "SERIES": "Serie",
  "SETTINGS": "Inställningar",
  "SHORE": "Shore",
  "SOFT": "Soft",
  "STAINLESS": "Rustfri",
  "STANDARD": "Standard",
  "SUB_CATEGORY": "Underkategori",
  "SUBMIT": "Skicka",
  "SUCCESS": "Lyckades",
  "SUGGEST_ID": "Föreslå id",
  "TECHNICAL_INFORMATION": "Teknisk information",
  "TEMPERATURE_RANGE": "Temperaturområde",
  "TEMPLATE": "Mall",
  "TEMPLATE_NAME": "Namn",
  "TEXTS": "Texter",
  "TORQUE": "Nm",
  "TOWER_DIAMETER": "Torndiameter (mm)",
  "TRACE_DIAMETER": "Spårdiameter (mm)",
  "TRANSLATE_PROPERTY": "Översättningsbar egenskap",
  "TUBE_LENGTH": "Slanglängd (mm)",
  "TURNING_RADIUS": "Svängradie",
  "TYPE": "Typ",
  "UNSAVED_CHANGES_CONTENT": "Du har osparade ändringar. Fortsätter du kommer dessa gå förlorade",
  "UNSAVED_CHANGES_HEADING": "Osparade ändringar",
  "UPLOAD_DOCUMENT(S)": "Ladda upp dokument",
  "UPLOAD_EXCEL": "Ladda upp XLSX",
  "UPLOAD_IMAGE(S)": "Ladda upp bilder",
  "UPLOADED_TO_PRODUCTS": "Uppladdat till följande produkter",
  "USERNAME": "Användarnamn",
  "USERS": "Användare",
  "VACUUM": "Vakuum",
  "VALUES": "Värden",
  "VG_VARUGRUPPBESKR": "Varugrupp",
  "VOLTAGE": "Spänning",
  "WEB_WIDTH": "Banbredd",
  "WEIGHT": "Vikt",
  "WEIGHT_PER_METER": "Vikt per meter",
  "WHEEL_AXIS": "Hjulaxel",
  "WHEEL_DIAMETER": "Hjuldiameter (mm)",
  "WHEEL_TRACK": "Hjulbana",
  "WIDTH": "Bredd",
  "WORKING_PRESSURE": "Arbetstryck (bar)",
  "WRITE": "Skriv",
  "PROPERTIES_VGVARUGRUPPBESKR": "Varugrupp",
  "PROPERTIES_ARARTNR": "Artikelnr",
  "PROPERTIES_ARARTBESKR": "Artikelbeskrivning",
  "PROPERTIES_AREXTRA1": "Söktext",
  "PROPERTIES_ARARTBESKRSPEC": "Artikelbeskrivning 2",
  "SUPPLIER_NAME": "Leverantör",
  "VG_Q_RESPONSIBLE": "Produktansvarig",
  "LENGTH_MM": "Längd (mm)",
  "LIGHTOPENING_MM": "Ljusöppning (mm)",
  "MINDIAMETER_MM": "Diameter, min (mm)",
  "MAXDIAMETER_MM": "Diameter, max (mm)",
  "THICKNESS_MM": "Tjocklek (mm)",
  "ASSORTMENTLEVEL1": "Sortimentsnivå 1",
  "ASSORTMENTLEVEL2": "Sortimentsnivå 2",
  "ASSORTMENTLEVEL3": "Sortimentsnivå 3",
  "ASSORTMENTLEVEL4": "Sortimentsnivå 4",
  "ASSORTMENTLEVEL5": "Sortimentsnivå 5",
  "ASSORTMENTLEVEL6": "Sortimentsnivå 6",
  "DEGREE_OF_PROTECTION": "Kapslingsgrad",
  "HELP_ENCLOSURE": "Hjelp kapsling",
  "FRONT_TYPE": "Type Front",
  "VOLTAGE_FEED": "Matespenning",
  "HELP_TENSION": "Hjelp Spenning",
  "FEED_RATE": "Matefrekvens",
  "HELP_EFFECT": "Hjelp Effekt",
  "RATED_CURRENT": "Merkeström",
  "OVERLOAD60S": "Overlast 60s",
  "OVERLOAD2_5S": "Overlast 2,5s",
  "OVERLOAD4S": "Overlast 4s",
  "OVERLOAD6S": "Overlast 6s",
  "MAIN_FILTER": "Nettfilter",
  "BRAKE_CONTROL": "Bremsestyring",
  "OUTPUT_FREQUENCY": "Utgangsfrekvens",
  "FIELDBUS1": "Fältbus 1",
  "FIELDBUS2": "Fältbus 2",
  "PROGRAMMABLE_INPUTS": "Programerbare innganger",
  "PROGRAMMABLE_OUTPUTS": "Programerbare utganger",
  "SAFETY": "Säkerhet",
  "PROCESS_CONTROLS": "Prosess kontroller",
  "DISPLAY_TYPE": "Typ av display",
  "ENCLOSURE_SIZE": "Kapslings størrelse",
  "DIMENSION_WHD_MM": "BxHxDmm",
  "TYPE_DESIGNATION": "Typ",
  "ENGINE_TYPE": "Motortyp",
  "TECHINCAL_CHARACTERISTICS_CATALOG": "Tekniska egenskaper / katalog",
  "IN_STOCK_VALUE": "{{value}} STK",
  "PCS": "STK",
  "OH_TA_MAILNOTIFIED": "E-Mail leveransinformation",
  "OH_DELLEVTILLATEN_0": "Ja",
  "OH_DELLEVTILLATEN_10": "Nej",
  "PART_DELIVERY": "Delleverans",
  "PART_DELIVERY_MESSAGE": "Produkterna kommer dellevereras, frakt tillkommer på varje enskild leverans.",
  "ASK_PRICE": "Forespørsel",
  "NOT_IN_STOCK": "Inte i lager",
  "AVAILABILITY_ESTIMATED_CONTACT_US": "Normal leveranstid vid beställning {{date}}. Kontakta oss om du behöver det snart.",
  "AVAILABILITY_ESTIMATED": "Normal leveranstid vid beställning {{date}}.",
  "AVAILABILITY_CONTACT_US": "Kontakta oss om du behöver det snart.",
  "STOCK_STATUS": "{{stock}} {{unit}}",
  "AVAILABILITY_ESTIMATED_DATE_CONTACT_US": "Normal leveranstid vid beställning: {{date}}. Kontakta oss om du behöver det snart.",
  "AVAILABILITY_ESTIMATED_DATE": "{{qty}} {{unit}} tilgjengelig {{date}}. Avvik kan forekomme.",
  "AVAILABILITY_ESTIMATED_DATE_SHORT": "Tilgjengelig {{date}}. Avvik kan forekomme",
  "AVAILABILITY_DEVIATIONS_MAY_OCCUR": "Avvik kan forekomme",
  "CONCEPT_RANGE_NAME": "Konsept sortimentsnavn",
  "NET_PRICE": "netto",
  "OTTO_FREIGHT_COST": "Beställningar på mindre än kr 500 debiteras kr 250,- för förpackning och förpackning.",
  "OH_KUNDBESTNR": "Ordernummer",
  "OH_EDITEXT": "Meddelande till kundcenter",
  "OH_GODSMARKE3": "E-post",
  "OH_GODSMARKE4": "Telefon",
  "OTTO_OLSEN_PICK_TWO_HOURS_CONFIRMATION": "Varorna kan hämtas tidigast två timmar efter mottagandet av orderbekräftelsen",
  "OTTO_OLSEN_QUOTATION": "Tilbud",
  "OTTO_OLSEN_ORDER": "Ordre",
  "OTTO_OLSEN_DELIVERY_DATE_MAY_CHANGE": "Leveransdatum kan ändras med olika leveransmetoder",
  "SEND_PASSWORD_RESET": "Send nytt passord",
  "FORGOT_PASSWORD": "Nytt passord",
  "SET_PASSWORD": "Registrer nytt passord",
  "RESET_NEW_PASSWORD": "Nytt passord",
  "INVOICES": "Faktura",
  "PO_NUMBER": "Bestillingsnummer",
  "YOUR_REFERENCE": "Din referanse",
  "CUSTOMER_DASHBOARD": "Dashboard",
  "SAVE_CART_TO_EXCEL": "Spara kundvagnen i Excel",
  "SALES_PER_YEAR": "Försäljning per år",
  "COMPANY_ORG_NAME": "Organisasjonsnummer",
  "ENTER_COMPANY_ORG_NAME": "Ange organisasjonsnummer",
  "COMPANY_ZIP_CODE": "Postadresse (postnummer)",
  "ENTER_COMPANY_ZIP_CODE": "Ange postadresse (postnummer)",
  "COMPANY_CITY": "Postadresse (poststed)",
  "ENTER_COMPANY_CITY": "Ange postadresse (poststed)",
  "COMPANY_DELIVERY_ADDRESS": "Leveringsadresse",
  "ENTER_COMPANY_DELIVERY_ADDRESS": "Ange leveringsadresse",
  "COMPANY_DELIVERY_ZIP_CODE": "Leveringsadresse (postnummer)",
  "ENTER_COMPANY_DELIVERY_ZIP_CODE": "Ange leveringsadresse (postnummer)",
  "COMPANY_DELIVERY_CITY": "Leveringsadresse (Sted)",
  "ENTER_COMPANY_DELIVERY_CITY": "Ange leveringsadresse (Sted)",
  "COMPANY_DELIVERY_COUNTRY": "Leveringsadresse (Land)",
  "ENTER_COMPANY_DELIVERY_COUNTRY": "Ange leveringsadresse (Land)",
  "CONTACT_TITLE": "Kontaktperson (tittel / posisjon)",
  "ENTER_CONTACT_TITLE": "Ange kontaktperson (tittel / posisjon)",
  "COMPANY_EMAIL_INVOICE": "Epostadresse (faktura)",
  "ENTER_COMPANY_EMAIL_INVOICE": "Ange epostadresse (faktura)",
  "COMPANY_EMAIL_CONFIRMATION": "Epostadresse (ordrebekreftelse)",
  "ENTER_COMPANY_EMAIL_CONFIRMATION": "Ange epostadresse (ordrebekreftelse)",
  "COMPANY_PHONE": "Telefonnummer (firma)",
  "ENTER_COMPANY_PHONE": "Ange telefonnummer (firma)",
  "COMPANY_PREFERED_CURRENCY": "Ønsket handelsvaluta (NOK / Euro)",
  "ENTER_COMPANY_PREFERED_CURRENCY": "Ange ønsket handelsvaluta (NOK / Euro)",
  "COMMENTS": "Kommentarer",
  "ENTER_COMMENTS": "Ange kommentarer",
  "NOT_VALID_LENGTH": "Numerisk {{length}} siffer uten punktum eller mellomrom.",
  "DOWNLOAD_PDF": "Ladda ned PDF",
  "CART_ARTICLE_SKU_TOTAL": "{{article}} artiklar",
  "EXTRA_PRODUCTS": "Eget produktsortiment",
  "ENTER_COUNTRY": "Ange land",
  "OTTO_COUNTRY": "Postadresse (land)",
  "OTTO_ENTER_COUNTRY": "Ange postadresse (land)",
  "OTTO_EMAIL": "Epostadresse (kontaktperson)",
  "OTTO_ENTER_EMAIL": "Ange epostadresse (kontaktperson)",
  "OTTO_PHONE": "Telefonnummer (kontaktperson)",
  "OTTO_ENTER_PHONE": "Ange telefonnummer (kontaktperson)",
  "EXISTING_CUSTOMER_NEED_ACCOUNT": "Befintlig kund och behöver ett konto, klicka här",
  "NEW_CUSTOMER_NEED_ACCOUNT": "Ny kund och behöver ett konto, klicka här",
  "CURRENT_CUSTOMER_BLOCKED": "Nuvarande kund är blockerad för att skapa användare. Kontakta oss för mer information.",
  "FILE_NOT_FOUND": "Filen hittades inte",
  "VIEW": "View only",
  "FEE_ADDED_LOT_SIZE": "Gebyr tilkommer (partistørrelse: {{value}})",
  "POST_ADDRESS": "Postadress",
  "DELIVERY_ADDRESS_TAB": "Alternativ leveringsadr",
  "CREATE_ACCOUNT_FOR_NEW_CUSTOMER": "Opprett ny konto for eksisterende kunde",
  "REQUEST_ACCOUNT": "Søknad om kundeforhold",
  "BARCODE_SCANNER_QUICKORDER": "Barcode Scanner Quickorder",
  "DELIVERY_DATE_CHANGED_DUE_TO_STOCK": "Leveransdatum ändras beroende på tillgängligt lager",
  "ARTNRLEV_ALREADY_EXISTS": "Valgt lev. varenummer finnes allerede",
  "FREE_FREIGHT_ALERT": "{{value}} PCS",
  "FREE_FREIGHT_AGREEMENT": "Beställningen skickas kostnadsfritt enligt avtalet",
  "MISSING_FREE_FREIGHT": ",-. Du saknar {{value}}, för fri frakt",
  "SCAN_NEW": "Skanna ny",
  "MENU": "Meny",
  "LOGGED_AS": "Loggad som",
  "PRODUCT_NUMBER": "Produktnummer",
  "PRODUCT_NAME": "Produktnamn",
  "PRODUCT_FEATURES": "Produktfunktioner",
  "CLEAN_CART": "Slett alle varelinjer",
  "NUMBER_ARTICLES": "Antall artikler",
  "CART_CONTENT": "Varukorgens innehåll",
  "SCAN_BARCODE_WITH_CAMERA": "Skanna streckkoden med kameran",
  "OTTO_OLSEN_ORDER_DETAIS": "Otto Olsen - Beställningsuppgifter för beställningsnr. {{order}}. Kundens ordernummer: {{customerOrderId}}",
  "CUSTOMER_ORDER_ID": "Kundens ordernr",
  "DEVIVERED": "Levereras",
  "OTTO_OLSEN_ORDER_OVERVIEW": "Beställningsöversikt",
  "MARKUP_BELOW_TWO": "Markup är under 2!",
  "TOOLTIP_COMMENT": "Denna produktinformation kommer endast att skickas via e-post och kommer inte att finnas tillgänglig på produktsidan.",
  "NOTES": "Anteckningar",
  "VIEWER_DESCRIPTION": "Navigera, sök och se pris",
  "USER_DESCRIPTION": "Beställ, navigera, sök och se priser",
  "NOTES_TO_PRODUCT_RESPONSIBLE": "Kommentar til produktansvarig",
  "FORM_ERROR_NO_SPACE": "Fältet ska inte innehålla ett mellanslag.",
  "SCANNER_CONFIGS": "Skannerkonfigurationer",
  "TIME_SCANNER": "Tid läst ms",
  "SCAN_PRODUCTS_IN_CART": "Skanna produkter som redan finns i kundvagnen",
  "PROHIBITED_CHARACTERS_VALIDATOR": "Följande tecken är förbjudna: ‒ — ’ ” ¼ ½ ¾",
  "AR_ARTVIKT": "Enhetsvikt (kg)",
  "3D_MODEL": "3D-modell",
  "DOWNLOAD_3D_MODEL": "Ladda ner 3D-modell",
  "FORMAT": "Formatera",
  "VIEW_PRODUCT_AS_3D_MODEL": "Visa produkten som en 3D-modell",
  "VIEW_PRODUCT_IMAGES": "Visa produktbilder",
  "3D_MODEL_VIEWER": "3D-modellvisare",
  "Y_AXIS_UP_VECTOR": "Sätt Y-axeln som uppvektor",
  "Z_AXIS_UP_VECTOR": "Sätt Z-axeln som uppvektor",
  "FLIP_UP_VECTOR": "Vänd upp vektor",
  "FIXED_UP_VECTOR": "Fixad vektor",
  "FREE_ORBIT": "Fri omloppsbana",
  "PERSPECTIVE_CAMERA": "Perspektivkamera",
  "ORTHOGRAPHIC_CAMERA": "Ortografisk kamera",
  "BACK_TO_ORDER": "Tillbaka till orderöversikt",
  "FORM_WARNING_ENABLE_EDIT_DELIVERY_ADDRESS": "Leveransdatum kan ändras",
  "FORM_DANGER_ENABLE_EDIT_DELIVERY_ADDRESS": "Det är inte tillåtet att ange en postbox som leveransadress",
  "ORDER_ANALYTICS_BETA": "Beställningsanalys (beta)",
  "SALES_DASHBOARD_BETA": "Försäljningsöversikt (beta)",
  "MEETIGN_AMOUNT": "Mötens belopp",
  "TOTAL_LAST_MONTH": "Totalt förra månaden",
  "TOTAL_THIS_MONTH": "Totalt denna månad",
  "COMPLETED_THIS_MONTH": "Slutfört denna månad",
  "COMPLETED_LAST_MONTH": "Slutförd förra månaden",
  "MEETINGS_TARGET": "Framsteg denna månad",
  "MEETINGS_LAST_MONTH_TARGET": "Framsteg förra månaden",
  "CONVERTED_IN_ORDER_THIS_MONTH": "Faktiska månadsmöten genererade ordning",
  "CONVERTED_IN_ORDER_LAST_MONTH": "Föregående månads möten genererade ordning",
  "SEE_PRICE_DETAIL": "Visa prisuppgifter",
  "HIDE_PRICE_DETAIL": "Dölj prisinformation",
  "PACKAGING": "Förpackning",
  "MIN.PURCHASE": "Minsta köp",
  "CUTTING_FEE": "Täckningsavgift",
  "CUTTING_FEE_TEXT": "- tillkommer vid köp av andra kvantiteter än hela paket",
  "IN_CUTTING_FEE": "i styckningsavgift",
  "INCL": "Inkl.",
  "REC_PRICE": "Rec.price",
  "HIDE_EDIT_PIM_LINKS": "Dölj redigera PIM-länkar",
  "SAVED_CARTS_BY": "Av",
  "SAVED_CARTS_AT": "vid",
  "EXIT_SCANNER_MODE": "Avsluta skannerläge",
  "BARCODE_SCANNER": "Barcode Scanner",
  "DELIVERY_LOCATION_MENU": "Välj kund/avd.",
  "NUMBER_COMMA_VALIDATION": "Ogiltiga tecken angivna. Endast siffror och kommatecken är tillåtna.",
  "OVERDUE_INVOICES": "Förfallna fakturor",
  "OVERDUE": "Försenad",
  "LIMIT_ORDER_ERROR": "Tyvärr är det inte möjligt att beställa mer än {{stock}} för denna produkt. Kontakta Otto Olsen om du behöver fler än detta nummer.",
  "ACCESS_FORBIDDEN_ERROR": "Du har inte tillgång till att se denna produkt.",
  "NO_ACCESS": "Ingen åtkomst",
  "VARESPESIFIKASJON": "Produktspecifikation",
  "FILTER_IN_STOCK": "Visa varor i lager",
  "FILTER_NO_STOCK": "Visa varor som inte finns i lager",
  "WEBSHOP_OTTO_OLSEN": "Otto Olsen B2B",
  "DEFAULT_SCANNER": "Standardskanner",
  "NEW_SCANNER": "Ny skanner",
  "CALCULATE_PRICE": "Beregn pris",
  "PACK_SIZE_IS": "Paketstorleken är",
  "YOU_WERE_NOT_LOGGED_IN": "Du ble ikke logget inn",
  "YOUR_USER_IS_NOT_CONNECTED_TO_A_CUSTOMER": "Din bruker, {{user}}, er ikke tilkoblet en kunde i systemet.",
  "PLEAS_CONTACT_OTTO_OLSEN_AT": "Ta kontakt med Otto Olsen pa post@oo.no eller 63 89 0800 for hjelp.",
  "PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION": "Bestillinger gjennom nettbutikken vil bli levert ut fra beholdning i {{Warehouse}}.",
  "PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION_2": "For a bestille denne varen fra annen lokasjon krever at du tar kontakt med Otto Olsen direkte.",
  "CHANGES_MAY_OCCUR": "Endringer kan forekomme",
  "STOCK_STATUS_LABEL": "Lagerstatus"
}