import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import {
  JeevesCustomerActionConfigService,
  JeevesCustomerDeliveryLocationListConfigService,
  JeevesCustomerDetailsComponent,
  JeevesDeliveryAddressesService
} from 'gung-standard-jeeves';
import {
  AuthService,
  CustomerService,
  LocationConfigService,
  NavbarConfigService,
  NavigationConfig,
  RouterEventsService
} from 'gung-standard';
import { GungTabChangeEvent } from 'gung-common';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

@Component({
  selector: 'otto-olsen-customer-details',
  templateUrl: './otto-olsen-customer-details.component.html',
  styleUrls: ['./otto-olsen-customer-details.component.scss']
})
export class OttoOlsenCustomerDetailsComponent extends JeevesCustomerDetailsComponent implements OnInit, AfterViewInit {
  pageTitle = '';
  isSalesOrAdmin: boolean = false;
  preSelectTabId: string;
  
  constructor(
    route: ActivatedRoute,
    customerService: CustomerService,
    public configService: JeevesCustomerActionConfigService,
    public jeevesCustomerDeliveryLocationListConfigService: JeevesCustomerDeliveryLocationListConfigService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected authService: AuthService,
    protected routerEventsService: RouterEventsService,
    protected locationConfigService: LocationConfigService,
    navbarConfigService: NavbarConfigService,
    protected titleService: Title,
    protected translateService: TranslateService,
    protected router: Router,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    super(
      route,
      customerService,
      configService,
      jeevesCustomerDeliveryLocationListConfigService,
      authService,
      routerEventsService,
      locationConfigService,
      navbarConfigService,
      environment
    );

    if (route.snapshot.queryParams.tabId) {
      this.preSelectTabId = route.snapshot.queryParams.tabId;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isSalesOrAdmin = roles.findIndex(r => r === 'ADMIN') > -1 || roles.findIndex(r => r === 'SALES') > -1;
    });
  }

  ngAfterViewInit() {
    if (this.preSelectTabId) {
      this.ngbTab.select(this.preSelectTabId);
      delete this.preSelectTabId;
      this.router.navigate([], {
        queryParams: {
          'tabId': null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  tabChanged(event: GungTabChangeEvent) {
    // const tabId = Number(event.nextId.replace('lib-gung-tab-', ''));
    const tabs: string[] = (this.ngbTab.tabs as any)._results.map(t => t.id);
    const tabId = tabs.findIndex(t => t === event.nextId);
    if (event.nextId === 'orderTab' ) {
      this.pageTitle = this.translateService.instant('OTTO_OLSEN_ORDER_OVERVIEW');
      this.titleService.setTitle( this.pageTitle );
    } else {
      this.titleService.setTitle( 'Otto Olsen B2B' );
    }
    
    this.locationConfigService.add({
      path: 'customer-details',
      scrollX: tabId,
      scrollY: tabId
    });
  }
}
