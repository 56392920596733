import { Injectable } from '@angular/core';
import { AssortmentTreeImageSelectionService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenAssortmentTreeImageSelectionService extends AssortmentTreeImageSelectionService {
  public tooltipEnabled = false;

  constructor() {
    super();
  }
}
