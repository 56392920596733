import { Component, OnInit } from '@angular/core';
import { OttoOlsenInvoiceListConfigService } from '../../services/otto-olsen-invoice-list-config.service';

@Component({
  selector: 'otto-olsen-invoice-list',
  templateUrl: './otto-olsen-invoice-list.component.html',
  styleUrls: ['./otto-olsen-invoice-list.component.scss']
})
export class OttoOlsenInvoiceListComponent implements OnInit {
  // Had to overwrite the component and specify the config service specifically for some reason.
  // JeevesInvoiceListComponent would not take the overwritten config service.
  constructor(public configService: OttoOlsenInvoiceListConfigService) {}

  ngOnInit(): void {}
}
