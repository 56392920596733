import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScannedActionsSubject } from '@ngrx/store';
import { LoginComponent, AuthService, LoginCheckService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-login',
  templateUrl: './otto-olsen-login.component.html',
  styleUrls: ['./otto-olsen-login.component.scss']
})
export class OttoOlsenLoginComponent extends LoginComponent {
  onSubmit() {
    super.onSubmit();
    localStorage.removeItem('InterfaceMode');
  }
}
