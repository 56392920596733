<div class="barcode-footer">    
  <div class="d-flex btns-footer-scanner">
    <button type="button" class="btn btn-primary" [routerLink]="'/mobile-checkout'">
      <i class="far fa-shopping-cart"></i><br>
      <span translate>CART</span>
    </button>
    <button type="button" class="btn btn-primary" [routerLink]="'/search-scanner'">
      <i class="far fa-search"></i><br>
      <span translate>SEARCH</span>
    </button>
    <button type="button" class="btn btn-primary" [routerLink]="'/mobile'">
      <i class="fas fa-bars"></i><br>
      <span translate>MENU</span>
    </button>
    <button type="button" class="btn btn-primary" [routerLink]="'/barcode-scanner'">
      <i class="fas fa-barcode"></i><br>
      <span translate>SCAN_NEW</span>
    </button>
  </div>
</div>
