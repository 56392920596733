import { Injectable } from '@angular/core';
import { AuthService, CartService, PriceService, SelectedCustomerService } from 'gung-standard';
import { Observable, forkJoin, of } from 'rxjs';
import { first, mergeMap, map } from 'rxjs';

export interface OttoOlsenFreightCondition {
  totalValue: number;
  currency: string;
  cartRowsCount: number;
  customerApplyFreight: boolean;
  deliveryTerms?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenFreightCostService {
  public freightCostProductId = '9999135';

  constructor(
    protected priceService: PriceService,
    protected cartService: CartService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  calculateFreight(): Observable<OttoOlsenFreightCondition> {
    return forkJoin([
      this.cartService.getCurrentCart().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first())
    ]).pipe(
      mergeMap(([cart, customer]) => {
        const customerApplyFreight = !!customer?.extra?.kus?.expavgkd;
        const idQtyMap = cart.reduce((acc, curr) => ({ ...acc, [curr.productId]: curr.qty }), {});
        const ids = Object.keys(idQtyMap);
        if (!customerApplyFreight || ids.includes(this.freightCostProductId)) {
          return forkJoin({ rows: of([]), prices: of([]), customer: of(customer) });
        }
        return forkJoin({
          rows: of(cart),
          prices: this.priceService.getCartRowPricesObservable(cart).pipe(first()),
          customer: of(customer)
        });
      }),
      map(({ rows, prices, customer }) => {
        let deliveryTerms;
        let customerApplyFreight = !!customer?.extra?.kus?.expavgkd;
        const toReturnRows = rows.map(row => {
          const rowPrice = prices.find(r => r.productId === row.productId);
          if (!rowPrice) {
            throw new Error('Could not find mapped product or price');
          }
          return {
            price: rowPrice,
            cartRow: row
          };
        });
        const totalValue = toReturnRows
          .map(row => row.price.cartRowUnitPriceInclRowDiscount.value * row.cartRow.qty)
          .reduce((acc, curr) => acc + curr, 0);
        const currency = prices.length > 0 ? prices[0].customerNetPrice.currencyCode : '';
        if (customer?.extra.kus?.q_jis_fri_levering_grens && totalValue > customer.extra.kus.q_jis_fri_levering_grens) {
          customerApplyFreight = false;
          deliveryTerms = '62';
        }
        return {
          totalValue,
          currency,
          cartRowsCount: rows.length,
          customerApplyFreight,
          deliveryTerms
        } as OttoOlsenFreightCondition;
      })
    );
  }
}
