import { Component } from '@angular/core';
import { UserTableComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-user-table',
  templateUrl: './otto-olsen-user-table.component.html',
  styleUrls: ['./otto-olsen-user-table.component.scss']
})
export class OttoOlsenUserTableComponent extends UserTableComponent {

}
