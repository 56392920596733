<div class="table-responsive gung-responsive">
  <table class="table table-carts">
    <thead>
      <tr>
        <th translate>DATE</th>
        <th translate>NAME</th>
        <th translate>CUSTOMER_NAME</th>
        <th translate>CREATED</th>
        <th translate>LAST_UPDATED</th>
        <th width="1%"><!-- ACTION --></th>
        <th width="1%"><!-- ACTION --></th>
      </tr>
    </thead>
    <tbody *ngFor="let cart of data">
      <tr>
        <td [attr.data-label]="'DATE' | translate">{{ dateUtilService.parseDate(cart.creationDate) | date: dateUtilService.dateFormat }}</td>
        <td [attr.data-label]="'NAME' | translate">{{ cart.name }} 
          <i *ngIf="!isCartDetail" class="text-primary new-tab fa-solid fa-arrow-up-right-from-square" (click)="openCartDetailTab(cart)" ></i>
        </td>
        <td [attr.data-label]="'CUSTOMER_NAME' | translate">{{ cart.customerId ? cart.extra.customer?.name + ' (' + cart.customerId + ')' : '' }}</td>
        <td [attr.data-label]="'CREATED' | translate">
          <span *ngIf="cart.extra.created">
            <span translate>SAVED_CARTS_BY</span>
            {{ cart.extra.created.by }}
            <span translate>SAVED_CARTS_AT</span>
            {{ cart.extra.created.at | date: 'd.M.yyyy HH:mm' }}
          </span>
        </td>
        <td [attr.data-label]="'LAST_UPDATED' | translate">
          <span *ngIf="cart.extra.lastUpdated">
            <span translate>SAVED_CARTS_BY</span>
            {{ cart.extra.lastUpdated.by }}
            <span translate>SAVED_CARTS_AT</span>
            {{ cart.extra.lastUpdated.at | date: 'd.M.yyyy HH:mm' }}
          </span>
        </td>
        <td></td>
        <td>
          <div class="actions-buttons d-flex">
            <button type="button" class="btn btn-link p-0" (click)="loadCart(cart)">
              <i class="fas fa-cart-plus fa-2x"></i>
            </button>
            <button
              type="button"
              class="btn btn-link p-0 ml-3"
              (click)="$event.preventDefault(); $event.stopPropagation(); deleteCart(cart)"
            >
              <i class="fas fa-trash-alt fa-2x"></i>
            </button>
            <button
            type="button"
            class="btn btn-link p-0 ml-4"
            (click)="
              !_isCollapsedCart[cart.id] ? fetchProductData(cart) : undefined;
              _isCollapsedCart[cart.id] = !_isCollapsedCart[cart.id]
            "
          >
            <i
              class="fas fa-2x"
              [ngClass]="{ 'fa-angle-up': _isCollapsedCart[cart.id], 'fa-angle-down': !_isCollapsedCart[cart.id] }"
            ></i>
          </button>
          </div>

        </td>
      </tr>
      <tr #collapse="ngbCollapse" [ngbCollapse]="!_isCollapsedCart[cart.id]" (ngbCollapseChange)="_isCollapsedCart[cart.id] = $event">
        <div class="d-md-none text-right p-3">
          <button
            *ngIf="!cart.extra._isEditCart"
            type="button"
            class="btn btn-link p-0 mx-1"
            (click)="cart.extra._isEditCart = !cart.extra._isEditCart"
          >
            <i class="fas fa-edit fa-2x"></i>
          </button>
          <button
            *ngIf="cart.extra._isEditCart"
            type="button"
            class="btn btn-link p-0 mx-1"
            (click)="onSave(cart); cart.extra._isEditCart = !cart.extra._isEditCart"
          >
            <i class="fas fa-save fa-2x"></i>
          </button>
        </div>
        <td class="pl-3" colspan="6">
          <div class="table-responsive table-responsive gung-responsive" *ngIf="mapData[cart.id]; else loading">
            <table class="table table-sm table-borderless table-striped">
              <thead>
                <tr>
                  <th><span translate>PRODUCT_ID</span></th>
                  <th><span translate>NAME</span></th>
                  <th class="text-right"><span translate>QTY</span></th>
                  <th width="1%"><!-- refresh icon --></th>
                  <th class="text-right"><span translate>PRICE</span></th>
                  <th width="1%"><!-- ACTION --></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of mapData[cart.id]">
                  <td [attr.data-label]="'PRODUCT_ID' | translate">
                    <a [routerLink]="['/product/', row.productId]">
                      {{ row.productId }}
                    </a>
                  </td>
                  <td [attr.data-label]="'NAME' | translate" >
                    {{ row?.product?.name }}
                  </td>
                  <td [attr.data-label]="'QTY' | translate" class="edit-qty text-right">
                    <div *ngIf="cart.extra._isEditCart" class="edit-cart-buttons">
                      <button
                        type="button"
                        class="btn btn-link p-0"
                        (click)="refreshQty(row, cart)"
                      >
                        <i class="fas fa-redo"></i>
                      </button>
                      
                      <button
                        type="button"
                        class="btn btn-link py-0 ml-2"
                        (click)="deleteRow(row, cart)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                    <span *ngIf="!cart.extra._isEditCart">{{ row.qty }}</span>
                    <div *ngIf="cart.extra._isEditCart">
                      <div class="input-group-prepend">
                        <button
                          class="btn btn-primary btn-sm"
                          tabindex="-1"
                          (click)="sub(null,row, cart, inputEle); $event.preventDefault(); $event.stopPropagation()"
                        >
                          <i class="fas fa-minus" style="font-size: 1rem"></i>
                        </button>
                      </div>
                      <input
                        #inputEle
                        type="number"
                        class="form-control form-control-sm text-right"
                        [(ngModel)]="row.qty"
                        [ngModelOptions]="{ updateOn: 'blur' }"
                        (ngModelChange)="updateQuantity($event, row, cart, inputEle)"
                        (click)="$event.preventDefault(); $event.stopPropagation()"
                        [class.font-weight-bold]="checkModulus(row.qty, row.product?.extra.ar?.artfsgforp).multiple"
                        [step]="row.product?.packageSize || 1"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-sm btn-primary"
                          tabindex="-1"
                          (click)="add(null, row, cart, inputEle); $event.preventDefault(); $event.stopPropagation()"
                        >
                          <i class="fas fa-plus" style="font-size: 1rem"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td class="refresh">
                    <button
                      *ngIf="cart.extra._isEditCart"
                      type="button"
                      class="btn btn-link p-0"
                      (click)="refreshQty(row, cart)"
                    >
                      <i class="fas fa-redo"></i>
                    </button>
                  </td>
                  <td [attr.data-label]="'PRICE' | translate"  class="text-right price">
                    <lib-price
                      [price]="row?.product?.extra?.price?.value"
                      [currency]="row?.product?.extra?.price?.currencyCode"
                    ></lib-price>
                  </td>
                  <td class="delete-row">
                    <button
                      *ngIf="cart.extra._isEditCart"
                      type="button"
                      class="btn btn-link py-0"
                      (click)="deleteRow(row, cart)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-uppercase text-right"><span translate>CART_SUBTOTAL</span></td>
                  <td></td>
                  <td>
                    <lib-price [price]="cart.extra._total" [currency]="cart.extra._currencyCode"></lib-price>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </td>
        <td>
          <div class="hide-mobile d-flex justify-content-center align-items-center">
            <button
              *ngIf="!cart.extra._isEditCart"
              type="button"
              class="btn btn-link p-0 mx-1"
              (click)="cart.extra._isEditCart = !cart.extra._isEditCart"
            >
              <i class="fas fa-edit fa-2x"></i>
            </button>
            <button
              *ngIf="cart.extra._isEditCart"
              type="button"
              class="btn btn-link p-0 mx-1"
              (click)="onSave(cart); cart.extra._isEditCart = !cart.extra._isEditCart"
            >
              <i class="fas fa-save fa-2x"></i>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <div class="row">
    <div class="col-12 m-2 p-2 text-center">
      <i class="fa fa-spinner fa-spin-lg"></i>
    </div>
  </div>
</ng-template>
