import { Component, OnInit, OnChanges, OnDestroy, Optional } from '@angular/core';
import { JeevesDeliveryAddressesService, SummarizeOrderJeevesComponent } from 'gung-standard-jeeves';
import {
  AuthService,
  CartRow,
  CartService,
  CheckoutRow,
  CustomerService,
  PriceService,
  Product,
  ProductService,
  PriceConfigService,
  GungGoogleTagManagerService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'otto-olsen-summarize-order',
  templateUrl: './otto-olsen-summarize-order.component.html',
  styleUrls: ['./otto-olsen-summarize-order.component.scss']
})
export class OttoOlsenSummarizeOrderComponent extends SummarizeOrderJeevesComponent implements OnInit, OnChanges, OnDestroy {
  unsubscribe: Subject<boolean> = new Subject<boolean>();
  productsMapExtra?: { [productId: string]: Product };
  cartRowsProducts: CheckoutRow[];
  cartRowsExtra: CheckoutRow[];
  public hidePrice: boolean;

  constructor(
    protected customerService: CustomerService,
    protected productService: ProductService,
    protected authService: AuthService,
    protected priceService: PriceService,
    public dateUtilService: DateUtilService,
    private cartService: CartService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected priceConfigService: PriceConfigService,
    @Optional()
    protected gungGoogleTagManagerService: GungGoogleTagManagerService
  ) {
    super(customerService, productService, authService, priceService, dateUtilService, jeevesDeliveryAddressesService, gungGoogleTagManagerService);
    this.priceConfigService.hidePrice.pipe(takeUntil(this.unsubscribe)).subscribe(hidePrice => (this.hidePrice = hidePrice));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }

  protected mapProducts(ids: string[]) {
    this.productService.getProductsByIds(ids).subscribe(products => {
      this.productsMap = {};
      products.forEach(product => (this.productsMap[product.id] = product));

      const cartRowsProducts: string[] = [];
      const cartRowsExtra: string[] = [];
      for (const row of this.checkout.rows) {
        const product = products.find(p => p.id === row.id);
        const qty = row.quantity;
        let originalSize;
        let packageSize;
        if (product?.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
          originalSize = Number(product.extra.ar.artfsgforp);
        }
        if (product?.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
          packageSize = Number(product.extra.ar.q_salesbatchsize);
        }

        if (originalSize && !Number.isInteger(qty / originalSize) && product?.extra.ar?.q_jis_kaltillegg_artnr) {
          // Cutting fee product
          cartRowsExtra.push(product.extra.ar?.q_jis_kaltillegg_artnr);
        }
        if (product) {
          cartRowsProducts.push(row.id);
        }

        row.extra.googleAnalytics = 
        {
          name: this.productsMap[row.id]?.name,
          price: this.pricesMap[row.id].cartRowUnitPriceInclRowDiscount.value,
          currency: this.pricesMap[row.id].cartRowUnitPriceInclRowDiscount.currencyCode,
          hasDiscount: this.pricesMap[row.id].cartRowDiscountPercent > 0,
          discount: this.pricesMap[row.id].cartRowDiscountPercent
        };
      }

      this.cartRowsProducts = this.checkout.rows.filter(row => cartRowsProducts.findIndex(id => id === row.id) > -1);
      this.cartRowsExtra = this.checkout.rows.filter(row => cartRowsExtra.findIndex(id => id === row.id) > -1);

      this.productService.getFullProductsByIds(this.cartRowsExtra.map(r => r.id)).subscribe(productsV2 => {
        // productsV2.forEach(product => this.productsMap[product.id] = product);
        this.productsMapExtra = {};
        productsV2.forEach(product => (this.productsMapExtra[product.id] = product));
      });
    });
  }

  protected nextBtnClickedCb(nextInfo?: any) {
    for (const cartRow of this.cartRowsExtra) {
      const row = {
        productId: cartRow.id,
        productPartialId: cartRow.partialId,
        targetStockId: cartRow.targetStockId
      };
      this.checkout.rows = this.checkout.rows.filter(r => r !== cartRow);
      this.cartService.removeRow(row as CartRow);
    }
    super.nextBtnClickedCb(nextInfo);
  }
}
