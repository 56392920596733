<form [formGroup]="form" *ngIf="!loading && form" (ngSubmit)="submitForm()">
  <div class="row">
    <div class="col-12 col-md-6" formGroupName="ar">
      <b>Vareinformasjon</b>
      <table style="width: 100%">
        <tr>
          <td>
            <lib-gung-text-input
              gungTranslate="Varebeskrivelse"
              formControlName="artbeskr"
              tooltip="TOOLTIP_VAREBESKRIVELSE"
            ></lib-gung-text-input>
            <lib-forms-message-error
              [controller]="form.get(['ar', 'artbeskr'])"
              [errorMessages]="{ PROHIBITEDCHARACTERSVALIDATOR: 'PROHIBITED_CHARACTERS_VALIDATOR' }"
            ></lib-forms-message-error>
          </td>
          <td>
            <lib-gung-text-input
              gungTranslate="Varespesifikasjon"
              formControlName="artbeskrspec"
              tooltip="TOOLTIP_VARESPESIFIKASJON"
            ></lib-gung-text-input>
            <lib-forms-message-error
              [controller]="form.get(['ar', 'artbeskrspec'])"
              [errorMessages]="{ PROHIBITEDCHARACTERSVALIDATOR: 'PROHIBITED_CHARACTERS_VALIDATOR' }"
            ></lib-forms-message-error>
          </td>
        </tr>
      </table>
      <lib-gung-text-area-input
        gungTranslate="Varebeskrivelse 2"
        formControlName="artbeskr2"
        tooltip="TOOLTIP_VAREBESKRIVELSE2"
        (keyup)="onKeyUp($event, form.get(['ar', 'artbeskr2']), 75)"
      >
      </lib-gung-text-area-input>
      <lib-forms-message-error [controller]="form.get(['ar', 'artbeskr2'])"></lib-forms-message-error>
      <table style="width: 100%">
        <tr>
          <td>
            <lib-gung-text-input
              gungTranslate="Søketekst"
              formControlName="extra1"
              tooltip="TOOLTIP_EXTRA1"
            ></lib-gung-text-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'extra1'])"></lib-forms-message-error>
          </td>
          <td>
            <label for="typeahead-prevent-manual-entry-varugruppkod">
              <span translate>Varegruppekode</span>
              <i class="fal fa-question-circle" ngbTooltip="{{ 'TOOLTIP_VAREGRUPPEKODE' | translate }}"></i>
            </label>
            <input
              #varugruppKod
              id="typeahead-prevent-manual-entry-varugruppkod"
              type="text"
              class="form-control"
              [(ngModel)]="modelSearches[fieldFormatter('ar.varugruppkod')]"
              [ngbTypeahead]="search[fieldFormatter('ar.varugruppkod')]"
              [inputFormatter]="varugruppkodDisplay"
              [resultFormatter]="varugruppkodResultFormatter"
              (selectItem)="selectVarugruppkodItem($event, 'ar.varugruppkod')"
              (focus)="openOptions($event)"
              (ngModelChange)="ngModelChangeVarugruppkod($event, 'ar.varugruppkod')"
              [ngModelOptions]="{ standalone: true }"
              [editable]="true"
            />
            <lib-forms-message-error [controller]="form.get(['ar', 'varugruppkod'])"></lib-forms-message-error>
          </td>
        </tr>
      </table>
      <!-- <lib-meta-select-input
        gungTranslate="ProduktKlasse"
        formControlName="artprodklass"
        table="xp"
        metaId="artprodklass"
        metaDisplay="artprodklbeskr"
        showId="true"
        tooltip="TOOLTIP_PRODUKTKLASSE"
      >
      </lib-meta-select-input> -->
      <table style="width: 100%">
        <tr>
          <td>
            <label for="typeahead-prevent-manual-entry-artprodklass">
              <span translate>ProduktKlasse</span>
              <i class="fal fa-question-circle" ngbTooltip="{{ 'TOOLTIP_PRODUKTKLASSE' | translate }}"></i>
            </label>
            <input
              #artprodklass
              id="typeahead-prevent-manual-entry-artprodklass"
              type="text"
              class="form-control"
              [(ngModel)]="modelSearches[fieldFormatter('ar.artprodklass')]"
              [ngbTypeahead]="search[fieldFormatter('ar.artprodklass')]"
              [inputFormatter]="artprodklassDisplay"
              [resultFormatter]="artprodklassResultFormatter"
              (selectItem)="selectArtprodklassItem($event, 'ar.artprodklass')"
              (focus)="openOptions($event)"
              (ngModelChange)="ngModelChangeArtprodklass($event, 'ar.artprodklass')"
              [ngModelOptions]="{ standalone: true }"
              [editable]="true"
            />
            <lib-forms-message-error [controller]="form.get(['ar', 'artprodklass'])"></lib-forms-message-error>
          </td>
          <td>
            <label for="typeahead-prevent-manual-entry-artrabklass">
              <span translate>Rabattgruppe</span>
              <i class="fal fa-question-circle" ngbTooltip="{{ 'TOOLTIP_RABATTGRUPPE' | translate }}"></i>
            </label>
            <input
              #artrabklass
              id="typeahead-prevent-manual-entry-artrabklass"
              type="text"
              class="form-control"
              [(ngModel)]="modelSearches[fieldFormatter('ar.artrabklass')]"
              [ngbTypeahead]="search[fieldFormatter('ar.artrabklass')]"
              [inputFormatter]="artrabklassDisplay"
              [resultFormatter]="artrabklassResultFormatter"
              (selectItem)="selectArtrabklassItem($event, 'ar.artrabklass')"
              (focus)="openOptions($event)"
              (ngModelChange)="ngModelChangeArtrabklass($event, 'ar.artrabklass')"
              [ngModelOptions]="{ standalone: true }"
              [editable]="true"
            />
            <lib-forms-message-error [controller]="form.get(['ar', 'artrabklass'])"></lib-forms-message-error>
          </td>
        </tr>
      </table>
      <table style="width: 100%">
        <tr>
          <td>
            <lib-meta-select-input
              gungTranslate="Enhet"
              formControlName="enhetskod"
              table="xen"
              metaId="enhetskod"
              metaDisplay="enhetskodbeskr"
              tooltip="TOOLTIP_ENHET"
            >
            </lib-meta-select-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'enhetskod'])"></lib-forms-message-error>
          </td>
          <td>
            <lib-gung-select-option-input
              gungTranslate="Antall desimaler"
              [optionsList]="[
                { id: 0, name: '0' },
                { id: 1, name: '1' },
                { id: 2, name: '2' },
                { id: 3, name: '3' }
              ]"
              formControlName="antdec"
              tooltip="TOOLTIP_ANTALL_DESIMALER"
            >
            </lib-gung-select-option-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'antdec'])"></lib-forms-message-error>
          </td>
          <td>
            <lib-gung-number-input
              gungTranslate="Enhetsvekt (kg)"
              formControlName="artvikt"
              decimals="4"
              tooltip="TOOLTIP_ENHETSVEKT"
            >
            </lib-gung-number-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'artvikt'])"></lib-forms-message-error>
          </td>
        </tr>
      </table>
      <table style="width: 100%">
        <tr>
          <td style="width: 20%">
            <lib-gung-text-input
              gungTranslate="Pakkestørrelse"
              formControlName="artfsgforp"
              tooltip="TOOLTIP_PAKKESTORRELSE"
            >
            </lib-gung-text-input>
            <lib-forms-message-error
              [controller]="form.get(['ar', 'artfsgforp'])"
              [errorMessages]="{ NUMBERANDCOMMAVALIDATOR: 'NUMBER_COMMA_VALIDATION' }"
            ></lib-forms-message-error>
          </td>
          <td style="width: 40%">
            <label for="typeahead-prevent-manual-entry-2">
              <span translate>HS-kode</span>
              <i class="fal fa-question-circle" ngbTooltip="{{ 'TOOLTIP_HS_KODE' | translate }}"></i>
            </label>
            <input
              #hsKodeElement
              id="typeahead-prevent-manual-entry-2"
              type="text"
              class="form-control"
              [(ngModel)]="modelSearches[fieldFormatter('ar.artstatnr')]"
              [ngbTypeahead]="search[fieldFormatter('ar.artstatnr')]"
              [inputFormatter]="hsKodeDisplay"
              [resultFormatter]="hsKodeResultFormatter"
              (selectItem)="selectHrKodeItem($event, 'ar.artstatnr')"
              (focus)="openOptions($event)"
              (ngModelChange)="ngModelChangeHrKode($event, 'ar.artstatnr')"
              [ngModelOptions]="{ standalone: true }"
              [editable]="true"
            />
            <lib-forms-message-error [controller]="form.get(['ar', 'artstatnr'])"></lib-forms-message-error>
          </td>
          <td style="width: 40%">
            <label for="typeahead-prevent-manual-entry-3">
              <span translate>Opprinnelsesland</span>
              <i class="fal fa-question-circle" ngbTooltip="{{ 'TOOLTIP_OPPRINNELSESLAND' | translate }}"></i>
            </label>
            <input
              #countryElement
              id="typeahead-prevent-manual-entry-3"
              type="text"
              class="form-control"
              [(ngModel)]="modelSearches[fieldFormatter('ar.ursprungsland')]"
              [ngbTypeahead]="search[fieldFormatter('ar.ursprungsland')]"
              [inputFormatter]="countryDisplay"
              [resultFormatter]="countryResultFormatter"
              (selectItem)="selectCountryItem($event, 'ar.ursprungsland')"
              (focus)="openOptions($event)"
              (ngModelChange)="ngModelChangeCountry($event, 'ar.ursprungsland')"
              [ngModelOptions]="{ standalone: true }"
              [editable]="true"
            />
            <lib-forms-message-error [controller]="form.get(['ar', 'ursprungsland'])"></lib-forms-message-error>
          </td>
        </tr>
      </table>
      <table style="width: 100%">
        <tr>
          <td style="width: 50%">
            <lib-meta-select-input
              class="tooltip-wide"
              gungTranslate="Varetype"
              formControlName="itemtypecd1"
              table="atp1"
              metaId="itemtypecd1"
              metaDisplay="itemtypedesc"
              showId="true"
              tooltip="TOOLTIP_VARETYPE"
            >
            </lib-meta-select-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'itemtypecd1'])"></lib-forms-message-error>
          </td>
          <td style="width: 50%">
            <ng-container
              *ngTemplateOutlet="
                searchElement;
                context: {
                  $implicit: null,
                  index: null,
                  placeholder: null,
                  label: 'Avtalekunde',
                  tooltip: 'TOOLTIP_AVTALEKUNDE',
                  fieldName: 'ar.ftgnr'
                }
              "
            >
            </ng-container>
            <lib-forms-message-error [controller]="form.get(['ar', 'ftgnr'])"></lib-forms-message-error>
          </td>
        </tr>
      </table>
      <table style="width: 100%">
        <tr>
          <td>
            <lib-meta-select-input
              class="tooltip-wide"
              gungTranslate="Lagerføringstype"
              formControlName="lagtyp"
              table="xz"
              metaId="lagtyp"
              metaDisplay="lagtypbeskr"
              showId="true"
              tooltip="TOOLTIP_LAGTYP"
              [optionsFilterFunctionFullRecord]="isAllowedLagtyp.bind(this)"
            >
            </lib-meta-select-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'lagtyp'])"></lib-forms-message-error>
          </td>
          <td>
            <lib-gung-number-input
              gungTranslate="Holdbarhet i dager"
              formControlName="qshelflifeindays"
              tooltip="TOOLTIP_SQSHELFLIFEINDAYS"
            >
            </lib-gung-number-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'qshelflifeindays'])"></lib-forms-message-error>
          </td>
          <td>
            <lib-gung-select-option-input
              gungTranslate="Sertifikatklasse"
              [optionsList]="[
                { id: 10, name: 'Må ha certifikat' },
                { id: '', name: '' }
              ]"
              formControlName="q_jis_classid"
              tooltip="TOOLTIP_QJISCLASSID"
            >
            </lib-gung-select-option-input>
            <lib-forms-message-error [controller]="form.get(['ar', 'q_jis_classid'])"></lib-forms-message-error>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-12 col-md-6">
      <div formGroupName="al">
        <b>Leverandør</b><br />
        <table style="width: 100%">
          <tr>
            <td>
              <ng-container
                *ngTemplateOutlet="
                  searchElement;
                  context: {
                    $implicit: null,
                    index: null,
                    placeholder: null,
                    label: 'Leverandør',
                    tooltip: 'TOOLTIP_LEVERANDOR',
                    fieldName: 'al.levnr'
                  }
                "
              >
              </ng-container>
              <lib-forms-message-error [controller]="form.get(['al', 'levnr'])"></lib-forms-message-error>
            </td>
            <td>
              <lib-gung-text-input
                gungTranslate="Lev. varenummer"
                formControlName="artnrlev"
                tooltip="TOOLTIP_LEVVARENUMMER"
                (focusout)="checkIfSupplierArticleNumberAlreadyExists()"
              ></lib-gung-text-input>
              <div
                *ngIf="testRegex('/^\\S*$/', form?.get(['al', 'artnrlev'])?.value)"
                class="alert alert-warning"
                role="alert"
              >
                <span translate>FORM_ERROR_NO_SPACE</span>
              </div>
              <span class="badge badge-danger mb-1" *ngIf="productIdAlreadyExists">
                <i class="fas fa-exclamation-triangle mr-1"></i>
                <span translate>ARTNRLEV_ALREADY_EXISTS</span>
              </span>
              <lib-forms-message-error
                [controller]="form.get(['al', 'artnrlev'])"
                [errorMessages]="{ FORM_ERROR_PATTERN: 'FORM_ERROR_NO_SPACE' }"
              ></lib-forms-message-error>
            </td>
          </tr>
        </table>
        <table style="width: 100%">
          <tr>
            <td>
              <lib-gung-text-input
                gungTranslate="Lev. varenavn"
                formControlName="levsartbeskr"
                tooltip="TOOLTIP_LEVVARENAVN"
              >
              </lib-gung-text-input>
              <lib-forms-message-error [controller]="form.get(['al', 'levsartbeskr'])"></lib-forms-message-error>
            </td>
          </tr>
        </table>
        <lib-gung-text-area-input
          gungTranslate="Leverandør varespesifikasjon"
          formControlName="levvarespesifikasjon"
          tooltip="TOOLTIP_LEVERANDOR_VARESPESIFIKASJON"
          (keyup)="onKeyUp($event, form.get(['al', 'levvarespesifikasjon']), 75)"
        >
        </lib-gung-text-area-input>
        <lib-forms-message-error [controller]="form.get(['al', 'levvarespesifikasjon'])"></lib-forms-message-error>
        <table style="width: 100%">
          <tr>
            <td>
              <lib-gung-number-input
                gungTranslate="Innpris (valuta)"
                formControlName="vb_inpris_netto"
                (ngModelChange)="inPriceFieldChanged.next()"
                tooltip="TOOLTIP_INNPRIS_VALUTA"
              >
              </lib-gung-number-input>
              <lib-forms-message-error [controller]="form.get(['al', 'vb_inpris_netto'])"></lib-forms-message-error>
            </td>
            <td>
              <lib-gung-number-input
                gungTranslate="Rabatt"
                formControlName="rabatt1"
                tooltip="TOOLTIP_RABATT"
                (ngModelChange)="inPriceFieldChanged.next()"
              ></lib-gung-number-input>
              <lib-forms-message-error [controller]="form.get(['al', 'rabatt1'])"></lib-forms-message-error>
            </td>
            <td>
              <lib-meta-select-input
                gungTranslate="Valuta"
                formControlName="valkod"
                table="xx"
                metaId="valkod"
                metaDisplay="valkod"
                tooltip="TOOLTIP_VALUTA"
                (ngModelChange)="inPriceFieldChanged.next()"
              >
              </lib-meta-select-input>
              <lib-forms-message-error [controller]="form.get(['al', 'valkod'])"></lib-forms-message-error>
            </td>
          </tr>
        </table>
        <table style="width: 100%">
          <tr>
            <td>
              <lib-gung-number-input
                gungTranslate="Pris enhetsfaktor"
                formControlName="inkprisper"
                tooltip="TOOLTIP_PRISENHETSFAKTOR"
                (ngModelChange)="inPriceFieldChanged.next()"
              ></lib-gung-number-input>
              <lib-forms-message-error [controller]="form.get(['al', 'inkprisper'])"></lib-forms-message-error>
            </td>
            <td>
              <lib-gung-number-input
                gungTranslate="Innpris netto (NOK)"
                formControlName="artinpris"
                [renderStatic]="true"
                tooltip="TOOLTIP_INPRIS_NETTO"
              >
              </lib-gung-number-input>
              <lib-forms-message-error [controller]="form.get(['al', 'artinpris'])"></lib-forms-message-error>
            </td>
            <td>
              <lib-gung-number-input
                gungTranslate="Markup"
                formControlName="markupFactor"
                [renderStatic]="true"
                tooltip="TOOLTIP_MARKUP"
              ></lib-gung-number-input>
              <lib-forms-message-error [controller]="form.get(['al', 'markupFactor'])"></lib-forms-message-error>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td></td>
          </tr>
        </table>
        <div class="row">
          <div class="col-10">
            <lib-gung-number-input
              gungTranslate="Listepris (NOK)"
              formControlName="artlistpris"
              tooltip="TOOLTIP_LISTEPRIS"
              [renderStatic]="!allowEditListPrice"
            >
            </lib-gung-number-input>

            <div
              class="alert alert-warning"
              *ngIf="allowEditListPrice && listPrice && purchasePriceNet && listPrice / purchasePriceNet < 2"
              translate
            >
              MARKUP_BELOW_TWO
            </div>
          </div>
          <div class="col-1 pl-2 mt-1 text-right">
            <input
              type="checkbox"
              class="form-check-input"
              [checked]="allowEditListPrice"
              (change)="allowEditListPrice = !allowEditListPrice"
            />
            <span translate>EDIT</span>
          </div>
        </div>
        <lib-forms-message-error [controller]="form.get(['al', 'artlistpris'])"></lib-forms-message-error>
        <table style="width: 100%">
          <tr>
            <td>
              <lib-gung-text-input
                gungTranslate="Pakkestørrelse innkjøp"
                formControlName="multipelextqty"
                tooltip="TOOLTIP_PAKKESTORRELSE_INNKJOP"
              >
              </lib-gung-text-input>
              <lib-forms-message-error
                [controller]="form.get(['al', 'multipelextqty'])"
                [errorMessages]="{ NUMBERANDCOMMAVALIDATOR: 'NUMBER_COMMA_VALIDATION' }"
              ></lib-forms-message-error>
            </td>
            <td>
              <lib-gung-text-input
                gungTranslate="Ledetid"
                formControlName="ledtidenllev"
                tooltip="TOOLTIP_LEDETID"
              ></lib-gung-text-input>
              <lib-forms-message-error [controller]="form.get(['al', 'ledtidenllev'])"></lib-forms-message-error>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <lib-gung-text-area-input
          gungTranslate="NOTES_TO_PRODUCT_RESPONSIBLE"
          formControlName="productNotes"
          tooltip="TOOLTIP_NOTE"
          (keyup)="onKeyUp($event, form.get(['productNotes']), 75)"
        >
        </lib-gung-text-area-input>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-4">
      <div *ngIf="isCreatingProduct" class="alert alert-danger">Creating product</div>
      <div *ngIf="!isCreatingProduct && createdProduct" class="alert alert-info">
        Product created with id {{ createdProduct.id }}. <a href="/product/{{ createdProduct.id }}">Go to product</a>
      </div>
      <div *ngIf="!isCreatingProduct && error" class="alert alert-info">
        Product create error<br />
        <pre>{{ error.error | json }}</pre>
      </div>
    </div>
    <div class="col-12 text-right">
      <button type="button" class="btn btn-block btn-primary" type="submit" translate>CREATE</button>
    </div>
  </div>
</form>

<ng-template
  #searchElement
  let-searchmodel
  let-index="index"
  let-placeholder="placeholder"
  let-label="label"
  let-fieldName="fieldName"
  let-tooltip="tooltip"
>
  <label for="typeahead-prevent-manual-entry" class="control-label">
    <span translate>{{ label }} </span>
    <i class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <input
    #searchInput
    id="typeahead-prevent-manual-entry"
    type="text"
    class="form-control"
    [(ngModel)]="modelSearches[fieldFormatter(fieldName)]"
    [ngbTypeahead]="search[fieldFormatter(fieldName)]"
    [inputFormatter]="display"
    [resultFormatter]="resultFormatter"
    (selectItem)="selectItem($event, searchInput, fieldName)"
    placeholder="{{ placeholder | translate }}"
    (focus)="openOptions($event)"
    (focusout)="focusout($event, modelSearches[fieldFormatter(fieldName)])"
    (blur)="checkIfEmpty($event.target.value, fieldName)"
    (ngModelChange)="ngModelChange($event, fieldName)"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="isDisabled(fieldName)"
    [showHint]="false"
  />
  <!-- {standalone: true, updateOn: 'blur'} -->
</ng-template>
