import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RemoveBlockedProductsStepComponent, ProductService, CartService, GungModalService } from 'gung-standard';
import { forkJoin, of } from 'rxjs';
import { first } from 'rxjs';
import { OttoOlsenFreightCostService } from '../../../../services/otto-olsen-freight-cost.service';

@Component({
  selector: 'otto-olsen-remove-blocked-products-step',
  template: '',
  styles: []
})
export class OttoOlsenRemoveBlockedProductsStepComponent extends RemoveBlockedProductsStepComponent {
  ignoreBlockedProducts = [this.freightCostService.freightCostProductId];

  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected modalService: GungModalService,
    protected translateService: TranslateService,
    private freightCostService: OttoOlsenFreightCostService
  ) {
    super(productService, cartService, modalService, translateService);
  }

  protected checkBlockedProducts(): void {
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      this.cartService
        .getCurrentCart()
        .pipe(first())
        .subscribe(currentCartRows => {
          const productIds = currentCartRows
            .map(cartRow => cartRow.productId)
            .filter(id => !this.ignoreBlockedProducts.includes(id));

          forkJoin({
            cartRows: of(currentCartRows),
            blockedProducts: this.productService.getBlockedProducts(productIds).pipe(first())
          }).subscribe(({ cartRows, blockedProducts }) => {
            const blockedProductsIds: string[] = [];
            const rowsToRemove: any[] = [];
            for (const productId of Object.keys(blockedProducts)) {
              // in case product is blocked then remove it from the cart
              if (blockedProducts[productId]) {
                const cartRow = cartRows.find(cr => cr.productId === productId);
                if (!!cartRow) {
                  rowsToRemove.push(cartRow);
                  blockedProductsIds.push(productId);
                }
              }
            }
            this.cartService.bulkRemoveRows(rowsToRemove);

            if (blockedProductsIds.length > 0) {
              this.modalService
                .openConfirmYesNoModal(
                  this.translateService.instant('BLOCKED_PRODUCTS'),
                  this.translateService.instant('BLOCKED_PRODUCTS_MESSAGE', {
                    products: blockedProductsIds.join(', ')
                  }),
                  null,
                  'OK',
                  null
                )
                .then(_ => {});
            }

            this.stepDone.emit(this.checkout);
          });
        });
    });
  }
}
