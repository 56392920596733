<div *ngIf="order">
  <div class="d-flex flex-row justify-content-between align-items-center mb-4 mt-4">
    <div>
      <h2 *ngIf="order.extra.oh.ordstat !== 0">Order {{ order.id }}</h2>
      <h2 *ngIf="order.extra.oh.ordstat === 0">Quote {{ order.id }}</h2>
      <h6 *ngIf="order.extra.deliveryCustomer">{{ order.extra.deliveryCustomer.name }}</h6>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <lib-gung-accordion>
        <lib-gung-accordion-item [collapsed]="false">
          <ng-template libGungAccordionHeader>{{ 'DETAILS_AND_CONDITIONS' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col col-xs-12 col-md-6">
                <h5 translate>DETAILS</h5>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ORDER_NUMBER"
                  [(ngModel)]="order.extra.oh.ordernr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="YOUR_REFERENCE_NO"
                  [(ngModel)]="order.extra.oh.kundbestnr"
                ></lib-gung-text-input-horizontal>
                <lib-gung-meta-output
                  [value]="order.extra.oh.ordstat"
                  [gungTranslate]="'ORDER_STATUS'"
                  metadataTable="x7"
                  metaOptionName="ordstatbeskr"
                  [horizontal]="true"
                ></lib-gung-meta-output>
                <lib-gung-date-output
                  [horizontal]="true"
                  [gungTranslate]="'ORDER_DATE'"
                  [value]="order.extra.oh.orddatum"
                >
                </lib-gung-date-output>
                <lib-gung-date-output
                  [horizontal]="true"
                  [gungTranslate]="'ESTIMATED_DELIVERY_DATE'"
                  [value]="order.extra.oh.ordberlevdat"
                >
                </lib-gung-date-output>
              </div>
              <div class="col col-xs-12 col-md-6">
                <h5 translate>CONDITIONS</h5>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'PAYMENT_TERMS'"
                  [value]="order.extra.oh.betkod"
                  metadataTable="x2"
                  metaOptionName="betvillbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_TERMS'"
                  [value]="order.extra.oh.levvillkkod"
                  metadataTable="x2e"
                  metaOptionName="levvillkbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_METHOD'"
                  [value]="order.extra.oh.levsattkod"
                  metadataTable="x2f"
                  metaOptionName="levsattbeskr"
                >
                </lib-gung-meta-output>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item>
          <ng-template libGungAccordionHeader>{{ 'SHIPPING_INFORMATION' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col col-12">
                <h5 translate>ADDRESS</h5>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="CUSTOMER_REFERENCE"
                  [(ngModel)]="order.extra.oh.kundref2"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="OH_ORDLEVADR1"
                  [(ngModel)]="order.extra.oh.ordlevadr1"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="OH_ORDLEVADR2"
                  [(ngModel)]="order.extra.oh.ordlevadr2"
                ></lib-gung-text-input-horizontal>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="OH_ORDLEVADR3"
                  [(ngModel)]="order.extra.oh.ordlevadr3"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ZIP"
                  [(ngModel)]="order.extra.oh.ftgpostnr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="OH_ORDLEVADR4"
                  [(ngModel)]="order.extra.oh.ordlevadr4"
                ></lib-gung-text-input-horizontal>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'COUNTRY'"
                  [value]="order.extra.oh.ordlevadrlandskod"
                  metadataTable="xr"
                  metaOptionName="land"
                >
                </lib-gung-meta-output>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item>
          <ng-template libGungAccordionHeader>{{ 'ADDITIONAL_INFORMATION' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="form-group row">
              <label for="oh.editext" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                >MESSAGE</label
              >
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="editext"
                  id="oh.editext"
                  disabled="true"
                  [(ngModel)]="order.extra.oh.editext"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="oh.edit" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                >INTERNAL_TEXT</label
              >
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="edit"
                  id="oh.edit"
                  disabled="true"
                  [(ngModel)]="order.extra.oh.edit"
                ></textarea>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>     
      </lib-gung-accordion>
    </div>
  </div>
  <div class="row">
    <div class="col-12 table-responsive gung-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>PRODUCT_ID</th>
            <th translate>NAME</th>
            <th translate>ESTIMATED_DELIVERY_DATE</th>
            <th translate>QUANTITY</th>
            <th translate>UNIT_PRICE</th>
            <th translate>TOTAL_PRICE</th>
          </tr>
        </thead>
        <tbody *ngIf="order">
          <tr *ngFor="let row of order.rows">
            <td [attr.data-label]="'PRODUCT_ID' | translate">
              <a [routerLink]="row.extra._path ? row.extra._path : ['/product/', row.productId]">{{ row.productId }}</a>
            </td>
            <td [attr.data-label]="'NAME' | translate">
              <!-- GET NAME HERE IF NOT EXIST ON ARTBESKR -->
              <a [routerLink]="row.extra._path ? row.extra._path : ['/product/', row.productId]">{{ row.extra.orp.artbeskr || row.name }}</a>
            </td>
            <td [attr.data-label]="'ESTIMATED_DELIVERY_DATE' | translate">
              {{ row.extra.orp.ordberlevdat | date: dateUtilService.dateFormat }}
            </td>
            <td [attr.data-label]="'QUANTITY' | translate">
              {{ row.quantity }}
            </td>
            <td [attr.data-label]="'UNIT_PRICE' | translate">
              {{ row.extra.orp.vb_pris | gungCurrency: order.extra.oh.valkod:'symbol-narrow':'1.2-2' }}
            </td>
            <td [attr.data-label]="'TOTAL_PRICE' | translate">
              {{ row.extra.orp.vb_pris * row.quantity | gungCurrency: order.extra.oh.valkod:'symbol-narrow':'1.2-2' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
