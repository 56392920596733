<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>RECENT_ORDERS</div>

    <div class="table-responsive-sm">
      <table class="table table-borderless table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" translate>ORDER_ID</th>
            <th scope="col" translate>ORDER_STATUS</th>
            <!-- <th scope="col" translate>CUSTOMER_NAME</th> -->
            <th scope="col" translate>ORDER_DATE</th>
            <th scope="col" translate>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of orders" (click)="goToDetail(order)">
            <td [attr.data-label]="'ORDER_ID' | translate">{{ order.id }}</td>
            <td [attr.data-label]="'ORDER_STATUS' | translate">
              {{ metadataLookup('x7', 'ordstatbeskr', order.extra.oh.ordstat) }}
            </td>
            <!-- <td [attr.data-label]="'CUSTOMER_NAME' | translate">{{ order.extra.salesOrders.customerName || order.extra.deliveryCustomer.name || '-' }}</td> -->
            <td [attr.data-label]="'ORDER_DATE' | translate">
              {{ order.extra.oh.orddatum | date: dateUtilService.dateFormat }}
            </td>
            <td [attr.data-label]="'TOTAL' | translate">
              <lib-price
                [price]="order.extra.oh.ohvbordsum"
                [currency]="order.extra.oh.valkod"
                class="form-control-plaintext p-0"
              ></lib-price>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
