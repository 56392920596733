import { Component } from '@angular/core';

@Component({
  selector: 'otto-olsen-demo-account',
  templateUrl: './otto-olsen-demo-account.component.html',
  styleUrls: ['./otto-olsen-demo-account.component.scss']
})
export class OttoOlsenDemoAccountComponent {

}
