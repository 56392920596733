import { Injectable } from '@angular/core';
import { TranslationLocalService } from 'gung-standard';
import seTranslations from '../i18n/se.json';
import enTranslations from '../i18n/en.json';
import noTranslations from '../i18n/no.json';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenTranslationLocalService extends TranslationLocalService {
  constructor(protected http: HttpClient, protected handler: HttpBackend) {
    super(http, handler);
  }

  protected async getCustTranslations(lang: string): Promise<any> {
    if (lang === 'se') {
      return seTranslations;
    }
    if (lang === 'en') {
      return enTranslations;
    }
    if (lang === 'no') {
      return noTranslations;
    }
    return {};
  }
}
