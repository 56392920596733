import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnInit,
  Renderer2,
  Type,
  ViewChild
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  AssortmentService,
  ProductService,
  PriceService,
  GungFlowService,
  MetadataService,
  AssortmentTreeNavigationConfigService,
  AssortmentTreeImageSelectionService,
  CloudPimFiltersService,
  GungTitleService
} from 'gung-standard';
import { OttoOlsenAssortmentTreeNavigationComponent } from '../../otto-olsen-assortment-tree-navigation/otto-olsen-assortment-tree-navigation.component';
import { OttoOlsenPimEditConfigService } from '../../../services/otto-olsen-pim-edit-config.service';

@Component({
  selector: 'otto-olsen-mobile-assortment-tree-navigation',
  templateUrl: './otto-olsen-mobile-assortment-tree-navigation.component.html',
  styleUrls: ['./otto-olsen-mobile-assortment-tree-navigation.component.scss']
})
export class OttoOlsenMobileAssortmentTreeNavigationComponent
  extends OttoOlsenAssortmentTreeNavigationComponent
  implements OnInit
{
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected assortmentService: AssortmentService,
    protected productService: ProductService,
    protected translateService: TranslateService,
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected assortmentTreeNavigationConfigService: AssortmentTreeNavigationConfigService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    cloudPimFiltersService: CloudPimFiltersService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected titleService: Title,
    protected gungTitleService: GungTitleService
  ) {
    super(
      router,
      route,
      authService,
      assortmentService,
      productService,
      translateService,
      priceService,
      gungFlowService,
      metadataService,
      componentFactoryResolver,
      changeDetectorRef,
      assortmentTreeNavigationConfigService,
      assortmentTreeImageSelectionService,
      cloudPimFiltersService,
      ottoOlsenPimEditConfigService,
      titleService,
      gungTitleService
    );
  }

 
}
