<div *ngFor="let order of data" class="card mb-2">
  <div class="card-body">
    <!-- <div class="card-title row">
            <div class="col-auto ml-auto">
                <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
            </div>
        </div> -->
    <div class="row">
      <div class="col-6">
        <lib-gung-text-input [gungTranslate]="'ORDER_NUMBER'" [renderStatic]="true" [ngModel]="order.id">
        </lib-gung-text-input>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="mb-0 font-weight-bold" translate>TOTAL</label>
          <lib-price
            [price]="order.extra.oh.ohvbordsum"
            [currency]="order.extra.oh.valkod"
            class="form-control-plaintext p-0"
          ></lib-price>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="order.extra.oh.orddatum"> </lib-gung-date-output>
      </div>
      <div class="col-4">
        <lib-gung-date-output [gungTranslate]="'EXPECTED_DELIVERY_DATE'" [value]="order.extra.oh.ordberlevdat">
        </lib-gung-date-output>
      </div>
      <div class="col-4">
        <lib-gung-meta-output
          [value]="order.extra.oh.ordstat"
          [gungTranslate]="'ORDER_STATUS'"
          metadataTable="x7"
          metaOptionName="ordstatbeskr"
        ></lib-gung-meta-output>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'NAME'"
            [renderStatic]="true"
            [ngModel]="
              order.extra.oh.ordlevadr1 ||
              (order.extra.deliveryCustomer ? order.extra.deliveryCustomer.name : order.extra.customerName)
            "
          >
          </lib-gung-text-input>
        </div>
      </div>
      <div class="col-4">
        <lib-gung-text-input
          [gungTranslate]="'YOUR_REFERENCE'"
          [renderStatic]="true"
          [ngModel]="order.extra.oh.kundref2"
        >
        </lib-gung-text-input>
      </div>
      <div class="col-4">
        <lib-gung-text-input [gungTranslate]="'PO_NUMBER'" [renderStatic]="true" [ngModel]="order.extra.oh.kundbestnr">
        </lib-gung-text-input>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div *ngIf="order.extra.deliveries">
          <div *ngFor="let delivery of order.extra.deliveries">
            <a
              *ngIf="delivery.fraktsendnummer"
              target="_blank"
              (click)="$event.stopPropagation()"
              class="d-block"
              href="https://sporing.bring.no/sporing/{{ delivery.fraktsendnummer }}?lang=no"
            >
              <button type="button" class="btn btn-outline-primary">
                <i class="far fa-truck ml-1"></i>
                <span>&nbsp;{{ 'TRACK_ORDER' | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="delivery.ordlevanvisnrad2"
              target="_blank"
              class="d-block"
              (click)="$event.stopPropagation(); checkDeliveryNote(delivery.ordernr, delivery.ordlevanvisnrad2, $event)"
            >
              <button type="button" class="btn btn-outline-primary mt-1">
                <i class="far fa-clipboard ml-1"></i>
                <span>&nbsp;{{ 'DELIVERY_NOTE' | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="order.extra.oh.q_jis_coc && delivery.ordlevanvisnrad2"
              target="_blank"
              class="d-block"
              (click)="$event.stopPropagation(); checkCoc(delivery.ordernr, delivery.ordlevanvisnrad2, $event)"
            >
              <button type="button" class="btn btn-outline-primary mt-1">
                <i class="far fa-file-pdf ml-1"></i>
                <span>&nbsp;{{ 'COC' | translate }}</span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="col-6 text-right">
        <a [routerLink]="'/orders/' + order.id" (click)="orderNavigationService.setOrderNavigation(order.id, getOrderIds(data))">
          <button type="button" class="btn btn-primary" translate>SHOW_DETAILS</button>
        </a>
      </div>
    </div>
  </div>
</div>
