<i *ngIf="showOnlyIcons; else alternative" class="fas fa-box" [ngClass]="class"></i>
<ng-template #alternative>
  <span *ngIf="!!productionDays">Produksjonsvare ({{productionDays}} dgr)<br></span>
  <a
    [ngClass]="class"
    [class.disable-underline]="!isSales"
    [class.p-0]="isSales"
    (click)="isSales ? openModal() : undefined"
    [innerHTML]="status"
    placement="bottom"
    ngbTooltip="{{ tooltip }}"
  ></a>
  <span [innerHTML]="addicionalInfo"></span>
</ng-template>
