import { OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Component, ComponentFactoryResolver, HostListener, Type } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject, catchError, first, of, takeUntil } from 'rxjs';
import { countCharactersIgnoringHTML, shortenCharactersIgnoringHTML } from 'gung-common';
import { AssortmentTreeNavigationComponent, AuthService, AssortmentService, ProductService, PriceService, GungFlowService, MetadataService, AssortmentTreeNavigationConfigService, AssortmentTreeImageSelectionService, CloudPimFiltersService, AssortmentRecursiveExtended, ProductExtended, GungTitleService } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { OttoOlsenPimEditConfigService } from '../../services/otto-olsen-pim-edit-config.service';

@Component({
  selector: 'otto-olsen-assortment-tree-navigation',
  templateUrl: './otto-olsen-assortment-tree-navigation.component.html',
  styleUrls: ['./otto-olsen-assortment-tree-navigation.component.scss']
})
export class OttoOlsenAssortmentTreeNavigationComponent extends AssortmentTreeNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  infoPage = false;
  currentAssortmentPimUrl: string = '';
  currentConceptPimUrl: string = '';
  isAssortmentManager: boolean = false;
  isProductManager: boolean = false;
  pimEditUrlHide: boolean = false;
  isProductAccessForbidden: boolean = false;
  protected unsubscribe: Subject<void> = new Subject();

  @HostListener('click', ['$event'])
  onClick(event) {
    const { target }: { target: HTMLElement } = event;
    if (target.parentElement?.localName === 'a' && target.parentElement?.classList?.contains('cad-link')) {
      event.preventDefault()
      event.stopPropagation();
      const url = (target?.parentElement as HTMLLinkElement)?.href;
      window.open(url, '_BLANK');
    }
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected assortmentService: AssortmentService,
    protected productService: ProductService,
    protected translateService: TranslateService,
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected assortmentTreeNavigationConfigService: AssortmentTreeNavigationConfigService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    cloudPimFiltersService: CloudPimFiltersService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected titleService: Title,
    protected gungTitleService: GungTitleService
  ) {
    super(
      router,
      route,
      authService,
      assortmentService,
      productService,
      translateService,
      priceService,
      gungFlowService,
      metadataService,
      componentFactoryResolver,
      changeDetectorRef,
      assortmentTreeNavigationConfigService,
      assortmentTreeImageSelectionService,
      cloudPimFiltersService,
      titleService,
      gungTitleService
    );
  }

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(userRoles => {
      this.isAssortmentManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'assortments');
      this.isProductManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'products-concepts');
    });
    this.ottoOlsenPimEditConfigService.pimEditUrlHide.pipe(takeUntil(this.unsubscribe)).subscribe(pimEditUrlHide => (this.pimEditUrlHide = pimEditUrlHide));
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.titleService.setTitle('Otto Olsen B2B');
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.removeParkerScript();
    super.ngOnDestroy();
  }

  protected getListType() {
    this.removeParkerScript();
    return super.getListType().pipe(
      catchError(error => {
        if (error?.status === 403) {
          this.isProductAccessForbidden = true;
          this.titleService.setTitle('Otto Olsen B2B' + ' - ' + this.translateService.instant('NO_ACCESS'));
        }
        return of();
      })
    );
  }

  renderLayout(layoutComponent: Type<any>, data: any = {}): void {
    this.titleService.setTitle(this.currentAssortment.id + ' ' + this.currentAssortment.name + ' - ' + 'Otto Olsen B2B');
    this.removeParkerScript();
    this.titleService.setTitle(this.translateService.instant('WEBSHOP_OTTO_OLSEN'));
    this.currentAssortment.extra.bullets = [];
    if (this.currentAssortment?.extra?.pim?.bullet1) {
      this.currentAssortment.extra.bullets.push(this.currentAssortment.extra.pim.bullet1);
    }
    if (this.currentAssortment?.extra?.pim?.bullet2) {
      this.currentAssortment.extra.bullets.push(this.currentAssortment.extra.pim.bullet2);
    }
    if (this.currentAssortment?.extra?.pim?.bullet3) {
      this.currentAssortment.extra.bullets.push(this.currentAssortment.extra.pim.bullet3);
    }
    // Info pages - Sections
    this.infoPage =
      this.currentAssortment?.extra?.pim?.infoPage &&
      (this.currentAssortment.extra.pim.infoPage === 'true' || this.currentAssortment.extra.pim.infoPage === true);
    // Don't show table if no products or if it is not an infoPage
    if (
      this.infoPage ||
      ((this.type === 'CONCEPT' ||
        this.type === 'CONCEPTS' ||
        this.type === 'PRODUCTS' ||
        this.type === 'PRODUCTS_GROUPED') &&
        this.products &&
        this.products.length === 0)
    ) {
      return;
    }
    if (this.type === 'CONCEPT' || this.type === 'PRODUCTS') {
      const priceSort = {
        isDisplay: false,
        isDisplayGrid: false,
        isDisplayList: false,
        path: '_gungCustomerPriceValue',
        sort: undefined
      };
      data.dynamicColumns.push(priceSort);
      const availabilitiesSort = {
        isDisplay: false,
        isDisplayGrid: false,
        isDisplayList: false,
        path: 'availabilities',
        sort: undefined
      };
      data.dynamicColumns.push(availabilitiesSort);
      const idSort = {
        isDisplay: false,
        isDisplayGrid: false,
        isDisplayList: false,
        path: 'id',
        sort: undefined
      };
      data.dynamicColumns.push(idSort);
    }
    if (this.currentAssortment.description && countCharactersIgnoringHTML(this.currentAssortment.description) > 800) {
      this.currentAssortment.extra.descriptionShort = shortenCharactersIgnoringHTML(this.currentAssortment.description, 700);
      this.currentAssortment.extra.descriptionShort += '...';
    }
    this.setTitle();
    super.renderLayout(layoutComponent, data);
    if (this.type === 'PRODUCT' && this.products && this.products.length === 1 && this.products[0].extra.pim?.parker) {
      this.loadParkerScript(this.products[0].extra.pim?.parker);
    } else if (this.type === 'CONCEPT' && this.currentAssortment.extra.pim?.parker) {
      this.loadParkerScript(this.currentAssortment.extra.pim?.parker);
    }
  }

  setTitle() {
    if (this.titleService) {
      if (this.currentAssortment && this.type !== 'PRODUCT' && this.type !== 'CONCEPT') {
        const title = this.breadcrumbMenu.map(b => b.label).slice(1).join(' > ');
        this.titleService.setTitle(`${title} - Webshop Otto Olsen`);
      } else if (this.type === 'CONCEPT') {
        this.titleService.setTitle(`Konsept: ${this.currentAssortment.name} - Webshop Otto Olsen`);
      } else if (this.type === 'PRODUCT') {
        this.titleService.setTitle(`Produkt: (${this.products[0].id}) ${this.products[0].name} - Webshop Otto Olsen`);
      }
    }

  }

  loadParkerScript(mfrsku) {
    if (document.getElementById('parkerScript')) {
      document.body.removeChild(document.getElementById('parkerScript'));
    }
    const body = document.body;
    const script = document.createElement('script');
    script.id = 'parkerScript';
    script.type = 'text/javascript';
    script.innerHTML = ``;
    // script.id = 'hs-script-loader';
    script.src = `https://637425643789843928.publisher.impartner.io/Csp/?mfrname=parker&mfrsku=${mfrsku}&t=InlineSyndication`;
    script.async = true;
    // script.defer = true;
    // body.appendChild(script);
    document.getElementById('parker').appendChild(script);
  }

  private removeParkerScript() {
    if (document.getElementById('parkerScript')) {
      // document.body.removeChild(document.getElementById('parkerScript'));
      document.getElementById('parker').removeChild(document.getElementById('parkerScript'));
    }
    const generatedElements = document.querySelectorAll('*[id^="cspInlineContainer_CSP_GLOBAL_637425643789843928"]');
    if (generatedElements.length > 0) {
      // generatedElements.forEach(ele => document.body.removeChild(ele));
      generatedElements.forEach(ele => document.getElementById('parker').removeChild(ele));
    }
    if (document.getElementById('parker')) {
      document.getElementById('parker').innerHTML = '';
    }
  }

  openNewWindow() {
    const generatedElements = document.querySelectorAll('*[id^="cspInlineContainer_CSP_GLOBAL_637425643789843928"]');
    if (generatedElements.length > 0) {
      // let html = generatedElements[0].outerHTML;
      const myWindow = window.open('', '', 'width=1000,height=1000');
      const doc = myWindow.document;
      doc.open();
      doc.write(`<html><head><title></title></head><body>
      <script async=true type='text/javascript' src='https://637466361576589561.publisher.impartner.io/Csp/?mfrname=parker&mfrsku=SKAR38/30X371&t=InlineSyndication'></script>`);
      // doc.write(html);
      doc.write('</body></html>');
      doc.close();
    }
  }

  getDocuments() {
    if (this.currentAssortment && this.currentAssortment.extra.documents) {
      this.currentAssortment.documents = getDocuments(this.currentAssortment, this.currentLang, this.userRoles);
    }
    this.buildExternalPimLinks();
  }

  buildExternalPimLinks() {
    this.currentAssortmentPimUrl = environment.externalPimUrl + 'assortment/' + (this.currentAssortment?.id?.charAt(0) === 's' ? this.currentAssortment?.id?.substring(1) : this.currentAssortment?.id);
    this.currentConceptPimUrl = environment.externalPimUrl + 'pim-item-concept/' + this.productId;
  }
}

export function getDocuments(currentAssortment: AssortmentRecursiveExtended | ProductExtended, currentLang: string, userRoles: string[]): { name: string; url: string; extra: { [s: string]: any } }[] {
  const documents: { name: string; url: string; extra: { [s: string]: any } }[] = [];
  if (currentAssortment?.extra?.documents) {
    const baseUrl = 'https://cdn2.gung.io/';

    for (const doc of currentAssortment.extra.documents) {
      if (doc.extra?.permission === 'Internal' && (!userRoles.includes('SALES') && !userRoles.includes('ADMIN'))) {
        continue;
      }
      let name: string;
      if (doc.i18n && doc.i18n[currentLang]) {
        name = doc.i18n[currentLang].description;
      }
      if (!name && doc.extra.i18n && doc.extra.i18n[currentLang]) {
        name = doc.extra.i18n[currentLang].description || doc.extra.i18n[currentLang].documentType;
      }
      if (!name && doc.extra.documentType) {
        name = doc.extra.documentType;
      }
      documents.push({
        name: name || 'DOCUMENT',
        url: baseUrl + doc.s3Uri + '?etag=' + doc.s3ETag,
        extra: doc.extra
      });
    }
  }
  return documents;
}
