import { Injectable, TemplateRef } from '@angular/core';

export interface ToastInfo {
  header?: string;
  body?: string | TemplateRef<any>;
  delay?: number;
  classname?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenToastService {
  enabled = false;

  toasts: any[] = [];

  defaultOptions = {
    delay: 50
  };

  showOptions(options: ToastInfo) {
    if (!this.enabled) {
      return;
    }
    this.toasts.push({ ...options, ...this.defaultOptions });
  }

  show(body: string, options: ToastInfo = {}) {
    this.showOptions({ body, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
