import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'otto-olsen-view3d-model-modal',
  templateUrl: './otto-olsen-view3d-model-modal.component.html',
  styleUrls: ['./otto-olsen-view3d-model-modal.component.scss']
})
export class OttoOlsenView3dModelModalComponent {
  @Input() models: string[];

  constructor(public activeModal: NgbActiveModal) { }
}
