import { Injectable } from '@angular/core';
import { HomeNavigationConfigService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenHomeNavigationConfigService extends HomeNavigationConfigService {
  multiCustomersUserLink = ['/delivery-location'];
  singleCustomerUserLink = ['/'];
  anonymousLink = ['/'];
}
