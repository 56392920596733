<div *ngFor="let order of data" class="card mb-2">
  <div class="card-body">
    <div class="card-title row d-flex- align-items-center">
      <div class="col-auto">
          <h5>{{ 'OH_KUNDBESTNR' | translate }}: 
            <a [routerLink]="'/orders/' + order.id" (click)="orderNavigationService.setOrderNavigation(order.id, getOrderIds(data))">
              {{ order.id }}
            </a>
          </h5>
          <div class="cutomer-order-no">
            <span *ngIf="order.extra.oh.kundbestnr"> {{ 'CUSTOMER_ORDER_ID' | translate }}: </span>{{order.extra.oh.kundbestnr}}
          </div>
      </div>
      
      <div class="col-auto ml-auto">
        <lib-action-btn [item]="order" [config]="configService"></lib-action-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-3">
        <div class="pb-1">
          <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="order.extra.oh.orddatum"></lib-gung-date-output>
        </div>
        <div class="pb-1">
          <div *ngIf="order.extra.oh.ordstat && order.extra.oh.ordstat > 50; else expectedDelivery" class="delivered-box">
            <label class="delivered"> {{ 'EXPECTED_DELIVERY_DATE' | translate }} </label>
            <p class=""> {{ 'DEVIVERED' | translate }} <i class="fa fa-check" aria-hidden="true"></i> </p>
          </div>
          <ng-template #expectedDelivery>
            <lib-gung-date-output [gungTranslate]="'EXPECTED_DELIVERY_DATE'" [value]="order.extra.oh.ordberlevdat">
            </lib-gung-date-output>
          </ng-template>

        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="pb-1">
          <lib-gung-meta-output
            [value]="order.extra.oh.ordstat"
            [gungTranslate]="'ORDER_STATUS'"
            metadataTable="x7"
            metaOptionName="ordstatbeskr"
          ></lib-gung-meta-output>
        </div>
        <div class="pb-1">
          <div class="form-group">
            <label class="mb-0 font-weight-bold" translate>TOTAL</label>
            <lib-price
              [price]="order.extra.oh.ohvbordsum"
              [currency]="order.extra.oh.valkod"
              class="form-control-plaintext p-0"
            ></lib-price>
          </div>
        </div>
      </div>
      <div class="col col-xs-6 col-md-3">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'NAME'"
            [renderStatic]="true"
            [ngModel]="
              order.extra.oh.ordlevadr1 ||
              (order.extra.deliveryCustomer ? order.extra.deliveryCustomer.name : order.extra.customerName)
            "
          >
          </lib-gung-text-input>
          <div class="pb-1">
            <lib-gung-meta-output
              [value]="order.extra.oh.ordlevadrlandskod"
              [gungTranslate]="'COUNTRY'"
              metadataTable="xr"
              metaOptionName="land"
            ></lib-gung-meta-output>
          </div>
        </div>
      </div>
      <div class="col col-xs-6 col-md-3">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'YOUR_REFERENCE'"
            [renderStatic]="true"
            [ngModel]="order.extra.oh.kundref2"
          >
          </lib-gung-text-input>
          <div class="pb-1">
            <lib-gung-text-input
              [gungTranslate]="'PO_NUMBER'"
              [renderStatic]="true"
              [ngModel]="order.extra.oh.kundbestnr"
            >
            </lib-gung-text-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-column" *ngIf="order.extra.deliveries && order.extra.deliveries.length > 0">
      <div *ngIf="order.extra.deliveries[0].fraktsendnummer" 
        class="d-flex align-items-center my-1 px-3 flex-wrap">
        <i class="far fa-truck ml-1"></i>
        <div *ngFor="let delivery of order.extra.deliveries" class="mx-2">
          <a
            target="_blank"
            (click)="$event.stopPropagation()"
            href="https://sporing.bring.no/sporing/{{ delivery.fraktsendnummer }}?lang=no"
          >
            <span>{{ delivery.fraktsendnummer }}</span>
          </a>
        </div>
      </div>
      <div *ngIf="order.extra.deliveries[0].ordlevanvisnrad2" 
        class="d-flex align-items-center my-1 px-3 flex-wrap">
        <i class="far fa-clipboard ml-1"></i>
        <div *ngFor="let delivery of order.extra.deliveries; let i = index; let c = count">
          <a
            *ngIf="delivery.ordlevanvisnrad2"
            class="ml-2"
            target="_blank"
            (click)="$event.stopPropagation(); checkDeliveryNote(delivery.ordernr, delivery.ordlevanvisnrad2, $event)"
          >
            <span>{{ 'DELIVERY_NOTE' | translate }} ({{delivery.ordlevanvisnrad2}})<!-- <span *ngIf="c > 1"> ({{i+1}}) </span>  --></span>
          </a>
        </div>
      </div>
      <div *ngIf="order.extra.oh.q_jis_coc && order.extra.deliveries[0].ordlevanvisnrad2" 
        class="d-flex align-items-center my-1 px-3 flex-wrap">
        <i class="far fa-file-pdf ml-1"></i>
        <div *ngFor="let delivery of order.extra.deliveries; let i = index; let c = count">
          <a
            *ngIf="delivery.ordernr"
            class="ml-2"
            target="_blank"
            (click)="$event.stopPropagation(); checkCoc(delivery.ordernr, delivery.ordlevanvisnrad2, $event)"
          >
            <span>&nbsp;{{ 'COC' | translate }} ({{delivery.ordernr}}) <!-- <span *ngIf="c > 1"> ({{i+1}}) </span> --> </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
