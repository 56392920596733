import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  QuickorderComponent,
  ProductService,
  CartService,
  AssortmentService,
  GungFlowService,
  EanNumbersService,
  AuthService,
  EanNumbers,
  Product,
  BarcodeScannerConfigService,
  ProductInputQuantityConfigService
} from 'gung-standard';

import { forkJoin, Observable, of } from 'rxjs';
import { first } from 'rxjs';


@Component({
  selector: 'otto-olsen-quickorder-scanner',
  templateUrl: './otto-olsen-quickorder-scanner.component.html',
  styleUrls: ['./otto-olsen-quickorder-scanner.component.scss']
})
export class OttoOlsenQuickorderScannerComponent
  /* extends QuickorderComponent
  implements OnInit, AfterViewInit, OnDestroy */
{
  /* loaded = false;
  enableDebug = false;

  previousCodeReaded: string;

  @ViewChild('scannerInput', { static: false }) public scannerInput: ElementRef<HTMLInputElement>;
  @ViewChild('quantityElement', { static: false }) public quantityElement: ElementRef<HTMLInputElement>;

  eanNumbers: EanNumbers[];
  products: Product[];

  showFullscreen = false;
  isCollapsed = true;

  barcodePicker: BarcodePicker;

  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected assortmentService: AssortmentService,
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected eanNumbersService: EanNumbersService,
    protected toastService: OttoOlsenToastService,
    protected barcodeScannerConfigService: BarcodeScannerConfigService,
    protected route: ActivatedRoute,
    productInputQuantityConfigService: ProductInputQuantityConfigService
  ) {
    super(productService, cartService, assortmentService, productInputQuantityConfigService);
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams?.debug) {
      this.toastService.enabled = queryParams.debug === 'true';
    }
  }
  ngAfterViewInit(): void {
    this.loaded = true;
    this.startProReader();
    this.toastService.clear();
    this.toastService.show('toastService INIT...', { classname: 'bg-info' });
    return;
    this.eanNumbersService
      .getEanNumbers()
      .pipe(first())
      .subscribe(eanNumbers => {
        this.eanNumbers = eanNumbers;
        this.loaded = true;
        this.startProReader();
        return;
        forkJoin([
          this.authService.getCurrentUser().pipe(first()),
          this.gungFlowService.getSelectedFlow().pipe(first())
        ]).subscribe(([user, flow]) => {
          this.productService.getProductsByAssortmentExpandedAndFlow(user.assortment, flow.id).subscribe(products => {
            this.products = products;
          });
        });
      });
    return;
    forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first()),
      this.eanNumbersService.getEanNumbers().pipe(first())
    ]).subscribe(([user, flow, eanNumbers]) => {
      this.eanNumbers = eanNumbers;
      this.productService.getProductsByAssortmentExpandedAndFlow(user.assortment, flow.id).subscribe(products => {
        this.products = products;
        this.loaded = true;
        this.startProReader();
      });
    });
  }

  ngOnDestroy(): void {
    if (this.barcodePicker) {
      this.barcodePicker.destroy();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  startProReader() {
    configure(this.barcodeScannerConfigService.apiKey, {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk/build'
    }).then(() => {
      BarcodePicker.create(
        document.getElementById('scandit-barcode-picker'),
        this.barcodeScannerConfigService.getBarcodeConfig()
      ).then(barcodePicker => {
        this.barcodePicker = barcodePicker;
        barcodePicker.applyScanSettings(this.barcodeScannerConfigService.getScanSettings());
        // barcodePicker.setPlaySoundOnScanEnabled(false);
        // barcodePicker.setVibrateOnScanEnabled(false);
        barcodePicker.setViewfinderArea(this.barcodeScannerConfigService.scanSettings.searchArea);
        barcodePicker.on('scan', scanResult => {
          // console.log('scanResult', scanResult);
          this.onScanSuccessPro(scanResult.barcodes[0].data, null);
          // scanResult.barcodes.reduce((string, barcode) => {
          //   console.log('string', string);
          //   this.onBarcodeScanned(barcode.data);
          // }, '');
        });
      });
    });
  }

  onScanSuccessPro(decodedText, decodedResult) {
    if (this.previousCodeReaded !== decodedText) {
      this.previousCodeReaded = decodedText;
      // console.log(`Code matched = ${decodedText}`, decodedResult);
      this.toastService.show(`Code matched = ${decodedText}`);
      this.eanNumbersService.getEanNumber(decodedText).subscribe(eanProductService => {
        const eanProduct = eanProductService && eanProductService[0] ? eanProductService[0] : null;
        // console.log('eanProduct', eanProduct);
        if (eanProduct) {
          this.selectAndAddProduct(eanProduct.sku);
        }
      });
      this.barcodeScannerConfigService.pauseScanning(this.barcodePicker);
      setTimeout(() => {
        if (this.previousCodeReaded === decodedText) {
          this.previousCodeReaded = undefined;
        }
      }, this.barcodeScannerConfigService.pauseScanningTime);
    }
  }

  selectAndAddProduct(productId?: string, sku?: string) {
    this.product = undefined;
    this.productService
      .getProduct(productId)
      .pipe(first())
      .subscribe(product => {
        if (!product) {
          return;
        }
        this.product = product;
        const row = this.cart.find(c => c.productId === productId);
        this.quantity = 1;
        if (row) {
          this.quantity = row.qty;
          this.quantity++;
        }
        const cartRow = this.cart.find(c => c.productId === productId);
        if (cartRow) {
          this.cartService.setProductQuantity(
            productId,
            Number(this.quantity),
            cartRow.targetStockId,
            cartRow.productPartialId
          );
        } else {
          this.cartService.addToCart(productId, Number(this.quantity));
        }
      });
  }

  onScanSuccessPro2(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:
    if (this.previousCodeReaded !== decodedText) {
      this.previousCodeReaded = decodedText;
      console.log(`Code matched = ${decodedText}`, decodedResult);
      if (this.eanNumbers) {
        const eanProduct = this.eanNumbers.find(p => p.ean === decodedText);
        if (this.products) {
          // this.selectProduct({item: product, preventDefault: null});
          // this.addProductToCart(this.quantity || 1);
          this.selectAndAddProduct(undefined, eanProduct.sku);
          // this.quantityElement.nativeElement.focus();
        } else {
          this.selectAndAddProduct(eanProduct.sku);
        }
      } else {
        // this.selectProductById(decodedText);
        this.selectAndAddProduct(decodedText);
        // this.quantityElement.nativeElement.focus();
      }
      setTimeout(() => {
        if (this.previousCodeReaded === decodedText) {
          this.previousCodeReaded = undefined;
        }
      }, 1500);
    }
  }

  selectAndAddProduct2(productId?: string, sku?: string) {
    let request: Observable<Product>;
    if (sku) {
      const product = this.products.find(p => p.id === sku);
      this.product = product;
      request = of(product);
    } else if (productId) {
      request = this.productService.getProduct(productId).pipe(first());
    } else {
      return;
    }
    request.subscribe(product => {
      if (!product) {
        return;
      }
      this.product = product;
      const row = this.cart.find(c => c.productId === productId);
      this.quantity = 1;
      if (row) {
        this.quantity = row.qty;
        this.quantity++;
      }
      // setTimeout(() => this.isScrolledIntoView(), 0);
      if (!this.product) {
        return;
      }
      const cartRow = this.cart.find(c => c.productId === productId);
      if (cartRow) {
        this.cartService.setProductQuantity(
          productId,
          Number(this.quantity),
          cartRow.targetStockId,
          cartRow.productPartialId
        );
      } else {
        this.cartService.addToCart(productId, Number(this.quantity));
      }
    });
    // this.clearFields();
  } */
}
