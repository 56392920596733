import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProductService, Product, AvailabilityService, Availability, SimplifiedAvailability, AuthService, PriceService, GungFlowService, BaseViewConfigService, ProductInputQuantityConfigService, SelectedCustomerService, CartService, MetadataService, AssortmentService, gungGetPropertyOfProduct, BaseDetailData, PriceConfigService } from 'gung-standard';
import { filter, first, mergeMap, Observable, takeUntil } from 'rxjs';
import { OttoOlsenProductAssortmentParentService } from '../../../services/otto-olsen-product-assortment-parent.service';
import { getDocuments } from '../../otto-olsen-assortment-tree-navigation/otto-olsen-assortment-tree-navigation.component';
import { OttoOlsenProductDetailsComponent, ProductExtended } from '../../otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenPimEditConfigService } from '../../../services/otto-olsen-pim-edit-config.service';
import { OttoOlsenProductService } from '../../../services/otto-olsen-product.service';

@Component({
  selector: 'otto-olsen-mobile-product-description',
  templateUrl: './otto-olsen-mobile-product-description.component.html',
  styleUrls: ['./otto-olsen-mobile-product-description.component.scss']
})
export class OttoOlsenMobileProductDescriptionComponent extends OttoOlsenProductDetailsComponent implements OnInit {
  public number = n => Number(n);
  showParker = true;
  roles: string[];
  currentQty: number;

  constructor(
    protected route: ActivatedRoute,
    protected productService: OttoOlsenProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: OttoOlsenProductAssortmentParentService,
    protected titleService: Title,
    protected selectedCustomerService: SelectedCustomerService,
    protected cartService: CartService,
    protected priceConfigService: PriceConfigService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      translateService,
      metadataService,
      gungFlowService,
      assortmentService,
      baseViewConfigService,
      productAssortmentParentService,
      titleService,
      selectedCustomerService,
      priceConfigService,
      ottoOlsenPimEditConfigService,
      cartService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.roles = roles;
    });

    this.cartService
      .getProductQty(this.productId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(qty => {
        setTimeout(() => {
          this.currentQty = qty;
        }, 0);
      });
  }

  add(product: Product, availability: Availability | SimplifiedAvailability, qtyToAdd: number) {
    if (!this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(this.currentQty + qtyToAdd, availability, product, this.roles)) {
      return;
    }
    this.cartService.addToCart(product.id, qtyToAdd, undefined, undefined, undefined, undefined, undefined, product.name);
  }

  subtract(product: Product, availability: Availability | SimplifiedAvailability, qtyToSubtract: number) {
    this.cartService.subtractFromCart(product.id, qtyToSubtract);
  }

  loadParkerScript(mfrsku) {
    this.removeParkerScript();
    if (document.getElementById('parkerScript')) {
      document.body.removeChild(document.getElementById('parkerScript'));
    }
    const body = document.body;
    const script = document.createElement('script');
    script.id = 'parkerScript';
    script.type = 'text/javascript';
    script.innerHTML = ``;
    // script.id = 'hs-script-loader';
    script.src = `https://637425643789843928.publisher.impartner.io/Csp/?mfrname=parker&mfrsku=${mfrsku}&t=InlineSyndication`;
    script.async = true;
    // script.defer = true;
    // body.appendChild(script);
    document.getElementById('parker').appendChild(script);
  }

  private removeParkerScript() {
    if (document.getElementById('parkerScript')) {
      // document.body.removeChild(document.getElementById('parkerScript'));
      document.getElementById('parker').removeChild(document.getElementById('parkerScript'));
    }
    const generatedElements = document.querySelectorAll('*[id^="cspInlineContainer_CSP_GLOBAL_637425643789843928"]');
    if (generatedElements.length > 0) {
      // generatedElements.forEach(ele => document.body.removeChild(ele));
      generatedElements.forEach(ele => document.getElementById('parker').removeChild(ele));
    }
    if (document.getElementById('parker')) {
      document.getElementById('parker').innerHTML = '';
    }
  }

  protected handleObservable(observable: Observable<BaseDetailData>): void {
    observable.subscribe(d => {
      const { product: productAux, availability, price, user } = d;
      this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
      const product: ProductExtended = productAux;
      this.titleService.setTitle(product.id + ' ' + product.name + ' - ' + 'Otto Olsen B2B');
      if (product.extra.artv) {
        this.addonIds = product.extra.artv.map(r => r.artnrtillvalart);
      }
      if (product.extra.pim && product.extra.pim.relatedProducts) {
        this.pimRelatedProductsIds = product.extra.pim.relatedProducts.split(',');
      }
      // Video from parent if not in product
      product.extra.pim.videolink = product.extra?.pim?.videolink || this.currentAssortment?.extra?.pim?.videolink;
      // Documents from assortment parent and product
      product.documents = [];
      product.documents.push(...getDocuments(product, this.currentLang, user.roles));
      if (this.currentAssortment) {
        getDocuments(this.currentAssortment, this.currentLang, user.roles).forEach(document => {
          const existing = product.documents.find(e => {
            return e.url === document.url;
          });
          if (!existing) {
            product.documents.push(document);
          }
        });
      }
      // Bullets
      product.extra.bullets = [];
      ['bullet1', 'bullet2', 'bullet3'].forEach(key => {
        const fromProduct = product.extra.pim[key];
        if (fromProduct) {
          product.extra.bullets.push(fromProduct);
        }
      });
      // Product description
      if (product.extra.i18n && product.extra.i18n[this.currentLang]) {
        product.description = product.extra.i18n[this.currentLang].description || '';
      }
      if (!product.description && product.extra.pim && product.extra.pim.description) {
        product.description = product.extra.pim.description || '';
      }
      if (product.extra.bullets.length === 0 && !product.description) {
        if (product.extra.bullets.length === 0) {
          // Bullets
          ['bullet1', 'bullet2', 'bullet3'].forEach(key => {
            const fromAssortment = this.currentAssortment && this.currentAssortment.extra.pim[key];
            if (fromAssortment) {
              product.extra.bullets.push(fromAssortment);
            }
          });
        }
        if (
          this.currentAssortment &&
          this.currentAssortment.extra.pim &&
          this.currentAssortment.extra.pim.description
        ) {
          product.description = this.currentAssortment.extra.pim.description || '';
        }
        if (
          this.currentAssortment &&
          this.currentAssortment.extra.i18n &&
          this.currentAssortment.extra.i18n[this.currentLang] &&
          this.currentAssortment.extra.i18n[this.currentLang].assortmentDescription
        ) {
          product.description = this.currentAssortment.extra.i18n[this.currentLang].assortmentDescription || '';
        }
      }

      this.product = product;
      this.availability = availability;
      this.price = price;
      
      // assortmentParentPath
      if (!this.currentAssortment) {
        this.authService
          .getCurrentUser()
          .pipe(
            filter(u => !!u),
            first(),
            mergeMap(u => {
              return this.productAssortmentParentService.getProductAssortmentParentByProductIds(
                this.product.id,
                u.assortment
              );
            })
          )
          .subscribe(productAssortmentParent => {
            if (productAssortmentParent) {
              const currentProductAssortmentParent = productAssortmentParent[this.product.id];
              if (currentProductAssortmentParent) {
                this.calculateAssortmentPath(currentProductAssortmentParent.map(path => path.id));
              }
            }
          });
      }

      // Sort TemplateProperties by the sortDetailTable
      const sortByTable = (a, b) => {
        if (this.sortDetailTable.includes(a.path) && this.sortDetailTable.includes(b.path)) {
          const aIdx = this.sortDetailTable.indexOf(a.path);
          const bIdx = this.sortDetailTable.indexOf(b.path);
          return aIdx - bIdx;
        }
        if (this.sortDetailTable.includes(a.path)) {
          return 1;
        }
        if (this.sortDetailTable.includes(b.path)) {
          return -1;
        }
        return 0;
      };

      // Product table details
      this.productDetailsTable = [];
      if (product.extra.productTemplateProperties) {
        product.extra.productTemplateProperties.sort(sortByTable).forEach(templateProp => {
          if (templateProp.isDisplayDetails) {
            const value = gungGetPropertyOfProduct(templateProp, this.product, this.currentLang, this.metadataService);
            if (!!value) {
              this.productDetailsTable.push({ title: templateProp.translationKey, value });
            }
          }
        });
      } else if (this.currentAssortment && this.currentAssortment.extra.itemProperties) {
        this.currentAssortment.extra.itemProperties.sort(sortByTable).forEach(templateProp => {
          if (
            templateProp.isDisplayDetails &&
            this.productDetailsTable.findIndex(prop => prop.title === templateProp.translationKey) === -1
          ) {
            const value = gungGetPropertyOfProduct(templateProp, this.product, this.currentLang, this.metadataService);
            if (!!value) {
              this.productDetailsTable.push({ title: templateProp.translationKey, value });
            }
          }
        });
      }
      this.detailTable.forEach(detail => {
        const value = gungGetPropertyOfProduct(detail, this.product, this.currentLang, this.metadataService);
        if (!!value && this.productDetailsTable.findIndex(table => table.title === detail.translationKey) === -1) {
          this.productDetailsTable.push({ title: detail.translationKey, value });
        }
      });
      // Product package size
      if (
        product.extra.ar.q_jis_fast_pakke_strl &&
        product.extra.ar.artfsgforp &&
        Number(product.extra.ar.artfsgforp) > 0
      ) {
        product.packageSize = Number(product.extra.ar.artfsgforp);
      }
      // Product original size without cutting
      if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
        product.originalSize = Number(product.extra.ar.artfsgforp);
      }
      if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
        product.packageSize = Number(product.extra.ar.q_salesbatchsize);
      }
      if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
        product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
      }
      //Parker
      if (this.product.extra.pim.parker) {
        this.loadParkerScript(this.product.extra.pim.parker);
      } else if (this.currentAssortment.extra.pim.parker) {
        this.loadParkerScript(this.currentAssortment.extra.pim.parker);
      }else{
        this.showParker = false;
      }

    });
  }

}