<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>MEETIGN_AMOUNT</div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>CONVERTED_IN_ORDER_THIS_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ convertedCurrentMoth.length | number: '1.0-0' }}</span>
    </div>

    <div class="progress-container my-2">
      <ngb-progressbar [animated]="true" [value]="(convertedCurrentMoth.length / 30) * 100"> </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title">{{ ((convertedCurrentMoth.length / 30) * 100 || 0).toFixed() }}%</span>
        <span class="sub-title" translate>MEETINGS_TARGET</span>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>CONVERTED_IN_ORDER_LAST_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ convertedLastMoth.length | number: '1.0-0' }}</span>
    </div>

    <div class="progress-container my-2">
      <ngb-progressbar [animated]="true" [value]="(convertedLastMoth.length / 30) * 100"> </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title">{{ ((convertedLastMoth.length / 30) * 100 || 0).toFixed() }}%</span>
        <span class="sub-title" translate>MEETINGS_LAST_MONTH_TARGET</span>
      </div>
    </div>

  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
