import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GungLanguageConfigService } from 'gung-common';
import { GungListRouterEventsService } from 'gung-list';
import { LoadingState, NavigationConfig } from 'gung-standard';
import {
  AppWrapperComponent,
  AppState,
  AuthService,
  ProductService,
  MetadataService,
  GoogleAnalyticsService,
  NavbarConfigService,
  RouterEventsService,
  SelectedCustomerService,
  CartService,
  BarcodeScannerConfigService,
  GungModalService
} from 'gung-standard';
import { Subject, filter } from 'rxjs';
import { first, takeUntil } from 'rxjs';

@Component({
  selector: 'otto-olsen-app-wrapper',
  templateUrl: './otto-olsen-app-wrapper.component.html',
  styleUrls: ['./otto-olsen-app-wrapper.component.scss']
})
export class OttoOlsenAppWrapperComponent extends AppWrapperComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<boolean> = new Subject<boolean>();
  public isScannerOnly = false;
  hideMenuRoutes = [
    'mobile',
    'barcode-scanner',
    'search-scanner',
    'mobile-product',
    'mobile-checkout',
    'checkout-confirm'
  ];
  errorSelectedCustomer = false;
  public username: string;

  constructor(
    protected store: Store<AppState>,
    protected authService: AuthService,
    protected productService: ProductService,
    protected metadataService: MetadataService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected navbarConfigService: NavbarConfigService,
    protected router: Router,
    protected routerEventsService: RouterEventsService,
    protected selectedCustomerService: SelectedCustomerService,
    protected cartService: CartService,
    protected barcodeScannerConfigService: BarcodeScannerConfigService,
    protected gungModalService: GungModalService,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected route: ActivatedRoute,
    protected translate: TranslateService,
    protected gungLanguageConfigService: GungLanguageConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    super(
      store,
      authService,
      productService,
      metadataService,
      googleAnalyticsService,
      navbarConfigService,
      router,
      routerEventsService,
      gungListRouterEventsService,
      route,
      translate,
      selectedCustomerService,
      gungLanguageConfigService,
      environment
    );
    this.authService.getCurrentUser().pipe(filter(user => !!user),first()).subscribe(user => this.username = user.username);
  }

  ngOnInit() {
    this.authService
      .getHasRoles('SCANNER')
      .pipe()
      .subscribe(isScannerOnly => {
        this.isScannerOnly = isScannerOnly;
        if (isScannerOnly) {
          this.hideMenuRoutes.push('order-confirmation');
        }
      });
    super.ngOnInit();
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(_ =>
        this.navbarConfigService
          .getMainMenu()
          .pipe(first())
          .subscribe(menu => (this.mainMenu = menu))
      );
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  collapseTopMenu(): void {
    if (!!this.navbar) {
      this.navbar.collapseTopMenu();
      this.topMenuChange(!this.navbar.isCollapsed);
    }
  }

  clearCart() {
    this.gungModalService.openConfirmYesNoModal(null, 'CLEAR_CART_QUESTION', { size: 'sm' }).then(result => {
      if (result) {
        this.cartService.clearCart();
      }
    });
  }

  quickCheckout() {
    this.barcodeScannerConfigService.openQuickCheckoutModal().then(
      result => {},
      reason => {}
    );
  }

  updateAppState() {
    const keys = Object.keys(this.states);

    const filteredKeys = keys.filter(key => {
      return this.states[key] === LoadingState.LOADING;
    });

    this.isLoading = filteredKeys.length !== 0;

    const invalidKeys = keys.filter(key => {
      return this.states[key] === LoadingState.INVALID;
    });
    const error = invalidKeys.length > 0;
    this.errorOccured = this.devMode ? false : error;
    if (error) {
      console.error(`Error in the following keys: ${invalidKeys}`);
      if (invalidKeys.includes('selectedCustomer') && !this.errorSelectedCustomer) {
        this.errorSelectedCustomer = true;
      }
    }
  }

  protected subscribeCustomer() {
    this.states.selectedCustomer = LoadingState.LOADING;
    this.store
      .select(state => state.customer)
      .subscribe({
        next: customerState => {
          if (customerState.doneLoading) {
            if (!!customerState.selectedCustomer?.id) {
              this.states.selectedCustomer = LoadingState.VALID;
            } else {
              this.states.selectedCustomer = LoadingState.INVALID;
            }
          }

          this.updateAppState();
        },
        error: _ => {
          this.states.selectedCustomer = LoadingState.INVALID;
          this.updateAppState();
        }
      });
  }
}
