import { Injectable } from '@angular/core';
import { JeevesDeliveryAddressesService, JeevesOrderDeliveryAddress } from 'gung-standard-jeeves';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenDeliveryAddressesService extends JeevesDeliveryAddressesService {
  protected getMappedFr(
    fr: any,
    source: 'deliveryLocation' | 'customerAddress',
    deliveryLocationId?: string
  ): JeevesOrderDeliveryAddress {
    if (fr.ftgpostadr5) {
      const res: JeevesOrderDeliveryAddress = {
        addressValues: {
          ordlevadr1: fr.ftgnamn || '',
          ordlevadr2: fr.ftgpostadr5 || '',
          ordlevadr3: '',
          ordlevadr4: fr.ftglevpostnr || '',
          ftgpostnr: fr.ftglevpostnr || '',
          ordlevadrbstort: fr.ftgpostlevadr3,
          ordlevadrlandskod: fr.landskod || '',
          ordlevplats1: deliveryLocationId
        },
        source
      };

      return res;
    } else {
      const res: JeevesOrderDeliveryAddress = {
        addressValues: {
          ordlevadr1: fr.ftgnamn || '',
          ordlevadr2: fr.ftgpostadr5 || '',
          ordlevadr3: '',
          ordlevadr4: fr.ftglevpostnr || '',
          ftgpostnr: fr.ftglevpostnr || '',
          ordlevadrbstort: fr.ftgpostlevadr3,
          ordlevadrlandskod: fr.landskod || '',
          ordlevplats1: deliveryLocationId
        },
        source
      };

      return res;
    }
  }
}
