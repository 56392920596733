import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserDetailsComponent, UsersService } from 'gung-standard';
import { first, filter } from 'rxjs';

@Component({
  selector: 'otto-olsen-manage-users-details',
  templateUrl: './otto-olsen-manage-users-details.component.html',
  styleUrls: ['./otto-olsen-manage-users-details.component.scss']
})
export class OttoOlsenManageUsersDetailsComponent extends UserDetailsComponent {
  public isAdmin = false;
  constructor(
    protected route: ActivatedRoute, 
    protected router: Router, 
    protected usersService: UsersService,
    authService: AuthService
  ) {
    super(
      route,
      router,
      usersService
    );
    authService.getRoles().pipe(first(), filter(roles => !!roles)).subscribe(roles => this.isAdmin = roles.indexOf('ADMIN') > -1)
  }

}
