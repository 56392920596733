<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>COMPARE</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
    <div translate>COMPARE</div>
    <div class="my-2"><strong>{{planogram.name}}</strong></div>
    <div translate>TO</div>
    <div *ngIf="planograms">
        <select name="compare" id="compare" class="form-control form-select" [(ngModel)]="compareTo">
            <option [value]="option.id" *ngFor="let option of planograms">
              {{ option.name }}
            </option>
        </select>
    </div>

    <div class="form-group mt-3">
        <label for="name" class="control-label" translate>ENTER_EMAIL_TO_SEND_COMPARE</label>
        <input
          class="form-control"
          type="text"
          name="name"
          id="name"
          [(ngModel)]="email"
          ngbAutofocus
        />
      </div>
</div>

<div class="modal-footer">
  <div class="btn-group btn-group-justified btn-block">
    <button class="btn btn-secondary" (click)="cancel()" translate>CANCEL</button>
    <button class="btn btn-primary" (click)="confirm()" [disabled]="!compareTo || !email" translate>OK</button>
  </div>
</div>
