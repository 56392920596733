import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JeevesBarcodeScannerConfigService } from 'gung-standard-jeeves';
import { BaseViewConfigService } from 'gung-standard';
import { OttoOlsenToastService } from './otto-olsen-toast.service';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenBarcodeScannerService extends JeevesBarcodeScannerConfigService {
  apiKey =
    'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNjU0MjkwLTEwMTY1NTMxNCIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTY1NDI5MCIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi0xODk3NTU4MDgyfQ==';

  // apiKey =
  //   'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNjU0MjkwLVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTY1NDIMCIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NkbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJjaGVja0NvZGUiOjcwODM1NzQ2NX0=';

  //trial key
  //apiKey = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNjU0MjkwLVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNjU0MjkwIiwiY2hlY2tDb2RlIjotMjE0MDU5NTQ4OH0=';

  pauseScanningTime = 2000;
  enablePauseScanningOnRead = false;
  scanSettings = {
    codeDuplicateFilter: 1000, // Minimum delay between duplicate results
    searchArea: { x: 0.25, y: 0.5, width: 0.5, height: 0.01 }
  };

  constructor(
    protected toastService: OttoOlsenToastService,
    ngbModal: NgbModal,
    protected baseViewConfigService: BaseViewConfigService
  ) {
    super(ngbModal, baseViewConfigService);
  }

  pauseScanning(barcodePicker, pauseTime: number = this.pauseScanningTime) {
    this.toastService.show(`Pause Scanning - ${pauseTime}`, { classname: 'bg-danger text-light', delay: 500 });
    super.pauseScanning(barcodePicker, pauseTime);
  }
}
