import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from 'gung-common';
import { LoadSavedCartModalComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-load-saved-cart-modal',
  templateUrl: './otto-olsen-load-saved-cart-modal.component.html',
  styleUrls: ['./otto-olsen-load-saved-cart-modal.component.scss']
})
export class OttoOlsenLoadSavedCartModalComponent extends LoadSavedCartModalComponent implements OnInit {

  @Input()
  data: {
    date: Date,
    total: number,
    qty: number,
    customer: string,
    name: string
  };

  constructor(
    public activeModal: NgbActiveModal,
    public dateUtilService: DateUtilService
  ) {
    super(activeModal);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
