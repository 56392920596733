import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router, mapToCanActivate, mapToCanDeactivate } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { GungDashboardComponent } from 'gung-dashboard';
import {
  AdminGuard,
  AnonymousWithLoginOptionGuard,
  AuthGuard,
  CheckoutWrapperComponent,
  Customer,
  NavigationConfig,
  PdfToCartComponent,
  RequestAccountComponent,
  SavedCartListComponent,
  SelectedCustomerService,
  GungTitleGuard,
  OrderListComponent
} from 'gung-standard';
import { OttoOlsenAssortmentTreeNavigationComponent } from './components/otto-olsen-assortment-tree-navigation/otto-olsen-assortment-tree-navigation.component';
import { OttoOlsenCreateProductComponent } from './components/otto-olsen-create-product/otto-olsen-create-product.component';
import { OttoOlsenCustomerDetailsComponent } from './components/otto-olsen-customer-details/otto-olsen-customer-details.component';
import { OttoOlsenHomeComponent } from './components/otto-olsen-home/otto-olsen-home.component';
import { OttoOlsenLoginComponent } from './components/otto-olsen-login/otto-olsen-login.component';
import { OttoOlsenOrderDetailsComponent } from './components/otto-olsen-order-details/otto-olsen-order-details.component';
import { OttoOlsenProductDetailsComponent } from './components/otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenProductExportListComponent } from './components/otto-olsen-product-export-list/otto-olsen-product-export-list.component';
import { OttoOlsenProductFastSearchListComponent } from './components/otto-olsen-product-fast-search-list/otto-olsen-product-fast-search-list.component';
import { OttoOlsenProductListAssortmentComponent } from './components/otto-olsen-product-list-assortment/otto-olsen-product-list-assortment.component';
import { OttoOlsenProductListComponent } from './components/otto-olsen-product-list/otto-olsen-product-list.component';
import { OttoOlsenProductPaginationListComponent } from './components/otto-olsen-product-pagination-list/otto-olsen-product-pagination-list.component';
import { OttoOlsenQuickSearchComponent } from './components/otto-olsen-quick-search/otto-olsen-quick-search.component';
import { OttoOlsenQuickorderScannerComponent } from './components/otto-olsen-quickorder-scanner/otto-olsen-quickorder-scanner.component';
import { OttoOlsenMobileStartPageComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-start-page/otto-olsen-mobile-start-page.component';
import { OttoOlsenAuthGuardService } from './services/otto-olsen-auth-guard.service';
import { OttoOlsenScannerSearchComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-scanner-search/otto-olsen-scanner-search.component';
import { OttoOlsenMobileAssortmentTreeNavigationComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-assortment-tree-navigation/otto-olsen-mobile-assortment-tree-navigation.component';
import { OttoOlsenMobileCheckoutComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-checkout/otto-olsen-mobile-checkout.component';
import { OttoOlsenScannerCheckoutConfirmComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-scanner-checkout-confirm/otto-olsen-scanner-checkout-confirm.component';
import { OttoOlsenMobileProductDescriptionComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-product-description/otto-olsen-mobile-product-description.component';
import { OttoOlsenSavedCartDetailsComponent } from './components/otto-olsen-saved-cart-details/otto-olsen-saved-cart-details.component';
import { OttoOlsenOrderConfirmationComponent } from './components/checkout/otto-olsen-order-confirmation/otto-olsen-order-confirmation.component';
import { OttoOlsenAuthGuardRedirectLoginService } from './services/otto-olsen-auth-guard-redirect-login.service';
import { OttoOlsenFavouriteProductListComponent } from './components/otto-olsen-favourite-product-list/otto-olsen-favourite-product-list.component';
import { OttoOlsenDemoAccountComponent } from './components/otto-olsen-demo-account/otto-olsen-demo-account.component';
import { OttoOlsenCheckoutWrapperComponent } from './components/checkout/otto-olsen-checkout-wrapper/otto-olsen-checkout-wrapper.component';
import { OttoOlsenManageUsersWrapperComponent } from './components/otto-olsen-manage-users-wrapper/otto-olsen-manage-users-wrapper.component';
import { OttoOlsenManageUsersDetailsComponent } from './components/otto-olsen-manage-users-details/otto-olsen-manage-users-details.component';

@Component({
  selector: 'otto-olsen-root',
  template: `<otto-olsen-app-wrapper></otto-olsen-app-wrapper>`,
  styles: []
})
export class OttoOlsenAppComponent implements OnInit {
  title = 'WEBSHOP_OTTO_OLSEN';

  constructor(
    private router: Router,
    private translate: TranslateService,
    @Optional() @Inject('environment') protected environment: NavigationConfig
  ) {
    const lang = localStorage.getItem('selectedLanguage');
    translate.use(environment.production ? 'no' : lang);
    registerLocaleData(localeSv);
    registerLocaleData(localeEn);
    this.setupRoutes();

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  ngOnInit(): void {
    this.setRobotsMetaTag();
  }

  /**
   * remove website from search engine
   */
  setRobotsMetaTag() {
    const host = window.location.hostname;
    if (host === 'oo-dev.gung.io' || host === 'dev.oo.no') {
      const metaTag = document.createElement('meta');
      metaTag.name = 'robots';
      metaTag.content = 'noindex, nofollow';
      document.getElementsByTagName('head')[0].appendChild(metaTag);
    }
  }

  private setupRoutes() {
    const routerConfig = this.router.config;
    this.router.resetConfig([
      {
        path: '',
        component: OttoOlsenHomeComponent,
        canActivate: mapToCanActivate([AnonymousWithLoginOptionGuard, GungTitleGuard]),
        data: {
          gungTitle: this.title
        }
      },
      {
        path: 'mobile',
        component: OttoOlsenMobileStartPageComponent,
        canActivate: [OttoOlsenAuthGuardService]
      },
      {
        path: 'mobile-product',
        redirectTo: '',
        pathMatch: 'full'
      },
      {
        path: 'mobile-product',
        canActivate: [AnonymousWithLoginOptionGuard],
        children: [
          {
            path: '**',
            canActivate: [AnonymousWithLoginOptionGuard],
            component: OttoOlsenMobileAssortmentTreeNavigationComponent
          }
        ]
      },
      {
        path: 'mobile-checkout',
        canActivate: [OttoOlsenAuthGuardRedirectLoginService],
        component: OttoOlsenMobileCheckoutComponent
      },
      {
        path: 'mobile-product-detail/:productId',
        canActivate: [OttoOlsenAuthGuardRedirectLoginService],
        component: OttoOlsenMobileProductDescriptionComponent
      },
      {
        path: 'products',
        component: OttoOlsenProductListComponent,
        canActivate: [AnonymousWithLoginOptionGuard]
      },
      {
        path: 'products/p/:productId',
        component: OttoOlsenProductListComponent,
        canActivate: [AnonymousWithLoginOptionGuard]
      },
      {
        path: 'products-export',
        component: OttoOlsenProductExportListComponent,
        canActivate: [OttoOlsenAuthGuardRedirectLoginService]
      },
      {
        path: 'products-export/p/:productId',
        component: OttoOlsenProductExportListComponent,
        canActivate: [OttoOlsenAuthGuardRedirectLoginService]
      },
      {
        path: 'products-search',
        canActivate: [AnonymousWithLoginOptionGuard],
        component: OttoOlsenProductFastSearchListComponent
      },
      {
        path: 'categories/:assortmentId',
        canActivate: [AnonymousWithLoginOptionGuard],
        component: OttoOlsenProductListComponent
      },
      {
        path: 'products/a/:assortmentId',
        component: OttoOlsenProductListAssortmentComponent,
        canActivate: mapToCanActivate([OttoOlsenAuthGuardRedirectLoginService, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Eget produktsortiment for <GUNG>selectedCustomer.name</GUNG> - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'articles',
        redirectTo: '',
        pathMatch: 'full'
      },
      {
        path: 'articles',
        canActivate: [AnonymousWithLoginOptionGuard],
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitleOut: this.title
        },
        children: [
          {
            path: '**',
            canActivate: [AnonymousWithLoginOptionGuard],
            // component: AssortmentTreeNavigationComponent
            component: OttoOlsenAssortmentTreeNavigationComponent
          }
        ]
      },
      {
        path: 'quick-search',
        canActivate: [AnonymousWithLoginOptionGuard],
        component: OttoOlsenQuickSearchComponent
      },
      {
        path: 'quick-products',
        canActivate: [AnonymousWithLoginOptionGuard],
        component: OttoOlsenProductPaginationListComponent
      },
      {
        path: 'pdf-to-cart',
        canActivate: [OttoOlsenAuthGuardRedirectLoginService],
        component: PdfToCartComponent
      },
      {
        path: 'product/:productId',
        component: OttoOlsenProductDetailsComponent,
        canActivate: [AnonymousWithLoginOptionGuard],
        pathMatch: 'full'
      },
      {
        path: 'orders/:orderId',
        canActivate: [OttoOlsenAuthGuardRedirectLoginService],
        component: OttoOlsenOrderDetailsComponent
      },
      {
        path: 'orders',
        component: OrderListComponent,
        canActivate: mapToCanActivate([OttoOlsenAuthGuardService, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Tidligere bestillinger for <GUNG>selectedCustomer.name</GUNG> - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'customers/:customerId',
        component: OttoOlsenCustomerDetailsComponent,
        canActivate: mapToCanActivate([OttoOlsenAuthGuardRedirectLoginService, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Mine sider for <GUNG>selectedCustomer.name</GUNG> - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'customers-dashboard',
        component: GungDashboardComponent,
        canActivate: mapToCanActivate([OttoOlsenAuthGuardRedirectLoginService, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Dashboard for <GUNG>selectedCustomer.name</GUNG> - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'admin/create-product',
        component: OttoOlsenCreateProductComponent,
        canActivate: [OttoOlsenAuthGuardRedirectLoginService]
      },
      {
        path: 'request-account',
        component: RequestAccountComponent
      },
      {
        path: 'login',
        component: OttoOlsenLoginComponent
      },
      {
        path: 'demo/checkout',
        component: OttoOlsenDemoAccountComponent
      },
      {
        path: 'quickorder-scanner',
        component: OttoOlsenQuickorderScannerComponent,
        canActivate: [OttoOlsenAuthGuardRedirectLoginService, AdminGuard]
      },
      {
        path: 'search-scanner',
        component: OttoOlsenScannerSearchComponent,
        canActivate: [OttoOlsenAuthGuardService]
      },
      {
        path: 'checkout-confirm',
        component: OttoOlsenScannerCheckoutConfirmComponent,
        canActivate: mapToCanActivate([OttoOlsenAuthGuardService, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Takk for bestillingen - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'checkout',
        component: OttoOlsenCheckoutWrapperComponent,
        canActivate: mapToCanActivate([AuthGuard, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Utsjekk for <GUNG>selectedCustomer.name</GUNG> - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'cart/:cartId',
        component: OttoOlsenSavedCartDetailsComponent,
        canActivate: [OttoOlsenAuthGuardService]
      },
      {
        path: 'order-confirmation/:contextId',
        canActivate: [OttoOlsenAuthGuardRedirectLoginService],
        component: OttoOlsenOrderConfirmationComponent
      },
      {
        path: 'sales-dashboard-beta',
        component: GungDashboardComponent,
        canActivate: [OttoOlsenAuthGuardRedirectLoginService]
      },
      {
        path: 'favourites',
        component: OttoOlsenFavouriteProductListComponent,
        canActivate: mapToCanActivate([OttoOlsenAuthGuardService, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Favoritter - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'carts',
        component: SavedCartListComponent,
        canActivate: mapToCanActivate([OttoOlsenAuthGuardRedirectLoginService, GungTitleGuard]),
        canDeactivate: mapToCanDeactivate([GungTitleGuard]),
        data: {
          gungTitle: `Lagrede handlekurver for <GUNG>selectedCustomer.name</GUNG> - Webshop Otto Olsen`,
          gungTitleOut: this.title
        }
      },
      {
        path: 'admin/manage-users',
        component: OttoOlsenManageUsersWrapperComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'admin/users-details/:username',
        component: OttoOlsenManageUsersDetailsComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'sales/manage-users',
        component: OttoOlsenManageUsersWrapperComponent,
        canActivate: [AuthGuard],
        data: {
          rolesAllowed: ['ACTUATOR', 'SALES']
        }
      },
      {
        path: 'sales/users-details/:username',
        component: OttoOlsenManageUsersDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          rolesAllowed: ['ACTUATOR', 'SALES']
        }
      },
      ...routerConfig
    ]);
  }
}
