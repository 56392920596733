import { Injectable } from '@angular/core';
import { CalendarDate } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoCheckoutStateServiceService {
  public dates: CalendarDate[] = null;

  public selectedDeliveryMethod: string = null;
  public partDeliveriesForbidden: boolean = false;
  public selectedDeliveryDate: string;
  constructor() {}
}
