import { Component, OnInit } from '@angular/core';
import { CustomerUserActionConfigService, CustomerUserCardListComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-customer-user-card-list',
  templateUrl: './otto-olsen-customer-user-card-list.component.html',
  styleUrls: ['./otto-olsen-customer-user-card-list.component.scss']
})
export class OttoOlsenCustomerUserCardListComponent extends CustomerUserCardListComponent {
  constructor(public actionConfig: CustomerUserActionConfigService) {
    super(actionConfig);
  }
}
