import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GungFile, Product } from 'gung-standard';

interface Section {
  title: string;
  bullet1: string;
  bullet2: string;
  bullet3: string;
  bullet4: string;
  link1: string;
  link1_text: string;
  link2: string;
  link2_text: string;
  description: string;
  documents: GungFile[];
}

interface ProductExtended extends Product {
  documents?: GungFile[];
}

@Component({
  selector: 'otto-olsen-product-info-page',
  templateUrl: './otto-olsen-product-info-page.component.html',
  styleUrls: ['./otto-olsen-product-info-page.component.scss']
})
export class OttoOlsenProductInfoPageComponent implements OnInit {
  @Input()
  product: ProductExtended;

  sections: Section[] = [];
  email: string;
  phone: string;

  currentLang = this.translateService.currentLang || 'se';

  documents: GungFile[] = [];

  constructor(protected translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.product?.extra?.pim?.infoPage) {
      this.documents =
        this.product.documents?.filter(doc => !!doc.extra?.documentTag?.includes('DOC_TAGS')) || this.documents;
      const keys = Object.keys(this.product.extra.pim);
      const sectionArray = keys
        .filter(k => k.includes('section_'))
        .map(k => k.substring(0, k.indexOf('_', k.indexOf('_') + 1)));
      const sections = Array.from(new Set(sectionArray));
      for (const section of sections) {
        const s: Section = {
          title: this.product.extra.pim[section + '_title'],
          bullet1: this.product.extra.pim[section + '_bullet1'],
          bullet2: this.product.extra.pim[section + '_bullet2'],
          bullet3: this.product.extra.pim[section + '_bullet3'],
          bullet4: this.product.extra.pim[section + '_bullet4'],
          link1: this.product.extra.pim[section + '_link1'],
          link1_text: this.product.extra.pim[section + '_link1_text'],
          link2: this.product.extra.pim[section + '_link2'],
          link2_text: this.product.extra.pim[section + '_link2_text'],
          description: this.product.extra.pim[section + '_description'],
          documents: this.product.documents.filter(d => d.extra?.documentTag === section.replace('_', '').toUpperCase())
        };
        if (s.link1 && !(s.link1.includes('http://') || s.link1.includes('https://'))) {
          s.link1 = 'http://' + s.link1;
        }
        if (s.link2 && !(s.link2.includes('http://') || s.link2.includes('https://'))) {
          s.link2 = 'http://' + s.link2;
        }

        // https://gung-se.freshdesk.com/a/tickets/103464
        // Section 2 burde forsvinne automatisk når det ikke er noen berikelse i denne:
        if (s.title || s.description) {
          this.sections.push(s);
        }

        if (this.product.extra.i18n && this.product.extra.i18n[this.currentLang]?.assortmentDescription) {
          this.product.description = this.product.extra.i18n[this.currentLang]?.assortmentDescription;
        }

        this.email = 'epost@epost.no';
        this.phone = '2222222';
        if (this.product.extra.pim?.email) {
          this.email = this.product.extra.pim.email;
        }
        if (this.product.extra.pim?.phone) {
          this.phone = this.product.extra.pim.phone;
        }
      }
    }
  }
}
