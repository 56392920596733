import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, first, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenOrderNavigationService implements OnDestroy{
  private unsubscribe: Subject<void> = new Subject();
  private orderIdList: Subject<string[]> = new BehaviorSubject<string[]>([]);
  private currentOrderId: Subject<string> = new BehaviorSubject<string>('');
  private nextOrderId: string = '';
  private previousOrderId: string = '';
  public isOrderNavigationReady: boolean = false;
  public isNextButtonActive: boolean = true;
  public isPreviousButtonActive: boolean = true;
  public orderDetailsRoute: string = '/orders'

  constructor(
    protected router: Router
  ) {
    this.currentOrderId.pipe(takeUntil(this.unsubscribe)).subscribe(orderId => {
      this.orderIdList.pipe(first()).subscribe(orderList => {
        if (orderId && orderList.length > 0) {
          this.checkForNextAndPrevious(orderId, orderList);
        }
      });
    });
  }

  public setOrderNavigation(currentOrderId: string, orderIdList: string[]) {
    if (!currentOrderId && orderIdList?.length === 0) {
      return;
    }
    this.orderIdList.next(orderIdList);
    this.currentOrderId.next(currentOrderId);
  }

  public setOrderNavigationCurrentOrder(currentOrderId: string) {
    if (!currentOrderId) {
      return;
    }
    this.currentOrderId.next(currentOrderId);
  }

  public goToNextOrder() {
    if (!this.isOrderNavigationReady || !this.nextOrderId) {
      return;
    }

    this.router.navigate([this.orderDetailsRoute, this.nextOrderId], { replaceUrl: true });
    this.setOrderNavigationCurrentOrder(this.nextOrderId);
  }

  public goToPreviousOrder() {
    if (!this.isOrderNavigationReady || !this.previousOrderId) {
      return;
    }

    this.router.navigate([this.orderDetailsRoute, this.previousOrderId], { replaceUrl: true });
    this.setOrderNavigationCurrentOrder(this.previousOrderId);
  }

  private checkForNextAndPrevious(orderId: string, orderList: string[]) {
    const orderListLength: number = orderList.length;
    const orderIndex: number = orderList.findIndex(id => id === orderId);

    if (orderListLength === 1) {
      this.isOrderNavigationReady = false;
      this.previousOrderId = '';
      this.isPreviousButtonActive = false;
      this.nextOrderId = '';
      this.isNextButtonActive = false;
      return;
    } else {
      this.isOrderNavigationReady = true;
    }

    if (orderIndex === 0) {
      this.previousOrderId = '';
      this.isPreviousButtonActive = false;
      this.nextOrderId = orderList[orderIndex + 1];
      this.isNextButtonActive = true;
      return;
    }
    
    if (orderIndex === orderListLength - 1) {
      this.previousOrderId = orderList[orderIndex - 1];
      this.isPreviousButtonActive = true;
      this.nextOrderId = '';
      this.isNextButtonActive = false;
      return;
    }
    
    this.previousOrderId = orderList[orderIndex - 1];
    this.isPreviousButtonActive = true;
    this.nextOrderId = orderList[orderIndex + 1]
    this.isNextButtonActive = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
