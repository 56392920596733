<div class="row">
  <div class="col-12">
    <ngb-carousel *ngIf="slider" [showNavigationArrows]="false" [showNavigationIndicators]="false">
      <ng-template ngbSlide *ngFor="let slide of slider">
        <div class="picsum-img-wrapper d-flex align-items-center justify-content-center">
          <img class="center-block" [src]="slide.image.sourceUrl" alt="Random first slide" />
        </div>
        <div class="carousel-caption mh-100">
          <h3>{{ slide.text.text }} <a *ngIf="slide.link.enabled" [href]="slide.link.url" target="_NEW">{{ slide.link.text }}</a></h3>
          <p>{{ slide.textSecondary.text }}</p>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row mt-4">
  <!-- <div class="col-12">
    <button type="button" class="btn btn-primary w-100" [routerLink]="'/products-search'" translate>PRODUCT_FAST_SEARCH</button>
  </div> -->
</div>
<div class="row mt-4">
  <div class="col-12 col-sm-6 col-md-3 mb-2 assortment" *ngFor="let assortment of assortmentsList; let i = index">
    <div class="card h-100" [routerLink]="['/articles/', assortment.id]">
      <div class="card-body d-flex flex-column justify-content-between">
        <h5 class="card-title text-left">{{ assortment.name }}</h5>
        <p class="card-text text-left">{{ assortment.description }}</p>
        <a href="javascript:void(0)" class="card-link border-0 align-self-start">
          <i class="mt-auto fa-2x text-primary fas fa-long-arrow-alt-right"></i>
        </a>
      </div>
    </div>
  </div>
</div>
