<div *ngIf="product !== null; else loading">
  <div class="row">
    <div class="col-12 no-print">
      <p class="m-0">
        <b><span translate>PRODUCT_NUMBER</span>:</b> {{ product?.id }}
      </p>
      <p class="m-0">
        <b><span translate>PRODUCT_NAME</span>:</b> {{ product?.name }}
      </p>
    </div>
  </div>
  <div class="row pt-1 product-detail-data-section">
    <div class="col-6 no-print">
      <otto-olsen-image-carousel
        *ngIf="product"
        [openExternal]="true"
        [zoom]="false"
        [images]="
          product?.extra?.images &&
          product?.extra?.images.length > 0 &&
          product?.extra?.images[0].s3Uri !== 'images/no-image.jpg'
            ? product?.extra?.images
            : currentAssortment?.extra?.images
        "
        [videoUrl]="product?.extra?.pim?.videolink"
      ></otto-olsen-image-carousel>
    </div>

    <!-- Product data -->
    <div class="col">
      <div class="col mt-5">
        <h4 class="product-price">
          <lib-price [price]="price" type="GROSS"></lib-price>
          <span *ngIf="price?.customerDiscountPercent !== 0" [class.blur]="hidePrice">
            (-{{ price?.customerDiscountPercent }}%)</span
          >
          <span
            *ngIf="
              price?.customerDiscountPercent === 0 &&
              Number(product?.extra?.ar.artlistpris) !== Number(price.backendPriceLevel.price)
            "
            [class.blur]="hidePrice"
            >({{ 'NET_PRICE' | translate }})</span
          >
        </h4>
      </div>
      <div class="row" *ngIf="!isAnonymous">
        <div class="col-12">
          <i class="fas fa-box"></i>&nbsp;
          <otto-olsen-availability
            [availability]="availability"
            [isSales]="isSales"
            [unit]="product?.extra?.ar?.enhetskod"
            [details]="true"
            [decimals]="product?.extra?.ar.antdec"
            [productionDays]="
              product?.extra.ar?.anskaffningssatt &&
              product?.extra.ar?.anskaffningssatt * 1 === 12 &&
              product?.extra.ar?.artgenomloppstid
            "
          ></otto-olsen-availability>
        </div>
      </div>

      <div
        class="row mt-2"
        *ngIf="
          !isAnonymous &&
          !(product?.extra?.pim && product?.extra?.pim.pricerequest && product?.extra?.pim.pricerequest !== 'false') &&
          !(product?.extra?.ar.itemtypecd1 === 0 || product?.extra?.ar.itemtypecd1 === 7) &&
          (product?.extra?.pim?.activeInB2B || isSales)
        "
      ></div>

      <div class="row" *ngIf="!isOnlyDisplayFlow && !isAnonymous">
        <div
          class="col-12 col-md-6"
          *ngIf="
            !(
              product?.extra?.pim &&
              product?.extra?.pim.pricerequest &&
              product?.extra?.pim.pricerequest !== 'false'
            ) && !(product?.extra?.ar.itemtypecd1 === 0 || product?.extra?.ar.itemtypecd1 === 7);
            else priceRequest
          "
        >
          <otto-olsen-buy-btn
            name="buy-btn-package-size"
            [id]="product?.id"
            [minimumOrderQuantity]="product?.firstStepAmount || product?.packageSize"
            [stepAmount]="product?.packageSize"
            [decimals]="product?.extra?.ar.antdec"
            [originalSize]="product?.originalSize"
            [unit]="product?.extra?.ar?.enhetskod"
            [activeInB2B]="true"
            [product]="product"
            [availability]="availability"
          >
            <!-- They want products to be purchasable in the scanner, no matter if active in B2B or not https://support.gung.se/a/tickets/246248 -->
          </otto-olsen-buy-btn>
          <div class="mt-1 d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-sm btn-primary w-45"
              (click)="
                subtract(
                  product,
                  availability,
                  number(product.packageSize || 1) * 10
                ); $event.preventDefault(); $event.stopPropagation()
              "
            >
              -{{ number(product.packageSize || 1) * 10 }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary w-45"
              (click)="
                add(
                  product,
                  availability,
                  number(product.packageSize || 1) * 10
                ); $event.preventDefault(); $event.stopPropagation()
              "
            >
              +{{ number(product.packageSize || 1) * 10 }}
            </button>
          </div>
        </div>
        <ng-template #priceRequest>
          <div class="col-12 col-md-6">
            <a
              href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ product?.id }}"
              target="_blank"
              class="request-price-email"
            >
              <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
            </a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="mt-2">
    <lib-gung-accordion #acc="gungAccordion">
      <lib-gung-accordion-item>
        <ng-template libGungAccordionHeader>{{ 'PRODUCT_FEATURES' | translate }}</ng-template>
        <ng-template libGungAccordionBody>
          <div *ngIf="productDetailsTable?.length > 0" class="row mt-3 product-detail-data-section">
            <div class="col-12 col-md-6 product-tabel-detail" *ngFor="let item of productDetailsTable">
              <!-- <lib-gung-text-input-horizontal renderStatic="true" gungTranslate="{{item.title}}" [(ngModel)]="item.value"></lib-gung-text-input-horizontal> -->
              <div class="form-group row">
                <label class="col-form-label" [ngClass]="'col-12 col-sm-4'" translate>{{ item.title }}</label>
                <div class="col">
                  <span>
                    {{ item.value }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </lib-gung-accordion-item>
    </lib-gung-accordion>
  </div>
  <div class="accordion-tab" [hidden]="!showParker">
    <input type="checkbox" id="chck1" class="accordion-input" />
    <label class="accordion-tab-label" for="chck1" translate>DESCRIPTION</label>
    <div class="accordion-tab-content">
      <div id="parker"></div>
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
<otto-olsen-scanner-footer-menu></otto-olsen-scanner-footer-menu>
