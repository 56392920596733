import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  NavbarComponent,
  SelectedCustomerService,
  AuthService,
  CartService,
  NavbarConfigService,
  GungFlowService,
  ProductInlineSearchListConfigService
} from 'gung-standard';
import { first, takeUntil } from 'rxjs/operators';
import { OttoOlsenInlineSearchListConfigService } from '../../services/otto-olsen-inline-search-list-config.service';
import { OttoOlsenRoleSwitcherHelperService } from '../../services/otto-olsen-role-switcher-helper.service';
import { GlobalSearchConfigService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-navbar',
  templateUrl: './otto-olsen-navbar.component.html',
  styleUrls: ['./otto-olsen-navbar.component.scss']
})
export class OttoOlsenNavbarComponent extends NavbarComponent implements OnInit {
  public contactEmail = 'post@oo.no';
  public contactMobile = '63 89 08 00';
  public showInlineSearch = true;

  public isViewOnly = false;
  public isScannerOnly = false;
  public currentMode = localStorage.getItem('InterfaceMode');

  @Input() hideMenuRoutes;

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected elementRef: ElementRef,
    protected navbarConfigService: NavbarConfigService,
    protected gungFlowService: GungFlowService,
    public router: Router,
    protected globalSearchConfig: GlobalSearchConfigService,
    public configService: OttoOlsenInlineSearchListConfigService,
    protected ottoOlsenRoleSwitcherHelperService: OttoOlsenRoleSwitcherHelperService
  ) {
    super(selectedCustomerService, authService, cartService, elementRef, navbarConfigService, gungFlowService, router, globalSearchConfig);
    this.authService
      .getHasRoles('SCANNER')
      .pipe(first())
      .subscribe(isScannerOnly => (this.isScannerOnly = isScannerOnly));

      this.ottoOlsenRoleSwitcherHelperService.getRoleChangeObservable().pipe(takeUntil(this.unsubscribe)).subscribe(newRole => {
        this.currentMode = newRole;
        this.navbarHeight.emit(this.currentMode === 'B2B' ? 150 : 50 );  
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.getHasRoles('VIEW').subscribe(isViewOnly => (this.isViewOnly = isViewOnly));
  }

}
