import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import {
  ProductListConfigService,
  ProductService,
  AuthService,
  ProductSortService,
  GungFlowService,
  Product,
  SelectedCustomerService,
  Customer,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, filter, mergeMap, first } from 'rxjs';
import { OttoOlsenProductGridViewComponent } from '../components/otto-olsen-product-grid-view/otto-olsen-product-grid-view.component';
import { OttoOlsenProductPaginationListCardComponent } from '../components/otto-olsen-product-pagination-list-card/otto-olsen-product-pagination-list-card.component';
import { OttoOlsenProductTreeTableViewComponent } from '../components/otto-olsen-product-tree-table-view/otto-olsen-product-tree-table-view.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductAssortmentListConfigService extends ProductListConfigService {
  public selectedCustomer: Customer;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected selectedCustomerService: SelectedCustomerService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(productService, authService, productSortService, gungFlowService, cloudPimFiltersService, currentAssortmentService);
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenProductPaginationListCardComponent /* OttoOlsenProductGridViewComponent */,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<Product[]> {
    return this.assortmentSubject.pipe(
      switchMap((assortment: string) => {
        if (assortment == null) {
          return this.authService.getCurrentUser().pipe(
            filter(user => !!user),
            switchMap(user => this.fetchProducts(user && user.assortment))
          );
        }
        return this.fetchProducts(assortment);
      }),
      mergeMap(products => {
        return forkJoin([of(products), this.selectedCustomerService.getSelectedCustomer().pipe(first())]);
      }),
      switchMap(([products, customer]) => {
        this.selectedCustomer = customer;
        return of(products);
      })
    );
  }

  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }

  getSearchTerms(item: Product) {
    let referenceId;
    if (
      this.selectedCustomer &&
      item.extra._customerCrossReferences &&
      item.extra._customerCrossReferences[this.selectedCustomer.id]
    ) {
      referenceId = item.extra._customerCrossReferences[this.selectedCustomer.id].referenceId;
    }
    return [item.id, item.name, referenceId];
  }
}
