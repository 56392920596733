<div *ngIf="product != null; else loading">
  <div class="row pt-1 product-detail-data-section">
    <div class="col-6">
      <!-- <lib-image-carousel *ngIf="product" [openExternal]="true" [zoom]="false"
                  [images]="product.extra.images && product.extra.images.length > 0 && product.extra.images[0].s3Uri !== 'images/no-image.jpg' ? product.extra.images : currentAssortment?.extra?.images"></lib-image-carousel> -->
      <otto-olsen-image-carousel
        *ngIf="product"
        [openExternal]="true"
        [zoom]="false"
        [images]="
          product.extra.images &&
          product.extra.images.length > 0 &&
          product.extra.images[0].s3Uri !== 'images/no-image.jpg'
            ? product.extra.images
            : currentAssortment?.extra?.images
        "
        [videoUrl]="product.extra?.pim?.videolink"
      ></otto-olsen-image-carousel>
    </div>
    <div class="col-6">
      <!-- Product data -->
      <div
        class="row mt-2"
        *ngIf="
          !isAnonymous &&
          !(product.extra.pim && product.extra.pim.pricerequest && product.extra.pim.pricerequest !== 'false') &&
          !(product.extra.ar?.itemtypecd1 === 0 || product.extra.ar?.itemtypecd1 === 7) &&
          (product?.extra?.pim?.activeInB2B || isSales)
        "
      >
        <div class="col">
          <h5 class="product-price">
            <lib-price [price]="price" type="NET"></lib-price>
          </h5>
        </div>
      </div>
      <div class="row" *ngIf="!isAnonymous">
        <div class="col-12">
          <i class="fas fa-box"></i>&nbsp;
          <otto-olsen-availability
            [availability]="availability"
            [isSales]="isSales"
            [unit]="product.extra.ar?.enhetskod"
            [decimals]="product.extra.ar?.antdec"
            [productionDays]="
              product?.extra.ar?.anskaffningssatt &&
              product?.extra.ar?.anskaffningssatt * 1 === 12 &&
              product?.extra.ar?.artgenomloppstid
            "
          >
          </otto-olsen-availability>
        </div>
      </div>

      <div class="row" *ngIf="!isOnlyDisplayFlow && !isAnonymous">
        <div
          class="col-12 col-md-6"
          *ngIf="
            !(product.extra.pim && product.extra.pim.pricerequest && product.extra.pim.pricerequest !== 'false') &&
              !(product.extra.ar?.itemtypecd1 === 0 || product.extra.ar?.itemtypecd1 === 7);
            else priceRequest
          "
        >
          <otto-olsen-buy-btn
            name="buy-btn-package-size"
            [id]="product.id"
            [minimumOrderQuantity]="product.firstStepAmount || product.packageSize"
            [stepAmount]="product.packageSize"
            [decimals]="product.extra.ar?.antdec"
            [originalSize]="product.originalSize"
            [unit]="product.extra.ar?.enhetskod"
            [activeInB2B]="true"
            [product]="product"
            [availability]="availability"
          >
            <!-- They want products to be purchasable in the scanner, no matter if active in B2B or not https://support.gung.se/a/tickets/246248 -->
          </otto-olsen-buy-btn>
          <div class="mt-1 d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              (click)="
                subtract(
                  product,
                  availability,
                  number(product.packageSize || 1) * 10
                ); $event.preventDefault(); $event.stopPropagation()
              "
            >
              -{{ number(product.packageSize || 1) * 10 }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              (click)="
                add(
                  product,
                  availability,
                  number(product.packageSize || 1) * 10
                ); $event.preventDefault(); $event.stopPropagation()
              "
            >
              +{{ number(product.packageSize || 1) * 10 }}
            </button>
          </div>
        </div>
        <ng-template #priceRequest>
          <div class="col-12 col-md-6">
            <a
              href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ product.id }}"
              target="_blank"
              class="request-price-email"
            >
              <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
            </a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-- <button type="button" class="btn btn-primary"
                    [routerLink]="breadcrumbMenu[breadcrumbMenu.length-1].urlParts" translate>PRODUCT_DETAILS</button> -->
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
