<div class="table-net-price text-truncate">{{price.customerNetPrice.value | number : '1.2-2' : '' : '.' }} {{price.customerNetPrice.currencyCode}}/{{product.extra.ar.enhetskod}}</div>
<div class="table-gross-price" *ngIf="price.customerDiscountPercent && price.customerDiscountPercent > 0">
<span translate>REC_PRICE</span>: {{price.customerGrossPrice.value | number : '1.2-2' : '' : '.' }}<br/>
({{price.customerDiscountPercent}}% <span translate class="discount-string">DISCOUNT</span>)
</div>
<span
    *ngIf="
    price.customerDiscountPercent === 0 &&
    Number(product.extra.ar.artlistpris) !== Number(price.backendPriceLevel.price)
    "
    >({{ 'NETPRICE' | translate }})
</span>