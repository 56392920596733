import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocationConfigService, Order } from 'gung-standard';
import { OttoOlsenOrderNavigationService } from '../../services/otto-olsen-order-navigation.service';
import { DateUtilService } from 'gung-common';
import { JeevesOpenOrderTableListComponent } from 'gung-standard-jeeves';

@Component({
  selector: 'otto-olsen-open-order-table',
  templateUrl: './otto-olsen-open-order-table.component.html',
  styleUrls: ['./otto-olsen-open-order-table.component.scss']
})
export class OttoOlsenOpenOrderTableComponent extends JeevesOpenOrderTableListComponent {
  constructor(
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    public dateUtilService: DateUtilService,
    public orderNavigationService: OttoOlsenOrderNavigationService
  ) {
    super(
      locationConfigService,
      router,
      dateUtilService
    );
  }

  public getOrderIds(orders: Order[]): string[] {
    return orders.map(o => o.id);
  }
}
