import { Injectable } from '@angular/core';
import { NgbModalRef, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { OttoOlsenProductDetailsModalComponent } from '../components/otto-olsen-product-details-modal/otto-olsen-product-details-modal.component';
import { Location } from '@angular/common';
import { Availability, GungModalService, Product } from 'gung-standard';
import { OttoOlsenAvailabilityModalComponent } from '../components/otto-olsen-availability-modal/otto-olsen-availability-modal.component';
import { OttoOlsenView3dModelModalComponent } from '../components/otto-olsen-view3d-model-modal/otto-olsen-view3d-model-modal.component';
import { OttoOlsenAvailabilityWarehousesModalComponent } from '../components/otto-olsen-availability-warehouses-modal/otto-olsen-availability-warehouses-modal.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenModalService extends GungModalService {

  constructor(
    protected ngbModal: NgbModal,
    protected route: ActivatedRoute,
    protected router: Router,
    protected location: Location
  ) {
    super(ngbModal);
  }
  // public modalRef: NgbModalRef;

  modalOptions: NgbModalOptions = {
    backdrop: true,
    windowClass: 'modal-lg-custom',
    size: 'lg',
    keyboard: true
  };

  openProductDetailsModal(productId) {
    const id = OttoOlsenProductDetailsModalComponent.name;
    const modalRef = this.ngbModal.open(OttoOlsenProductDetailsModalComponent, this.modalOptions);
    modalRef.componentInstance.productId = productId;
    modalRef.result.finally(() => {
      const currentUrl = this.location.path();
      this.location.go(currentUrl.split('/p/')[0]);
    });

    this.modalRef.set(id, modalRef);
  }

  openAvailabilityModal(availability: Availability, modalOption?: NgbModalOptions, product?: Product): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(OttoOlsenAvailabilityWarehousesModalComponent, option);
    const componentInstance = ref.componentInstance as OttoOlsenAvailabilityWarehousesModalComponent;
    componentInstance.availability = availability;
    componentInstance.product = product;

    return ref.result;
  }

  openExpanded3DModelViewerModal(modelFile: string[], modalOption?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      size: 'lg',
      windowClass: 'modal-lg-viewer-custom',
      backdrop: 'static',
      keyboard: true,
      centered: true,
    };

    const ref = this.ngbModal.open(OttoOlsenView3dModelModalComponent, option);
    const componentInstance = ref.componentInstance as OttoOlsenView3dModelModalComponent;
    componentInstance.models = modelFile;
    return ref.result;
  }
}
