import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format, parse } from 'date-fns';
import {
  Availability,
  CalendarDate,
  CheckoutObject,
  CheckoutQuantityValidationService,
  CheckoutRow,
  Product,
  ValidationAction,
  ValidationRequest,
  ValidationResult
} from 'gung-standard';
import { JeevesCheckoutQuantityValidationService } from 'gung-standard-jeeves';
import { ProductExtended } from '../../../components/otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenFreightCostService } from '../../otto-olsen-freight-cost.service';

@Injectable({
  providedIn: 'root'
})
export class OttoCheckoutQuantityValidationService extends CheckoutQuantityValidationService {
  constructor(
    protected translateService: TranslateService,
    protected ottoOlsenFreightCostService: OttoOlsenFreightCostService
  ) {
    super(translateService);
  }

  public getQtyValidationResult(validationRequest: ValidationRequest): ValidationResult {
    const validationResult: ValidationResult = {
      row: validationRequest.row,
      product: validationRequest.product,
      availability: validationRequest.availability,
      valid: true,
      actions: []
    };

    // No validation for the freight cost product
    if (validationRequest.product.id === this.ottoOlsenFreightCostService.freightCostProductId) {
      return validationResult;
    }

    const partialDeliveriesAllowed = validationRequest.checkout.extra.oh.dellevtillaten !== '10';

    if (validationRequest.availability.currentAvailability < validationRequest.row.quantity) {
      if (this.checkStockForDeliveryDate(validationRequest)) {
        return validationResult;
      }

      // Force selection, Oscar Jacobson requires this
      validationResult.actions.push(super.getNOOPAction(validationResult));

      // LET'S CHECK IF ANY QTY AVAILABLE IN THE FUTURE HIGHER OR EQUAL THAN REQUESTED QTY
      // IF YES
      //    THEN IF ORDER DELIVERY DATE IS ON_OR_AFTER THAN THE DATE WHEN THE FUTURE AVAILABILITY IS AVAILABLE
      //        THEN
      //            RETURN OK
      //        OTHERWISE
      //            ADD PUSH DELIREY DATE ACTION
      // OTHERWISE
      //    DO NOT ADD THE ACTION TO PUSH DELIVERY DATE
      let addDeliveryPlanAction = false;
      if (partialDeliveriesAllowed) {
        // Some options only valid if partial deliveries allowed

        if (validationRequest.availability.currentAvailability < validationRequest.row.quantity) {
          const deliveryDate = this.getRowDeliveryDate(validationRequest.row, validationRequest.checkout);

          const futureAvailabilityPlan = this.getFutureAvailabilityPlan(
            deliveryDate,
            validationRequest.availability,
            validationRequest.row.quantity,
            validationRequest.calendarValidDeliveryDates
          );

          const planSuggested = {};
          let hasAnyQty = false;

          for (const element of futureAvailabilityPlan) {
            planSuggested[format(element.date, 'yyyy-MM-dd')] = element.qty;
            hasAnyQty = hasAnyQty || element.qty > 0;
          }

          const planOnRow = validationRequest.row.extra._deliveryPlan;
          const isPlanEqual = JSON.stringify(planSuggested) === JSON.stringify(planOnRow);
          if (isPlanEqual) {
            return validationResult;
          }

          if (hasAnyQty && futureAvailabilityPlan.length > 0) {
            addDeliveryPlanAction = true;
          }
        }

        let addPushDeliveryDateAction = false;
        if (validationRequest.availability.maxFutureStock >= validationRequest.row.quantity) {
          const deliveryDate = this.getRowDeliveryDate(validationRequest.row, validationRequest.checkout);
          const futureAvailability = super.getFutureAvailability(
            validationRequest.availability,
            validationRequest.row.quantity
          );

          if (deliveryDate >= futureAvailability.date && validationRequest.row.quantity <= futureAvailability.qty) {
            // DELIVERY DATE FOR THIS ITEM IS ON_OR_AFTER THAN IT WILL BE AVAILABLE IN FUTURE
            return validationResult;
          }

          addPushDeliveryDateAction = true;
        }

        if (addDeliveryPlanAction) {
          validationResult.actions.push(this.getDeliveryPlanAction(validationResult, validationRequest));
        }

        if (addPushDeliveryDateAction) {
          validationResult.actions.push(this.getPushDeliveryDateAction(validationResult, validationRequest));
        }
      }
      // set not valid action
      validationResult.valid = false;
      const availabilityValue = validationResult.availability.currentAvailability;
      validationResult.description =
        this.translateService.instant('NOT_ENOUGH_AVAILABILITY') +
        ' (' +
        validationResult.row.quantity +
        '>' +
        availabilityValue +
        ')';

      if (availabilityValue > 0) {
        // Reduce quantity not relevant if no quantity exists
        validationResult.actions.push(super.getLowerAvailabilityAction(validationResult));
      } else if (
        validationRequest.availability.maxFutureStock > 0 &&
        validationRequest.availability.maxFutureStock < validationRequest.row.quantity
      ) {
        // only add if not added the action to lower availability above not added
        validationResult.description =
          this.translateService.instant('NOT_ENOUGH_AVAILABILITY') +
          ' (' +
          validationResult.row.quantity +
          '>' +
          validationRequest.availability.maxFutureStock +
          ')';
        validationResult.actions.push(super.getLowerAvailabilityFutureStockAction(validationResult));
      }

      validationResult.selectedAction = validationResult.actions[0];
    }

    return validationResult;
  }

  checkStockForDeliveryDate(validationRequest: ValidationRequest): boolean {
    const deliveryDate = this.getRowDeliveryDate(validationRequest.row, validationRequest.checkout);
    const futureAvailability = super.getFutureAvailability(
      validationRequest.availability,
      validationRequest.row.quantity
    );

    if (deliveryDate >= futureAvailability.date && validationRequest.row.quantity <= futureAvailability.qty) {
      // DELIVERY DATE FOR THIS ITEM IS ON_OR_AFTER THAN IT WILL BE AVAILABLE IN FUTURE
      return true;
    }
    return false;
  }

  public getPushDeliveryDateAction(
    validationResult: ValidationResult,
    validationRequest: ValidationRequest
  ): ValidationAction {
    const futureDeliveryDate = super.getFutureAvailability(
      validationResult.availability,
      validationResult.row.quantity,
      validationRequest.calendarValidDeliveryDates
    ).date;
    const futureDeliveryDateStr = format(futureDeliveryDate, 'yyyy-MM-dd');

    return {
      description: this.translateService.instant('PUSH_DELIVERY_DATE') + ' -> ' + futureDeliveryDateStr,
      callback(): void {
        validationResult.row.extra.orp.ordberlevdat = format(futureDeliveryDate, 'yyyy-MM-dd');
      }
    };
  }

  public getDeliveryPlanAction(
    validationResult: ValidationResult,
    validationRequest: ValidationRequest
  ): ValidationAction {
    const deliveryDate = this.getRowDeliveryDate(validationRequest.row, validationRequest.checkout);
    const futureAvailabilityPlan = this.getFutureAvailabilityPlan(
      deliveryDate,
      validationRequest.availability,
      validationRequest.row.quantity,
      validationRequest.calendarValidDeliveryDates,
      validationRequest.product
    );

    const deliveryPlan = {};
    let lastDate = '';
    for (const element of futureAvailabilityPlan) {
      deliveryPlan[format(element.date, 'yyyy-MM-dd')] = element.qty;
      lastDate = format(element.date, 'yyyy-MM-dd');
    }

    return {
      description:
        this.translateService.instant('PARTIAL_DELIVERIES_ASAP') +
        ' ' +
        JSON.stringify(deliveryPlan).replace('{', '[').replace('}', ']').split('"').join(''),
      callback(): void {
        validationResult.row.extra._deliveryPlan = deliveryPlan;
      }
    };
  }

  public getRowDeliveryDate(row: CheckoutRow, checkout: CheckoutObject): Date {
    const partialDeliveriesAllowed = checkout.extra.oh.dellevtillaten !== '10';
    if (!partialDeliveriesAllowed) {
      return checkout.extra.oh.ordberlevdat
        ? parse(checkout.extra.oh.ordberlevdat, 'yyyy-MM-dd', new Date())
        : new Date();
    }

    row.extra.orp = row.extra.orp || {};
    if (!row.extra.orp.ordberlevdat) {
      row.extra.orp.ordberlevdat = row.extra.orp.ordberlevdat;
    }

    return row.extra.orp.ordberlevdat ? parse(row.extra.orp.ordberlevdat, 'yyyy-MM-dd', new Date()) : new Date();
  }

  public getFutureAvailabilityPlan(
    firstDate: Date,
    availability: Availability,
    requiredQty: number,
    calendarValidDeliveryDates: CalendarDate[] = null,
    product?: ProductExtended
  ): { qty: number; date: Date }[] {
    // Product package size
    let packageSize;
    if (
      product?.extra.ar?.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 0
    ) {
      packageSize = Number(product.extra.ar.artfsgforp);
    }
    let originalSize;
    // Product original size without cutting
    if (product?.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
      originalSize = Number(product.extra.ar.artfsgforp);
    }
    if (product?.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
      packageSize = Number(product.extra.ar.q_salesbatchsize);
    }
    const result = [];
    let leftToAllocate = requiredQty;

    // Transform the list of availabilities according to current calendar (and selected date)
    const remapped = this.transformAvailabilityDates(
      availability.extra.availabilities,
      firstDate,
      calendarValidDeliveryDates
    );

    for (const key of Object.keys(remapped)) {
      const value = remapped[key];

      if ((originalSize || packageSize) && (originalSize || packageSize) > leftToAllocate) {
        leftToAllocate = originalSize || packageSize;
      }

      if (leftToAllocate > 0) {
        const futureDate = parse(key, 'yyMMdd', new Date());

        const allocation = Math.min(leftToAllocate, value);
        leftToAllocate = leftToAllocate - allocation;
        const element = {
          qty: allocation,
          date: futureDate
        };
        result.push(element);
      }
    }

    return result;
  }
}
