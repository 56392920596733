import { Injectable } from '@angular/core';
import { Customer } from 'gung-standard';
import { JeevesCustomerListConfigService } from 'gung-standard-jeeves';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCustomerListConfigService extends JeevesCustomerListConfigService{
  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }

  getSearchTerms(item: Customer): string[] {
    const superTerms = super.getSearchTerms(item);
    if (item.extra.fr && item.extra.fr.orgnr) {
      return superTerms.concat([item.extra.fr.orgnr]);
    }
    return superTerms;
  }
}
