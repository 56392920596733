import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenPimEditConfigService {
  public pimEditUrlHide: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public hasPimEditUrlsRoles(userRoles: string[], type: string = 'any'): boolean {
    if (type === 'products-concepts' && userRoles.includes('PRODUCT-MANAGER')) {
      return true;
    } else if (type === 'assortments' && userRoles.includes('ASSORTMENT-MANAGER')) {
      return true;
    } else if (type === 'any' && (userRoles.includes('ASSORTMENT-MANAGER') || userRoles.includes('PRODUCT-MANAGER'))) {
      return true;
    } else {
      return false;
    }
  }
}
