import { Component, Input, OnInit } from '@angular/core';
import {
  LastVsThisYearSalesAmountSummaryCardComponent,
  DashboardKpiService,
  Timespan,
  SalesSummaryEntity
} from 'gung-dashboard';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs';

@Component({
  selector: 'otto-olsen-last-full-year-vs-this-year-sales-summary-card',
  templateUrl: './otto-olsen-last-full-year-vs-this-year-sales-summary-card.component.html',
  styleUrls: ['./otto-olsen-last-full-year-vs-this-year-sales-summary-card.component.scss']
})
export class OttoOlsenLastFullYearVsThisYearSalesSummaryCardComponent
  extends LastVsThisYearSalesAmountSummaryCardComponent
  implements OnInit
{
  salesAmountThisYear = 0;
  salesAmountLastYear = 0;
  loaded = false;

  @Input()
  preConditions: any;

  constructor(protected dashboardKpiService: DashboardKpiService) {
    super(dashboardKpiService);
  }

  ngOnInit(): void {
    const thisYearSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.Year);
    const lastYearSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.LastYear);

    forkJoin({
      salesSummaryGroupsThisYear: this.dashboardKpiService
        .getSalesSummary(SalesSummaryEntity.PRODUCT, thisYearSpan.fromDate, null, this.preConditions)
        .pipe(first()),
      salesSummaryGroupsLastYear: this.dashboardKpiService
        .getSalesSummary(SalesSummaryEntity.PRODUCT, lastYearSpan.fromDate, lastYearSpan.toDate, this.preConditions)
        .pipe(first())
    }).subscribe(({ salesSummaryGroupsThisYear, salesSummaryGroupsLastYear }) => {
      console.log({ salesSummaryGroupsThisYear, salesSummaryGroupsLastYear });
      this.salesAmountThisYear = salesSummaryGroupsThisYear.reduce((acc, curr) => acc + curr.totalAmount, 0);
      this.salesAmountLastYear = salesSummaryGroupsLastYear.reduce((acc, curr) => acc + curr.totalAmount, 0);
      this.loaded = true;
    });
  }
}
