<div class="row">
  <div *ngIf="existingCustomer; else newCustomerForm" class="col-12">
    <form
      *ngIf="!alertMessage || alertMessage.type !== 'success'"
      class="form mt-2 mb-3"
      [formGroup]="accountFormExistingCustomer"
      (ngSubmit)="onSubmit()"
    >
      <lib-gung-text-input
        gungTranslate="COMPANY_NAME"
        formControlName="companyName"
        placeholder="{{ 'ENTER_COMPANY_NAME' | translate }}"
        ngbAutofocus
      ></lib-gung-text-input>
      <lib-gung-text-input
        gungTranslate="CUSTOMER_ID"
        formControlName="companyId"
        placeholder="{{ 'NOT_REQUIRED' | translate }}"
      ></lib-gung-text-input>
      <div class="row">
        <div class="col-12 col-md-6">
          <lib-gung-text-input
            gungTranslate="CONTACT"
            formControlName="companyContactName"
            placeholder="{{ 'ENTER_CONTACT' | translate }}"
          ></lib-gung-text-input>
          <div formGroupName="extra">
            <lib-gung-text-input
              gungTranslate="CONTACT_TITLE"
              formControlName="companyContactTitle"
              placeholder="{{ 'ENTER_CONTACT_TITLE' | translate }}"
            ></lib-gung-text-input>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <lib-gung-text-input
            gungTranslate="OTTO_EMAIL"
            formControlName="companyContactEmail"
            placeholder="{{ 'OTTO_ENTER_EMAIL' | translate }}"
          ></lib-gung-text-input>
          <div
            *ngIf="
              (accountFormExistingCustomer.get('companyContactEmail').invalid &&
                (accountFormExistingCustomer.get('companyContactEmail').dirty ||
                  accountFormExistingCustomer.get('companyContactEmail').touched)) ||
              accountFormExistingCustomer.get('companyContactEmail').errors?.email
            "
            class="alert alert-danger"
          >
            <span translate>NOT_VALID_EMAIL</span>!
          </div>
          <lib-gung-text-input
            gungTranslate="PHONE"
            formControlName="companyContactPhone"
            placeholder="{{ 'ENTER_PHONE' | translate }}"
          ></lib-gung-text-input>
          <div
            *ngIf="
              (accountFormExistingCustomer.get('companyContactPhone').invalid &&
                (accountFormExistingCustomer.get('companyContactPhone').dirty ||
                  accountFormExistingCustomer.get('companyContactPhone').touched)) ||
              accountFormExistingCustomer.get('companyContactPhone').errors?.maxlength ||
              accountFormExistingCustomer.get('companyContactPhone').errors?.minlength
            "
            class="alert alert-danger"
          >
            <span>{{ 'NOT_VALID_LENGTH' | translate: { length: 8 } }}</span>
          </div>
        </div>
      </div>
      <div class="form-group row" formGroupName="extra">
        <div class="col">
          <label for="companyComments" class="col-form-label" translate>COMMENTS</label>
          <textarea
            class="form-control"
            type="text"
            name="companyComments"
            id="companyComments"
            formControlName="companyComments"
            placeholder="{{ 'ENTER_COMMENTS' | translate }}"
          ></textarea>
        </div>
      </div>
      <div *ngIf="alertMessage" class="alert alert-danger mt-2 text-center" role="alert">
        <span>{{ alertMessage.message | translate }}</span>
      </div>
      <button class="btn btn-primary mt-2 w-100" type="submit" [disabled]="loader ? true : null">
        <span translate>{{ accountForm.valid || loader ? 'REQUEST_ACCOUNT' : 'REQUEST_INVALID_FORM' }}</span>
        <i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
      </button>
    </form>
    <div
      *ngIf="alertMessage && alertMessage.type === 'success'"
      class="alert alert-success mt-2 text-center"
      role="alert"
    >
      <span>{{ alertMessage.message | translate }}</span>
    </div>
    <button
      *ngIf="alertMessage && alertMessage.type === 'success'"
      class="btn btn-primary mt-2 w-100"
      type="button"
      (click)="alertMessage = null"
    >
      <span translate>CREATE_NEW</span>
    </button>
  </div>

  <!-- newCustomerForm -->

  <ng-template #newCustomerForm>
    <div class="col-12">
      <form
        *ngIf="!alertMessage || alertMessage.type !== 'success'"
        class="form mt-2 mb-3"
        [formGroup]="accountForm"
        (ngSubmit)="onSubmit()"
      >
        <lib-gung-text-input
          gungTranslate="COMPANY_NAME"
          formControlName="companyName"
          placeholder="{{ 'ENTER_COMPANY_NAME' | translate }}"
          ngbAutofocus
        ></lib-gung-text-input>
        <div formGroupName="extra">
          <lib-gung-text-input
            gungTranslate="COMPANY_ORG_NAME"
            formControlName="companyOrgName"
            placeholder="{{ 'ENTER_COMPANY_ORG_NAME' | translate }}"
          ></lib-gung-text-input>
        </div>
        <div
          *ngIf="
            (accountForm.get('extra').get('companyOrgName').invalid &&
              (accountForm.get('extra').get('companyOrgName').dirty ||
                accountForm.get('extra').get('companyOrgName').touched)) ||
            accountForm.get('extra').get('companyOrgName').errors?.maxlength ||
            accountForm.get('extra').get('companyOrgName').errors?.minlength
          "
          class="alert alert-danger"
        >
          <span>{{ 'NOT_VALID_LENGTH' | translate: { length: 9 } }}</span>
        </div>
        <!-- <lib-gung-text-input gungTranslate="CUSTOMER_ID" formControlName="companyId"
            placeholder="{{'NOT_REQUIRED' | translate}}"></lib-gung-text-input> -->
        <!-- <div class="form-group row">
            <div class="col">
              <label for="companyAddressId" class="col-form-label" translate>ADDRESS</label>
              <textarea class="form-control" type="text" name="companyAddress" id="companyAddressId"
                formControlName="companyAddress" placeholder="{{'ENTER_ADDRESS' | translate}}"></textarea>
            </div>
          </div> -->
        <div class="row">
          <div class="col-12 col-md-6">
            <lib-gung-text-input
              gungTranslate="ADDRESS2"
              formControlName="companyAddress"
              placeholder="{{ 'ENTER_ADDRESS2' | translate }}"
            ></lib-gung-text-input>
            <div formGroupName="extra">
              <lib-gung-text-input
                gungTranslate="COMPANY_ZIP_CODE"
                formControlName="companyZipCode"
                placeholder="{{ 'ENTER_COMPANY_ZIP_CODE' | translate }}"
              ></lib-gung-text-input>
              <div
                *ngIf="
                  (accountForm.get('extra').get('companyZipCode').invalid &&
                    (accountForm.get('extra').get('companyZipCode').dirty ||
                      accountForm.get('extra').get('companyZipCode').touched)) ||
                  accountForm.get('extra').get('companyZipCode').errors?.maxlength ||
                  accountForm.get('extra').get('companyZipCode').errors?.minlength
                "
                class="alert alert-danger"
              >
                <span>{{ 'NOT_VALID_LENGTH' | translate: { length: 4 } }}</span>
              </div>
              <lib-gung-text-input
                gungTranslate="COMPANY_CITY"
                formControlName="companyCity"
                placeholder="{{ 'ENTER_COMPANY_CITY' | translate }}"
              ></lib-gung-text-input>
            </div>
            <!-- <lib-gung-text-input gungTranslate="VAT_NO" formControlName="companyOrganisationId"
                  placeholder="{{'ENTER_VAT' | translate}}"></lib-gung-text-input> -->
            <lib-gung-text-input
              gungTranslate="OTTO_COUNTRY"
              formControlName="companyCountry"
              placeholder="{{ 'OTTO_ENTER_COUNTRY' | translate }}"
            ></lib-gung-text-input>
          </div>
          <div class="col-12 col-md-6" formGroupName="extra">
            <lib-gung-text-input
              gungTranslate="COMPANY_DELIVERY_ADDRESS"
              formControlName="companyDeliveryAddress"
              placeholder="{{ 'ENTER_COMPANY_DELIVERY_ADDRESS' | translate }}"
            ></lib-gung-text-input>
            <lib-gung-text-input
              gungTranslate="COMPANY_DELIVERY_ZIP_CODE"
              formControlName="companyDeliveryZipCode"
              placeholder="{{ 'ENTER_COMPANY_DELIVERY_ZIP_CODE' | translate }}"
            ></lib-gung-text-input>
            <div
              *ngIf="
                (accountForm.get('extra').get('companyDeliveryZipCode').invalid &&
                  (accountForm.get('extra').get('companyDeliveryZipCode').dirty ||
                    accountForm.get('extra').get('companyDeliveryZipCode').touched)) ||
                accountForm.get('extra').get('companyDeliveryZipCode').errors?.maxlength ||
                accountForm.get('extra').get('companyDeliveryZipCode').errors?.minlength
              "
              class="alert alert-danger"
            >
              <span>{{ 'NOT_VALID_LENGTH' | translate: { length: 4 } }}</span>
            </div>
            <lib-gung-text-input
              gungTranslate="COMPANY_DELIVERY_CITY"
              formControlName="companyDeliveryCity"
              placeholder="{{ 'ENTER_COMPANY_DELIVERY_CITY' | translate }}"
            ></lib-gung-text-input>
            <lib-gung-text-input
              gungTranslate="COMPANY_DELIVERY_COUNTRY"
              formControlName="companyDeliveryCountry"
              placeholder="{{ 'ENTER_COMPANY_DELIVERY_COUNTRY' | translate }}"
            ></lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <lib-gung-text-input
              gungTranslate="OTTO_CONTACT"
              formControlName="companyContactName"
              placeholder="{{ 'ENTER_CONTACT' | translate }}"
            ></lib-gung-text-input>
            <div formGroupName="extra">
              <lib-gung-text-input
                gungTranslate="CONTACT_TITLE"
                formControlName="companyContactTitle"
                placeholder="{{ 'ENTER_CONTACT_TITLE' | translate }}"
              ></lib-gung-text-input>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <lib-gung-text-input
              gungTranslate="OTTO_EMAIL"
              formControlName="companyContactEmail"
              placeholder="{{ 'OTTO_ENTER_EMAIL' | translate }}"
            ></lib-gung-text-input>
            <div
              *ngIf="
                getFormControl('companyContactEmail').invalid &&
                (getFormControl('companyContactEmail').dirty || getFormControl('companyContactEmail').touched) &&
                getFormControl('companyContactEmail').errors?.email
              "
              class="alert alert-danger"
            >
              <span translate>NOT_VALID_EMAIL</span>!
            </div>
            <lib-gung-text-input
              gungTranslate="OTTO_PHONE"
              formControlName="companyContactPhone"
              placeholder="{{ 'ENTER_PHONE' | translate }}"
            ></lib-gung-text-input>
            <div
              *ngIf="
                (accountForm.get('companyContactPhone').invalid &&
                  (accountForm.get('companyContactPhone').dirty || accountForm.get('companyContactPhone').touched)) ||
                accountForm.get('companyContactPhone').errors?.maxlength ||
                accountForm.get('companyContactPhone').errors?.minlength
              "
              class="alert alert-danger"
            >
              <span>{{ 'NOT_VALID_LENGTH' | translate: { length: 8 } }}</span>
            </div>
          </div>
        </div>
        <div class="row" formGroupName="extra">
          <div class="col-12 col-md-6">
            <lib-gung-text-input
              gungTranslate="COMPANY_EMAIL_INVOICE"
              formControlName="companyEmailInvoice"
              placeholder="{{ 'ENTER_COMPANY_EMAIL_INVOICE' | translate }}"
            ></lib-gung-text-input>
            <div
              *ngIf="
                accountForm.get('extra').get('companyEmailInvoice').invalid &&
                (accountForm.get('extra').get('companyEmailInvoice').dirty ||
                  accountForm.get('extra').get('companyEmailInvoice').touched) &&
                accountForm.get('extra').get('companyEmailInvoice').errors?.email
              "
              class="alert alert-danger"
            >
              <span translate>NOT_VALID_EMAIL</span>
            </div>
            <lib-gung-text-input
              gungTranslate="COMPANY_EMAIL_CONFIRMATION"
              formControlName="companyEmailConfirmation"
              placeholder="{{ 'ENTER_COMPANY_EMAIL_CONFIRMATION' | translate }}"
            ></lib-gung-text-input>
            <div
              *ngIf="
                accountForm.get('extra').get('companyEmailConfirmation').invalid &&
                (accountForm.get('extra').get('companyEmailConfirmation').dirty ||
                  accountForm.get('extra').get('companyEmailConfirmation').touched) &&
                accountForm.get('extra').get('companyEmailConfirmation').errors?.email
              "
              class="alert alert-danger"
            >
              <span translate>NOT_VALID_EMAIL</span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <lib-gung-text-input
              gungTranslate="COMPANY_PHONE"
              formControlName="companyPhone"
              placeholder="{{ 'ENTER_COMPANY_PHONE' | translate }}"
            ></lib-gung-text-input>
            <div
              *ngIf="
                (accountForm.get('extra').get('companyPhone').invalid &&
                  (accountForm.get('extra').get('companyPhone').dirty ||
                    accountForm.get('extra').get('companyPhone').touched)) ||
                accountForm.get('extra').get('companyPhone').errors?.maxlength ||
                accountForm.get('extra').get('companyPhone').errors?.minlength
              "
              class="alert alert-danger"
            >
              <span>{{ 'NOT_VALID_LENGTH' | translate: { length: 8 } }}</span>
            </div>
            <lib-gung-text-input
              gungTranslate="WEBSITE"
              formControlName="companyWebSite"
              placeholder="{{ 'ENTER_COMPANY_WEBSITE' | translate }}"
            ></lib-gung-text-input>
          </div>
          <div class="col-12 col-md-6">
            <lib-gung-text-input
              gungTranslate="COMPANY_PREFERED_CURRENCY"
              formControlName="companyPreferedCurrency"
              placeholder="{{ 'ENTER_COMPANY_PREFERED_CURRENCY' | translate }}"
            ></lib-gung-text-input>
          </div>
        </div>
        <div class="form-group row" formGroupName="extra">
          <div class="col">
            <label for="companyComments" class="col-form-label" translate>COMMENTS</label>
            <textarea
              class="form-control"
              type="text"
              name="companyComments"
              id="companyComments"
              formControlName="companyComments"
              placeholder="{{ 'ENTER_COMMENTS' | translate }}"
            ></textarea>
          </div>
        </div>
        <div *ngIf="alertMessage" class="alert alert-danger mt-2 text-center" role="alert">
          <span>{{ alertMessage.message | translate }}</span>
        </div>
        <button class="btn btn-primary mt-2 w-100" type="submit" [disabled]="loader ? true : null">
          <span translate>{{ accountForm.valid || loader ? 'REQUEST_ACCOUNT' : 'REQUEST_INVALID_FORM' }}</span>
          <i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
        </button>
      </form>
      <div
        *ngIf="alertMessage && alertMessage.type === 'success'"
        class="alert alert-success mt-2 text-center"
        role="alert"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
      <button
        *ngIf="alertMessage && alertMessage.type === 'success'"
        class="btn btn-primary mt-2 w-100"
        type="button"
        (click)="alertMessage = null"
      >
        <span translate>CREATE_NEW</span>
      </button>
    </div>
  </ng-template>
</div>
