<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>SALES_PER_YEAR</div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>THIS_YEAR</span>
      </div>
      <span class="current-statistic text-primary">{{ salesAmountThisYear | number: '1.0-0' }}</span>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>LAST_YEAR</span>
      </div>
      <span class="current-statistic text-primary">{{ salesAmountLastYear | number: '1.0-0' }}</span>
    </div>

    <div class="progress-container mt-2">
      <ngb-progressbar [animated]="true" [value]="(salesAmountThisYear / salesAmountLastYear) * 100"> </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title">{{ ((salesAmountThisYear / salesAmountLastYear) * 100 || 0).toFixed() }}%</span>
        <span class="sub-title" translate>COMPARED_LAST_YEAR</span>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
