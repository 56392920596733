import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import {
  SelectedCustomerService,
  CustomerUserService,
  CustomerUserListConfigService
} from 'gung-standard';
import { OttoOlsenCustomerUserCardListComponent } from '../components/otto-olsen-customer-user-card-list/otto-olsen-customer-user-card-list.component';
import { Observable, map } from 'rxjs';
import { differenceInDays } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'gung-standard/lib/state/auth/types';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCustomerUserListConfigService extends CustomerUserListConfigService {
  constructor(
    protected customerUserService: CustomerUserService,
    protected selectedCustomerService: SelectedCustomerService,
    protected translateService: TranslateService
  ) {
    super(
      customerUserService,
      selectedCustomerService
    );
  }

  getLayouts(): ListLayout<any>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenCustomerUserCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'CustomerUsers grid'
      }
    ];
  }

  getItems(): Observable<User[]> {
    return super.getItems().pipe(
      map(users => {
        return users.map(user => {
          if (user.extra?.loginData?.latestSuccessfulLogin) {
            const successDaysAgo = differenceInDays(new Date(), new Date(user.extra.loginData.latestSuccessfulLogin));
            if (!isNaN(successDaysAgo)) {
              user.extra._successLatestLoginDays = successDaysAgo > 0 ? `${successDaysAgo+1} ` + this.translateService.instant('DAYS_AGO') : this.translateService.instant('TODAY');
            }
          }
          if (user.extra?.loginData?.latestFailedLogin) {
            const failedDaysAgo = differenceInDays(new Date(), new Date(user.extra.loginData.latestFailedLogin));
            if (!isNaN(failedDaysAgo)) {
              user.extra._failedLatestLoginDays = failedDaysAgo > 0 ? `${failedDaysAgo+1} ` + this.translateService.instant('DAYS_AGO') : this.translateService.instant('TODAY');
            }
          }
          return user;
        });
      })
    );
  }
}
