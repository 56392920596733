import { Component } from '@angular/core';
import { OttoOlsenOrderNavigationService } from '../../services/otto-olsen-order-navigation.service';

@Component({
  selector: 'otto-olsen-order-navigation-buttons',
  templateUrl: './otto-olsen-order-navigation-buttons.component.html',
  styleUrls: ['./otto-olsen-order-navigation-buttons.component.scss']
})
export class OttoOlsenOrderNavigationButtonsComponent {
  constructor(
    public orderNavigationService: OttoOlsenOrderNavigationService
  ) {}
}
