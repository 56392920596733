import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AssortmentTreeNavigationConfigService, BaseViewConfigService } from 'gung-standard';
import { OttoOlsenMobileProductDescriptionComponent } from '../components/otto-olsen-barcode-scanner/otto-olsen-mobile-product-description/otto-olsen-mobile-product-description.component';
import { OttoOlsenConceptDetailComponent } from '../components/otto-olsen-concept-detail/otto-olsen-concept-detail.component';
import { OttoOlsenProductDetailsComponent } from '../components/otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenProductTreeListComponent } from '../components/otto-olsen-product-tree-list/otto-olsen-product-tree-list.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenAssortmentTreeNavigationConfigService extends AssortmentTreeNavigationConfigService {
  constructor(baseViewConfigService: BaseViewConfigService, protected router: Router) {
    super(baseViewConfigService);
  }

  getProductDetailLayout(): any {
    if(this.router.url.includes('/mobile-product/')){
      return OttoOlsenMobileProductDescriptionComponent;
    }
    return OttoOlsenProductDetailsComponent;
    
  }

  getConceptDetailLayout() {
    return OttoOlsenConceptDetailComponent;
  }

  getProductsLayout() {
    return OttoOlsenProductTreeListComponent;
  }
}
