import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { AssortmentTreeListConfigService, AssortmentService, AuthService, AssortmentRecursive } from 'gung-standard';
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, first, map } from 'rxjs';
import { OttoOlsenAssortmentTreeListViewComponent } from '../components/otto-olsen-assortment-tree-list-view/otto-olsen-assortment-tree-list-view.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenAssortmentTreeListConfigService extends AssortmentTreeListConfigService {
  constructor(protected assortmentService: AssortmentService, protected authService: AuthService) {
    super(assortmentService);
  }

  getLayouts(): ListLayout<AssortmentRecursive>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenAssortmentTreeListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }

  getItems(): Observable<AssortmentRecursive[]> {
    return super.getItems().pipe(
      mergeMap(assorments => forkJoin([of(assorments), this.authService.getRoles().pipe(first())])),
      map(([assortments, roles]) => {
        if (!roles.includes('SALES') && !roles.includes('ADMIN')) {
          // Hide assortment not active in B2B for users
          return assortments.filter(a => {
            return a.extra.pim?.activeInB2B;
          });
        }
        return assortments;
      })
    );
  }

  getSearchGroupCss() {
    return '';
  }
}
