import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PimTemplateProperties } from 'gung-list';
import {
  ProductTreeTableViewComponent,
  PriceService,
  GungFlowService,
  AuthService,
  MetadataService,
  LocationConfigService,
  Product,
  Customer,
  SelectedCustomerService,
  ColumnSortSelectionService,
  GungAnonymousConfigService
} from 'gung-standard';
import { first } from 'rxjs';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'otto-olsen-product-tree-table-view',
  templateUrl: './otto-olsen-product-tree-table-view.component.html',
  styleUrls: ['./otto-olsen-product-tree-table-view.component.scss']
})
export class OttoOlsenProductTreeTableViewComponent extends ProductTreeTableViewComponent implements OnDestroy {
  selectedCustomer: Customer;

  public Number = Number;
  public requestPriceEmail = environment.requestPriceEmail;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected metadataService: MetadataService,
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected columnSortSelectionService: ColumnSortSelectionService,
    private selectedCustomerService: SelectedCustomerService,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(priceService, gungFlowService, authService, metadataService, locationConfigService, router, route, gungAnonymousConfigService);
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.columnSortSelectionService.setSelectedColumnSort(null);
    this.clearSortColumns();
  }

  sortColumn(headerKey: PimTemplateProperties) {
    const ascSort = !headerKey.sort || headerKey.sort === 'asc' ? -1 : 1;
    this.clearSortColumns();
    headerKey.sort = ascSort === 1 ? 'asc' : 'desc';
    this.columnSortSelectionService.setSelectedColumnSort(headerKey);
  }

  clearSortColumns() {
    // clear all other columns sorts
    for (const c of this.dynamicColumns) {
      c.sort = undefined;
    }
  }
}
