import { Component, OnInit } from '@angular/core';
import { BarcodeScannerConfigService, ScannerComponent } from 'gung-standard';
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';

@Component({
  selector: 'otto-olsen-scanner',
  templateUrl: './otto-olsen-scanner.component.html',
  styleUrls: ['./otto-olsen-scanner.component.scss']
})
export class OttoOlsenScannerComponent extends ScannerComponent implements OnInit {

  constructor(protected barcodeScannerConfigService: BarcodeScannerConfigService) {
    super(barcodeScannerConfigService);
  }

  async ngOnInit(): Promise<void> {
    try {
      if (!BarcodeScanner.license) {
        BarcodeScanner.license = this.barcodeScannerConfigService.apiKey;
        BarcodeScanner.engineResourcePath = 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.42/dist/';
      }
      this.scanner = await (this.pScanner = BarcodeScanner.createInstance());
      let settings = await this.scanner.getRuntimeSettings();
      settings.region.regionMeasuredByPercentage = 1;
      settings.region.regionLeft = 25;
      settings.region.regionTop = 40;
      settings.region.regionRight = 75;
      settings.region.regionBottom = 60;
      await this.scanner.updateRuntimeSettings(settings);
      await this.scanner.setUIElement(document.querySelector('.component-barcode-scanner') as any);

      this.scanner.onFrameRead = (results: any) => {
        console.log('--result', results)
        for (const result of results) {
          this.outputCodeScanned.emit(result.barcodeText);
        }
      };
      await this.scanner.open();
      this.scanner.setVideoFit("cover");
    } catch (ex) {
      let errMsg;
      if (ex.message.includes('network connection error')) {
        errMsg =
          'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
    }
  }

  async ngOnDestroy() {
    if (this.pScanner) {
      (await this.pScanner).destroyContext();
    }
  }

  /* async initCamera() {
    console.log('here');
    try {
      if (!BarcodeScanner.license) {
        BarcodeScanner.license =
          'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNTE5OTM1LVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNTE5OTM1IiwiY2hlY2tDb2RlIjo0MDAxMjI0ODl9';
        BarcodeScanner.engineResourcePath = 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.1/dist/';
      }
      const scanner = await (this.pScanner = BarcodeScanner.createInstance());
      const scanSettings = await scanner.getScanSettings();
      scanSettings.whenToPlaySoundforSuccessfulRead = "frame";
      await scanner.updateScanSettings(scanSettings);
      await scanner.setUIElement(document.querySelector('.component-barcode-scanner') as any);
      scanner.onFrameRead = (results: any) => {
        for (const result of results) {
          this.outputCodeScanned.emit(result.barcodeText);
        }
      };

      await scanner.open();
      (document.querySelector('.dce-video-container video') as any).style['object-fit'] = 'cover';
    } catch (ex) {
      let errMsg;
      if (ex.message.includes('network connection error')) {
        errMsg =
          'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
    }
  } */
}
