import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, BackendInterceptor, GungModalService, ManageUsersWrapperComponent, UsersListConfigService } from 'gung-standard';
import { first, filter } from 'rxjs';

@Component({
  selector: 'otto-olsen-manage-users-wrapper',
  templateUrl: './otto-olsen-manage-users-wrapper.component.html',
  styleUrls: ['./otto-olsen-manage-users-wrapper.component.scss']
})
export class OttoOlsenManageUsersWrapperComponent extends ManageUsersWrapperComponent {
  public isAdmin = false;

  constructor(
    public usersListConfigService: UsersListConfigService,
    protected modalService: NgbModal,
    protected router: Router,
    protected backendInterceptor: BackendInterceptor,
    protected gungModalService: GungModalService,
    authService: AuthService
  ) {
    super(
      usersListConfigService,
      modalService,
      router,
      backendInterceptor,
      gungModalService
    );

    authService.getRoles().pipe(first(), filter(roles => !!roles)).subscribe(roles => this.isAdmin = roles.indexOf('ADMIN') > -1)
  }
}
