import { Injectable } from '@angular/core';
import { FastSearchLayout, FastsearchLayoutComponent, SearchResult } from 'gung-list';
import {
  ProductFastSearchListConfigService,
  AuthService,
  ProductService,
  Product,
  ProductFastsearchDetailComponent
} from 'gung-standard';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs';
import { OttoOlsenProductFastsearchCardComponent } from '../components/otto-olsen-product-fastsearch-card/otto-olsen-product-fastsearch-card.component';
import { OttoOlsenProductService } from './otto-olsen-product.service';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductFastSearchListConfigService extends ProductFastSearchListConfigService {
  constructor(protected productService: OttoOlsenProductService, protected authService: AuthService) {
    super(productService, authService);
  }

  getAssortment(): string {
    return 'sGUNG';
  }

  getItems(terms: string[], skip: number, limit: number): Observable<SearchResult<any>> {
    return this.productService.getSearchAssortments(terms[0]).pipe(
      switchMap(result => {
        const assortments = result.assortments.map(a => {
          const _path = a.path.map(p => p.id);
          _path.splice(0, 1, '/articles');
          return { ...a.assortment, _path };
        });
        const concepts = result.concepts.map(c => {
          const _path = c.path.map(p => p.id);
          _path.splice(0, 1, '/articles');
          return { ...c.concept, _path };
        });
        const items = [...assortments, ...concepts];
        const searchResultProducts = {
          items,
          totalItems: items.length,
          skipped: null
        };
        return of(searchResultProducts);
      })
    );
  }

  getLayout(): FastSearchLayout<Product> {
    return {
      getLayout: () => FastsearchLayoutComponent,
      getListItemComponent: () => OttoOlsenProductFastsearchCardComponent,
      getDetailItemComponent: () => ProductFastsearchDetailComponent
    };
  }
}
