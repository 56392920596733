import { Injectable } from '@angular/core';
import { GungModalService, NewsActionConfigService, NewsService } from 'gung-standard';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenNewsActionConfigService extends NewsActionConfigService {
  constructor(protected gungModalService: GungModalService, protected newsService: NewsService) {
    super(gungModalService, newsService);
  }

  getIsEnabledShowUserNewsInPage(): Observable<boolean> {
    // by default this is disable
    // to enable this for a customer
    // override this method in a customer implementation
    return of(false);
  }
}
