import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenBarcodeSearchService {
  constructor(protected http: HttpClient) {}

  public search(barcode: string): Observable<OttoOlsenBarcodeSearchResult> {
    const url = `json/barcode-scanner/${barcode}`;
    return this.http.get<OttoOlsenBarcodeSearchResult>(url);
  }
}

export interface OttoOlsenBarcodeSearchResult {
  barcode: string;
  productId: string;
  data: { [key: string]: any };
}
