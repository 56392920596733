import { Component } from '@angular/core';
import { ConceptDetailComponent, ConceptDetailProductListConfigService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-concept-detail',
  template: '<otto-olsen-filter-list #conceptList [configService]="configService"></otto-olsen-filter-list>',
  styleUrls: ['./otto-olsen-concept-detail.component.scss']
})
export class OttoOlsenConceptDetailComponent extends ConceptDetailComponent {
  constructor(public configService: ConceptDetailProductListConfigService) {
    super(configService);
  }
}
