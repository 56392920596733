<div class="container">
  <div class="row">
    <div class="col-auto mb-2">
      <a class="btn btn-outline-dark ml-2" [routerLink]="['../../manage-users']" role="button">
        <i class="fa-solid fa-arrow-left mr-3"></i> {{ 'BACK_TO_USERS' | translate }}
      </a>
    </div>
    <div class="col-12">
      <lib-gung-tabset [destroyOnHide]="false" class="user-details-tabs" *ngIf="currentUser">
        <lib-gung-tab [title]="'USER' | translate">
          <ng-template libGungTabContent>
            <otto-olsen-manage-user-details-user [currentUser]="currentUser" (validPasswordChanged)="onValidPasswordChanged($event)"></otto-olsen-manage-user-details-user>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'CUSTOMERS' | translate">
          <ng-template libGungTabContent>
            <otto-olsen-manage-user-details-customers [currentUser]="currentUser"></otto-olsen-manage-user-details-customers>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'SUBASSORTMENT' | translate" [disabled]="!isAdmin">
          <ng-template libGungTabContent>
            <lib-user-details-subassortment [currentUser]="currentUser"></lib-user-details-subassortment>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'ORDER_PARAMS' | translate" [disabled]="!isAdmin">
          <ng-template libGungTabContent>
            <lib-user-details-order-params [currentUser]="currentUser"></lib-user-details-order-params>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'SUPPLIERS' | translate" [disabled]="!isAdmin">
          <ng-template libGungTabContent>
            <lib-user-details-managed-supplier [currentUser]="currentUser"></lib-user-details-managed-supplier>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
  <div class="text-center my-2" *ngIf="showError">
    {{ errorMessage }}
  </div>
  <div class="row justify-content-end pt-4">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button type="button" class="btn btn-sm btn-warning" (click)="cancelChanges()">
          {{ 'CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="saveCurrentUser()">
          {{ 'SAVE_CHANGES' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
