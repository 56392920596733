import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  CartService,
  CheckoutStepComponent,
  CustomerProductPrice,
  Price,
  PriceService,
  Product,
  ProductService
} from 'gung-standard';
import { forkJoin, of } from 'rxjs';
import { first, mergeMap } from 'rxjs';
import { OttoOlsenFreightCostService } from '../../../../services/otto-olsen-freight-cost.service';

@Component({
  selector: 'otto-olsen-cutting-fee',
  templateUrl: './otto-olsen-cutting-fee.component.html',
  styleUrls: ['./otto-olsen-cutting-fee.component.scss']
})
export class OttoOlsenCuttingFeeComponent extends CheckoutStepComponent implements OnInit {
  ignoreProducts = [this.freightCostService.freightCostProductId];

  constructor(
    private cartService: CartService,
    protected productService: ProductService,
    protected priceService: PriceService,
    private freightCostService: OttoOlsenFreightCostService,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    forkJoin([this.onNextBtnClicked.pipe(first()), this.cartService.getCurrentCart().pipe(first())])
      .pipe(
        mergeMap(([_, currentCart]) => {
          currentCart = currentCart.filter(c => !this.ignoreProducts.includes(c.productId));
          const ids = currentCart.map(r => r.productId);
          return forkJoin([of(currentCart), this.productService.getProductsByIds(ids).pipe(first())]);
        })
      )
      .subscribe(([currentCart, products]) => {
        const _extraRows: ExtraRow[] = [];
        currentCart.map(row => {
          const product = products.find(p => p.id === row.productId);
          const qty = row.qty;
          let originalSize;
          let packageSize;
          if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
            originalSize = Number(product.extra.ar.artfsgforp);
          }
          if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
            packageSize = Number(product.extra.ar.q_salesbatchsize);
          }

          if (originalSize && !Number.isInteger(row.qty / originalSize) && product?.extra.ar?.q_jis_kaltillegg_artnr) {
            // Add cutting fee
            this.cartService.addToCart(
              product.extra.ar.q_jis_kaltillegg_artnr,
              1,
              row.targetStockId,
              row.productPartialId
            );
            this.cartService.setExtra(
              product.extra.ar.q_jis_kaltillegg_artnr,
              {
                orp: {
                  ordberlevdat: row.extra?.orp?.ordberlevdat || this.datePipe.transform(new Date(), 'yyyy-MM-dd')
                }
              },
              row.targetStockId,
              row.productPartialId
            );
            const idx = _extraRows.findIndex(r => r.id === product.extra.ar.q_jis_kaltillegg_artnr);
            if (idx === -1) {
              _extraRows.push({
                id: product.extra.ar.q_jis_kaltillegg_artnr,
                quantity: 1,
                targetStockId: row.targetStockId,
                product: null,
                price: null
              });
            } else {
              _extraRows[idx].quantity++;
            }
          }
        });
        this.checkout.extra._extraRows = _extraRows;

        // ---------------- USED IF IT WAS ONLY FOR DISPLAY
        // const extraIds = _extraRows.map(r => r.id);
        // forkJoin([
        //   this.productService.getFullProductsByIds(extraIds).pipe(first()),
        //   this.priceService.getCurrentCustomerPrices(extraIds).pipe(first())
        // ]).subscribe(([extraProducts, prices]) => {
        //   console.log("OttoOlsenCuttingFeeComponent - extraProducts", extraProducts);
        //   console.log("OttoOlsenCuttingFeeComponent- prices", prices);
        //   for (const product of _extraRows) {
        //     product.product = extraProducts.find(p => p.id === product.id);
        //     product.price = prices.find(p => p.productId === product.id);
        //   }
        //   this.checkout.extra._extraRows = _extraRows;
        //   console.log("OttoOlsenCuttingFeeComponent- _extraRows", this.checkout.extra._extraRows);
        // });
        this.stepDone.emit(this.checkout);
      });
  }

  public isVisible() {
    return false;
  }
}

interface ExtraRow {
  id: string;
  quantity: number;
  product: Product;
  price: CustomerProductPrice;
  targetStockId?: string;
}
