import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenRoleSwitcherHelperService {
  private roleSubject = new Subject<string>();
  
  setRole(role: string): void {
    localStorage.setItem('InterfaceMode', role);

    this.roleSubject.next(role);
  }

  getRoleChangeObservable() {
    return this.roleSubject.asObservable();
  }
}
