import { Injectable } from '@angular/core';
import { FilterListService, RenderFilter } from 'gung-list';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenFilterListService<T> extends FilterListService<T>{

  protected sortRenderFilterValues(renderFilters: RenderFilter[]): void {
    super.sortRenderFilterValues(renderFilters);
    renderFilters.map(renderFilter => {
      const renderFilterInternal = renderFilter as RenderFilter;

      if (!renderFilter.sort
          && renderFilter.valueList.findIndex(f => !!f.sortIndex) === -1) {

        renderFilterInternal.valueList = renderFilterInternal.valueList.sort((a, b) => {
          
          
          const aValueName = Array.isArray(a.valueName) ? a.valueName.join('') : a.valueName;
          const bValueName = Array.isArray(b.valueName) ? b.valueName.join('') : b.valueName;
          
          // Number
          const aValueNameNumber = aValueName.replace(/\./g, '').replace(',', '.');
          const bValueNameNumber = bValueName.replace(/\./g, '').replace(',', '.');
          if (
            aValueNameNumber.trim() !== '' &&
            bValueNameNumber.trim() !== '' &&
            // !isNaN(Number(aValueName)) &&
            !isNaN(parseFloat(aValueNameNumber)) &&
            // !isNaN(Number(bValueName))
            !isNaN(parseFloat(bValueNameNumber))
          ) {
            return Number(aValueNameNumber) - Number(bValueNameNumber);
          }
          // Other Alphanumeric
          return aValueName.localeCompare(bValueName);
        });
      }
    });
  }
}
