{
  "EXPORT_XLSX": "Export XLSX",
  "EXPORT_STOCKLIST": "Export Stocklist",
  "EXPORT_PRODUCT_INFO": "Export Product Info",
  "EXPORT_IMG": "Export Image",
  "EXPORT_PRICAT": "Export PRICAT",
  "PRODUCT_EXPORT_NAV_BAR": "Product Export",
  "PRODUCT_FAST_SEARCH": "Products fast search",
  "READ_MORE_AND_ORDER": "Read more & Order",
  "TECHINCAL_CHARACTERISTICS_CATALOG": "Technical characteristics / catalog",
  "IN_STOCK_VALUE": "{{value}} PCS",
  "OH_TA_MAILNOTIFIED": "E-Mail delivery information",
  "OH_DELLEVTILLATEN_0": "Yes",
  "OH_DELLEVTILLATEN_10": "No",
  "PART_DELIVERY": "Partial deliveries",
  "PART_DELIVERY_MESSAGE": "The products will be partially delivered, freight will be charged on each delivery.",
  "ASK_PRICE": "Ask for price",
  "NOT_IN_STOCK": "Not in stock",
  "AVAILABILITY_ESTIMATED_CONTACT_US": "Est. delivery date upon order {{date}}. Please contact us if urgent need.",
  "AVAILABILITY_ESTIMATED": "Est. delivery date upon order {{date}}.",
  "AVAILABILITY_CONTACT_US": "Please contact us if urgent need.",
  "STOCK_STATUS": "{{stock}} {{unit}}",
  "STOCK_STATUS_BOLD": "<b>{{stock}}</b> {{unit}}",
  "AVAILABILITY_ESTIMATED_DATE_CONTACT_US": "Normal delivery time when ordering: {{date}}. If you need it soon, contact us.",
  "AVAILABILITY_ESTIMATED_DATE": "{{qty}} {{unit}} available {{date}}. Deviations may occur.",
  "AVAILABILITY_ESTIMATED_DATE_SHORT": "Available {{date}}. Deviations may occur.",
  "AVAILABILITY_DEVIATIONS_MAY_OCCUR": "Deviations may occur.",
  "CONCEPT_RANGE_NAME": "Concept range name",
  "NET_PRICE": "net",
  "OTTO_FREIGHT_COST": "Orders of less than kr 500 will be charged kr 250,- for packaging and packaging.",
  "OH_KUNDBESTNR": "Order number",
  "OH_EDITEXT": "Message to customer center",
  "OH_GODSMARKE3": "Email",
  "OH_GODSMARKE4": "Phone",
  "OTTO_OLSEN_PICK_TWO_HOURS_CONFIRMATION": "The goods can be picked up at the earliest two hours after receiving the order confirmation reading",
  "OTTO_OLSEN_QUOTATION": "Quotation",
  "OTTO_OLSEN_ORDER": "Order",
  "OTTO_OLSEN_DELIVERY_DATE_MAY_CHANGE": "Delivery dates can change with different delivery method",
  "INVOICES": "Invoices",
  "PO_NUMBER": "PO Number",
  "YOUR_REFERENCE": "Your reference",
  "CUSTOMER_DASHBOARD": "Dashboard",
  "SAVE_CART_TO_EXCEL": "Save Cart to Excel",
  "SALES_PER_YEAR": "Sales per year",
  "COMPANY_ORG_NAME": "Org name",
  "ENTER_COMPANY_ORG_NAME": "Enter org name",
  "COMPANY_ZIP_CODE": "Zip code",
  "ENTER_COMPANY_ZIP_CODE": "Enter zip code",
  "COMPANY_CITY": "City",
  "ENTER_COMPANY_CITY": "Enter city",
  "COMPANY_DELIVERY_ADDRESS": "Street address",
  "ENTER_COMPANY_DELIVERY_ADDRESS": "Enter street address",
  "COMPANY_DELIVERY_ZIP_CODE": "Zip code",
  "ENTER_COMPANY_DELIVERY_ZIP_CODE": "Enter zip code",
  "COMPANY_DELIVERY_CITY": "City",
  "ENTER_COMPANY_DELIVERY_CITY": "Enter city",
  "COMPANY_DELIVERY_COUNTRY": "Country",
  "ENTER_COMPANY_DELIVERY_COUNTRY": "Enter country",
  "CONTACT_TITLE": "Title",
  "ENTER_CONTACT_TITLE": "Enter title",
  "COMPANY_EMAIL_INVOICE": "E-mail (invoice)",
  "ENTER_COMPANY_EMAIL_INVOICE": "Enter e-mail (invoice)",
  "COMPANY_EMAIL_CONFIRMATION": "E-mail (confirmation)",
  "ENTER_COMPANY_EMAIL_CONFIRMATION": "Enter e-mail (confirmation)",
  "COMPANY_PHONE": "Phone",
  "ENTER_COMPANY_PHONE": "Enter phone",
  "COMPANY_PREFERED_CURRENCY": "Prefered currency",
  "ENTER_COMPANY_PREFERED_CURRENCY": "Enter prefered currency",
  "COMMENTS": "Comments",
  "ENTER_COMMENTS": "Enter comments",
  "NOT_VALID_LENGTH": "Numeric {{length}} digit with no periods or spaces.",
  "DOWNLOAD_PDF": "Download PDF",
  "CART_ARTICLE_SKU_TOTAL": "{{article}} articles",
  "EXTRA_PRODUCTS": "Own product assortment",
  "CREATE_USER_ASSOCIATE_TO": "Create user associate to",
  "ENTER_COUNTRY": "Enter country",
  "ENTER_CONTACT": "Enter contact",
  "ENTER_PHONE": "Enter phone",
  "OTTO_COUNTRY": "Postadresse (land)",
  "OTTO_ENTER_COUNTRY": "Oppgi postadresse (land)",
  "OTTO_EMAIL": "Epostadresse (kontaktperson)",
  "OTTO_ENTER_EMAIL": "Oppgi epostadresse (kontaktperson)",
  "OTTO_PHONE": "Telefonnummer (kontaktperson)",
  "OTTO_ENTER_PHONE": "Oppgi telefonnummer (kontaktperson)",
  "EXISTING_CUSTOMER_NEED_ACCOUNT": "Existing customer and need an account, click here",
  "NEW_CUSTOMER_NEED_ACCOUNT": "New customer and need an account, click here",
  "CURRENT_CUSTOMER_BLOCKED": "Current customer is blocked to create user. Please contact us for more information.",
  "FILE_NOT_FOUND": "File not found",
  "VIEW": "View only",
  "FEE_ADDED_LOT_SIZE": "Fee to be added (lot size: {{value}})",
  "POST_ADDRESS": "Postal address",
  "DELIVERY_ADDRESS_TAB": "Alternative delivery address",
  "CREATE_ACCOUNT_FOR_NEW_CUSTOMER": "Create new account for existing customer",
  "BARCODE_SCANNER_QUICKORDER": "Barcode Scanner Quickorder",
  "DELIVERY_DATE_CHANGED_DUE_TO_STOCK": "Delivery date changed according to stock available",
  "CONFIRM_DELETE_SHOPPING_CART": "Are you sure you want to delete the following saved shopping cart",
  "ARTNRLEV_ALREADY_EXISTS": "Valgt lev. varenummer finnes allerede",
  "FREE_FREIGHT_ALERT": "{{value}} PCS",
  "FREE_FREIGHT_AGREEMENT": "The order is sent free of charge according to the agreement",
  "FREE_FREIGHT_AFTER": "Shipping: {{name}} has an agreement on free shipping on orders over {{value}}",
  "MISSING_FREE_FREIGHT": ",-. You are missing {{value}}, for free shipping",
  "SCAN_NEW": "Scan new",
  "MENU": "Menu",
  "LOGGED_AS": "Logged as",
  "PRODUCT_NUMBER": "Product Number",
  "PRODUCT_NAME": "Product Name",
  "PRODUCT_FEATURES": "Product Features",
  "CLEAN_CART": "Clean cart",
  "NUMBER_ARTICLES": "Number of articles",
  "CART_CONTENT": "Contents of the shopping cart",
  "SCAN_BARCODE_WITH_CAMERA": "Scan the barcode with the camera",
  "OTTO_OLSEN_ORDER_DETAIS": "Otto Olsen - Order details for order no. {{order}}. The customer's order number: {{customerOrderId}}",
  "CUSTOMER_ORDER_ID": "Customer order id",
  "DEVIVERED": "Delivered",
  "OTTO_OLSEN_ORDER_OVERVIEW": "Otto Olsen - Order overview",
  "MARKUP_BELOW_TWO": "Markup is below 2!",
  "TOOLTIP_NOTE": "Here you can add additional information.",
  "TOOLTIP_COMMENT": "This product information will only be sent via email and will not be available on the product page.",
  "NOTES": "Notes",
  "VIEWER_DESCRIPTION": "Navigate, search and see price",
  "USER_DESCRIPTION": "Order, navigate, search and see prices",
  "NOTES_TO_PRODUCT_RESPONSIBLE": "Kommentar til produktansvarlig",
  "GO_TO_PRODUCTS": "Go to product",
  "FORM_ERROR_NO_SPACE": "The field should not contain a space.",
  "SCANNER_CONFIGS": "Scanner Configs",
  "TIME_SCANNER": "Time Read ms",
  "SCAN_PRODUCTS_IN_CART": "Scan Products Already on cart",
  "PROHIBITED_CHARACTERS_VALIDATOR": "The following characters are prohibited: ‒ — ’ ” ¼ ½ ¾",
  "AR_ARTVIKT": "Unit weight (kg)",
  "3D_MODEL": "3D-model",
  "DOWNLOAD_3D_MODEL": "Download 3D-model",
  "FORMAT": "Format",
  "VIEW_PRODUCT_AS_3D_MODEL": "Show product as a 3D model",
  "VIEW_PRODUCT_IMAGES": "Show product images",
  "3D_MODEL_VIEWER": "3D-model viewer",
  "Y_AXIS_UP_VECTOR": "Set Y axis as up vector",
  "Z_AXIS_UP_VECTOR": "Set Z axis as up vector",
  "FLIP_UP_VECTOR": "Flip up vector",
  "FIXED_UP_VECTOR": "Fixed up vector",
  "FREE_ORBIT": "Free orbit",
  "PERSPECTIVE_CAMERA": "Perspective camera",
  "ORTHOGRAPHIC_CAMERA": "Orthographic camera",
  "DOCUMENT": "Document",
  "BACK_TO_ORDER": "Back to order overview",
  "FORM_WARNING_ENABLE_EDIT_DELIVERY_ADDRESS": "Delivery date may be changed",
  "FORM_DANGER_ENABLE_EDIT_DELIVERY_ADDRESS": "It is not allowed to enter a post office box as a delivery address",
  "ORDER_ANALYTICS_BETA": "Order analytics (Beta)",
  "SALES_DASHBOARD_BETA": "Sales dashboard (Beta)",
  "MEETIGN_AMOUNT": "Meeting Amount",
  "TOTAL_LAST_MONTH": "Total last Month",
  "TOTAL_THIS_MONTH": "Total this Month",
  "COMPLETED_THIS_MONTH": "Completed this month",
  "COMPLETED_LAST_MONTH": "Completed last month",
  "MEETINGS_TARGET": "Progress this month",
  "MEETINGS_LAST_MONTH_TARGET": "Progress last month",
  "CONVERTED_IN_ORDER_THIS_MONTH": "Actual month meetings generated order",
  "CONVERTED_IN_ORDER_LAST_MONTH": "Previous month meetings generated order",
  "SEE_PRICE_DETAIL": "Show price details",
  "HIDE_PRICE_DETAIL": "Hide price details",
  "PACKAGING": "Packaging",
  "MIN.PURCHASE": "Min. Purchase",
  "CUTTING_FEE": "Cutting Fee",
  "CUTTING_FEE_TEXT": "- is added when purchasing quantities other than whole packages",
  "IN_CUTTING_FEE": "in cutting fee",
  "INCL": "Incl.",
  "REC_PRICE": "Rec.price",
  "HIDE_EDIT_PIM_LINKS": "Hide edit PIM links",
  "SAVED_CARTS_BY": "By",
  "SAVED_CARTS_AT": "at",
  "EXIT_SCANNER_MODE": "Exit scanner mode",
  "BARCODE_SCANNER": "Barcode Scanner",
  "DELIVERY_LOCATION_MENU": "Choose customer/dep.",
  "NUMBER_COMMA_VALIDATION": "Invalid characters entered. Only numbers and a comma are allowed.",
  "OVERDUE_INVOICES": "Overdue invoices",
  "OVERDUE": "Overdue",
  "LIMIT_ORDER_ERROR": "Unfortunately, it is not possible to order more than {{stock}} for this product. Contact Otto Olsen if you need more than this number.",
  "ACCESS_FORBIDDEN_ERROR": "You do not have access to view this product.",
  "NO_ACCESS": "No access",
  "VARESPESIFIKASJON": "Product specification",
  "FILTER_IN_STOCK": "Show items in stock",
  "STOCK_FILTER": "Stock",
  "DEFAULT_SCANNER": "Default scanner",
  "NEW_SCANNER": "New scanner",
  "WEBSHOP_OTTO_OLSEN": "Otto Olsen B2B",
  "CALCULATE_PRICE": "Calculate price",
  "PACK_SIZE_IS": "The package size is",
  "YOU_WERE_NOT_LOGGED_IN": "You were not logged in",
  "YOUR_USER_IS_NOT_CONNECTED_TO_A_CUSTOMER": "Your user, {{user}}, is not connected to a customer in our system.",
  "PLEAS_CONTACT_OTTO_OLSEN_AT": "Pleas contact Otto Olsen at post@oo.no or 63 89 0800 for assistance.",
  "ADMIN": "Admin (All permissions in Gung)",
  "SALES": "Selger",
  "DEMO": "Demo (No orders into Jeeves)",
  "KUNDEBRUKER_READ_ONLY": "Skal ikke kunne bestille (Read only)",
  "BACK_TO_USERS": "Back to users",
  "PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION": "Bestillinger gjennom nettbutikken vil bli levert ut fra beholdning i {{Warehouse}}.",
  "PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION_2": "For a bestille denne varen fra annen lokasjon krever at du tar kontakt med Otto Olsen direkte.",
  "CHANGES_MAY_OCCUR": "Endringer kan forekomme",
  "STOCK_STATUS_LABEL": "Lagerstatus"
}