<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>MEETIGN_AMOUNT</div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>TOTAL_THIS_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ totalCurrentMonth.length | number: '1.0-0' }}</span>
    </div>

    <div class="progress-container my-2">
      <ngb-progressbar [animated]="true" [value]="(totalCurrentMonth.length / 80) * 100"> </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title">{{ ((totalCurrentMonth.length / 80) * 100 || 0).toFixed() }}%</span>
        <span class="sub-title" translate>MEETINGS_TARGET</span>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>TOTAL_LAST_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ totalLasMoth.length | number: '1.0-0' }}</span>
    </div>
    
    <div class="progress-container my-2">
      <ngb-progressbar [animated]="true" [value]="(totalLasMoth.length / 80) * 100"> </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title">{{ ((totalLasMoth.length / 80) * 100 || 0).toFixed() }}%</span>
        <span class="sub-title" translate>MEETINGS_LAST_MONTH_TARGET</span>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>COMPLETED_THIS_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ completedCurrentMonth.length | number: '1.0-0' }}</span>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>COMPLETED_LAST_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ completedLastMonth.length | number: '1.0-0' }}</span>
    </div>

  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
