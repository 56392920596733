<div class="pt-1" *ngFor="let user of data">
  <hr />
  <div class="row">
    <div class="col-8 col-md-6">
      <h5 class="m-0 cursor-pointer d-inline" >
        <a class="user-link" [routerLink]="['..', 'users-details', user.username]" >{{ user.name + ' - (' + user.username + ')' }}</a> 
      </h5>
      <div *ngIf="user.extra?._managedCompanies" class="company-links-container">
        <span *ngFor="let managedCompany of user.extra._managedCompanies" class="company-link">
          <a [routerLink]="'/customers/' + managedCompany.id">
            {{ managedCompany.id + ' - ' + managedCompany.name }}
          </a>
          <span class="comma">, </span>
        </span>
      </div>
    </div>
    <div class="col-4 col-md-6">
      <lib-action-btn [item]="user" [config]="userAction" class="float-right"></lib-action-btn>
    </div>
  </div>
  <div class="row" [ngClass]="{'mt-1': user.extra?._managedCompanies}">
    <div class="col-md-6 col-xs-12">
      <div class="row pt-1">
        <div class="col-4">
          {{ 'USERNAME' | translate }}
        </div>
        <div class="col-8">
          {{ user.username }}
        </div>
      </div>
      <div class="row pt-1">
        <div class="col-4">
          {{ 'EMAIL' | translate }}
        </div>
        <div class="col-8">
          <a href="mailto:'{{ user.email }}'">{{ user.email }}</a>
        </div>
      </div>
      <div class="row pt-1" *ngIf="!!user.roles && user.roles.length > 0">
        <div class="col-4">
          {{ 'ROLES' | translate }}
        </div>
        <div class="col-8">
          {{ '["' + user.roles.join('","') + '"]' }}
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xs-12">
      <div class="row pt-1">
        <div class="col-4">
          {{ 'ASSORTMENT' | translate }}
        </div>
        <div class="col-8">
          {{ user.assortment }}
        </div>
      </div>
      <div class="row pt-1" *ngIf="!!user.managedMultistockIds && user.managedMultistockIds.length > 0">
        <div class="col-4">
          {{ 'STOCKS' | translate }}
        </div>
        <div class="col-8">
          {{ '["' + user.managedMultistockIds.join('","') + '"]' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(user?.extra?.createdAt && user?.extra?.createdBy) || user?.extra?.loginData" class="row mt-4 ml-0 mr-0 login-information">
    <div *ngIf="user?.extra?.loginData" class="col-md-6 col-xs-12 mb-3 pl-0 pr-0 d-flex flex-column">
      <span class="font-weight-bold" translate>SUCCESSFUL_LOGIN_ATEMPTS</span>
      <span><b>{{ user.extra.loginData.loginSucessAttempts }}</b>&nbsp;&nbsp;<span *ngIf="user.extra._successLatestLoginDays">(<span translate>LAST_LOGIN</span>: {{ user.extra._successLatestLoginDays }} - {{ user.extra.loginData.latestSuccessfulLogin | date: 'd.M.yyyy HH:mm' }})</span></span>
    </div>
    <div *ngIf="user?.extra?.loginData" class="col-md-6 col-xs-12 mb-3 pl-0 pr-0 d-flex flex-column">
      <span class="font-weight-bold" translate>FAILED_LOGIN_ATEMPTS</span>
      <span><b>{{ user.extra.loginData.loginFailAttempts }}</b>&nbsp;&nbsp;<span *ngIf="user.extra._failedLatestLoginDays">(<span translate>LAST_LOGIN</span>: {{ user.extra._failedLatestLoginDays }} - {{ user.extra.loginData.latestFailedLogin | date: 'd.M.yyyy HH:mm' }})</span></span>
    </div>
    <div *ngIf="user?.extra?.createdAt && user?.extra?.createdBy" class="col-12 mb-2 pl-0 pr-0 d-flex flex-column">
      <span><b translate>USER_WAS_CREATED_AT_BY</b> {{ user.extra.createdAt | date: 'd.M.yyyy HH:mm' }}, <span translate>BY</span> {{ user.extra.createdBy }}</span>
    </div>
  </div>
</div>
