import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { OptionsListMulti } from 'gung-common';
import { AuthService, BackendFeatureService, PasswordPolicyService, UserDetailsUserComponent, UserGroup, UsersService } from 'gung-standard';
import { first, filter, of, switchMap } from 'rxjs';

@Component({
  selector: 'otto-olsen-manage-user-details-user',
  templateUrl: './otto-olsen-manage-user-details-user.component.html',
  styleUrls: ['./otto-olsen-manage-user-details-user.component.scss']
})
export class OttoOlsenManageUserDetailsUserComponent extends UserDetailsUserComponent {
  public isAdmin = false;
  public warehouses: OptionsListMulti[] = [
    {
      id: '30',
      name: 'Hovedlager',
      selected: false
    },
    {
      id: '17',
      name: 'Kristiansand',
      selected: false
    },
    {
      id: '15',
      name: 'Alesund',
      selected: false
    }
  ];
  public userGroupsScannerBoks: UserGroup[] = [
    {"key":"15-SCANNER-2BOKS","selected":false},
    {"key":"17-SCANNER-2BOKS","selected":false},
    {"key":"30-SCANNER-2BOKS","selected":false},
  ];
  public userGroupsScannerKunde: UserGroup[] = [
    {"key":"15-SCANNER-KUNDE","selected":false},
    {"key":"17-SCANNER-KUNDE","selected":false},
    {"key":"30-SCANNER-KUNDE","selected":false}
  ];
  public userGroupsWithoutScanner: UserGroup[] = [];

  constructor(
    protected usersService: UsersService,
    protected http: HttpClient,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService,
    authService: AuthService
  ) {
    super(
      usersService,
      http,
      backendFeatureService,
      passwordPolicyService
    );
    authService.getRoles().pipe(first(), filter(roles => !!roles)).subscribe(roles => this.isAdmin = roles.indexOf('ADMIN') > -1)
  }

  ngOnInit() {
    const featureId = 'passwordPolicies';

    this.backendFeatureService
      .isActivated(featureId)
      .pipe(
        first(),
        switchMap(isActivated => {
          if (isActivated) {
            return this.passwordPolicyService.getPasswordRequirements();
          } else {
            return of({});
          }
        })
      )
      .subscribe(requirements => {
        this.passwordRequirements = requirements;
      });

    this.usersService.getDefaultUserRoles().subscribe(roles => {
      this.userRoles = roles;
      for (const role of this.currentUser.roles) {
        const roleDefinedFromService = this.userRoles.find(x => x.id === role);
        if (roleDefinedFromService !== undefined) {
          roleDefinedFromService.selected = true;
        } else {
          // User has a role on it which does not exist in usersService, add it here to allow removal of the role
          this.userRoles.push({ selected: true, id: role });
        }
      }
      this.userRoles = this.userRoles.sort((a, b) => {
        if (a.id === 'USER' && b.id !== 'VIEW') {
          return 1;
        }
        if (b.id === 'USER' && a.id !== 'VIEW') {
          return -1;
        }
        if (a.id === 'VIEW') {
          return 1;
        }
        if (b.id === 'VIEW') {
          return -1;
        }
        return 0;
      })
    });

    this.http.get<any>('json/system/GUNG').subscribe(systemSettings => {
      const settingsGroups = systemSettings.extra.userGroups || [];
      const tmp: UserGroup[] = [];
      for (const key of Object.keys(this.currentUser[this.activeGroupsKey])) {
        tmp.push({
          key,
          selected: this.currentUser[this.activeGroupsKey][key]
        });
      }

      (settingsGroups as string[]).forEach(settingsGroup => {
        const userGroup = tmp.find(group => group.key === settingsGroup);
        if (!userGroup) {
          tmp.push({
            key: settingsGroup,
            selected: false
          });
        }
      });
      this.userGroups = tmp.sort((a,b) => a.key.localeCompare(b.key));
      this.userGroupsScannerBoks = this.userGroups.filter(g => g.key.indexOf('-SCANNER-2BOKS') > -1);
      this.userGroupsScannerKunde = this.userGroups.filter(g => g.key.indexOf('-SCANNER-KUNDE') > -1);
      this.userGroupsWithoutScanner = this.userGroups.filter(g => g.key.indexOf('-SCANNER-') === -1)
    });

    this.multiStocksIds = this.currentUser.managedMultistockIds;

    this.currentUser.extra?.displayStockIds?.forEach(id => {
      const warehouse = this.warehouses.find(w => w.id === id);
      if (warehouse) {
        warehouse.selected = true 
      }
    });
  }

  setSelectedWarehouses(event: string[]): void {
    this.currentUser.extra.displayStockIds = event;
  }

  toogleRole(id: string, selected: boolean): void {
    if (id === 'VIEW' && !selected) {
      this.userRoles.find(role => role.id === 'USER').selected = true;
      const indexOf = this.currentUser.roles.indexOf('USER');
      if (indexOf === -1) {
        this.currentUser.roles.push('USER');
      }
    } else if (id === 'USER' && selected) {
      this.userRoles.find(role => role.id === 'VIEW').selected = false;
      const indexOf = this.currentUser.roles.indexOf('VIEW');
      if (indexOf > -1) {
        this.currentUser.roles.splice(indexOf, 1);
      }
    } 
    this.userRoles.find(role => role.id === id).selected = !selected;
    // if was selected then remove it roles collection
    if (selected) {
      const indexOf = this.currentUser.roles.indexOf(id);
      this.currentUser.roles.splice(indexOf, 1);
    } else {
      this.currentUser.roles.push(id);
    }
  }

  update(): void {
    super.update();
    if (!this.currentUser.extra.displayStockIds) {
      this.currentUser.extra.displayStockIds = [];
    }
    this.currentUser.managedMultistockIds.forEach(stockId => {
      const idx = this.warehouses.findIndex(w => w.id === stockId);
      if (this.currentUser.extra.displayStockIds.indexOf(stockId) === -1 && idx !== -1) {
        this.warehouses[idx].selected = true;
        this.currentUser.extra.displayStockIds.push(stockId);
      }
    });
    this.warehouses = structuredClone(this.warehouses);
  }

}
