import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ProductExportListComponent,
  ProductExportListConfigService,
  ProductSelectionService,
  Product,
  CurrentAssortmentService,
  AssortmentService
} from 'gung-standard';
import { ActivatedRoute } from '@angular/router';
import { OttoOlsenModalService } from '../../services/otto-olsen-modal.service';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';

@Component({
  selector: 'otto-olsen-product-export-list',
  template:
    '<lib-filter-list #productExportList [configService]="productListConfigService" [topFilter]="true"></lib-filter-list>',
  styleUrls: ['./otto-olsen-product-export-list.component.scss'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class OttoOlsenProductExportListComponent extends ProductExportListComponent implements OnInit {
  constructor(
    public productListConfigService: ProductExportListConfigService,
    public route: ActivatedRoute,
    protected modalService: OttoOlsenModalService,
    protected currentAssortmentService: CurrentAssortmentService,
    protected assortmentService: AssortmentService
  ) {
    super(productListConfigService, route, currentAssortmentService, assortmentService);
  }
  @ViewChild('productExportList') productExportList: FilterListComponent<Product>;

  ngOnInit() {
    super.ngOnInit();
    this.route.paramMap.subscribe(params => {
      // Product Details
      const productId = params.get('productId');

      if (productId != null) {
        this.modalService.openProductDetailsModal(productId);
      }

      if (this.productExportList) {
        this.productExportList.renderNewLayout();
        this.productExportList.renderNewFilters();
      }
    });
  }
}
