import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, GungBase } from 'gung-standard';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs';

export interface OttoOlsenProductAssortmentParentPathTreeMap {
  [s: string]: OttoOlsenProductAssortmentParentPath[];
}

interface OttoOlsenProductAssortmentParentPath extends GungBase {
  sortIndex: number;
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductAssortmentParentService {
  constructor(protected http: HttpClient, protected authService: AuthService) {}

  protected productAssortmentParent: BehaviorSubject<OttoOlsenProductAssortmentParentPathTreeMap>;

  public getProductAssortmentParent(
    assortmentId: string,
    noCache?: boolean
  ): Observable<OttoOlsenProductAssortmentParentPathTreeMap> {
    if (this.productAssortmentParent && !noCache) {
      return this.productAssortmentParent;
    }
    this.productAssortmentParent = new BehaviorSubject(null);

    const url = `json/otto-product-assortment-parent/${assortmentId}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '7200');
    return this.http
      .get<OttoOlsenProductAssortmentParentPathTreeMap>(url, { headers })
      .pipe(tap(data => this.productAssortmentParent.next(data)));
  }

  public getProductAssortmentParentPathByCustomerAssortment(
    noCache?: boolean
  ): Observable<OttoOlsenProductAssortmentParentPathTreeMap> {
    if (this.productAssortmentParent && !noCache) {
      return this.productAssortmentParent;
    } else {
      return this.authService.getCurrentUser().pipe(
        filter(user => !!user),
        first(),
        mergeMap(user => {
          return this.getProductAssortmentParent(user.assortment).pipe(first());
        })
      );
    }
  }

  public getProductAssortmentParentPathById(productId: string, noCache?: boolean): Observable<string[]> {
    let request;
    if (this.productAssortmentParent && !noCache) {
      request = of(this.productAssortmentParent);
    } else {
      request = this.authService.getCurrentUser().pipe(
        filter(user => !!user),
        first(),
        mergeMap(user => {
          return this.getProductAssortmentParent(user.assortment).pipe(first());
        })
      );
    }

    return request.pipe(
      map(productAssortmentParent => {
        if (productAssortmentParent) {
          const currentProductAssortmentParent = productAssortmentParent[productId];
          if (currentProductAssortmentParent) {
            return currentProductAssortmentParent.map(path => path.id);
          }
        }
      })
    );
  }

  public getProductAssortmentParentByProductIds(productIds: string, assortmentId: string) {
    const url = `json/otto-product-assortment-parent-by-product-ids/${assortmentId}?productIds=${productIds}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '7200');
    return this.http.get<OttoOlsenProductAssortmentParentPathTreeMap>(url, { headers });
  }

  public postProductAssortmentParentByProductIds(productIds: string[], assortmentId?: string) {
    if (assortmentId) {
      const url = `json/otto-product-assortment-parent-by-product-ids/${assortmentId}`;
      return this.http.post<OttoOlsenProductAssortmentParentPathTreeMap>(url, productIds);
    }
    return this.authService.getCurrentUser().pipe(
      filter(user => !!user),
      first(),
      mergeMap(user => {
        const url = `json/otto-product-assortment-parent-by-product-ids/${user.assortment}`;
        return this.http.post<OttoOlsenProductAssortmentParentPathTreeMap>(url, productIds);
      })
    );
  }
}
