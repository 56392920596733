import { Component } from '@angular/core';
import { FavouriteProductsService, FavouriteProductIconComponent, AuthService } from 'gung-standard';
import { filter, first } from 'rxjs';

@Component({
  selector: 'otto-olsen-favourite-product-icon',
  templateUrl: './otto-olsen-favourite-product-icon.component.html',
  styleUrls: ['./otto-olsen-favourite-product-icon.component.scss']
})
export class OttoOlsenFavouriteProductIconComponent extends FavouriteProductIconComponent {
  isAnonymous = true;

  constructor(protected favouritesService: FavouriteProductsService, protected authService: AuthService) {
    super(favouritesService);
    this.authService
      .getRoles()
      .pipe(
        filter(roles => !!roles),
        first()
      )
      .subscribe(roles => {
        if (roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }
      });
  }
}
