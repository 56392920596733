import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'otto-olsen-scanner-options-modal',
  templateUrl: './otto-olsen-scanner-options-modal.component.html',
  styleUrls: ['./otto-olsen-scanner-options-modal.component.scss']
})
export class OttoOlsenScannerOptionsModalComponent implements OnInit {

  timeReadAgain: Number;
  addSameProduct: boolean;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  save(){
    this.activeModal.close({timeReadAgain: this.timeReadAgain, addSameProduct: this.addSameProduct});
  }
}
