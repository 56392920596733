import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { compareAsc } from 'date-fns';
import { DeliveryDatePickerComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-delivery-date-picker',
  templateUrl: './otto-olsen-delivery-date-picker.component.html',
  styleUrls: ['./otto-olsen-delivery-date-picker.component.scss']
})
export class OttoOlsenDeliveryDatePickerComponent extends DeliveryDatePickerComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (!changes.minInitialDate.firstChange) {
      const minInitialDate = this.minInitialDate as Date;
      let dateModel: NgbDate = new NgbDate(
        minInitialDate.getFullYear(),
        minInitialDate.getMonth() + 1,
        minInitialDate.getDate()
      );
      this.firstDatePickerDate = dateModel;
      this.ngModel = dateModel;
      this.dateSelected(dateModel);
    }
  }
}
