<div *ngIf="mappedData; else loading" class="row pb-2 d-none d-md-flex cart-rows">
  <div class="col-4 col-lg-1"></div>
  <div class="d-none d-xl-block col-12 col-md-6 col-lg-4 col-xl-2"></div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b *ngIf="!isUser" translate>QUANTITY</b>
    <b *ngIf="isUser" translate>REQUESTED_QUANTITY</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
    <b translate>AVAILABILITY</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b *ngIf="!isUser" translate>PRICE</b>
    <b *ngIf="isUser" translate>UNIT_PRICE</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1" *ngIf="!isUser">
    <b translate>DISCOUNT_PERCENT</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
    <b *ngIf="!isUser" translate>TOTAL</b>
    <b *ngIf="isUser" translate>TOTAL</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b translate>DELIVERY</b>
  </div>
  <div class="col-1"></div>
</div>
<span *ngFor="let row of mappedData; trackBy: trackByFn">
  <div class="row pb-2 align-items-center">
    <div class="col-12" style="font-size: 1rem">
      <div
        [routerLink]="[
          row.product.extra.assortmentParentPath ? '/articles/' + row.product.extra.assortmentParentPath : '/product/',
          row.product.id
        ]"
      >
        <b>{{ row.product.id }}</b> - {{ row.product.name }}
      </div>
      <div class="d-block">
        <b
          *ngIf="
            selectedCustomer &&
            row.product.extra._customerCrossReferences &&
            row.product.extra._customerCrossReferences[selectedCustomer.id]
          "
          class="small"
        >
          {{ row.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
        </b>
        <a class="ml-2" (click)="openCrossReferenceEditor(row)">
          <i class="fas fa-edit"></i>
        </a>
      </div>
      {{
        row.product.description.length > 40 ? (row.product.description | slice: 0:40) + '..' : row.product.description
      }}
    </div>
    <div
      class="col-3 col-lg-1 d-flex align-items-center navigate-to-product-details"
      [routerLink]="[
        row.product.extra.assortmentParentPath ? '/articles/' + row.product.extra.assortmentParentPath : '/product/',
        row.product.id
      ]"
    >
      <img
        class="img-fluid"
        src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/64x64/filters:fill(white):background_color(white):format(jpeg)/{{
          row.product.extra.images[0].s3Uri
        }}"
        alt=""
      />
    </div>

    <div class="col-8 col-lg-4 col-xl-2 text-truncate navigate-to-product-details px-0">
      <div class="pb-sm-4 pb-lg-0">
        <button class="btn btn-primary btn-sm" (click)="editNote(row.cartRow)">
          <span translate style="font-size: 1rem">NOTES</span>
        </button>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0 col-request-quantity">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>QUANTITY</b>
        </div>
        <div class="col-8 col-xl-12">
          <div class="row">
            <div class="col pr-0">
              <div
                *ngIf="
                  isAnonymous ||
                    (!(
                      row.product.extra.pim &&
                      row.product.extra.pim.pricerequest &&
                      row.product.extra.pim.pricerequest !== 'false'
                    ) &&
                      !(row.product.extra.ar.itemtypecd1 === 0 || row.product.extra.ar.itemtypecd1 === 7));
                  else priceRequest
                "
              >
                <otto-olsen-buy-btn
                  name="buy-btn-package-size"
                  [id]="row.product.id"
                  [checkoutCart]="true"
                  [minimumOrderQuantity]="row.product?.firstStepAmount || row.product?.packageSize"
                  [stepAmount]="row.product?.packageSize"
                  [decimals]="row.product?.extra.ar.antdec"
                  [activeInB2B]="row?.product?.extra?.pim?.activeInB2B"
                >
                </otto-olsen-buy-btn>
              </div>
              <ng-template #priceRequest>
                <a
                  href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ row.product.id }}"
                  target="_blank"
                  class="request-price-email"
                >
                  <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
                </a>
              </ng-template>
            </div>
            <div class="col-auto pl-1 d-flex align-items-center">
              {{ row.product.extra.ar?.enhetskod }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-3 col-xl-1 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>AVAILABILITY</b>
        </div>
        <div class="col-8 col-xl-12 text-center px-0">
          <otto-olsen-availability
            [availability]="row.cartRow?.extra?._availability_reference"
            [unit]="row.product?.extra.ar?.enhetskod"
            [decimals]="row.product?.extra.ar.antdec"
            [productionDays]="row.product?.extra.ar?.anskaffningssatt && (row.product?.extra.ar?.anskaffningssatt * 1) === 12 && row.product?.extra.ar?.artgenomloppstid"
          ></otto-olsen-availability>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0">
      <div class="row pt-1" *ngIf="!isUser">
        <div class="col-4 d-flex d-xl-none">
          <b translate>PRICE</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <input
            type="number"
            class="form-control text-right px-1"
            [value]="parseFloatFixed(row.overridePrice, 2)"
            [placeholder]="row.originalPrice | number: '1.2-2'"
            (blur)="updateOverridePrice(row, parseFloatFixed($event.target.value, 2))"
          />
        </div>
      </div>
      <div class="row pt-1" *ngIf="isUser">
        <div class="col-4 d-flex d-lg-none">
          <b translate>UNIT_PRICE</b>
        </div>
        <div class="col-8 col-lg-12 d-flex">
          <b>
            <lib-price [price]="row.price.cartRowUnitPrice.value"></lib-price>
            <span *ngIf="row.price.cartRowDiscountPercent !== 0"
              >(-{{ parseFloatFixed(row.price.cartRowDiscountPercent, 2) }}%)</span
            >
          </b>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-1 offset-sm-3 offset-lg-1 offset-xl-0" *ngIf="!isUser">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>DISCOUNT_PERCENT</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <input
            type="number"
            class="form-control discount-control px-1"
            [value]="parseFloatFixed(row.discountPercent || row.cartRowPriceElement.cartRowDiscountPercent || 0, 2)"
            (blur)="updateDiscountPercentage(row, $event.target.value, true)"
            placeholder="%"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-1 offset-sm-3 offset-lg-1 offset-xl-0">
      <div class="row pt-1" *ngIf="!isUser">
        <div class="col-4 d-flex d-xl-none">
          <b translate>TOTAL</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <lib-price [price]="row.price.cartRowTotalPriceInclRowDiscount.value"></lib-price>
        </div>
      </div>
      <div class="row pt-1" *ngIf="isUser">
        <div class="col-4 d-flex d-xl-none">
          <b translate>TOTAL_PRICE</b>
        </div>
        <div class="col-8 col-md-6 d-flex">
          <lib-price [price]="row.price.cartRowTotalPriceInclRowDiscount.value"></lib-price>
          <!-- {{ row.price.cartRowTotalPriceInclRowDiscount.value | gungCurrency:
          row.price.cartRowTotalPriceInclRowDiscount.currencyCode}} -->
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-3 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-12 col-lg-12 col-xl-6">
          <div class="row pt-1">
            <div class="col-4 d-flex d-xl-none">
              <b translate>DELIVERY</b>
            </div>
            <div class="col-8 col-xl-12 d-flex" *ngIf="!partDeliveriesForbidden">
              <!-- <div class="input-group">
                <input class="form-control input-date" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker"
                  readonly container="body" [value]="row.deliveryDate ? row.deliveryDate : formatNgbDate(row.minDate)"
                  [minDate]="row.minDate" (dateSelect)="updateDeliveryDate(row, $event)" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                    <i class="fal fa-calendar-alt"></i>
                  </button>
                </div>
              </div> -->
              <lib-delivery-date-picker
                *ngIf="row.minDate"
                (selectedDate)="updateDeliveryDate(row, $event)"
                [initialDate]="row.deliveryDate ? row.deliveryDate : formatNgbDate(row.minDate)"
                [deliveryMethod]="selectedDeliveryMethod"
                [minInitialDate]="row.minDate"
                [label]="null"
              ></lib-delivery-date-picker>
            </div>
            <div class="col-8 col-xl-12 d-flex" *ngIf="partDeliveriesForbidden">
              <lib-delivery-date-picker
                *ngIf="selectedDeliveryDate"
                [initialDate]="selectedDeliveryDate"
                [currentselectedDate]="selectedDeliveryDate"
                [label]="null"
                [disabled]="true"
              ></lib-delivery-date-picker>
            </div>
          </div>
        </div>

        <div
          class="d-none d-xl-block col-2 col-md-1 order-first order-xl-0 offset-11 offset-xl-0 d-xl-flex align-items-center col-delete-button"
        >
          <ng-container *ngTemplateOutlet="rowBtns; context: { row }"></ng-container>
        </div>
      </div>
    </div>

    <div
      class="d-xl-none col-2 col-md-1 order-first order-xl-0 offset-11 offset-xl-0 d-flex align-items-center col-delete-button"
    >
      <ng-container *ngTemplateOutlet="rowBtns; context: { row }"></ng-container>
    </div>
    <div class="col-12">
      <div class="d-block pt-2">
        <div
          *ngIf="row.cartRow.extra.warning && !warningWasActiveBefore[row.productId]"
          class="d-flex alert alert-danger p-1"
        >
          <span translate>DELIVERY_DATE_CHANGED_DUE_TO_STOCK</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row hr"></div>
</span>
<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>

<ng-template #rowBtns let-row="row">
  <button
    *ngIf="enableDuplicateRow"
    type="button"
    class="btn btn-secondary btn-sm mr-1"
    (click)="duplicateProduct(row, index)"
  >
    <i class="fas fa-copy fa-2x"></i>
  </button>
  <button class="btn btn-light delete-button" (click)="removeRow(row)">
    <i class="fas fa-trash-alt fa-2x"></i>
  </button>
  <button *ngIf="favouriteIcon" class="btn btn-light delete-button ml-2" (click)="favouriteIcon.toggle()">
    <i class="fa-star fa-2x" [ngClass]="favouriteIcon.isFavourite ? 'fas' : 'far'"></i>
  </button>
  <lib-favourite-product-icon #favouriteIcon [id]="row.product.id" class="d-none"></lib-favourite-product-icon>
</ng-template>
