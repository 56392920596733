<div *ngIf="loading && (!filteredItems || filteredItems.length <= 0)" class="progress mt-10">
  <div
    class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
    role="progressbar"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
    style="width: 75%"
  ></div>
</div>
<div class="filter-list">
  <div class="filter-list-wrapper">
    <ng-container *ngTemplateOutlet="topFilter ? upFilter : sideFilter"></ng-container>
    <div class="row" *ngIf="selectionActions">
      <lib-selection-bar
        [selectionActions]="selectionActions"
        [filteredItems]="filteredItems"
        [allItems]="items"
        [actionButtonTitle]="selectionActionsButtonTitle"
        (barHeight)="adjustBottomMargin($event)"
      ></lib-selection-bar>
    </div>
  </div>
</div>

<div (click)="toggleFilters()" [ngClass]="shouldDisplayOverlay ? 'overlay-visible' : 'overlay-hidden'"></div>

<ng-template #sideFilter>
  <div
    class="row filter-list-background"
    [hidden]="loading && (!filteredItems || filteredItems.length <= 0)"
    [ngStyle]="containerStyles"
  >
    <div
      #gungFilters
      [ngClass]="getGungFilterClasses()"
      [class]="getFilterGroupCss()"
      class="col-12 sticky gung-filters d-flex flex-column"
    >
      <div class="gung-filters-inner d-flex flex-column p-0">
        <div class="filter-header px-4 py-3" [hidden]="!renderFilters || renderFilters.length == 0">
          <i class="fas fa-sliders-h pr-2"></i>
          <span class="font-weight-bold" translate>FILTERS</span>
        </div>

        <otto-olsen-filter-list-side-filter
          *ngFor="let renderFilter of renderFilters"
          [renderFilter]="renderFilter"
          (selectValue)="setSelection(renderFilter.name, $event, true)"
          (deselectValue)="setSelection(renderFilter.name, $event, false)"
          (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"
        ></otto-olsen-filter-list-side-filter>
        <lib-selected-filter
          [renderFilters]="renderFiltersForSelected"
          (deselectAll)="clearSelection()"
          (deselectValue)="setSelection($event.filterName, $event.valueId, false)"
          (deselectValueRange)="setSelection($event.filterName, null, false, $event.valueIdRange)"
        >
        </lib-selected-filter>
      </div>
    </div>
    <div
      [ngClass]="{
        'col-12 col-lg-9': renderFilters?.length > 0,
        'col-12': !(renderFilters?.length > 0)
      }"
    >
      <div class="product-list-search-group pt-2">
        <div class="row" [ngClass]="getSearchGroupCss()">
          <div class="mb-2 col-md col-12">
            <div class="row">
              <ng-template name="searchFields" libCustomHost></ng-template>
            </div>
          </div>
          <div class="col-12">
            <div class="m-0 mt-2 text-results-top-filters">
              <span
                style="padding-top: 1px"
                class="mt-1 mr-3"
                *ngIf="renderItems && items && filteredItems && filteredItems.length === items.length"
              >
                {{ 'PRODUCT_LIST_TOTAL' | translate: { render: (renderItems | async).length, total: items.length } }}
              </span>
              <span
                style="padding-top: 1px"
                class="mt-1 mr-3"
                *ngIf="renderItems && items && filteredItems && filteredItems.length !== items.length"
              >
                {{
                  'PRODUCT_LIST_FILTER_TOTAL'
                    | translate
                      : { render: (renderItems | async).length, filter: filteredItems.length, total: items.length }
                }}
              </span>
              <lib-selected-filter
                [topFilter]="true"
                [renderFilters]="renderFiltersForSelected"
                (deselectAll)="clearSelection()"
                (deselectValue)="setSelection($event.filterName, $event.valueId, false)"
                (deselectValueRange)="setSelection($event.filterName, null, false, $event.valueIdRange)"
              >
              </lib-selected-filter>
            </div>
          </div>
          <div class="col col-md-3" *ngIf="batchSizes && batchSizes.length > 1">
            <lib-filter-list-batch (batchSizeUpdated)="setBatchSize($event)" [batchSizes]="batchSizes">
            </lib-filter-list-batch>
          </div>
          <div class="col-auto" *ngIf="layouts && layouts.length > 1">
            <button
              class="btn"
              *ngFor="let layout of layouts; let layoutIndex = index"
              (click)="changeLayout(layoutIndex)"
            >
              <i [ngClass]="layout.getIconClass()"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12" *ngIf="flowHtml">
            <div class="flow-html" [innerHTML]="flowHtml | safeHtml"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ng-template libCustomHost></ng-template>
            <p *ngIf="filteredItems && filteredItems.length === 0" class="nothing-found">
              <span [innerHTML]="getNothingFoundTranslateTag() | translate"></span>
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <button
            [ngClass]="getLoadMoreCss()"
            *ngIf="!!filteredItems && filteredItems.length > limit"
            class="btn"
            (click)="loadMore()"
            style="overflow-anchor: none"
          >
            <span translate [translateParams]="{ limit: limit, total: filteredItems.length, batchSize: batchSize }"
              >LOAD_MORE</span
            >
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="filteredItems && filteredItems.length > 0 && renderFilters && renderFilters.length > 0">
      <a
        [ngClass]="{ 'hide-filter-toggle': getGungFilterClasses().open }"
        class="gung-filters-toggle"
        (click)="toggleFilters()"
      >
        <i class="fa fa-filter"></i>
      </a>

      <a
        [ngClass]="{ 'hide-filter-toggle': !getGungFilterClasses().open }"
        class="gung-filters-toggle close-filters"
        (click)="toggleFilters()"
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</ng-template>
<!-- upFilter -->
<ng-template #upFilter>
  <div
    class="row filter-list-background"
    [hidden]="loading && (!filteredItems || filteredItems.length <= 0)"
    [ngStyle]="containerStyles"
  >
    <!-- style="margin-top: -30px;" -->
    <div class="col-12">
      <div class="container fixed-search" [ngClass]="getSearchGroupCss()">
        <div class="row">
          <div class="col-12">
            <lib-filter-list-search
              (searchUpdated)="setSearch($event)"
              [initSearchTerm]="searchTerm"
            ></lib-filter-list-search>
          </div>

          <div class="col-12">
            <div class="m-0 mt-2 text-results-top-filters">
              <span
                style="padding-top: 1px"
                class="mt-1 mr-3"
                *ngIf="renderItems && items && filteredItems && filteredItems.length === items.length"
              >
                {{ 'PRODUCT_LIST_TOTAL' | translate: { render: (renderItems | async).length, total: items.length } }}
              </span>
              <span
                style="padding-top: 1px"
                class="mt-1 mr-3"
                *ngIf="renderItems && items && filteredItems && filteredItems.length !== items.length"
              >
                {{
                  'PRODUCT_LIST_FILTER_TOTAL'
                    | translate
                      : { render: (renderItems | async).length, filter: filteredItems.length, total: items.length }
                }}
              </span>
              <lib-selected-filter
                [topFilter]="true"
                [renderFilters]="renderFiltersForSelected"
                (deselectAll)="clearSelection()"
                (deselectValue)="setSelection($event.filterName, $event.valueId, false)"
                (deselectValueRange)="setSelection($event.filterName, null, false, $event.valueIdRange)"
              >
              </lib-selected-filter>
            </div>
          </div>
        </div>
      </div>

      <div class="row margin-top-when-fixed-top-filters top-filter-wrapper">
        <div class="col-12" *ngIf="flowHtml">
          <div class="flow-html" [innerHTML]="flowHtml | safeHtml"></div>
        </div>
        <div *ngIf="isSmallScreen; else isNotSmallScreen">
          <div
            #gungFilters
            [ngClass]="getGungFilterClasses()"
            [class]="getFilterGroupCss()"
            class="col-12 sticky gung-filters d-flex flex-column flex-wrap"
          >
            <div class="d-flex flex-column">
              <lib-filter-list-top-filter
                *ngFor="let renderFilter of renderFilters"
                [renderFilter]="renderFilter"
                (selectValue)="setSelection(renderFilter.name, $event, true)"
                (deselectValue)="setSelection(renderFilter.name, $event, false)"
                (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"
              ></lib-filter-list-top-filter>
            </div>
          </div>
        </div>
        <ng-template #isNotSmallScreen>
          <div class="col-12 sticky gung-filters d-flex flex-wrap">
            <div
              #gungFilters
              *ngFor="let renderFilter of renderFilters"
              class="mb-1"
              [ngClass]="getGungFilterClasses()"
              [class]="getFilterGroupCss()"
            >
              <lib-filter-list-top-filter
                [renderFilter]="renderFilter"
                (selectValue)="setSelection(renderFilter.name, $event, true)"
                (deselectValue)="setSelection(renderFilter.name, $event, false)"
                (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"
              ></lib-filter-list-top-filter>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="row">
        <div class="col-12">
          <ng-template libCustomHost></ng-template>
          <p *ngIf="filteredItems && filteredItems.length === 0" class="nothing-found">
            <span [innerHTML]="getNothingFoundTranslateTag() | translate"></span>
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <button
          [ngClass]="getLoadMoreCss()"
          *ngIf="!!filteredItems && filteredItems.length > limit"
          class="btn"
          (click)="loadMore()"
          style="overflow-anchor: none"
        >
          <span translate [translateParams]="{ limit: limit, total: filteredItems.length, batchSize: batchSize }"
            >LOAD_MORE</span
          >
        </button>
      </div>
    </div>
    <div *ngIf="filteredItems && filteredItems.length > 0 && renderFilters && renderFilters.length > 0">
      <a
        [ngClass]="{ 'hide-filter-toggle': getGungFilterClasses().open }"
        class="gung-filters-toggle"
        (click)="toggleFilters()"
      >
        <i class="fa fa-filter"></i>
      </a>

      <a
        [ngClass]="{ 'hide-filter-toggle': !getGungFilterClasses().open }"
        class="gung-filters-toggle close-filters"
        (click)="toggleFilters()"
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</ng-template>
