import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavbarcartComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-navbarcart',
  templateUrl: './otto-olsen-navbarcart.component.html',
  styleUrls: ['./otto-olsen-navbarcart.component.scss']
})
export class OttoOlsenNavbarcartComponent extends NavbarcartComponent{
  @ViewChild('iconShake', { read: ElementRef, static:false }) iconShake: ElementRef;
  @ViewChild('numberShake', { read: ElementRef, static:false }) numberShake: ElementRef;

  ngOnInit() {
    const sub = this.navbarCartService.getContents().subscribe(contents => {
      if (this.cartTotalItems !== contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0)) {
        this.iconShake.nativeElement.classList.add('shake-cart');
        this.numberShake.nativeElement.classList.add('shake-cart');
        setTimeout(() => {
          this.iconShake.nativeElement.classList.remove('shake-cart');
          this.numberShake.nativeElement.classList.remove('shake-cart');
        }, 500);
      }
      this.cartContents = contents;
      this.cartTotalRows = contents.rows.length;
      this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
      
    });

    this.subs.push(sub);
  }

  
}
