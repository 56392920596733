import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  JeevesSalesCartListComponent,
  JeevesSalesCartListRow,
  JeevesUpdateKuarModalComponent
} from 'gung-standard-jeeves';
import {
  AuthService,
  ProductService,
  CartService,
  PriceService,
  AvailabilityService,
  SelectedCustomerService,
  GungModalService,
  CartRow,
  Availability,
  CartRowPrice,
  Product,
  Customer,
  GungFlowService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { first, map, tap } from 'rxjs';
import { Subscription, forkJoin, of } from 'rxjs';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { DatePipe } from '@angular/common';
import { environment } from './../../../../environments/environment';
import { OttoCheckoutStateServiceService } from '../../../services/utils/otto-checkout-state-service.service';
import { ProductTableRowExtended } from '../../otto-olsen-product-pagination-list-card/otto-olsen-product-pagination-list-card.component';
import { ProductExtended } from '../../otto-olsen-product-details/otto-olsen-product-details.component';

export interface JeevesSalesCartListRowExtended extends JeevesSalesCartListRow {
  product: ProductExtended;
}

@Component({
  selector: 'otto-olsen-jeeves-sales-cart-list',
  templateUrl: './otto-olsen-jeeves-sales-cart-list.component.html',
  styleUrls: ['./otto-olsen-jeeves-sales-cart-list.component.scss']
})
export class OttoOlsenJeevesSalesCartListComponent
  extends JeevesSalesCartListComponent
  implements OnInit, OnChanges, OnDestroy
{
  public requestPriceEmail = environment.requestPriceEmail;

  public mappedData: JeevesSalesCartListRowExtended[];
  private cachedData2: { [id: string]: { product: ProductExtended; price: CartRowPrice; availability: Availability } } =
    {};
  private subscriptions2: Subscription[] = [];

  selectedLanguageCode: string;
  isUser = false;

  currentLang = this.translateService.currentLang || 'se';
  isAnonymous: boolean;
  selectedCustomer: Customer;
  public partDeliveriesForbidden = false;
  get selectedDeliveryDate(): string {
    return this.ottoCheckoutState.selectedDeliveryDate;
  }
  get selectedDeliveryMethod(): string {
    return this.ottoCheckoutState.selectedDeliveryMethod;
  }

  markDisabled: (date: NgbDate, _current: { year: number; month: number }) => boolean;

  constructor(
    protected authService: AuthService,
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected selectedCustomerService: SelectedCustomerService,
    protected dateUtilService: DateUtilService,
    protected translateService: TranslateService,
    private gungModalService: GungModalService,
    private datePipe: DatePipe,
    public ottoCheckoutState: OttoCheckoutStateServiceService,
    protected modalService: NgbModal,
    protected flowService: GungFlowService
    
  ) {
    super(productService, cartService, priceService, availabilityService, selectedCustomerService, dateUtilService, flowService);
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
    this.partDeliveriesForbidden = this.ottoCheckoutState.partDeliveriesForbidden;
    this.markDisabled = this._markDisabled.bind(this);
  }

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(currentUser => {
        if (currentUser.roles.includes('USER') && currentUser.roles.length === 1) {
          this.isUser = true;
        }
        if (currentUser.roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }

        super.ngOnInit();
        this.selectedLanguageCode = this.translateService.currentLang || 'se';
      });

    this.updateData();
  }

  ngOnChanges() {
    this.subscriptions2.forEach(s => s.unsubscribe());

    this.updateData();
  }

  ngOnDestroy(): void {
    this.subscriptions2.forEach(s => s.unsubscribe());
  }

  public openCrossReferenceEditor(product: ProductTableRowExtended) {
    const modal = this.modalService.open(JeevesUpdateKuarModalComponent, { size: 'lg' });
    modal.componentInstance.product = product.product;
  }

  updateData() {
    const ids = this.data.map(c => c.productId);
    const cachedIds = Object.keys(this.cachedData2);
    const toGetIds = ids.filter(id => !cachedIds.includes(id));

    const sub = forkJoin({
      products: this.productService.getProductsByIds(toGetIds).pipe(first()),
      prices: this.priceService.getCartRowPricesObservable(this.data).pipe(first()),
      availabilities: this.availabilityService.getAvailabilities(toGetIds, undefined, true).pipe(
        first(),
        map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))
      ),
      cartRows: of(this.data),
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
    })
      .pipe(
        first(),
        tap(resp => {
          this.cachedData2 = {
            ...this.cachedData2,
            ...toGetIds
              .map(id => ({
                product: resp.products.find(p => p.id === id),
                price: resp.prices.find(p => p.productId === id),
                availability: resp.availabilities.find(av => av.productId === id)
              }))
              .reduce((acc, curr) => ({ ...acc, [curr.product.id]: curr }), {})
          };
        }),
        map(data => {
          const cachedProducts = Object.values(this.cachedData2).map(d => d.product);
          const cachedPrices = data.prices;
          const cachedAvs = Object.values(this.cachedData2).map(d => d.availability);

          cachedProducts.map(product => {
            // Product description
            if (product.extra.i18n && product.extra.i18n[this.currentLang]) {
              product.description = product.extra.i18n[this.currentLang].description || '';
            }
            if (!product.description && product.extra.pim) {
              product.description = product.extra.pim.description || '';
            }
            if (!product.description) {
              product.description = '';
            }
          });

          return this.mapData(cachedProducts, cachedPrices, cachedAvs, data.cartRows, data.customer);
        })
      )
      .subscribe(d => this.updateMappedData(d));

    this.subscriptions2.push(sub);
  }

  public mapData(
    products: Product[],
    prices: CartRowPrice[],
    availabilities: Availability[],
    cartRows: CartRow[],
    customer: Customer
  ): JeevesSalesCartListRow[] {
    const toReturn = super.mapData(products, prices, availabilities, cartRows, customer);
    return toReturn.map((cr: JeevesSalesCartListRow) => {
      const av = availabilities.find(a => a.productId === cr.productId);
      cr.product.extra._availability_reference = av;

      const product: ProductExtended = cr.product;
      if (
        product.extra.ar.q_jis_fast_pakke_strl &&
        product.extra.ar.artfsgforp &&
        Number(product.extra.ar.artfsgforp) > 0
      ) {
        (product as ProductExtended).packageSize = Number(product.extra.ar.artfsgforp);
      }
      // Product original size without cutting
      if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
        product.originalSize = Number(product.extra.ar.artfsgforp);
      }
      if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
        product.packageSize = Number(product.extra.ar.q_salesbatchsize);
      }
      if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
        product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
      }

      return cr;
    });
  }

  editNote(row: CartRow): void {
    let noteInput = '';
    if (!!row.extra.orp) {
      noteInput = row.extra.orp.edit || row.extra.orp.editext || '';
    }

    this.gungModalService.openEditNoteModal(noteInput).then(
      (result: any) => {
        if (!!result && result.hasOwnProperty('note')) {
          this.cartService.setExtra(
            row.productId,
            {
              orp: {
                editext: result.note,
                edit: result.note
              }
            },
            row.targetStockId,
            row.productPartialId
          );
        }
      },
      reason => {}
    );
  }

  updateDeliveryDate(row: JeevesSalesCartListRow, deliveryDate) {
    const date = deliveryDate.date;
    const dString: string = this.datePipe.transform(date, 'yyyy-MM-dd');

    // const oldVal = row.deliveryDate || this.formatNgbDate(row.minDate);

    // if (oldVal === dString) {
    //   return;
    // }

    // if (!deliveryDate) {
    //   this.cartService.removeExtraField(row.productId, 'orp.ordberlevdat', row.targetStockId);
    //   return;
    // }

    this.cartService.setExtra(
      row.productId,
      {
        orp: {
          ordberlevdat: dString
        },
        _user_selected_ordberlevdat: dString
      },
      row.targetStockId,
      row.productPartialId
    );
  }

  // true if the date should be marked as disabled
  public _markDisabled(date: NgbDate, _current: { year: number; month: number }) {
    //   console.log("_markDisabled", date,this.ottoCheckoutState.dates);
    if (!this.ottoCheckoutState.dates) {
      return false;
    }

    const foundDate = this.ottoCheckoutState.dates
      // first filter out all the invalid dates
      .filter(d => d.valid)
      .map(d => d.date)
      // find a matching date with the one to check
      .filter(
        d =>
          d.getFullYear() === date.year &&
          // js date object is zero indexed
          d.getMonth() + 1 === date.month &&
          d.getDate() === date.day
      );

    // return true if the date wasn't found, false if it was.
    return foundDate.length === 0;
  }

  updateMappedData(d: JeevesSalesCartListRow[]) {
    super.updateMappedData(d);
    return;
    d.map(cr => {
      const product: ProductExtended = cr.product;
      if (
        product.extra.ar.q_jis_fast_pakke_strl &&
        product.extra.ar.artfsgforp &&
        Number(product.extra.ar.artfsgforp) > 0
      ) {
        (product as ProductExtended).packageSize = Number(product.extra.ar.artfsgforp);
      }
      // Product original size without cutting
      if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
        product.originalSize = Number(product.extra.ar.artfsgforp);
      }
      if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
        product.packageSize = Number(product.extra.ar.q_salesbatchsize);
      }
      if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
        product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
      }
    });
    this.mappedData = d;
  }
}
