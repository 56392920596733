import { Component, OnInit } from '@angular/core';
import { OttoOlsenProductCardComponent } from '../otto-olsen-product-card/otto-olsen-product-card.component';
import { PriceService, GungFlowService, AuthService, SelectedCustomerService } from 'gung-standard';
import { Location } from '@angular/common';
import { OttoOlsenModalService } from '../../services/otto-olsen-modal.service';

@Component({
  selector: 'otto-olsen-product-export-card',
  templateUrl: './../otto-olsen-product-card/otto-olsen-product-card.component.html',
  styleUrls: ['./../otto-olsen-product-card/otto-olsen-product-card.component.scss']
})
export class OttoOlsenProductExportCardComponent extends OttoOlsenProductCardComponent {
  isExport = true;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected modalService: OttoOlsenModalService,
    protected location: Location,
    protected authService: AuthService,
    selectedCustomerService: SelectedCustomerService
  ) {
    super(priceService, gungFlowService, modalService, location, authService, selectedCustomerService);
  }
}
