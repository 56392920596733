import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FavouriteProductListComponent, FavouriteProductListConfigService, Product } from 'gung-standard';
import { first, of, switchMap } from 'rxjs';
import { OttoOlsenProductExportActionConfigService } from '../../services/otto-olsen-product-export-action-config.service';
import { ExportSelection, SelectionAction } from 'gung-list';

@Component({
  selector: 'otto-olsen-favourite-product-list',
  templateUrl: './otto-olsen-favourite-product-list.component.html',
  styleUrls: ['./otto-olsen-favourite-product-list.component.scss']
})
export class OttoOlsenFavouriteProductListComponent extends FavouriteProductListComponent {
  protected favouriteTemplateId: string = 'EGET_PRODUCT_ASSORTMENT_EXPORT';
  protected favouriteExportAction: SelectionAction<Product>;

  constructor(
    public favouriteProductListConfig: FavouriteProductListConfigService,
    protected route: ActivatedRoute,
    protected productExportActionConfigService: OttoOlsenProductExportActionConfigService
  ) {
    super(
      favouriteProductListConfig,
      route
    );
  }

  ngOnInit(): void {
    this.productExportActionConfigService.getSelectionActionByTemplateId(this.favouriteTemplateId).pipe(first()).subscribe(exportAction => {
      this.favouriteExportAction = exportAction;
    });
  }

  public exportFavourites(): void {
    this.favouriteProductListConfig.getItems().pipe(
      first(),
      switchMap(favourites => {
        const action: ExportSelection<Product> = {
          selectedItems: {},
          selectedItemCount: favourites.length
        };

        for (const favourite of favourites) {
          action.selectedItems[favourite.id] = favourite;
        }

        return of(action);
      })
    ).subscribe(selectionAction => {
      this.favouriteExportAction.performAction(selectionAction);
    });
  }
}
