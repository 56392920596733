import { Component, OnInit } from '@angular/core';
import { PriceService, GungFlowService, AuthService, SelectedCustomerService } from 'gung-standard';
import { OttoOlsenProductGridViewComponent } from '../otto-olsen-product-grid-view/otto-olsen-product-grid-view.component';

@Component({
  selector: 'otto-olsen-favourite-product-grid-view',
  templateUrl: './otto-olsen-favourite-product-grid-view.component.html',
  styleUrls: ['./otto-olsen-favourite-product-grid-view.component.scss']
})
export class OttoOlsenFavouriteProductGridViewComponent extends OttoOlsenProductGridViewComponent {
  
}
