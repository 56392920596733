import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionButton } from 'gung-common';
import { AuthService, GungModalService, UserActionConfigService, UsersService } from 'gung-standard';
import { User } from 'gung-standard/lib/state/auth/types';
import { Observable, filter, first, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenUserActionConfigService extends UserActionConfigService {
  baseSales = [
    {
      title: 'EDIT_USER',
      action: (item: User) =>
        this.router.navigate(['/sales/users-details/' + item.username], { queryParamsHandling: '' })
    }
  ];

  constructor(
    protected router: Router,
    protected modalService: NgbModal,
    protected usersService: UsersService,
    protected gungModalService: GungModalService,
    protected authService: AuthService
  ) {
    super(
      router,
      modalService,
      usersService,
      gungModalService
    );
  }
  getButtons(item: User): Observable<ActionButton<User>[]> {
    return this.authService.getRoles().pipe(
      filter(roles => !!roles), 
      first(),
      switchMap(roles => {
        if (roles.indexOf('ADMIN') > -1 || roles.indexOf('ACTUATOR') > -1) {
          return super.getButtons(item);
        } else {
          return new Observable<ActionButton<User>[]>(subscriber => {
            subscriber.next(this.baseSales);
            subscriber.complete();
          });
        }
      })
    );
  }
}
