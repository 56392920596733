import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GungModalService, GungVideo, ImageCarouselComponent } from 'gung-standard';
import { DomSanitizer } from '@angular/platform-browser';
import { OttoOlsenViewer3dComponent } from '../otto-olsen-viewer3d/otto-olsen-viewer3d.component';

@Component({
  selector: 'otto-olsen-image-carousel',
  templateUrl: './otto-olsen-image-carousel.component.html',
  styleUrls: ['./otto-olsen-image-carousel.component.scss']
})
export class OttoOlsenImageCarouselComponent extends ImageCarouselComponent implements OnInit {
  @Input()
  videoUrl: string;

  video: GungVideo;

  @ViewChild('videoSlide')
  videoSlide: ElementRef;

  @Input()
  model3dFile?: string[];

  @ViewChild('ottoOlsenViewer3d')
  ottoOlsenViewer3d: OttoOlsenViewer3dComponent;

  view3DModel: boolean = false;

  imageSize: string = '300x300';

  ngOnInit(): void {
    this.images = this.images.filter(img => {
      const isNotLogiaImage = img?.extra?.imageTag !== 'LOGIA';
      return isNotLogiaImage;
    });

    super.ngOnInit();
  }

  toggleView3DModel() {
    this.view3DModel = !this.view3DModel;
  }

  loadVideoUrl() {
    if (this.videoUrl) {
      if (this.videoUrl.includes('youtube.com')) {
        const spliter = 'youtube.com/watch?v=';
        const code = this.videoUrl.substring(this.videoUrl.indexOf(spliter) + spliter.length);
        if (!code) {
          return;
        }
        this.video = {
          url: this.videoUrl,
          provider: 'youtube',
          code,
          embed: this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://www.youtube-nocookie.com/embed/${code}?controls=1&enablejsapi=1`
          )
        };
      } else if (this.videoUrl.includes('vimeo.com')) {
        const spliter = 'vimeo.com/';
        const code = this.videoUrl.substring(this.videoUrl.indexOf(spliter) + spliter.length);
        if (!code) {
          return;
        }
        this.video = {
          url: this.videoUrl,
          provider: 'vimeo',
          code,
          embed: this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://player.vimeo.com/video/${code}?api=1&title=0&byline=0&portrait=0`
          )
        };
      }
    }
  }

  select(index: number): void {
    const activeId = this.myCarousel.activeId;
    this.myCarousel.select(this.imageIdString + index);
    const hostEle = document.getElementById(this.imageIdString + '' + this.images.length) as HTMLIFrameElement;
    if (hostEle && this.video.provider === 'youtube') {
      if (activeId === this.imageIdString + '' + this.images.length) {
        // Pause playing video when scrool out
        hostEle.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      }
      if (index === this.images.length) {
        // Play video when scroll in
        hostEle.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
      }
    } else if (hostEle && this.video.provider === 'vimeo') {
      if (activeId === this.imageIdString + '' + this.images.length) {
        // Pause playing video when scrool out
        const url = this.video.embed;
        this.video.embed = undefined;
        this.video.embed = url;
      }
    }
  }
}
