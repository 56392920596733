import { Injectable } from '@angular/core';
import { ProductExportService, Product, ExportProductSelectionDataType } from 'gung-standard';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductExportService extends ProductExportService {
  exportExcel(
    itemList: Product[],
    template?: string,
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    const templateName = template.toLowerCase().replace(' ', '-').replace('_', '-') || 'product-data';

    const dateString = new Date().toLocaleString().replace(', ', '+');

    const formattedFileName = templateName + '-' + itemList.length + '-items-' + dateString + '.xlsx';

    return this.downloadFile(
      'generate-product-excel-from-products-ids',
      itemList,
      ExportProductSelectionDataType.Xlsx,
      template,
      {
        overrideFilename: formattedFileName,
        ...additionalVariables
      }
    );
  }
}
