import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { JeevesCustomerDeliveryLocationListConfigService, DeliveryLocationsService } from 'gung-standard-jeeves';
import { MetadataService, GungBase } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenJeevesCustomerDeliveryLocationService extends JeevesCustomerDeliveryLocationListConfigService {
  constructor(
    protected deliveryLocationsService: DeliveryLocationsService,
    protected metadataService: MetadataService
  ) {
    super(deliveryLocationsService, metadataService);
  }

  getFilters(): ConfigBaseFilter<GungBase>[] {
    return [];
  }
}
