import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthGuard, LoginCheckResponse, LoginModalComponent } from 'gung-standard';
import { Observable, first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenAuthGuardRedirectLoginService extends AuthGuard {
  protected async checkLogin(redirectUrl: string, rolesAllowed?: string[]): Promise<boolean> {
    const authenticated1 = await this.authService.getIsAuthenticated().pipe(first()).toPromise();
    const headers: { [s: string]: string | string[] } = {};
    headers.maxAge = '-1';
    return this.http
      .get<LoginCheckResponse>('user/login-check', { headers })
      .toPromise()
      .then(async loginCheckResponse => {
        // console.log(loginCheckResponse);
        if (authenticated1 && loginCheckResponse.loggedIn === false) {
          await this.modalService.open(LoginModalComponent, { size: 'lg', backdrop: 'static', keyboard: false }).result;
        }
        const authenticated = await this.authService.getIsAuthenticated().pipe(first()).toPromise();
        if (!authenticated) {
          this.router.navigate(['/login'], { queryParams: { redirectUrl } });
          return false;
        }

        const isAnonymous = await this.authService.getHasRoles('ANONYMOUS').pipe(first()).toPromise();
        if (isAnonymous) {
          this.router.navigate(['/login'], { queryParams: { redirectUrl } });
          return false;
        }

        if (!rolesAllowed) {
          return true;
        }

        const isAllowed = await this.checkRoles(rolesAllowed);
        if (!isAllowed || isAnonymous) {
          this.router.navigate(['/unauthorized']);
          return false;
        }

        return true;
      });
  }
}
