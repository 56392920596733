import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';

@Component({
  selector: 'otto-olsen-free-scanner',
  templateUrl: './otto-olsen-free-scanner.component.html',
  styleUrls: ['./otto-olsen-free-scanner.component.scss']
})
export class OttoOlsenFreeScannerComponent implements AfterViewInit {
  @Output()
  outputCodeScanned = new EventEmitter<string>();

  @Input()
  autoInitCamera = true;

  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  public scannerConfig: ScannerQRCodeConfig = {
    src: undefined,
    fps: 30,
    vibrate: 0,
    decode: 'utf-8',
    isBeep: false,
    constraints: {
      video: {
        width: window.innerWidth
      },
    },
    canvasStyles: [
         { /* layer */
           lineWidth: 1,
           fillStyle: '#00950685',
           strokeStyle: '#00950685',
         },
         { /* text */
           font: '17px serif',
           fillStyle: '#ff0000',
           strokeStyle: '#ff0000',
         }
       ],
  };

  deviceList: MediaDeviceInfo[] = [];
  currentDeviceId: string;
  currentDeviceIndex: number;

  toolbarItems: {
    icon: string,
    itemAction(): void
  }[] = [
    {
      icon: 'fa fa-power-off',
      itemAction: () => {
        this.handle(this.action, this.action.isStart ? 'stop' : 'start');
      }
    }, {
      icon: 'fad fa-camera-alt',
      itemAction: () => {
        this.changeCamera();
      }
    }, {
      icon: 'fa-solid fa-flashlight',
      itemAction: () => {
        this.handle(this.action, 'torcher');
      }
    }
  ];

  ngAfterViewInit(): void {
    this.action.isReady.subscribe((res: any) => {
      if (this.autoInitCamera) {
        this.handle(this.action, 'start');
      }
    });
  }

  changeCamera(){
    const maxIndex: number = this.deviceList.length - 1;
    const nextIndex: number = (this.currentDeviceIndex) + 1 > maxIndex ? 0 : this.currentDeviceIndex + 1;
    const nextId: string = this.deviceList?.[nextIndex]?.deviceId;

    if (!!nextId) {
      this.currentDeviceId = nextId;
      this.currentDeviceIndex = nextIndex;
      this.action.playDevice(this.currentDeviceId);
    }
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    // e && action && action.pause();
    this.outputCodeScanned.emit(e?.[0]?.value || '');
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: MediaDeviceInfo[]) => {
      this.initDevices(devices);
      action.playDevice(this.currentDeviceId);
    };

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        alert
      );
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }

  initDevices(devices: MediaDeviceInfo[]) {
    this.deviceList = devices;
    if (!this.currentDeviceId) {
      const device = this.deviceList.find((d) =>
        /back|rear|environment/gi.test(d.label)
      ); // Default Back Facing Camera

      this.currentDeviceId = device ? device.deviceId : this.deviceList[0].deviceId;
    }

    this.currentDeviceIndex = this.deviceList.findIndex(d => d.deviceId === this.currentDeviceId);
  }
}
