import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import {
  PriceService,
  GungFlowService,
  AuthService,
  AssortmentTreeImageSelectionService,
  SelectedCustomerService,
  Customer,
  Product,
  PriceConfigService,
  ColumnSortSelectionService,
  GungAnonymousConfigService
} from 'gung-standard';
import { environment } from './../../../environments/environment';
import { first, takeUntil } from 'rxjs';
import { OttoOlsenConceptDetailProductListViewComponent } from '../otto-olsen-concept-detail-product-list-view/otto-olsen-concept-detail-product-list-view.component';
import { Router } from '@angular/router';
import { OttoOlsenPimEditConfigService } from '../../services/otto-olsen-pim-edit-config.service';

@Component({
  selector: 'otto-olsen-concept-detail-product-card',
  templateUrl: './otto-olsen-concept-detail-product-card.component.html',
  styleUrls: ['./otto-olsen-concept-detail-product-card.component.scss']
})
export class OttoOlsenConceptDetailProductCardComponent extends OttoOlsenConceptDetailProductListViewComponent implements OnChanges, OnDestroy {
  public requestPriceEmail = environment.requestPriceEmail;
  public Number = Number;

  @Input()
  public allData?: Product[];
  selectedCustomer: Customer;

  public hidePrice: boolean;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    protected columnSortSelectionService: ColumnSortSelectionService,
    protected selectedCustomerService: SelectedCustomerService,
    protected router: Router,
    protected priceConfigService: PriceConfigService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(
      priceService,
      gungFlowService,
      authService,
      assortmentTreeImageSelectionService,
      columnSortSelectionService,
      selectedCustomerService,
      router,
      priceConfigService,
      ottoOlsenPimEditConfigService,
      gungAnonymousConfigService
    );
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
    this.priceConfigService.hidePrice.pipe(takeUntil(this.unsubscribe)).subscribe(hidePrice => (this.hidePrice = hidePrice));
  }

  mailPrice(id){
    window.location.href = "mailto:" + this.requestPriceEmail + "?subject=Forespørsel "+ id ;
  }
}
