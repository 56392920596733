import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestAccountComponent } from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'otto-olsen-request-account',
  templateUrl: './otto-olsen-request-account.component.html',
  styleUrls: ['./otto-olsen-request-account.component.scss']
})
export class OttoOlsenRequestAccountComponent extends RequestAccountComponent implements OnInit {
  @Input()
  existingCustomer = false;

  accountFormExistingCustomer: FormGroup;

  constructor(protected formBuilder: FormBuilder, protected httpClient: HttpClient, protected translateService: TranslateService) {
    super(formBuilder, httpClient, translateService);
  }

  ngOnInit() {
    this.initForm();
    this.initFormExistingCustomer();
  }

  initForm() {
    this.accountForm = this.formBuilder.group({
      companyName: this.formBuilder.control('', [Validators.required]),
      // companyId: this.formBuilder.control('', []),
      companyAddress: this.formBuilder.control('', [Validators.required]),
      // companyOrganisationId: this.formBuilder.control('', [Validators.required]),
      companyCountry: this.formBuilder.control('', [Validators.required]),
      companyContactName: this.formBuilder.control('', [Validators.required]),
      companyContactEmail: this.formBuilder.control('', [Validators.required, Validators.email]),
      companyContactPhone: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8)
      ]),
      extra: this.formBuilder.group({
        companyOrgName: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9)
        ]),
        companyZipCode: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4)
        ]),
        companyCity: this.formBuilder.control('', [Validators.required]),
        companyDeliveryAddress: this.formBuilder.control('', [Validators.required]),
        companyDeliveryZipCode: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4)
        ]),
        companyDeliveryCity: this.formBuilder.control('', [Validators.required]),
        companyDeliveryCountry: this.formBuilder.control('', [Validators.required]),
        companyContactTitle: this.formBuilder.control('', [Validators.required]),
        companyEmailInvoice: this.formBuilder.control('', [Validators.required, Validators.email]),
        companyEmailConfirmation: this.formBuilder.control('', [Validators.required, Validators.email]),
        companyPhone: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(8)
        ]),
        companyPreferedCurrency: this.formBuilder.control('', [Validators.required]),
        companyWebSite: this.formBuilder.control('', []),
        companyComments: this.formBuilder.control('', [])
      })
    });
  }

  onSubmit() {
    if (!this.existingCustomer) {
      super.onSubmit();
      return;
    }
    this.alertMessage = null;

    if (this.accountFormExistingCustomer.invalid) {
      this.accountFormExistingCustomer.markAllAsTouched();
      return;
    }

    this.accountFormExistingCustomer.disable();
    this.loader = true;
    const form = this.accountFormExistingCustomer.getRawValue();
    form.extra.companyComments = 'Existing customer and need an account. ' + form.extra.companyComments;
    this.sendRequestAccount(form);
  }

  initFormExistingCustomer() {
    this.accountFormExistingCustomer = this.formBuilder.group({
      companyName: this.formBuilder.control('', [Validators.required]),
      companyId: this.formBuilder.control('', []),
      companyAddress: this.formBuilder.control('', []),
      // companyOrganisationId: this.formBuilder.control('', [Validators.required]),
      companyCountry: this.formBuilder.control('', []),
      companyContactName: this.formBuilder.control('', [Validators.required]),
      companyContactEmail: this.formBuilder.control('', [Validators.required, Validators.email]),
      companyContactPhone: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8)
      ]),
      extra: this.formBuilder.group({
        companyOrgName: this.formBuilder.control('', []),
        companyZipCode: this.formBuilder.control('', []),
        companyCity: this.formBuilder.control('', []),
        companyDeliveryAddress: this.formBuilder.control('', []),
        companyDeliveryZipCode: this.formBuilder.control('', []),
        companyDeliveryCity: this.formBuilder.control('', []),
        companyDeliveryCountry: this.formBuilder.control('', []),
        companyContactTitle: this.formBuilder.control('', [Validators.required]),
        companyEmailInvoice: this.formBuilder.control('', []),
        companyEmailConfirmation: this.formBuilder.control('', []),
        companyPhone: this.formBuilder.control('', []),
        companyPreferedCurrency: this.formBuilder.control('', []),
        companyWebSite: this.formBuilder.control('', []),
        companyComments: this.formBuilder.control('', [])
      })
    });
  }
}
