import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import {
  AccountRequestsCardListComponent,
  AccountRequestListConfigService,
  AccountRequestService,
  AccountRequest
} from 'gung-standard';
import { OttoOlsenAccountRequestsCardListComponent } from '../components/otto-olsen-account-requests-card-list/otto-olsen-account-requests-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenAccountRequestListConfigService extends AccountRequestListConfigService {
  constructor(protected accountRequestService: AccountRequestService) {
    super(accountRequestService);
  }

  getLayouts(): ListLayout<AccountRequest>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenAccountRequestsCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }
}
