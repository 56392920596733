import { Component, OnInit } from '@angular/core';
import { ProductPaginationListComponent, ProductPaginationListConfigService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-product-pagination-list',
  template: '<otto-olsen-pagination-list #productList [configService]="configService"></otto-olsen-pagination-list>',
  styleUrls: ['./otto-olsen-product-pagination-list.component.scss']
})
export class OttoOlsenProductPaginationListComponent extends ProductPaginationListComponent {
  constructor(public configService: ProductPaginationListConfigService) {
    super(configService);
  }
}
