import { OnInit, AfterViewInit, Component, Input } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
/* import * as OV from 'online-3d-viewer'; */
import { OttoOlsenModalService } from '../../services/otto-olsen-modal.service';

@Component({
  selector: 'otto-olsen-viewer3d',
  templateUrl: './otto-olsen-viewer3d.component.html',
  styleUrls: ['./otto-olsen-viewer3d.component.css']
})
export class OttoOlsenViewer3dComponent implements OnInit, AfterViewInit {

  @Input()
  public modelFile: string[];

  @Input()
  public width = '800px';

  @Input()
  public height = '600px';

  @Input()
  public initLoad = true;

  @Input()
  public isExpanded = false;

  viewerId: string = 'viewer';

  // If there is a need to show anything beyond the simple 3D Viewer, set this to TRUE
  @Input()
  public showMoreOptions = false;

  loadingModel = true;

  public embeddedViewer;

  cameraMode: number;

  constructor(protected modalService: OttoOlsenModalService) {
  }

  ngOnInit(): void {
    this.viewerId += new Date().getTime().toString();
  }

  ngAfterViewInit(): void {
    

     OV.SetExternalLibLocation ('assets/3d/libs'); // tell the engine where to find the libs folder
    // OV.Init3DViewerElements (); // init all viewers on the page
    if (this.initLoad) {
      this.init3DViewerElements();
    } 
  }

  public init3DViewerElements() {
    // get the parent element of the viewer
    const parentDiv = document.getElementById(this.viewerId);

    if (parentDiv) {
      // initialize the viewer with the parent element and some parameters
      this.embeddedViewer = new OV.EmbeddedViewer(parentDiv, {
        backgroundColor : new OV.RGBAColor (255, 255, 255, 255),
        defaultColor : new OV.RGBColor (200, 200, 200),
        onModelLoaded : () => {
            let model = this.embeddedViewer.GetModel();
            this.cameraMode = this.embeddedViewer.viewer.GetCameraMode();
            if (!this.isExpanded) {
              this.embeddedViewer.viewer.SetFixUpVector(false);
            }
            this.loadingModel = false;
        }
      });
      
      if (this.modelFile?.length > 0) {
        this.embeddedViewer.LoadModelFromUrlList(this.modelFile);
      } else {
        throw new Error('modelFile not found');
      }
    } else {
      throw new Error('parentDiv viewer not found');
    }
  }

  openModal() {
    this.modalService.openExpanded3DModelViewerModal(this.modelFile).then(
      result => {},
      reject => {}
    );
  }

  setYAxisUpVector() {
    this.embeddedViewer.viewer.SetUpVector(OV.Direction.Y, true);
  }

  setZAxisUpVector() {
    this.embeddedViewer.viewer.SetUpVector(OV.Direction.Z, true);
  }

  flipUpVector() {
    this.embeddedViewer.viewer.FlipUpVector();
  }

  fixedVectorMovement() {
    this.embeddedViewer.viewer.SetFixUpVector(true);
  }

  freeOrbitMovement() {
    this.embeddedViewer.viewer.SetFixUpVector(false);
  }

  perspectiveCamera() {
    this.embeddedViewer.viewer.SetCameraMode(OV.CameraMode.Perspective);
    this.cameraMode = this.embeddedViewer.viewer.GetCameraMode();
  }

  orthographicCamera() {
    this.embeddedViewer.viewer.SetCameraMode(OV.CameraMode.Orthographic);
    this.cameraMode = this.embeddedViewer.viewer.GetCameraMode();
  }
}
