import { Injectable } from '@angular/core';
import { GungAnonymousConfigService } from 'gung-standard';
import {
  PriceConfigService,
  GungCurrencyPipe,
  CustomerProductPrice,
  AuthService,
  CartService,
  PriceService,
  ProductService
} from 'gung-standard';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, mergeMap, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenPriceConfigService extends PriceConfigService {
  isAnonymous = true;
  public enableHidePrice = true;

  constructor(
    protected currencyPipe: GungCurrencyPipe,
    protected authService: AuthService,
    cartService: CartService,
    priceService: PriceService,
    productService: ProductService,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(currencyPipe, cartService, priceService, productService, authService, gungAnonymousConfigService);

    this.authService
      .getRoles()
      .pipe(
        filter(roles => !!roles),
        first()
      )
      .subscribe(roles => {
        if (roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }
      });
  }

  protected getDisplay(): string | boolean {
    return 'code'; // 'code' | 'symbol' | 'symbol-narrow'
  }

  public getCurrency(): string {
    return 'NOK';
  }

  public getPriceHTML(
    price: CustomerProductPrice | string,
    mode: string,
    display?: string | boolean,
    currency?: string
  ): string {
    if (this.isAnonymous) {
      return '';
    }
    return super.getPriceHTML(price, mode, display, currency);
  }
}
