<div class="row" style="padding-bottom: 2px">
  <div class="col-12 p-0">
    <div class="input-group" [class.hide-desktop]="!showButtons">
      <div class="input-group-prepend">
        <button
          class="btn btn-primary btn-sm"
          (click)="sub(); setSelectedId()"
          tabindex="-1"
          [disabled]="currentQty <= 0 || disabled"
        >
          <i class="fas fa-minus"></i>
        </button>
      </div>
      <input
        #inputEle
        [id]="id"
        type="number"
        placeholder="0"
        [ngClass]="isAvailability ? 'form-control form-control-sm text-center' : 'form-control text-center'"
        (focus)="setSelectedId()"
        [ngModel]="formatValue(currentQty)"
        (ngModelChange)="set($event, inputEle)"
        [disabled]="disabled"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (keyup.enter)="focusNext(id)"
      />

      <div class="input-group-append">
        <button class="btn btn-sm btn-primary" (click)="add(); setSelectedId()" tabindex="-1" [disabled]="disabled">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</div>
