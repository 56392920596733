import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ProductService,
  AvailabilityService,
  AuthService,
  PriceService,
  GungFlowService,
  BaseViewConfigService,
  SelectedCustomerService,
  MetadataService,
  AssortmentService,
  CartService,
  PriceConfigService,
  Availability,
  Product,
  SimplifiedAvailability,
  ProductInputQuantityConfigService
} from 'gung-standard';
import { first, takeUntil } from 'rxjs';
import { OttoOlsenProductAssortmentParentService } from '../../../services/otto-olsen-product-assortment-parent.service';
import { OttoOlsenProductDetailsComponent } from '../../otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenPimEditConfigService } from '../../../services/otto-olsen-pim-edit-config.service';
import { OttoOlsenProductService } from '../../../services/otto-olsen-product.service';

@Component({
  selector: 'otto-olsen-mobile-product-details',
  templateUrl: './otto-olsen-mobile-product-details.component.html',
  styleUrls: ['./otto-olsen-mobile-product-details.component.scss']
})
export class OttoOlsenMobileProductDetailsComponent extends OttoOlsenProductDetailsComponent implements OnInit {
  public number = n => Number(n);
  roles: string[];
  currentQty: number;
  
  constructor(
    protected route: ActivatedRoute,
    protected productService: OttoOlsenProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: OttoOlsenProductAssortmentParentService,
    protected titleService: Title,
    protected selectedCustomerService: SelectedCustomerService,
    protected cartService: CartService,
    protected priceConfigService: PriceConfigService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      translateService,
      metadataService,
      gungFlowService,
      assortmentService,
      baseViewConfigService,
      productAssortmentParentService,
      titleService,
      selectedCustomerService,
      priceConfigService,
      ottoOlsenPimEditConfigService,
      cartService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.roles = roles;
    });

    this.cartService
      .getProductQty(this.productId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(qty => {
        setTimeout(() => {
          this.currentQty = qty;
        }, 0);
      });
  }

  add(product: Product, availability: Availability | SimplifiedAvailability, qtyToAdd: number) {
    if (!this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(this.currentQty + qtyToAdd, availability, product, this.roles)) {
      return;
    }
    this.cartService.addToCart(product.id, qtyToAdd, undefined, undefined, undefined, undefined, undefined, product.name);
  }

  subtract(product: Product, availability: Availability | SimplifiedAvailability, qtyToSubtract: number) {
    this.cartService.subtractFromCart(product.id, qtyToSubtract);
  }
}
