import { Component, Input, OnInit } from '@angular/core';
import { DashboardKpiService, LastVsThisMonthSalesAmountSummaryCardComponent } from 'gung-dashboard';
import { ActivityInternal, ActivityInternalListConfigService } from 'gung-standard';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'otto-olsen-beta-last-vs-this-month-meetings',
  templateUrl: './otto-olsen-beta-last-vs-this-month-meetings.component.html',
  styleUrls: ['./otto-olsen-beta-last-vs-this-month-meetings.component.scss']
})
export class OttoOlsenBetaLastVsThisMonthMeetingsComponent extends LastVsThisMonthSalesAmountSummaryCardComponent implements OnInit {
  protected unsubscribe: Subject<void> = new Subject();
  totalCurrentMonth: string[];
  totalLasMoth: string[];
  completedCurrentMonth: string[];
  completedLastMonth: string[];
  currentDate: Date;

  amountOfCustomers;
  amountOfCustomersWhoOrdered;

  @Input()
  preConditions: any;

  constructor(
    protected dashboardKpiService: DashboardKpiService,
    public activityInternalListConfigService: ActivityInternalListConfigService
  ) {
    super(dashboardKpiService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dashboardKpiService
      .getActiveCustomersThisYearComparison(this.preConditions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    this.activityInternalListConfigService.getItems().subscribe(items => {
      const completeActivities = ['30'];
      this.currentDate = new Date();
      const currentMonth = this.currentDate.getMonth();
      const lastMonth = this.currentDate.getMonth() - 1;

      this.totalCurrentMonth = this.filterItemsByMonth(items, currentMonth);
      this.totalLasMoth = this.filterItemsByMonth(items, lastMonth);
      this.completedCurrentMonth = this.filterItemsByMonth(items, currentMonth, completeActivities);
      this.completedLastMonth = this.filterItemsByMonth(items, lastMonth, completeActivities);
    });
  }

  filterItemsByMonth(items: ActivityInternal[], month: number, status?: string[]): any[] {
    const filteredItems = items.filter(act => {
      const createdDate = new Date(act.date);
      const actDate = new Date(act.activityDate);

      const isMatchingMonth = (date: Date) => date.getMonth() === month;
      const isMatchingStatus = (status: string[]) => status.includes(act.status);
      const isMatchingSalesPerson = (salesPersonId: string) => act.customerResponsible === salesPersonId;

      if (status) {
        if (this.preConditions) {
          return (
            isMatchingMonth(actDate) &&
            isMatchingStatus(status) &&
            isMatchingSalesPerson(this.preConditions.salesPersonId)
          );
        }
        return isMatchingMonth(actDate) && isMatchingStatus(status);
      }

      if (this.preConditions) {
        return isMatchingMonth(actDate) && isMatchingSalesPerson(this.preConditions.salesPersonId);
      }
      return isMatchingMonth(actDate);
    });

    return filteredItems;
  }
}
