<ng-template #loading>
  <div class="progress mt-5" style="margin-left: -1rem; margin-right: -1rem">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
      role="progressbar"
      aria-valuenow="75"
      aria-valuemin="0"
      aria-valuemax="100"
      style="width: 75%"
    ></div>
  </div>
</ng-template>
<div *ngIf="mappedData; else loading">
  <div class="table-head clearfix">
    <div class="table-col c-image"></div>
    <div class="table-col c-notes"></div>
    <div class="table-col c-qty">
      <b translate>QUANTITY</b>
    </div>
    <div class="table-col c-availability">
      <b translate>AVAILABILITY</b>
    </div>
    <div class="table-col c-price">
      <b translate>PRICE</b>
    </div>
    <div class="table-col c-discount">
      <b translate>DISCOUNT_PERCENT</b>
    </div>
    <div class="table-col c-total">
      <b translate>TOTAL</b>
    </div>
    <div class="table-col c-delivery">
      <b translate>DELIVERY</b>
    </div>
    <div class="table-col c-options"></div>
  </div>
  <div class="table-body">
    <div class="table-line clearfix" *ngFor="let row of mappedData; index as index; trackBy: trackByFn">
      <div class="product-name" style="font-size: 1rem">
        <div
          [routerLink]="[
            row.product.extra.assortmentParentPath
              ? '/articles/' + row.product.extra.assortmentParentPath
              : '/product/',
            row.product.id
          ]"
        >
          <b>{{ row.product.id }}</b> - {{ row.product.name }}
        </div>
        <div class="d-block">
          <b
            *ngIf="
              selectedCustomer &&
              row.product.extra._customerCrossReferences &&
              row.product.extra._customerCrossReferences[selectedCustomer.id]
            "
            class="small"
          >
            {{ row.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
          </b>
          <a class="ml-2" (click)="openCrossReferenceEditor(row)">
            <i class="fas fa-edit"></i>
          </a>
        </div>
      </div>
      <div class="line-cols">
        <div
          class="table-col c-image"
          [routerLink]="[
            row.product.extra.assortmentParentPath
              ? '/articles/' + row.product.extra.assortmentParentPath
              : '/product/',
            row.product.id
          ]"
        >
          <img
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/64x64/filters:fill(white):background_color(white):format(jpeg)/{{
              row.product.extra.images[0].s3Uri
            }}"
            alt=""
          />
        </div>
        <div class="table-col c-notes">
          <button class="btn btn-primary btn-sm" (click)="editNote(row.cartRow)">
            <span translate style="font-size: 1rem">NOTES</span>
          </button>
        </div>
        <div class="table-col c-qty">
          <b translate class="mobile-label">QUANTITY</b>
          <div
            *ngIf="
              isAnonymous ||
                (!(
                  row.product.extra.pim &&
                  row.product.extra.pim.pricerequest &&
                  row.product.extra.pim.pricerequest !== 'false'
                ) &&
                  !(row.product.extra.ar.itemtypecd1 === 0 || row.product.extra.ar.itemtypecd1 === 7));
              else priceRequest
            "
          >
            <div>
              <otto-olsen-buy-btn
                name="buy-btn-package-size"
                [id]="row.product.id"
                [checkoutCart]="true"
                [minimumOrderQuantity]="row.product?.firstStepAmount || row.product?.packageSize"
                [stepAmount]="row.product?.packageSize"
                [originalSize]="row.product?.originalSize"
                [productPartialId]="row.productPartialId"
                [decimals]="row.product?.extra.ar.antdec"
                [activeInB2B]="row?.product?.extra?.pim?.activeInB2B"
              >
              </otto-olsen-buy-btn>
              <span class="pl-1">
                {{ row.product.extra.ar?.enhetskod }}
              </span>
            </div>

            <span
              *ngIf="row.product?.originalSize && row.product?.extra.ar?.q_jis_kaltillegg_artnr && isInteger(row)"
              class="text-center"
            >
              <i class="fas fa-exclamation-triangle text-danger"></i>
              <span>{{ 'FEE_ADDED_LOT_SIZE' | translate: { value: row.product?.originalSize } }}</span>
            </span>
          </div>
          <ng-template #priceRequest>
            <a
              href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ row.product.id }}"
              target="_blank"
              class="request-price-email"
            >
              <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
            </a>
          </ng-template>
        </div>
        <div class="table-col c-availability">
          <b translate class="mobile-label">AVAILABILITY</b>
          <otto-olsen-availability
            [availability]="row.cartRow?.extra?._availability_reference"
            [unit]="row.product?.extra.ar?.enhetskod"
            [decimals]="row.product?.extra.ar.antdec"
            [productionDays]="row.product?.extra.ar?.anskaffningssatt && (row.product?.extra.ar?.anskaffningssatt * 1) === 12 && row.product?.extra.ar?.artgenomloppstid"
          ></otto-olsen-availability>
        </div>
        <div class="table-col c-price">
          <b translate class="mobile-label">PRICE</b>
          <input
            type="number"
            class="form-control text-right px-1"
            [value]="parseFloatFixed(row.overridePrice || row.originalPrice, 2)"
            [placeholder]="row.originalPrice | number: '1.2-2'"
            (blur)="updateOverridePrice(row, parseFloatFixed($event.target.value, 2))"
          />
        </div>
        <div class="table-col c-discount">
          <b translate class="mobile-label">DISCOUNT_PERCENT</b>
          <input
            type="number"
            class="form-control discount-control text-right px-1"
            [value]="
              parseFloatFixed(row.discountPercen, 2) ||
              parseFloatFixed(row.cartRowPriceElement.cartRowDiscountPercent, 2) ||
              0
            "
            (blur)="updateDiscountPercentage(row, $event.target.value)"
            placeholder="%"
          />
        </div>
        <div class="table-col c-total">
          <b translate class="mobile-label">TOTAL</b>
          <lib-price [price]="row.cartRowPriceElement" type="cartRowTotalPriceInclRowDiscount"></lib-price>
        </div>
        <div class="table-col c-delivery">
          <b translate class="mobile-label">DELIVERY</b>
          <div class="fit-datepicker">
            <div *ngIf="!partDeliveriesForbidden">
              <lib-delivery-date-picker
                class="request-delivery-date"
                [label]="null"
                [initialDate]="row.deliveryDate ? row.deliveryDate : formatNgbDate(row.minDate)"
                (selectedDate)="updateDeliveryDateWithWarnings(row, $event, false)"
                [minInitialDate]="row.minDate"
                [deliveryMethod]="row.deliveryMethod"
                [currentselectedDate]="row.deliveryDate"
              ></lib-delivery-date-picker>
            </div>
            <div *ngIf="partDeliveriesForbidden">
              <lib-delivery-date-picker
                *ngIf="ottoCheckoutState.selectedDeliveryDate"
                [initialDate]="ottoCheckoutState.selectedDeliveryDate"
                [currentselectedDate]="selectedDeliveryDate"
                [label]="null"
                [disabled]="true"
              ></lib-delivery-date-picker>
            </div>
          </div>
        </div>
        <div class="table-col c-options text-right clearfix">
          <ng-container *ngTemplateOutlet="rowBtns; context: { row }"></ng-container>
        </div>
      </div>
      <div class="">
        <div
          *ngIf="row.cartRow.extra.warning && !warningWasActiveBefore[row.productId]"
          class="d-flex alert alert-danger p-1 mt-1"
        >
          <span translate>DELIVERY_DATE_CHANGED_DUE_TO_STOCK</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
  OLD CODE Bootstrap
  <div *ngIf="mappedData; else loading">
  <div class="row pb-2 d-none d-md-flex">
    <div class="col-4 col-lg-1"></div>
    <div class="d-none d-xl-block col-12 col-md-6 col-lg-4 col-xl-2"></div>
    <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
      <b translate>QUANTITY</b>
    </div>
    <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
      <b translate>AVAILABILITY</b>
    </div>
    <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
      <b translate>PRICE</b>
    </div>
    <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
      <b translate>DISCOUNT_PERCENT</b>
    </div>
    <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
      <b translate>TOTAL</b>
    </div>
    <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
      <b translate>DELIVERY</b>
    </div>
    <div class="col-1"></div>
  </div>
  <span *ngFor="let row of mappedData; index as index; trackBy: trackByFn">
    <div class="row pb-2 align-items-center">
      <div class="col-12" style="font-size: 1rem">
        <div
          [routerLink]="[
            row.product.extra.assortmentParentPath
              ? '/articles/' + row.product.extra.assortmentParentPath
              : '/product/',
            row.product.id
          ]"
        >
          <b>{{ row.product.id }}</b> - {{ row.product.name }}
        </div>
        <div class="d-block">
          <b
            *ngIf="
              selectedCustomer &&
              row.product.extra._customerCrossReferences &&
              row.product.extra._customerCrossReferences[selectedCustomer.id]
            "
            class="small"
          >
            {{ row.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
          </b>
          <a class="ml-2" (click)="openCrossReferenceEditor(row)">
            <i class="fas fa-edit"></i>
          </a>
        </div>
      </div>
      <div
        class="col-3 col-lg-1 d-flex align-items-center navigate-to-product-details"
        [routerLink]="[
          row.product.extra.assortmentParentPath ? '/articles/' + row.product.extra.assortmentParentPath : '/product/',
          row.product.id
        ]"
      >
        <img
          class="img-fluid"
          src="https://cdn1.gung.io/fit-in/64x64/filters:fill(white):background_color(white):format(jpeg)/{{
            row.product.extra.images[0].s3Uri
          }}"
          alt=""
        />
      </div>

      <div class="col-8 col-lg-2 col-xl-1 text-truncate navigate-to-product-details px-0">
        <div class="pb-sm-4 pb-lg-0">
          <button class="btn btn-primary btn-sm" (click)="editNote(row.cartRow)">
            <span translate style="font-size: 1rem">NOTES</span>
          </button>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0 col-request-quantity">
        <div class="row">
          <div class="col-4 d-flex d-xl-none">
            <b translate>QUANTITY</b>
          </div>
          <div class="col-8 col-xl-12 d-flex">
            <div
              *ngIf="
                isAnonymous ||
                  (!(
                    row.product.extra.pim &&
                    row.product.extra.pim.pricerequest &&
                    row.product.extra.pim.pricerequest !== 'false'
                  ) &&
                    !(row.product.extra.ar.itemtypecd1 === 0 || row.product.extra.ar.itemtypecd1 === 7));
                else priceRequest
              "
            >
              <otto-olsen-buy-btn
                name="buy-btn-package-size"
                [id]="row.product.id"
                [checkoutCart]="true"
                [minimumOrderQuantity]="row.product?.firstStepAmount || row.product?.packageSize"
                [stepAmount]="row.product?.packageSize"
                [originalSize]="row.product?.originalSize"
                [productPartialId]="row.productPartialId"
                [decimals]="row.product?.extra.ar.antdec"
                [activeInB2B]="row?.product?.extra?.pim?.activeInB2B"
              >
              </otto-olsen-buy-btn>
              <span
                *ngIf="row.product?.originalSize && row.product?.extra.ar?.q_jis_kaltillegg_artnr && isInteger(row)"
                class="d-block text-center"
              >
                <i class="fas fa-exclamation-triangle text-danger"></i>
                <span>{{ 'FEE_ADDED_LOT_SIZE' | translate: { value: row.product?.originalSize } }}</span>
              </span>
            </div>
            <ng-template #priceRequest>
              <a
                href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ row.product.id }}"
                target="_blank"
                class="request-price-email"
              >
                <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
              </a>
            </ng-template>
            <div class="pl-1 d-flex align-items-center">
              {{ row.product.extra.ar?.enhetskod }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-3 col-xl-1 offset-sm-3 offset-lg-0">
        <div class="row pt-1">
          <div class="col-4 d-flex d-xl-none">
            <b translate>AVAILABILITY</b>
          </div>
          <div class="col-8 col-xl-12 px-0 text-center">
            <otto-olsen-availability
              [availability]="row.cartRow?.extra?._availability_reference"
              [unit]="row.product?.extra.ar?.enhetskod"
              [decimals]="row.product?.extra.ar.antdec"
            ></otto-olsen-availability>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0">
        <div class="row pt-1">
          <div class="col-4 d-flex d-xl-none">
            <b translate>PRICE</b>
          </div>
          <div class="col-8 col-xl-12 d-flex">
            <input
              type="number"
              class="form-control text-right px-1"
              [value]="parseFloatFixed(row.overridePrice, 2)"
              [placeholder]="row.originalPrice | number: '1.2-2'"
              (blur)="updateOverridePrice(row, parseFloatFixed($event.target.value, 2))"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-lg-3 col-xl-1 offset-sm-3 offset-lg-1 offset-xl-0">
        <div class="row pt-1">
          <div class="col-4 d-flex d-xl-none">
            <b translate>DISCOUNT_PERCENT</b>
          </div>
          <div class="col-8 col-xl-12 d-flex">
            <input
              type="number"
              class="form-control discount-control text-right px-1"
              [value]="
                parseFloatFixed(row.discountPercen, 2) ||
                parseFloatFixed(row.cartRowPriceElement.cartRowDiscountPercent, 2) ||
                0
              "
              (blur)="updateDiscountPercentage(row, $event.target.value)"
              placeholder="%"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-lg-3 col-xl-1 offset-sm-3 offset-lg-1 offset-xl-0">
        <div class="row pt-1">
          <div class="col-4 d-flex d-xl-none">
            <b translate>TOTAL</b>
          </div>
          <div class="col-8 col-xl-12 d-flex">
            <lib-price [price]="row.cartRowPriceElement" type="cartRowTotalPriceInclRowDiscount"></lib-price>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-lg-3 col-xl-3 offset-sm-3 offset-lg-0">
        <div class="row pt-1">
          <div class="col-12 col-lg-12 col-xl-6">
            <div class="row pt-1">
              <div class="col-4 d-flex d-xl-none">
                <b translate>DELIVERY</b>
              </div>
              <div class="col-8 col-xl-12 d-flex" *ngIf="!partDeliveriesForbidden">
                <lib-delivery-date-picker
                  class="request-delivery-date"
                  [label]="null"
                  [initialDate]="row.deliveryDate ? row.deliveryDate : formatNgbDate(row.minDate)"
                  (selectedDate)="updateDeliveryDateWithWarnings(row, $event, false)"
                  [minInitialDate]="row.minDate"
                  [deliveryMethod]="row.deliveryMethod"
                  [currentselectedDate]="row.deliveryDate"
                ></lib-delivery-date-picker>
              </div>
              <div class="col-8 col-xl-12 d-flex" *ngIf="partDeliveriesForbidden">
                <lib-delivery-date-picker
                  *ngIf="ottoCheckoutState.selectedDeliveryDate"
                  [initialDate]="ottoCheckoutState.selectedDeliveryDate"
                  [currentselectedDate]="selectedDeliveryDate"
                  [label]="null"
                  [disabled]="true"
                ></lib-delivery-date-picker>
              </div>
            </div>
          </div>

          <div
            class="d-none d-xl-block col-2 col-md-1 order-first order-xl-0 offset-11 offset-xl-0 d-xl-flex align-items-center col-delete-button"
          >
            <ng-container *ngTemplateOutlet="rowBtns; context: { row }"></ng-container>
          </div>
        </div>
      </div>

      <div
        class="d-xl-none col-2 col-md-1 order-first order-xl-0 offset-11 offset-xl-0 d-flex align-items-center col-delete-button"
      >
        <ng-container *ngTemplateOutlet="rowBtns; context: { row }"></ng-container>
      </div>
      <div class="col-12">
        <div class="d-block pt-2">
          <div
            *ngIf="row.cartRow.extra.warning && !warningWasActiveBefore[row.productId]"
            class="d-flex alert alert-danger p-1"
          >
            <span translate>DELIVERY_DATE_CHANGED_DUE_TO_STOCK</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row hr"></div>
  </span>
</div>
 -->
<ng-template #rowBtns let-row="row">
  <button
    *ngIf="enableDuplicateRow"
    type="button"
    class="btn btn-secondary btn-sm mr-1"
    (click)="duplicateProduct(row, index)"
  >
    <i class="fas fa-copy fa-2x"></i>
  </button>
  <button class="btn btn-light delete-button" (click)="removeRow(row)">
    <i class="fas fa-trash-alt fa-2x"></i>
  </button>
  <button *ngIf="favouriteIcon" class="btn btn-light delete-button ml-2" (click)="favouriteIcon.toggle()">
    <i class="fa-star fa-2x" [ngClass]="favouriteIcon.isFavourite ? 'fas' : 'far'"></i>
  </button>
  <lib-favourite-product-icon #favouriteIcon [id]="row.product.id" class="d-none"></lib-favourite-product-icon>
</ng-template>
