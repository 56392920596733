import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenDashboardDataService {
  private messageSource = new BehaviorSubject(null);
  private productCategoryMessageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();
  currentProductCategoryMessage = this.productCategoryMessageSource.asObservable();

  constructor() {
    this.messageSource.next(null);
    this.productCategoryMessageSource.next(null);
  }

  public selectedSalesPerson: string;
  public selectedProductCategory: string;

  setFilter(message: any) {
    this.messageSource.next(message);
  }

  setProductCategory(message: any) {
    this.productCategoryMessageSource.next(message);
  }
}
