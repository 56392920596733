import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeliveryLocationsJeevesComponent, DeliveryLocationsService } from 'gung-standard-jeeves';
import {
  AuthService,
  Availability,
  AvailabilityService,
  CalendarDate,
  CartRow,
  CartService,
  Customer,
  DeliveryDateService,
  MetadataService,
  SelectedCustomerService,
  TableRecord
} from 'gung-standard';
import { forkJoin, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs';
import { OttoCheckoutStateServiceService } from '../../../../services/utils/otto-checkout-state-service.service';
import { parse } from 'date-fns';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-otto-olsen-delivery-locations-jeeves',
  templateUrl: './otto-olsen-delivery-locations-jeeves.component.html',
  styleUrls: ['./otto-olsen-delivery-locations-jeeves.component.scss']
})
export class OttoOlsenDeliveryLocationsJeevesComponent extends DeliveryLocationsJeevesComponent implements OnInit {
  public formOh: FormGroup;
  public formProcargs: FormGroup;
  private userEmail;
  public enableEditDeliveryAddress = false;
  dates: CalendarDate[];
  cartRows: CartRow[];
  pushDeliveryDate = false;
  minDate;
  partDeliveryEntries: { id: string; name: string }[] = [];

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected deliveryLocationsService: DeliveryLocationsService,
    protected formBuilder: FormBuilder,
    protected authService: AuthService,
    protected availabilityService: AvailabilityService,
    protected ottoCheckoutState: OttoCheckoutStateServiceService,
    protected deliveryDateService: DeliveryDateService,
    protected cartService: CartService,
    protected metadataService: MetadataService,
    protected datePipe: DatePipe
  ) {
    super(selectedCustomerService, deliveryLocationsService, formBuilder, authService);
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        mergeMap(selectedCustomer =>
          forkJoin([
            of(selectedCustomer),
            this.deliveryLocationsService.getDeliveryLocationsForCustomer(selectedCustomer.id),
            this.authService.getCurrentUser().pipe(first())
          ])
        )
      )
      .subscribe(([selectedCustomer, deliveryLocations, user]) => {
        this.selectedCustomer = selectedCustomer;
        this.userEmail = user.email;
        this.initForm();
        this.deliveryLocations = [...deliveryLocations];

        if (this.selectedCustomer.extra.kus.ordlevplats1) {
          const deliveryLocation = this.deliveryLocations.find(location => {
            return location.extra.lp.ordlevplats1 === this.selectedCustomer.extra.kus.ordlevplats1;
          });
          this.deliveryLocationId = deliveryLocation.id;
        } else {
          this.deliveryLocations.unshift({
            id: this.selectedCustomer.id,
            name: this.selectedCustomer.name,
            extra: this.selectedCustomer.extra,
            timestamp: new Date()
          });
          this.deliveryLocationId = this.deliveryLocations[0].id;
        }
      });

    this.cartService.getCurrentCart().subscribe(cartRows => {
      this.cartRows = cartRows;
    });
  }

  protected enabledisableDeliveryAddress(value): void {
    this.enableEditDeliveryAddress = value;

    if (!value) {
      this.afterDeliveryLocationSet(this.deliveryLocationId);
    }
  }

  protected handleNextButtonClicked(): void {
    // console.log(this.form.invalid);
    if (this.form.invalid || this.formOh.invalid || (this.postalCode + '').length > 4) {
      this.form.markAllAsTouched();
      this.formOh.markAllAsTouched();
      return;
    }

    this.assignPostalCodeAndCity(this.postalCode, this.city);
    this.assignFormValues2(this.form);
    this.stepDone.emit(this.checkout);
  }

  private assignFormValues2(form: FormGroup) {
    const rawFormData = form.getRawValue();
    const rawformOhData = this.formOh.getRawValue();
    const rawFormProcargs = this.formProcargs.getRawValue();

    this.checkout.extra.procargs = {
      ...this.checkout.extra.procargs,
      ordlevadr3: form.get('ordlevadr1').value,
      ordlevadr4: this.postalCode,
      ordlevadrbstort: this.city,
      ...rawFormData
    };

    this.checkout.extra.oh = {
      ...this.checkout.extra.oh,
      ordlevadr3: form.get('ordlevadr1').value,
      ordlevadr4: this.postalCode,
      ordlevadrbstort: this.city,
      ...rawFormData
    };
    // console.log(this.checkout.extra.oh.ordberlevdat);

    if (rawformOhData.edit && (rawformOhData.edit as string).trim().length > 0) {
      if (!this.checkout.extra.confirmationEmails) {
        this.checkout.extra.confirmationEmails = [];
      }
      // this.checkout.extra.confirmationEmails.push('tyloproshop.se@tylohelo.com');
    }

    const assignFirstEmail =
      this.checkout.extra.confirmationEmails?.length > 0 ? this.checkout.extra.confirmationEmails[0] : undefined;

    this.checkout.extra.oh = {
      ...this.checkout.extra.oh,
      ...rawformOhData,
      godsmarke3: rawformOhData.ta_mailnotified,
      editext: rawformOhData.edit,
      q_oarepl_email_new_oa: assignFirstEmail
    };

    this.checkout.extra.procargs = {
      ...this.checkout.extra.procargs,
      ...rawFormProcargs
    };
  }

  protected initForm() {
    super.initForm();
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        this.formOh = this.formBuilder.group({
          // godsmarke3 : this.formBuilder.control(this.checkout.extra.oh.godsmarke3 || user.email || ''),
          godsmarke4: this.formBuilder.control(this.checkout.extra.oh.godsmarke4 || user.phone || ''),
          kundref2: this.formBuilder.control(this.checkout.extra.oh.kundref2 || user.name || ''),
          kundbestnr: this.formBuilder.control(this.checkout.extra.oh.kundbestnr || '', [Validators.required]),
          edit: this.formBuilder.control(this.checkout.extra.oh.edit || ''),
          ta_mailnotified: this.formBuilder.control(this.checkout.extra.oh.ta_mailnotified || this.userEmail || ''),
          dellevtillaten: this.formBuilder.control(this.checkout.extra.oh.dellevtillaten || '0')
        });
      });

    this.formProcargs = this.formBuilder.group({
      levsattkod: this.formBuilder.control(this.checkout.extra.procargs.levsattkod || '', [Validators.required]),
      levvillkkod: this.formBuilder.control('' + this.checkout.extra.procargs.levvillkkod || '')
    });
  }

  optionsFilterDeliveryMethod(customer: Customer, option: { key: string; value: string | TableRecord }): boolean {
    return option.key === '2' || option.key === '4';
  }

  onDeliveryMethodChanged(event) {
    this.checkout.extra.procargs.levsattkod = event || this.selectedCustomer.extra.kus.levsattkod;
    if (event === '' + this.selectedCustomer.extra.kus.levsattkod) {
      this.formProcargs.get('levvillkkod').setValue(this.selectedCustomer.extra.kus.levvillkkod);
      // this.noFreight = false;
    } else {
      this.formProcargs.get('levvillkkod').setValue('0');
      // this.noFreight = false;
    }
    // this.deliveryMethod22 = event === '22';
    if (event === '22') {
      // this.noFreight = true;
    }

    this.ottoCheckoutState.selectedDeliveryMethod = event;

    this.initDeliveryDatesBasedOnDeliveryMethod();
  }

  public initDeliveryDatesBasedOnDeliveryMethod() {
    forkJoin([
      this.availabilityService
        .getAvailabilities(
          this.cartRows.map(r => r.productId),
          undefined,
          true
        )
        .pipe(first()),
      this.deliveryDateService.getDeliveryDates(this.formProcargs.get('levsattkod').value).pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.authService.getRoles().pipe(first())
    ]).subscribe(([avs, dates, customer, roles]) => {
      // this.currentCustomer = customer;
      this.dates = dates;

      // this.isUser = (roles.length === 1 && roles.includes('USER'));
      // Calculate delivery date when delivery method changes
      this.checkPushDeliveryDate(this.formProcargs.get('levsattkod').value);
      this.formProcargs.get('levsattkod').valueChanges.subscribe(value => {
        this.checkPushDeliveryDate(value);
        this.handlePartDeliveryChange();
      });
      if (this.checkout.extra.oh.dellevtillaten !== '10') {
        this.assignRowDates();
      }

      avs = avs.map(av => ({ ...av, availabilities: av.extra.availabilities }));
      const nowDate = new Date();
      let minDate = nowDate;
      for (const row of this.cartRows) {
        const av = avs.find(a => a.productId === row.productId);
        if (av) {
          const tempDate = getMinDateSuper(av);
          if (minDate < tempDate) {
            minDate = tempDate;
          }
        }
        // if (row.extra.orp.ordberlevdat) {
        //   const tempDate = new Date(row.extra.orp.ordberlevdat);
        //   if (minDate < tempDate) {
        //     minDate = tempDate;
        //   }
        // }
      }
      // If now date is today and q_jis_cutoff_hour of delivery method is already past
      // if (this.pushDeliveryDate && nowDate.getTime() === minDate.getTime()) {
      //   minDate.setDate(minDate.getDate() + 1);
      //   minDate = this.setMinDate();
      // }
      // this.minDate = minDate;
      // this.loadingComponent = false;
    });
  }

  checkPushDeliveryDate(levsattkod: string) {
    const cutoffHour = this.metadataService.getMetadataValue('x2f', 'q_jis_cutoff_hour', levsattkod);
    if (cutoffHour && new Date().getHours() >= Number(cutoffHour)) {
      this.pushDeliveryDate = true;
    }
  }

  handlePartDeliveryChange(): void {
    // this.loadingComponent = true;
    const partDeliveryFormControlName = 'dellevtillaten';
    const selectedPArtDeliveryValue = this.formOh.controls[partDeliveryFormControlName].value || '';
    this.checkout.extra.oh.dellevtillaten = selectedPArtDeliveryValue;
    if (this.checkout.extra.oh.dellevtillaten === '0') {
      this.ottoCheckoutState.partDeliveriesForbidden = false;
      delete this.checkout.extra.oh.ordberlevdat;
      this.assignRowDates();
    } else {
      this.ottoCheckoutState.partDeliveriesForbidden = true;
      delete this.minDate;
      this.availabilityService
        .getAvailabilities(
          this.cartRows.map(r => r.productId),
          undefined,
          true
        )
        .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
        .subscribe(avs => {
          const nowDate = new Date();
          let minDateFromAvailability = nowDate;

          //    console.log("this.checkout.rows",this.checkout.rows);
          // console.log("this.cartRows",this.cartRows);
          // let maxAssignedDate = '1970-01-01';
          for (const row of this.cartRows) {
            const av = avs.find(a => a.productId === row.productId);
            // console.log("row",row);
            // console.log("av",av);
            if (av) {
              const tempDate = getMinDate(av, row.qty);
              if (minDateFromAvailability < tempDate) {
                minDateFromAvailability = tempDate;
              }
            }
            // if (row.extra._user_selected_ordberlevdat && row.extra._user_selected_ordberlevdat > maxAssignedDate) {
            //   maxAssignedDate = row.extra._user_selected_ordberlevdat;
            // }
          }

          // console.log("minDateFromAvailability",minDateFromAvailability);
          // If now date is today and q_jis_cutoff_hour of delivery method is already past
          if (this.pushDeliveryDate && nowDate.getTime() === minDateFromAvailability.getTime()) {
            minDateFromAvailability.setDate(minDateFromAvailability.getDate() + 1);
            minDateFromAvailability = this.setMinDate();
          }
          this.minDate = minDateFromAvailability;

          for (const testDate of this.dates) {
            if (testDate.date >= this.minDate) {
              if (testDate.valid) {
                this.minDate = testDate.date;
                break;
              }
            }
          }

          const dString: string = this.datePipe.transform(this.minDate, 'yyyy-MM-dd');

          this.checkout.extra.oh.ordberlevdat = dString;

          for (const row of this.cartRows) {
            this.cartService.removeExtraField(
              row.productId,
              'orp.ordberlevdat',
              row.targetStockId,
              row.productPartialId
            );
          }
          // if (dString > maxAssignedDate) {
          //   this.checkout.extra.oh.ordberlevdat = dString;
          // } else {
          //   this.checkout.extra.oh.ordberlevdat = maxAssignedDate;
          //   this.minDate = parse(maxAssignedDate, 'yyyy-MM-dd', this.minDate);
          // }
          this.createFullDeliveryPlan();
          // this.loadingComponent = false;
        });
    }
  }

  createFullDeliveryPlan() {
    for (const row of this.checkout.rows) {
      const deliveryPlan = {};
      deliveryPlan[this.checkout.extra.oh.ordberlevdat] = row.quantity;
      // This is important because used by backend to split lines
      row.extra._deliveryPlan = deliveryPlan;
    }
  }

  setMinDate(): Date {
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() + 1);
    const firstDateAvailable = this.deliveryDateService.findFirstAvailableDate(this.dates, nowDate);
    this.minDate = firstDateAvailable.date;
    return firstDateAvailable.date;
  }

  private initPartialDeliveries(): void {
    this.partDeliveryEntries = [
      { id: '0', name: 'OH_DELLEVTILLATEN_0' },
      { id: '10', name: 'OH_DELLEVTILLATEN_10' }
    ];
  }

  assignRowDates(): void {
    this.availabilityService
      .getAvailabilities(
        this.cartRows.map(r => r.productId),
        undefined,
        true
      )
      .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
      .subscribe(avs => {
        for (const row of this.cartRows) {
          const av = avs.find(a => a.productId === row.productId);
          row.extra._availability_reference = av;
          let mindate = null;
          if (av) {
            const tempDate = getMinDate(av, row.qty);
            const nowDate = new Date();
            let minDate = nowDate;
            if (minDate < tempDate) {
              minDate = tempDate;
            }

            for (const testDate of this.dates) {
              if (testDate.date >= minDate) {
                if (testDate.valid) {
                  minDate = testDate.date;
                  break;
                }
              }
            }
            // If now date is today and q_jis_cutoff_hour of delivery method is already past
            if (this.pushDeliveryDate && nowDate.getTime() === minDate.getTime()) {
              minDate.setDate(minDate.getDate() + 1);
              minDate = this.setMinDate();
            }

            const dString: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
            mindate = dString;
          }

          row.extra._calc_min_date = mindate;
          row.extra.orp = row.extra.orp || {};
          row.extra.orp.ordberlevdat = row.extra._calc_min_date;
          if (row.extra._user_selected_ordberlevdat) {
            if (row.extra._user_selected_ordberlevdat > row.extra._calc_min_date) {
              row.extra.orp.ordberlevdat = row.extra._user_selected_ordberlevdat;
            }
          }

          const deliveryPlan = {};
          const dates = Object.keys(av.availabilities);
          let quantityRemaining = row.qty;
          for (const date of dates) {
            const qty = av.availabilities[date];
            if (qty > 0 && quantityRemaining > 0) {
              const parsedDate = parse(date, 'yyMMdd', new Date());
              const formattedDate = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');

              let assignedDate = formattedDate;
              if (row.extra._user_selected_ordberlevdat > assignedDate) {
                assignedDate = row.extra._user_selected_ordberlevdat;
              }
              const currentOnDate = deliveryPlan[assignedDate] || 0;
              const currentAllocation = Math.min(qty, quantityRemaining);

              deliveryPlan[assignedDate] = currentOnDate + currentAllocation;

              quantityRemaining = quantityRemaining - currentAllocation;
            }
          }
          if (quantityRemaining > 0) {
            // Handle edge case of last quantities of expiring product
            deliveryPlan['2099-12-31'] = quantityRemaining;
          }
          row.extra._deliveryPlan = deliveryPlan;
        }
        // this.loadingComponent = false;
      });
  }

  protected afterDeliveryLocationSet(deliveryLocationId: string) {
    const newDeliveryLocation = this.deliveryLocations.find(location => location.id === deliveryLocationId);
    if (!newDeliveryLocation) {
      console.error(new Error(`Could not find delivery location: ${deliveryLocationId}`));
      return;
    }
    this.selectedDeliveryLocation = newDeliveryLocation;

    if (this.selectedDeliveryLocation.extra.fr.ftgpostadr5) {
      this.checkout.extra.procargs = this.checkout.extra.procargs || {};
      this.checkout.extra.procargs.ordlevadr1 = this.selectedDeliveryLocation.extra.fr.ftgnamn;
      this.checkout.extra.procargs.ordlevadr2 = this.selectedDeliveryLocation.extra.fr.ordlevadr5;
      this.checkout.extra.procargs.ordlevadr3 = this.selectedDeliveryLocation.extra.fr.ftgpostadr5;
      this.checkout.extra.procargs.ordlevadr4 = this.selectedDeliveryLocation.extra.fr.ftgpostlevadr3;
      this.checkout.extra.procargs.ordlevadrlandskod = this.selectedDeliveryLocation.extra.fr.landskod;
      this.checkout.extra.procargs.ordlevadrprovincecode = this.selectedDeliveryLocation.extra.fr.provincecode;
    } else {
      this.checkout.extra.procargs = this.checkout.extra.procargs || {};
      this.checkout.extra.procargs.ordlevadr1 = this.selectedDeliveryLocation.extra.fr.ftgnamn;
      this.checkout.extra.procargs.ordlevadr2 = this.selectedDeliveryLocation.extra.fr.ftgpostadr1;
      this.checkout.extra.procargs.ordlevadr3 = this.selectedDeliveryLocation.extra.fr.ftgpostadr2;
      this.checkout.extra.procargs.ordlevadrlandskod = this.selectedDeliveryLocation.extra.fr.landskod;
      this.checkout.extra.procargs.ordlevadrprovincecode = this.selectedDeliveryLocation.extra.fr.provincecode;
    }

    this.postalCode = this.selectedDeliveryLocation.extra.fr.ftglevpostnr;
    this.city =
      this.selectedDeliveryLocation.extra.fr.ftgpostlevadr3 || this.selectedDeliveryLocation.extra.fr.ftgpostadr3;

    this.assignPostalCodeAndCity(this.postalCode, this.city);

    const procargsKeys = Object.keys(this.form.getRawValue());

    const valueMap = {};
    procargsKeys.forEach(key => {
      valueMap[key] = this.checkout.extra.procargs[key] || '';
    });

    this.form.setValue(valueMap);
  }
}

const getMinDateSuper = (av: Availability): Date => {
  if (av.currentAvailability > 0) {
    return new Date();
  }
  if (av.maxFutureStock === 0) {
    return new Date();
  }
  const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] > 0);
  const dateFormat = 'yyMMdd';
  return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
};

const getMinDate = (av: Availability, quantity: number): Date => {
  if (av.currentAvailability > 0) {
    return new Date();
  }
  if (av.maxFutureStock === 0) {
    return new Date();
  }
  const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] >= quantity);
  const dateFormat = 'yyMMdd';
  return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
};


