import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, ConceptTreeListViewComponent } from 'gung-standard';
import { Subject, first, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OttoOlsenPimEditConfigService } from '../../services/otto-olsen-pim-edit-config.service';

@Component({
  selector: 'otto-olsen-concept-tree-list-view',
  templateUrl: './otto-olsen-concept-tree-list-view.component.html',
  styleUrls: ['./otto-olsen-concept-tree-list-view.component.scss']
})
export class OttoOlsenConceptTreeListViewComponent extends ConceptTreeListViewComponent implements OnInit, OnDestroy {
  public isUser = false;
  conceptPimUrl: string = environment.externalPimUrl + 'pim-item-concept/';
  isAssortmentManager: boolean = false;
  isProductManager: boolean = false;
  pimEditUrlHide: boolean = false;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected authService: AuthService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService
  ) {
    super();
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(
        roles =>
          (this.isUser = roles.indexOf('USER') > -1 && roles.indexOf('SALES') === -1 && roles.indexOf('ADMIN') === -1)
      );
  }

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(userRoles => {
      this.isAssortmentManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'assortments');
      this.isProductManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'products-concepts');
    });
    this.ottoOlsenPimEditConfigService.pimEditUrlHide.pipe(takeUntil(this.unsubscribe)).subscribe(pimUrlHide => (this.pimEditUrlHide = pimUrlHide));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
