import { Injectable, Type } from '@angular/core';
import { BarcodeScannerComponent, BaseViewConfigService, PriceService, ProductsInCartComponent, ScannerComponent } from 'gung-standard';
import { JeevesBaseViewConfigService } from 'gung-standard-jeeves';
import { OttoOlsenBarcodeScannerComponent } from '../components/otto-olsen-barcode-scanner/otto-olsen-barcode-scanner.component';
import { OttoOlsenProductsInCartComponent } from '../components/otto-olsen-barcode-scanner/otto-olsen-products-in-cart/otto-olsen-products-in-cart.component';
import { OttoOlsenScannerComponent } from '../components/otto-olsen-barcode-scanner/otto-olsen-scanner/otto-olsen-scanner.component';
import { OttoOlsenFreeScannerComponent } from '../components/otto-olsen-barcode-scanner/otto-olsen-free-scanner/otto-olsen-free-scanner.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenBaseViewConfigService extends JeevesBaseViewConfigService {
  constructor(protected priceServiceInput: PriceService) {
    super(priceServiceInput);
  }

  getProductsInCartComponent(): Type<ProductsInCartComponent> {
    return OttoOlsenProductsInCartComponent;
  }

  getBarcodeScannerComponent(): Type<BarcodeScannerComponent> {
    return OttoOlsenBarcodeScannerComponent;
  }

  getScannerComponent(): Type<ScannerComponent | any> {
    return OttoOlsenScannerComponent;
  }

  getFreeScannerComponent(): Type<OttoOlsenFreeScannerComponent> {
    return OttoOlsenFreeScannerComponent;
  }
}
