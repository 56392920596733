import { Injectable } from '@angular/core';
import { DeliveryLocationListConfigService } from 'gung-standard';
import { JeevesDeliveryLocationListConfigService } from 'gung-standard-jeeves';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenDeliveryLocationListConfigService extends JeevesDeliveryLocationListConfigService {

  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }

}
