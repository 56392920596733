import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductInlineSearchCardComponent, ProductInlineSearchListConfigService } from 'gung-standard';

interface ProductExtended extends Product {
  _path: string[];
}

@Component({
  selector: 'otto-olsen-product-inline-search-card',
  templateUrl: './otto-olsen-product-inline-search-card.component.html',
  styleUrls: ['./otto-olsen-product-inline-search-card.component.scss']
})
export class OttoOlsenProductInlineSearchCardComponent extends ProductInlineSearchCardComponent {
  data: ProductExtended;
}
