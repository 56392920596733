<div class="row" *ngIf="!loadingComponent && minDate; else loading">
  <div class="col-12 col-md-4">
    <div [formGroup]="form">
      <lib-meta-select-input
        formControlName="levsattkod"
        table="x2f"
        metaId="levsattkod"
        metaDisplay="levsattbeskr"
        [optionsFilterFunction]="optionsFilterDeliveryMethod.bind(this, currentCustomer)"
        (ngModelChange)="onDeliveryMethodChanged($event)"
      >
      </lib-meta-select-input>
      <div
        *ngIf="form.get('levsattkod').invalid && (form.get('levsattkod').dirty || form.get('levsattkod').touched)"
        class="alert alert-danger"
      >
        <span translate>FORM_ERROR_REQUIRED</span>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!isUser">
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="betkod" table="x2" metaId="betkod" metaDisplay="betvillbeskr">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!isUser">
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="saljare" table="salj" metaId="saljare" metaDisplay="saljarenamn">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!checkout.extra.isExportCustomer">
    <fieldset [disabled]="isUser || undefined">
      <div [formGroup]="form">
        <lib-meta-select-input
          formControlName="levvillkkod"
          table="x2e"
          metaId="levvillkkod"
          metaDisplay="levvillkbeskr"
        >
        </lib-meta-select-input>
      </div>
    </fieldset>
  </div>
  <div class="col-12 col-md-4">
    <div class="form-group" [formGroup]="form">
      <label for="partDelivery" class="control-label" translate>PART_DELIVERY</label>
      <select
        name="partDelivery"
        id="partDelivery"
        class="form-control"
        formControlName="dellevtillaten"
        (change)="handlePartDeliveryChange()"
      >
        <option [value]="entry.id" *ngFor="let entry of partDeliveryEntries">
          {{ entry.name | translate }}
        </option>
      </select>
    </div>
  </div>
  <div *ngIf="checkout.extra.oh.dellevtillaten == '10'" class="col-12 col-md-4">
    <div>
      <lib-delivery-date-picker
        *ngIf="minDate"
        (selectedDate)="dateSelected($event)"
        [initialDate]="checkout.extra.oh.ordberlevdat"
        [deliveryMethod]="checkout.extra.procargs.levsattkod"
        [minInitialDate]="minDate"
      ></lib-delivery-date-picker>
    </div>
    <div *ngIf="form.get('dellevtillaten').value === '0'" translate>PART_DELIVERY_MESSAGE</div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!isUser">
    <div class="form-group" [formGroup]="form">
      <label for="ordstat " class="control-label" translate>ORDER_TYPE</label>
      <select name="ordstat " id="ordstat " class="form-control" formControlName="ordstat">
        <option [value]="0" translate><span></span>OTTO_OLSEN_QUOTATION</option>
        <option [value]="13" translate>OTTO_OLSEN_ORDER</option>
      </select>
    </div>
  </div>
  <div *ngIf="isFreight && !noFreight" class="col-12 mt-2">
    <div class="alert alert-warning text-center" role="alert">
      <span translate>OTTO_FREIGHT_COST</span>
    </div>
  </div>
  <div *ngIf="deliveryMethod22" class="col-12 mt-2">
    <div class="alert alert-warning text-center" role="alert">
      <span translate>OTTO_OLSEN_PICK_TWO_HOURS_CONFIRMATION</span>
    </div>
  </div>
  <div class="col-12 mt-2">
    <div class="alert alert-danger text-center" role="alert">
      <span translate>OTTO_OLSEN_DELIVERYINFO</span>
    </div>
  </div>
  <div class="col-12" *ngIf="!isUser">
    <lib-filter-list #cartList [configService]="salesCartConfigService"></lib-filter-list>
  </div>
  <div class="col-12" *ngIf="isUser">
    <lib-filter-list #cartList [configService]="cartConfigService"></lib-filter-list>
  </div>
</div>

<ng-template #loading translate> LOADING </ng-template>
