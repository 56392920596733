import { Inject, Injectable, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { gungComparatorHelper } from 'gung-common';
import { PimTemplateProperties } from 'gung-list';
import { ColumnSortSelectionService, MetadataService, gungGetPropertyOfProduct } from 'gung-standard';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenColumnSortSelectionService extends ColumnSortSelectionService {
  constructor(
    @Optional() @Inject('environment') protected environment,
    @Optional()
    public metadataService: MetadataService,
    @Optional()
    protected translateService: TranslateService
  ) {
    super(environment, metadataService, translateService);
  }

  public sortItemsByColumn(data: any[], headerKey: PimTemplateProperties): any[] {
    const ascSort = !headerKey.sort || headerKey.sort === 'asc' ? -1 : 1;
    const metadata = headerKey.type === 'metadata';
    return data.sort((a, b) => {
      const aValue2 = metadata ? gungGetPropertyOfProduct(headerKey, a, this.currentLang, this.metadataService) : this.getValue(headerKey.path, a);
      const bValue2 = metadata ? gungGetPropertyOfProduct(headerKey, b, this.currentLang, this.metadataService) : this.getValue(headerKey.path, b);
      if (this.isInch(aValue2) || this.isInch(bValue2)) {
        // https://trello.com/c/OZbIXsYl/1057-incorrect-sorting-of-metadata-filter-in-product-view
        // Special case: value are something like '2 1/2"' vs '5/8"' vs '5/16"' vs '4"'
        let aValue3 = aValue2;
        let bValue3 = bValue2;
        if (aValue2) {
          aValue3 = this.getInchStringToNumber(aValue2);
        }
        if (bValue2) {
          bValue3 = this.getInchStringToNumber(bValue2);
        }
        return gungComparatorHelper(aValue3, bValue3, ascSort);
      }
      return gungComparatorHelper(aValue2, bValue2, ascSort);
    });
  }

  isInch(value: string) {
    if (value.toString() && value.toString().includes('"') && !value.toString().includes('G')) {
      return true;
    }
    return false;
  }

  getInchStringToNumber(value: string): number {
    let numberInch = Number(value);
    let rest: string;
    if (value.includes(' ')) {
      const valueSplit = value.split(' ');
      numberInch = Number(valueSplit[0]);
      rest = valueSplit[1];
      value = valueSplit[0];
    }
    if (value?.includes('"')) {
      value = value.replace('"', '');
      if (value?.includes('/')) {
        numberInch = this.evaluateFraction(value);
      } else {
        numberInch = Number(value);
      }
    }
    if (rest?.includes('"')) {
      rest = rest.replace('"', '');
      if (rest?.includes('/')) {
        numberInch += this.evaluateFraction(rest);
      } else {
        numberInch += Number(rest);
      }
    }
    if (isNaN(numberInch)) {
      return 0;
    }
    return numberInch;
  }

  evaluateFraction(fraction: string): number {
    const [numerator, denominator] = fraction.split('/').map(Number);
    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
      return NaN;
    }
    return numerator / denominator;
  }
}
