import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductFastsearchCardComponent } from 'gung-standard';

interface ProductExtended extends Product {
  _path: string[];
}

@Component({
  selector: 'otto-olsen-product-fastsearch-card',
  templateUrl: './otto-olsen-product-fastsearch-card.component.html',
  styleUrls: ['./otto-olsen-product-fastsearch-card.component.scss']
})
export class OttoOlsenProductFastsearchCardComponent extends ProductFastsearchCardComponent {
  data: ProductExtended;

  constructor(protected router: Router) {
    super();
  }
}
