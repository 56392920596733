import { EventEmitter, Injectable } from '@angular/core';
import { ActionButton } from 'gung-common';
import {
  EdiMessageBase,
  EdiOrder,
  EdiOrderActionConfigService,
  GungEdiMessageSendStatus,
  GungEdimessageType
} from 'gung-standard';
import { Observable, first, switchMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenEdiOrderActionConfigService extends EdiOrderActionConfigService {
  getButtons(item: EdiOrder, eventEmitter: EventEmitter<EdiOrder>): Observable<ActionButton<EdiOrder>[]> {
    return this.authService.getRoles().pipe(
      first(),
      switchMap(roles => {
        const isActuator = roles.indexOf('ACTUATOR') !== -1;
        const isAdmin = roles.indexOf('ADMIN') !== -1;

        const baseActions: ActionButton<EdiOrder>[] = [];
        if (item.gungEdiMessageSendStatus !== 'Sent' || isActuator || isAdmin) {
          baseActions.push({
            title: 'REVALIDATE',
            action: (ediOrder: EdiOrder) => this.validateMessage(ediOrder, event, eventEmitter)
          });
        }
        if (isAdmin) {
          baseActions.push({
            title: 'DOWNLOAD_MESSAGE',
            action: (ediMessage: EdiOrder) => this.downloadMessage(ediMessage, event)
          });
          if (item.gungEdiMessageSendStatus === 'ReadyToSend') {
            baseActions.push({
              title: 'SEND_TO_ERP',
              action: (ediOrder: EdiOrder) => this.postOrderMessageToErp(ediOrder, event, eventEmitter)
            });
          }
        }
        if (isActuator || isAdmin) {
          baseActions.push({
            title: 'ARCHIVE_MESSAGE',
            action: (ediMessage: EdiMessageBase) => this.archiveMessage(ediMessage, event, eventEmitter)
          });
        }
        if (item.gungEdiMessageSendStatus !== 'Sent' && item.gungEdiMessageSendStatus !== 'OnHold') {
          baseActions.push({
            title: 'SET_STATUS_ON_HOLD',
            action: (ediOrder: EdiOrder) =>
              this.ediService
                .setMessageStatus(item.id, GungEdimessageType.ORDER, GungEdiMessageSendStatus.OnHold)
                .subscribe(res => {
                  this.ediService.getMessageById(ediOrder.id, this.getMessageType()).subscribe(updatedMessage => {
                    eventEmitter.emit(updatedMessage);
                  });
                })
          });
        }
        if (item.gungEdiMessageSendStatus !== 'Sent' && item.gungEdiMessageSendStatus !== 'ReadyToSend') {
          baseActions.push({
            title: 'SET_STATUS_READY_TO_SEND',
            action: (ediOrder: EdiOrder) =>
              this.ediService
                .setMessageStatus(item.id, GungEdimessageType.ORDER, GungEdiMessageSendStatus.ReadyToSend)
                .subscribe(res => {
                  this.ediService.getMessageById(ediOrder.id, this.getMessageType()).subscribe(updatedMessage => {
                    eventEmitter.emit(updatedMessage);
                  });
                })
          });
        }

        if (item.ediChannel === 'EDI_EMAIL_SCANNER') {
          baseActions.push({
            title: 'DOWNLOAD_ORIGINAL_PDF',
            action: (ediOrder: EdiOrder) => this.downloadEmailScannerInput(ediOrder, event)
          });
        }

        return of(baseActions);
      })
    );
  }
}
