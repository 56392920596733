import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, OrderConfirmationComponent, OrderContextService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-order-confirmation',
  templateUrl: './otto-olsen-order-confirmation.component.html',
  styleUrls: ['./otto-olsen-order-confirmation.component.scss']
})
export class OttoOlsenOrderConfirmationComponent extends OrderConfirmationComponent implements OnInit {
  showScannerMenu = false;

  constructor(
    protected orderContextService: OrderContextService, 
    protected router: ActivatedRoute,
    protected authService: AuthService
  ){
    super(orderContextService, router);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authService
    .getHasRoles('SCANNER')
    .pipe()
    .subscribe(isScannerOnly => {
      this.showScannerMenu = isScannerOnly;
      
    });
  }

}
