import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  CartRow,
  CartService,
  CheckoutStepComponent,
  PriceService,
  SelectedCustomerService
} from 'gung-standard';
import {
  OttoOlsenFreightCostService,
  OttoOlsenFreightCondition
} from '../../../../services/otto-olsen-freight-cost.service';
import { forkJoin, of, Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs';

@Component({
  selector: 'otto-olsen-freight-cost',
  templateUrl: './otto-olsen-freight-cost.component.html',
  styleUrls: ['./otto-olsen-freight-cost.component.scss']
})
export class OttoOlsenFreightCostComponent extends CheckoutStepComponent implements OnInit {
  freightCostProductId = this.freightCostService.freightCostProductId;

  constructor(
    private cartService: CartService,
    private freightCostService: OttoOlsenFreightCostService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.checkout.extra.applyFreight) {
      forkJoin([
        this.onNextBtnClicked.pipe(first()),
        this.authService.getCurrentUser().pipe(first()),
        this.freightCostService.calculateFreight().pipe(first())
      ]).subscribe(([_, user, freightConfition]) => {
        if (freightConfition.cartRowsCount > 0) {
          const stockId = (user && user.managedMultistockIds[0]) || null;
          this.addFreightArticle(
            freightConfition.totalValue,
            freightConfition.currency,
            freightConfition.cartRowsCount,
            stockId
          );
        }
        this.stepDone.emit(this.checkout);
      });
    } else {
      forkJoin([this.onNextBtnClicked.pipe(first()), this.cartService.getCurrentCart().pipe(first())]).subscribe(
        ([_, cart]) => {
          const freightRow = cart.find(c => c.productId === this.freightCostProductId);
          if (freightRow) {
            this.cartService.removeRow(freightRow);
          }
          this.stepDone.emit(this.checkout);
        }
      );
    }
  }

  public isVisible() {
    return false;
  }

  addFreightArticle(totalValue: number, currency: string, rowsCount: number, stockId: string) {
    switch (currency) {
      case 'SEK':
      case 'EUR':
      case 'USD':
      case 'GBP':
      default:
        if (rowsCount > 0 && totalValue < 500) {
          // add it to extra in checkout to use it in summarize step
          this.cartService.addToCart(this.freightCostProductId, 1, stockId);
          this.cartService.setExtra(
            this.freightCostProductId,
            {
              _calculateFreight: true
            },
            stockId
          );
        }
        break;
    }
  }
}
