import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AssortmentService, getExtraImage, AssortmentRecursive } from 'gung-standard';
import { filter, first, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isBefore } from 'date-fns';

interface TemplateElement {
  link: {
    enabled: boolean;
    internal: boolean;
    url: string;
    text?: string;
  };
  image: {
    enabled: boolean;
    sourceUrl: string;
  };
  text: {
    enabled: boolean;
    text: string;
  };
  textSecondary: {
    enabled: boolean;
    text: string;
  };
}

interface AssortmentRecursiveExtended extends AssortmentRecursive {
  description?: string;
}

@Component({
  selector: 'otto-olsen-home',
  templateUrl: './otto-olsen-home.component.html',
  styleUrls: ['./otto-olsen-home.component.scss']
})
export class OttoOlsenHomeComponent implements OnInit {
  slider: TemplateElement[] = [];

  defaultSlider = {
    link: {
      enabled: false,
      internal: false,
      url: ''
    },
    image: {
      enabled: true,
      sourceUrl: './assets/frontpage.jpg'
    },
    text: {
      enabled: true,
      text: 'Med 60 000 lagerførte produkter og egen produksjon finner vi alltid en løsning'
    },
    textSecondary: {
      enabled: false,
      text: ''
    }
  }

  fagdagerSlider = {
    link: {
      enabled: true,
      internal: false,
      url: 'https://oo.no/otto-olsen-nytt/bli-med-pa-fagdager-5-6-juni/',
      text: 'Klikk her for å lese mer om arrangementet og for påmelding'
    },
    image: {
      enabled: true,
      sourceUrl: './assets/frontpage.jpg'
    },
    text: {
      enabled: true,
      text: '5. og 6. juni arrangerer Otto Olsen fagdager på hovedkontoret vårt. Det er gratis å delta, men krever registrering.'
    },
    textSecondary: {
      enabled: false,
      text: ''
    }
  }


  assortmentsList: AssortmentRecursiveExtended[] = [];

  public getExtraImage = getExtraImage;

  constructor(
    private router: Router,
    private authService: AuthService,
    private assortmentService: AssortmentService,
    protected translateService: TranslateService
  ) {
    const currentDate = new Date();
    const endDate = new Date("2024-06-01");
    if (isBefore(currentDate, endDate)) {
      this.slider.push(this.fagdagerSlider);
    } else {
      this.slider.push(this.defaultSlider);
    }


    const currentMode = localStorage.getItem('InterfaceMode');
    this.authService
      .getHasRoles('SCANNER')
      .pipe(first())
      .subscribe(isScannerOnly => {
        if (isScannerOnly) {
          if (currentMode === 'B2B') {
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/mobile']);
          }
        }
      });
    this.authService
      .getCurrentUser()
      .pipe(
        filter(user => !!user),
        first(),
        switchMap(user => this.assortmentService.getAssortment(user.assortment))
      )
      .subscribe(assortment => {
        const currentLang = this.translateService.currentLang || 'no';
        this.assortmentsList = assortment.children.map((a: AssortmentRecursiveExtended) => {
          if (a.extra.i18 && a.extra.i18[currentLang]) {
            if (a.extra.i18[currentLang].assortmentName) {
              a.name = a.extra.i18[currentLang].assortmentName;
            }
            if (a.extra.i18[currentLang].assortmentDescription) {
              a.description = a.extra.i18[currentLang].assortmentDescription;
            }
          }
          return a;
        });
      });
  }

  ngOnInit() {}

  navigateTo(link: { enabled: boolean; internal: boolean; url: string }) {
    if (!link.enabled) {
      return;
    }
    if (link.internal) {
      this.router.navigate([link.url]);
      return;
    }
    window.location.href = link.url;
  }
}
