import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent, SimpleConfigBaseFilter } from 'gung-list';
import {
  ConceptDetailProductListConfigService,
  ProductService,
  AuthService,
  ProductSortService,
  MetadataService,
  Product,
  BaseViewConfigService,
  DynamicColumnsService,
  ColumnSortSelectionService,
  PriceService
} from 'gung-standard';
import { Observable, first, forkJoin, of, switchMap } from 'rxjs';
import { OttoOlsenConceptDetailProductListViewComponent } from '../components/otto-olsen-concept-detail-product-list-view/otto-olsen-concept-detail-product-list-view.component';
import { OttoOlsenConceptDetailProductCardComponent } from '../components/otto-olsen-concept-detail-product-card/otto-olsen-concept-detail-product-card.component';

export class OttoOlsenAvailabilityFilter extends SimpleConfigBaseFilter<Product> {

  disableSearch = true;

  constructor(protected stockId) {
    super();
  }

  getOptionIds(item: Product): string[] {
    if (item.extra.availabilities[this.stockId].currentAvailability) {
      return ['FILTER_IN_STOCK'];
    }
    return [];
  }

  getOptionName(key: string): string {
    return key;
  }

  getName(): string {
    return 'STOCK_FILTER';
  }

  isActiveFromStart = () => true;
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenConceptDetailProductListConfigService extends ConceptDetailProductListConfigService {
  topFilter = false;
  stockId;

  isAnonymous: boolean = true;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected translationService: TranslateService,
    protected metadataService: MetadataService,
    protected baseViewConfigService: BaseViewConfigService,
    protected columnSortSelectionService: ColumnSortSelectionService,
    protected dynamicColumnsService: DynamicColumnsService,
    protected priceService: PriceService
  ) {
    super(
      productService,
      authService,
      productSortService,
      translationService,
      metadataService,
      baseViewConfigService,
      columnSortSelectionService,
      dynamicColumnsService
    );

    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isAnonymous = roles.includes('ANONYMOUS');
    });
  }

  getLayouts(): ListLayout<Product>[] {
    if (
      this.pimOptions &&
      this.pimOptions.showAsGrid &&
      (this.pimOptions.showAsGrid === true || this.pimOptions.showAsGrid === 'true')
    ) {
      return [
        {
          getIconClass: () => '',
          getListItemComponent: () => OttoOlsenConceptDetailProductCardComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        }
      ];
    }
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenConceptDetailProductListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<Product[]> {
    return super.getItems().pipe(switchMap(products =>
      forkJoin({
        products: of(products),
        authUser: this.authService.getCurrentUser().pipe(first()),
        prices: this.priceService.getCurrentCustomerPrices(products.map(p => p.id)).pipe(first())
      })),
      switchMap(({ products, authUser, prices }) => {
        this.stockId = authUser.managedMultistockIds[0];
        for (const product of products) {
          const price = prices.find(p => p.productId === product.id);
          if (!!price) {
            product.extra._gungCustomerPriceValue = !product.extra?.pim?.pricerequest && !(product.extra?.ar?.itemtypecd1 === 0 || product.extra?.ar?.itemtypecd1 === 7) ? (price.customerNetPrice?.value || 0) : -1;
          }
        }
        return of(products);
      })
    );
  }

  getBatchSizes(): number[] {
    return [40];
  }

  getLimit() {
    return 40;
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    const filters = this.filters;
    const indexStock = filters.findIndex(filter => filter.getName() === 'STOCK_FILTER');
    if (!this.isAnonymous && indexStock < 0) {
      filters.push(new OttoOlsenAvailabilityFilter(this.stockId));
    }

    return filters;
  }
}
