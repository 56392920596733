<div class="table-responsive gung-responsive">
  <table class="table table-borderless table-striped table-hover">
    <thead>
      <tr>
        <th scope="col" translate>ORDER_ID</th>
        <th scope="col" translate>ORDER_STATUS</th>
        <th scope="col" translate>CUSTOMER_NAME</th>
        <th scope="col" translate>ORDER_DATE</th>
        <th scope="col" translate>TOTAL</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let order of data" (click)="goToDetail(order); orderNavigationService.setOrderNavigation(order.id, getOrderIds(data))" [ngClass]="classSelector(order.extra.oh.ordstat)">
        <td [attr.data-label]="'ORDER_ID' | translate">{{ order.id }}</td>
        <td [attr.data-label]="'ORDER_STATUS' | translate" translate>OH_ORDSTAT_{{ order.extra.oh.ordstat }}</td>
        <td [attr.data-label]="'CUSTOMER_NAME' | translate">{{ order.extra.deliveryCustomer.name || '-' }}</td>
        <td [attr.data-label]="'ORDER_DATE' | translate">
          {{ order.extra.oh.orddatum | date: dateUtilService.dateFormat }}
        </td>
        <td [attr.data-label]="'TOTAL' | translate">
          <lib-price [price]="order.extra.oh.ohvbordsum" [currency]="order.extra.oh.valkod"></lib-price>
        </td>
      </tr>
    </tbody>
  </table>
</div>
