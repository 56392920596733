<div *ngIf="isRoleScannerKunde">
  <lib-confirmation-emails (emailsUpdated)="checkout.extra.confirmationEmails = $event" labelTranslation="Send ordrebekreftelse pr. epost til"></lib-confirmation-emails>
</div>
<div class="form-group">
    <label for="kundbestnr" class="control-label"><span translate>OH_KUNDBESTNR</span>*</label>
    <input class="form-control" type="text" id="kundbestnr" [(ngModel)]="kundbestnr" />
    <div
      *ngIf="!kundbestnr || (kundbestnr && kundbestnr.length===0)"
      class="alert alert-danger"
    >
      <span translate>FORM_ERROR_REQUIRED</span>
    </div>
  </div>
  <div class="form-group" >
    <label for="kundref2" class="control-label" translate>OH_KUNDREF2</label>
    <input class="form-control" type="text" id="kundref2" [(ngModel)]="kundref2" />
  </div>
  <div class="form-group" >
    <label for="edit" class="control-label w-100" translate>OH_EDITEXT</label>
    <textarea id="edit" [(ngModel)]="edit" class="form-control w-100" rows="9" cols="50"></textarea>
  </div>

<div >
    <span class="text-left d-inline-block w-50">
        <button class="btn border-primary" translate [routerLink]="'/mobile-checkout'">BACK</button>
    </span>
    <span class="text-right d-inline-block w-50" >
        <button *ngIf="!submitting" class="btn btn-primary" translate (click)="sendOrder()">SUBMIT_ORDER</button>
        <span *ngIf="submitting">SENDING</span>
    </span>
   
</div>