<div class="barcode-wrapper" *ngIf="initProductsInCart">
  <h5 class="text-center mb-3" translate>SCAN_BARCODE_WITH_CAMERA</h5>
  <div *ngIf="isScannerBetaGroup" class="d-flex flex-row">
    <button class="btn btn-primary" [ngClass]="{'active': !selectedFreeScanner}" (click)="switchScanner(false)" translate>DEFAULT_SCANNER</button>
    <button class="btn btn-primary" [ngClass]="{'active': selectedFreeScanner}" (click)="switchScanner(true)" translate>NEW_SCANNER</button>
  </div>
  <div class="camera-fix-area">
    <div class="scanner-tools" (click)="openScannerConfigs()"><i class="fa-solid fa-gear"></i></div>
    <div>
     
      <ng-template
        [libCustomHostDynamic]="selectedFreeScanner ? freeScannerComponent : scannerComponent"
        [inputs]="{}"
        [outputs]="{ outputCodeScanned: onBarcodeScanned.bind(this) }"
      >
      </ng-template>
    </div>
    <div class="not-found text-center" *ngIf="notFound">
      <div class="not-found-close cursor-pointer">
        <i class="fas fa-times click-icon" (click)="notFound = false"></i>
      </div>
      <span translate>Cannot find barcode</span> {{ notFoundCode }}
    </div>
  </div>

 
  <div class="cart-list" [class.cart-full]="fullscreen">
    <ng-template
      [libCustomHostDynamic]="productsInCartComponent"
      [inputs]="{ allProducts: products, stockIds: stockIds, fullscreen: fullscreen }"
      [outputs]="{}"
    >
    </ng-template>
  </div>
</div>

<div *ngIf="!initProductsInCart" class="text-center">
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</div>
