<div class="modal-header">
    <h4 translate>SCANNER_CONFIGS</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="form-group">
        <label for="time" class="control-label" translate>TIME_SCANNER</label>
        <input class="form-control" type="text" name="time" id="time" [(ngModel)]="timeReadAgain" />
    </div>
    <div class="form-group">
        <label for="addSameProduct" class="control-label mr-2" translate>SCAN_PRODUCTS_IN_CART</label>
        <input type="checkbox" name="addSameProduct" id="addSameProduct" [(ngModel)]="addSameProduct" />
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-block btn-primary" (click)="save()" translate>
    SAVE
    </button>
</div>
