import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays, differenceInMonths, startOfDay } from 'date-fns';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent, ListSortOption, SimpleConfigBaseFilter } from 'gung-list';
import { CustomerService, UsersListConfigService, UsersService, gungComparatorHelper } from 'gung-standard';
import { User } from 'gung-standard/lib/state/auth/types';
import { Observable, first, forkJoin, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { OttoOlsenUserTableComponent } from '../components/otto-olsen-user-table/otto-olsen-user-table.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenUsersListConfigService extends UsersListConfigService{
  
  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }

  getLayouts(): ListLayout<User>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenUserTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'UserTable'
      }
    ];
  }

  getItems(): Observable<User[]> {
    return super.getItems().pipe(
      switchMap(users => {
        return forkJoin({
          users: of(users),
          customers: this.customerService.getCustomers().pipe(first())
        })
      }),
      mergeMap(({users, customers}) => {
        users = users.map(user => {
          if (user.extra?.loginData?.latestSuccessfulLogin) {
            const successDaysAgo = differenceInDays(new Date(), startOfDay(new Date(user.extra.loginData.latestSuccessfulLogin)));
            if (!isNaN(successDaysAgo)) {
              user.extra._successLatestLoginDays = successDaysAgo > 0 ? `${successDaysAgo} ` + this.translateService.instant('DAYS_AGO') : this.translateService.instant('TODAY');
            }
          }
          if (user.extra?.loginData?.latestFailedLogin) {
            const failedDaysAgo = differenceInDays(new Date(), new Date(user.extra.loginData.latestFailedLogin));
            if (!isNaN(failedDaysAgo)) {
              user.extra._failedLatestLoginDays = failedDaysAgo > 0 ? `${failedDaysAgo} ` + this.translateService.instant('DAYS_AGO') : this.translateService.instant('TODAY');
            }
          }
          if (user.managedCompanyIds.length > 0) {
            user.extra._managedCompanies = customers.filter(c => user.managedCompanyIds.includes(c.id)).map(customer => ({ id: customer.id, name: customer.name }));
          }
          return user;
        });
        return of(users);
      }),
      map(users => users.sort((a, b) => gungComparatorHelper(new Date(a?.extra?.createdAt).getTime() || 0, new Date(b?.extra?.createdAt).getTime() || 0, -1)))
    );
  }

  getFilters(): ConfigBaseFilter<User>[] {
    return [
      ...super.getFilters(),
      new UserLoginStatusFilter(),
      new UserTimeSinceSuccessfulLoginFilter(this.translateService)
    ];
  }

  getSortOptions(): ListSortOption<User>[] {
    return [
      {
        getLabel: () => 'NEWEST_USERS_FIRST',
        getComparator: () => (item1: User, item2: User) =>
          gungComparatorHelper(
            new Date(item1?.extra?.createdAt).getTime() || 0,
            new Date(item2?.extra?.createdAt).getTime() || 0,
            -1
          )
      },
      {
        getLabel: () => 'OLDEST_USERS_FIRST',
        getComparator: () => (item1: User, item2: User) =>
          gungComparatorHelper(
            new Date(item1?.extra?.createdAt).getTime() || 0,
            new Date(item2?.extra?.createdAt).getTime() || 0,
            1
          )
      }
    ];
  }
 
  getSearchTerms(item: User): string[] {
    const companiesNames: string[] = item.extra?._managedCompanies?.map(c => c.name) || [];
    const companiesIds: string[] = item.extra?._managedCompanies?.map(c => c.id) || [];
    return [item.username, item.name, item.email, ...companiesIds, ...companiesNames];
  }
}

export class UserTimeSinceSuccessfulLoginFilter extends SimpleConfigBaseFilter<User> {
  type = 'RangeConfigBaseFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'TIME_SINCE_SUCESSFUL_LOGIN_MONTHS';
  }

  getOptionIds(item: User): string[] {
    if (item?.extra?.loginData?.latestSuccessfulLogin) {
      const monthsAgo = differenceInMonths(new Date(), new Date(item.extra.loginData.latestSuccessfulLogin));
      return [monthsAgo.toString()];
    } else {
      return [];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class UserLoginStatusFilter extends SimpleConfigBaseFilter<User> {
  getName(): string {
    return 'LOGIN_ATTEMPTS';
  }

  getOptionIds(item: User): string[] {
    if (item.extra?.loginData?.loginFailAttempts > 0 && item.extra?.loginData?.loginSucessAttempts === 0) {
      return ['HIDE_USERS_WITH_SUCCESS_ATEMPTS'];
    } else if (item.extra?.loginData?.loginFailAttempts === 0 && item.extra?.loginData?.loginSucessAttempts > 0) {
      return ['HIDE_USERS_WITH_FAIL_ATEMPTS'];
    } else {
      return [];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}