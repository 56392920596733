import { Injectable } from '@angular/core';
import { JeevesCustomerActionConfigService, JeevesModalService } from 'gung-standard-jeeves';
import { AuthService, Customer, GungModalService, NavbarConfigService, ReportCentralReportService, SelectedCustomerService } from 'gung-standard';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OttoOlsenCreateUserModalComponent } from '../components/otto-olsen-create-user-modal/otto-olsen-create-user-modal.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCustomerActionConfigService extends JeevesCustomerActionConfigService {
  constructor(
    protected modalService: JeevesModalService,
    protected authService: AuthService,
    protected router: Router,
    protected selectedCustomerService: SelectedCustomerService,
    protected ngbModal: NgbModal,
    gungModalService: GungModalService,
    navbarConfigService: NavbarConfigService,
    protected reportCentralReportService: ReportCentralReportService
  ) {
    super(modalService, authService, router, selectedCustomerService, ngbModal, gungModalService, navbarConfigService, reportCentralReportService);
  }

  createUser(customer: Customer): void {
    const userToCreateFrom = {
      username: '',
      name: customer.name,
      email: '',
      password: '',
      managedCompanyIds: customer.id,
      roles: ['USER'],
      managedMultistockIds: [],
      managedAssortmentIds: [],
      assortment: 's0',
      activeGroups: {},
      phone: ''
    };

    const modalRef = this.ngbModal.open(OttoOlsenCreateUserModalComponent, { size: 'lg' });
    modalRef.componentInstance.createUserFrom = userToCreateFrom;
    modalRef.componentInstance.userFromCustomer = true;
    modalRef.componentInstance.customer = customer;
    modalRef.result.then(
      (u: any) => {
        if (!!u) {
          this.router.navigate(['/admin/users-details/' + u.username]);
        }
      },
      reason => {}
    );
  }
}
