import { Component } from '@angular/core';
import { AuthService, UserDetailsCustomersComponent, UsersDetailsCustomersListConfigService, UsersService } from 'gung-standard';
import { first, filter } from 'rxjs';

@Component({
  selector: 'otto-olsen-manage-user-details-customers',
  templateUrl: './otto-olsen-manage-user-details-customers.component.html',
  styleUrls: ['./otto-olsen-manage-user-details-customers.component.scss']
})
export class OttoOlsenManageUserDetailsCustomersComponent extends UserDetailsCustomersComponent {
  public isAdmin = false;

  constructor(
    public usersDetailsCustomersListConfigService: UsersDetailsCustomersListConfigService,
    protected usersService: UsersService,
    authService: AuthService
  ) {
    super(
      usersDetailsCustomersListConfigService,
      usersService
    );
    authService.getRoles().pipe(first(), filter(roles => !!roles)).subscribe(roles => this.isAdmin = roles.indexOf('ADMIN') > -1)
  }
}
