import { Injectable } from '@angular/core';
import { JeevesInvoiceActionConfigService } from 'gung-standard-jeeves';
import { BackendRerouteService, Invoice } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenInvoiceActionConfigService extends JeevesInvoiceActionConfigService {
  base = [
    {
      title: 'DOWNLOAD_PDF',
      action: (item: Invoice) =>
        this.backendRerouteService.reroute('json/otto-invoices-external/get-invoice/' + item.id + '/1')
    }
  ];

  constructor(protected backendRerouteService: BackendRerouteService) {
    super();
  }
}
