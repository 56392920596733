import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService, SelectedCustomerService } from 'gung-standard';
import { Router } from '@angular/router';
import { OttoOlsenRoleSwitcherHelperService } from '../../../services/otto-olsen-role-switcher-helper.service';

@Component({
  selector: 'otto-olsen-mobile-start-page',
  templateUrl: './otto-olsen-mobile-start-page.component.html',
  styleUrls: ['./otto-olsen-mobile-start-page.component.scss']
})
export class OttoOlsenMobileStartPageComponent implements OnInit {
  user;
  customer;
  interfaceMode = 'InterfaceMode';
  constructor(
    protected authService: AuthService, 
    protected location: Location, 
    public router: Router,
    protected selectedCustomerService: SelectedCustomerService,
    protected ottoOlsenRoleSwitcherHelperService: OttoOlsenRoleSwitcherHelperService
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
    this.selectedCustomerService.getSelectedCustomer().subscribe(c => this.customer = c);
  }

  setB2bMode(mode: string): void {
    this.ottoOlsenRoleSwitcherHelperService.setRole(mode);
  }

  logout() {
    this.location.go('/');
    this.authService.logout();
    localStorage.removeItem('InterfaceMode');
  }

  exitScannermode() {
    this.setB2bMode('B2B');
    this.router.navigate(['/']);
  }
}
