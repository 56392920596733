<div *ngIf="orderNavigationService.isOrderNavigationReady" class="row">
  <div class="col-12 d-flex flex-row justify-content-end">
    <button
      [disabled]="!orderNavigationService.isPreviousButtonActive"
      class="btn btn-outline-secondary navigation-button-width"
      (click)="orderNavigationService.goToPreviousOrder()"
    >
      <i class="fa-regular fa-arrow-left"></i>
    </button>
    
    <button
      [disabled]="!orderNavigationService.isNextButtonActive"
      class="btn btn-outline-secondary navigation-button-width ml-2"
      (click)="orderNavigationService.goToNextOrder()"
    >
      <i class="fa-regular fa-arrow-right"></i>
    </button>
  </div>
</div>