import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Cart, CartsService, CreationDateFilterService, SavedCartConfigService, CustomerService, SavedCart } from 'gung-standard';
import { forkJoin, map, Observable, mergeMap, switchMap, of, first } from 'rxjs';
import { OttoOlsenSavedCartTableComponent } from '../components/otto-olsen-saved-cart-table/otto-olsen-saved-cart-table.component';


@Injectable({
  providedIn: 'root'
})
export class OttoOlsenSavedCartConfigService extends SavedCartConfigService {

  constructor(
    protected cartsService: CartsService,
    protected creationDateFilterService: CreationDateFilterService,
    protected customerService: CustomerService
  ) {
    super(cartsService, creationDateFilterService);
  }

  getLayouts(): ListLayout<SavedCart>[] {
    return [{
      getIconClass: () => '',
      getListItemComponent: () => OttoOlsenSavedCartTableComponent,
      getListLayoutComponent: () => ListLayoutMultipleComponent,
      getName: () => 'Product grid'
    }];
  }

  getItems(): Observable<SavedCart[]> {
    return super.getItems().pipe(
      map((carts: SavedCart[]) => carts.map(c => {
        // c.extra._isCollapsedCart = false;
        // c.extra._isEditCart = false;
        return c;
      })),
      mergeMap(carts => {
        const customers = {carts: of(carts)};
        for (const cart of carts) {
          if (cart.customerId) {
            customers[cart.customerId] = this.customerService.getCustomer(cart.customerId).pipe(first())
          }
        }
        return forkJoin(customers);
      }),
      switchMap(({carts, ...customers}) => {
        for (const cart of carts) {
          if (cart.customerId && customers[cart.customerId]) {
            cart.extra.customer = customers[cart.customerId];
          }
        }
        return of(carts);
      })
    );
  }

  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }
}
