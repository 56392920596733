import { Injectable } from '@angular/core';
import {
  ProductListConfigService,
  ProductService,
  AuthService,
  Product,
  MetadataService,
  ProductSortService,
  AssortmentService,
  GungFlowService,
  ProductGridViewComponent,
  Customer,
  SelectedCustomerService,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, switchMap, first } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { OttoOlsenProductGridViewComponent } from '../components/otto-olsen-product-grid-view/otto-olsen-product-grid-view.component';

export interface ProductConcept extends Product {
  name: string;
  productType: string;
  proxyId: any;
  subProductIds: string[];
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductListConfigService extends ProductListConfigService {
  public selectedCustomer: Customer;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected translationService: TranslateService,
    protected assortmentService: AssortmentService,
    private selectedCustomerService: SelectedCustomerService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(productService, authService, productSortService, gungFlowService, cloudPimFiltersService, currentAssortmentService);
  }

  getSearchTerms(item: Product) {
    let referenceId;
    if (
      this.selectedCustomer &&
      item.extra._customerCrossReferences &&
      item.extra._customerCrossReferences[this.selectedCustomer.id]
    ) {
      referenceId = item.extra._customerCrossReferences[this.selectedCustomer.id].referenceId;
    }
    return [item.id, item.name, referenceId];
  }

  getItems(): Observable<Product[]> {
    return this.assortmentService.getUserAssortment().pipe(
      first(),
      filter(assortment => !!assortment),
      switchMap(assortment => this.productService.getProductsByAssortment(assortment.id).pipe(first())),
      switchMap(products => {
        return forkJoin([of(products), this.selectedCustomerService.getSelectedCustomer().pipe(first())]);
      }),
      switchMap(([products, customer]) => {
        this.selectedCustomer = customer;
        return of(products);
      })
    );
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenProductGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }
}
