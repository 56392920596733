import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  ProductListComponent,
  ProductListConfigService,
  Product,
  AuthService,
  BackendInterceptor
} from 'gung-standard';
import { ActivatedRoute } from '@angular/router';
import { OttoOlsenModalService } from '../../services/otto-olsen-modal.service';
import { FilterListComponent } from 'gung-list';
import { first } from 'rxjs';

@Component({
  selector: 'otto-olsen-product-list',
  template:
    '<lib-filter-list #productList [configService]="productListConfigService" [topFilter]="true"></lib-filter-list>',
  styleUrls: ['./otto-olsen-product-list.component.scss']
})
export class OttoOlsenProductListComponent extends ProductListComponent implements OnInit {
  constructor(
    public productListConfigService: ProductListConfigService,
    public route: ActivatedRoute,
    protected modalService: OttoOlsenModalService,
    protected authService: AuthService,
    backendInterceptor: BackendInterceptor
  ) {
    super(productListConfigService, route, backendInterceptor);
  }

  @ViewChild('productList') productList: FilterListComponent<Product>;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const assortmentId = params.get('assortmentId');

      if (assortmentId != null) {
        this.productListConfigService.assortmentSubject.next(assortmentId);
      } else {
        this.authService
          .getCurrentUser()
          .pipe(first())
          .subscribe(user => this.productListConfigService.assortmentSubject.next(user.assortment));
      }

      // Product Details
      const productId = params.get('productId');

      if (productId != null) {
        this.modalService.openProductDetailsModal(productId);
      }
      if (this.productList) {
        this.productList.renderNewLayout();
        this.productList.renderNewFilters();
      }
    });
  }
}
