import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { OttoOlsenAppComponent } from './otto-olsen-app.component';
import {
  GungStandardModule,
  TranslationLocalService,
  createTranslateLocalLoader,
  ProductExportListConfigService,
  ProductListConfigService,
  ProductFastSearchListConfigService,
  BackendInterceptor,
  NavbarConfigService,
  ProductPaginationListConfigService,
  CheckoutConfigService,
  PriceConfigService,
  AssortmentTreeNavigationConfigService,
  ConceptDetailProductListConfigService,
  AssortmentTreeImageSelectionService,
  SalesCartConfigService,
  ProductService,
  OrderListConfigService,
  AvailabilityHelperService,
  ConceptTreeListConfigService,
  CartConfigService,
  ProductTreeListConfigService,
  CheckoutQuantityValidationService,
  AssortmentTreeListConfigService,
  AccountRequestListConfigService,
  InvoiceListConfigService,
  NewsActionConfigService,
  UsersService,
  FavouriteProductListConfigService,
  CustomerUserListConfigService,
  BarcodeScannerConfigService,
  SavedCartConfigService,
  GungModalService,
  ProductInputQuantityConfigService,
  BaseViewConfigService,
  CartsService,
  UsersListConfigService,
  CustomerListConfigService,
  DeliveryLocationListConfigService,
  OpenOrdersConfigService,
  ProductExportService,
  PriceService,
  LanguageConfigService,
  HomeNavigationConfigService,
  GOOGLE_TAG_MANAGER_ID,
  ProductExportActionConfigService,
  ColumnSortSelectionService,
  UserActionConfigService,
  EdiOrderActionConfigService
} from 'gung-standard';
import { FilterListService, GungListModule, SliderComponent } from 'gung-list';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { OttoOlsenBackendInterceptorService } from './services/otto-olsen-backend-interceptor.service';
import { OttoOlsenTranslationLocalService } from './services/otto-olsen-translation-local.service';
import { OttoOlsenHomeComponent } from './components/otto-olsen-home/otto-olsen-home.component';
import { OttoOlsenProductExportListConfigService } from './services/otto-olsen-product-export-list-config.service';
import { OttoOlsenProductCardComponent } from './components/otto-olsen-product-card/otto-olsen-product-card.component';
import { OttoOlsenProductExportCardComponent } from './components/otto-olsen-product-export-card/otto-olsen-product-export-card.component';
import { OttoOlsenItemSelectionButtonComponent } from './components/otto-olsen-item-selection-button/otto-olsen-item-selection-button.component';
import { OttoOlsenProductDetailsModalComponent } from './components/otto-olsen-product-details-modal/otto-olsen-product-details-modal.component';
import { OttoOlsenProductListComponent } from './components/otto-olsen-product-list/otto-olsen-product-list.component';
import { OttoOlsenProductExportListComponent } from './components/otto-olsen-product-export-list/otto-olsen-product-export-list.component';
import {
  GungStandardJeevesModule,
  JeevesCustomerActionConfigService,
  JeevesCustomerDeliveryLocationListConfigService,
  JeevesCustomerListConfigService,
  JeevesInvoiceActionConfigService,
  JeevesInvoiceListConfigService,
  JeevesOrderActionConfigService,
  JeevesDeliveryAddressesService
} from 'gung-standard-jeeves';
import { environment } from '../environments/environment';
import { OttoOlsenProductFastSearchListConfigService } from './services/otto-olsen-product-fast-search-list-config.service';
import { OttoOlsenProductFastSearchListComponent } from './components/otto-olsen-product-fast-search-list/otto-olsen-product-fast-search-list.component';
import { OttoOlsenNavbarConfigService } from './services/otto-olsen-navbar-config.service';
import { OttoOlsenProductPaginationListCardComponent } from './components/otto-olsen-product-pagination-list-card/otto-olsen-product-pagination-list-card.component';
import { OttoOlsenDeliveryLocationsJeevesComponent } from './components/checkout/steps/otto-olsen-delivery-locations-jeeves/otto-olsen-delivery-locations-jeeves.component';
import { OttoOlsenCheckoutConfigService } from './services/otto-olsen-checkout-config.service';
import { OttoOlsenPriceConfigService } from './services/otto-olsen-price-config.service';
import { OttoOlsenNavbarComponent } from './components/otto-olsen-navbar/otto-olsen-navbar.component';
import { OttoOlsenAppWrapperComponent } from './components/otto-olsen-app-wrapper/otto-olsen-app-wrapper.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { OttoOlsenProductDetailsComponent } from './components/otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenFavouriteProductIconComponent } from './components/otto-olsen-favourite-product-icon/otto-olsen-favourite-product-icon.component';
import { OttoOlsenAssortmentTreeNavigationConfigService } from './services/otto-olsen-assortment-tree-navigation-config.service';
import { OttoOlsenProductListConfigService } from './services/otto-olsen-product-list-config.service';
import { OttoOlsenConceptDetailProductListConfigService } from './services/otto-olsen-concept-detail-product-list-config.service';
import { OttoOlsenAssortmentTreeImageSelectionService } from './services/otto-olsen-assortment-tree-image-selection.service';
import { OttoOlsenSalesCartListComponent } from './components/otto-olsen-sales-cart-list/otto-olsen-sales-cart-list.component';
import { OttoOlsenSalesCartConfigService } from './services/otto-olsen-sales-cart-config.service';
import { OttoOlsenAssortmentTreeNavigationComponent } from './components/otto-olsen-assortment-tree-navigation/otto-olsen-assortment-tree-navigation.component';
import { OttoOlsenProductService } from './services/otto-olsen-product.service';
import { OttoOlsenProductExportService } from './services/otto-olsen-product-export.service';
import { OttoOlsenOrderListConfigService } from './services/otto-olsen-order-list-config.service';
import { OttoOlsenConceptDetailProductListViewComponent } from './components/otto-olsen-concept-detail-product-list-view/otto-olsen-concept-detail-product-list-view.component';
import { OttoOlsenConceptDetailProductCardComponent } from './components/otto-olsen-concept-detail-product-card/otto-olsen-concept-detail-product-card.component';
import { OttoOlsenAvailabilityHelperService } from './services/otto-olsen-availability-helper.service';
import { OttoOlsenConceptTreeListViewComponent } from './components/otto-olsen-concept-tree-list-view/otto-olsen-concept-tree-list-view.component';
import { OttoOlsenConceptTreeListConfigService } from './services/otto-olsen-concept-tree-list-config.service';
import { OttoOlsenOrderTermsJeevesComponent } from './components/checkout/steps/otto-olsen-order-terms-jeeves/otto-olsen-order-terms-jeeves.component';
import { OttoOlsenOrderCardListComponent } from './components/otto-olsen-order-card-list/otto-olsen-order-card-list.component';
import { OttoOlsenAvailabilityComponent } from './components/otto-olsen-availability/otto-olsen-availability.component';
import { OttoOlsenJeevesSalesCartListComponent } from './components/checkout/otto-olsen-jeeves-sales-cart-list/otto-olsen-jeeves-sales-cart-list.component';
import { OttoOlsenCartConfigService } from './services/otto-olsen-cart-config.service';
import { OttoOlsenOrderDetailsComponent } from './components/otto-olsen-order-details/otto-olsen-order-details.component';
import { OttoOlsenBuyBtnComponent } from './components/otto-olsen-buy-btn/otto-olsen-buy-btn.component';
import { OttoOlsenProductTreeListViewComponent } from './components/otto-olsen-product-tree-list-view/otto-olsen-product-tree-list-view.component';
import { OttoOlsenProductTreeListConfigService } from './services/otto-olsen-product-tree-list-config.service';
import { OttoOlsenProductTreeTableViewComponent } from './components/otto-olsen-product-tree-table-view/otto-olsen-product-tree-table-view.component';
import { OttoOlsenImageCarouselComponent } from './components/otto-olsen-image-carousel/otto-olsen-image-carousel.component';
import { OttoOlsenProductInfoPageComponent } from './components/otto-olsen-product-info-page/otto-olsen-product-info-page.component';
import { OttoOlsenFilterListComponent } from './components/otto-olsen-filter-list/otto-olsen-filter-list.component';
import { OttoOlsenConceptDetailComponent } from './components/otto-olsen-concept-detail/otto-olsen-concept-detail.component';
import { OttoOlsenProductTreeListComponent } from './components/otto-olsen-product-tree-list/otto-olsen-product-tree-list.component';
import { OttoOlsenDateUtilService } from './services/utils/otto-olsen-date-util.service';
import { OttoOlsenFreightCostComponent } from './components/checkout/steps/otto-olsen-freight-cost/otto-olsen-freight-cost.component';
import { OttoOlsenAssignJeevesDefaultComponent } from './components/checkout/steps/otto-olsen-assign-jeeves-default/otto-olsen-assign-jeeves-default.component';
import { OttoOlsenOrderTermsJeevesWithCartListComponent } from './components/checkout/steps/otto-olsen-order-terms-jeeves-with-cart-list/otto-olsen-order-terms-jeeves-with-cart-list.component';
import { OttoOlsenFavouriteProductIconOnlyComponent } from './components/otto-olsen-favourite-product-icon-only/otto-olsen-favourite-product-icon-only.component';
import { OttoOlsenProductGridViewComponent } from './components/otto-olsen-product-grid-view/otto-olsen-product-grid-view.component';
import { DecimalPipe } from '@angular/common';
import { OttoCheckoutQuantityValidationService } from './services/checkout/steps/otto-checkout-quantity-validation.service';
import { OttoOlsenAssortmentTreeListConfigService } from './services/otto-olsen-assortment-tree-list-config.service';
import { OttoOlsenAssortmentTreeListViewComponent } from './components/otto-olsen-assortment-tree-list-view/otto-olsen-assortment-tree-list-view.component';
import { OttoOlsenCustomerDetailsComponent } from './components/otto-olsen-customer-details/otto-olsen-customer-details.component';
import { OttoOlsenDashboardCardService } from './services/otto-olsen-dashboard-card.service';
import { DashboardCardService } from 'gung-dashboard';
import { OttoOlsenCustomersSummaryCardComponent } from './components/dashboard/otto-olsen-customers-summary-card/otto-olsen-customers-summary-card.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { OttoOlsenLastTenOrdersCardComponent } from './components/dashboard/otto-olsen-last-ten-orders-card/otto-olsen-last-ten-orders-card.component';
import { OttoOlsenCreateProductComponent } from './components/otto-olsen-create-product/otto-olsen-create-product.component';
import { OttoOlsenJeevesCustomerDeliveryLocationService } from './services/otto-olsen-jeeves-customer-delivery-location.service';
import { OttoOlsenLastFullYearVsThisYearSalesSummaryCardComponent } from './components/dashboard/otto-olsen-last-full-year-vs-this-year-sales-summary-card/otto-olsen-last-full-year-vs-this-year-sales-summary-card.component';
import { OttoOlsenLoginComponent } from './components/otto-olsen-login/otto-olsen-login.component';
import { OttoOlsenRequestAccountComponent } from './components/otto-olsen-request-account/otto-olsen-request-account.component';
import { OttoOlsenAccountRequestListConfigService } from './services/otto-olsen-account-request-list-config.service';
import { OttoOlsenAccountRequestsCardListComponent } from './components/otto-olsen-account-requests-card-list/otto-olsen-account-requests-card-list.component';
import { OttoOlsenLastYearToDateVsThisYearToDateSalesSummaryCardComponent } from './components/dashboard/otto-olsen-last-year-to-date-vs-this-year-to-date-sales-summary-card/otto-olsen-last-year-to-date-vs-this-year-to-date-sales-summary-card.component';
import { OttoOlsenInvoiceListConfigService } from './services/otto-olsen-invoice-list-config.service';
import { OttoOlsenInvoiceCardListComponent } from './components/otto-olsen-invoice-card-list/otto-olsen-invoice-card-list.component';
import { OttoOlsenInvoiceListComponent } from './components/otto-olsen-invoice-list/otto-olsen-invoice-list.component';
import { OttoOlsenInvoiceActionConfigService } from './services/otto-olsen-invoice-action-config.service';
import { OttoOlsenNewsActionConfigService } from './services/otto-olsen-news-action-config.service';
import { OttoOlsenCreateUserModalComponent } from './components/otto-olsen-create-user-modal/otto-olsen-create-user-modal.component';
import { OttoOlsenCustomerActionConfigService } from './services/otto-olsen-customer-action-config.service';
import { OttoOlsenFooterComponent } from './components/otto-olsen-footer/otto-olsen-footer.component';
import { OttoOlsenProductListAssortmentComponent } from './components/otto-olsen-product-list-assortment/otto-olsen-product-list-assortment.component';
import { OttoOlsenUsersService } from './services/otto-olsen-users.service';
import { OttoOlsenCheckoutButtonsComponent } from './components/otto-olsen-checkout-buttons/otto-olsen-checkout-buttons.component';
import { OttoOlsenOrderActionConfigService } from './services/otto-olsen-order-action-config.service';
import { OttoOlsenSummarizeOrderComponent } from './components/checkout/steps/otto-olsen-summarize-order/otto-olsen-summarize-order.component';
import { OttoOlsenFavouriteProductListConfigService } from './services/otto-olsen-favourite-product-list-config.service';
import { OttoOlsenFavouriteProductGridViewComponent } from './components/otto-olsen-favourite-product-grid-view/otto-olsen-favourite-product-grid-view.component';
import { OttoOlsenProductInlineSearchCardComponent } from './components/otto-olsen-product-inline-search-card/otto-olsen-product-inline-search-card.component';
import { OttoOlsenInlineSearchNavbarComponent } from './components/otto-olsen-inline-search-navbar/otto-olsen-inline-search-navbar.component';
import { OttoOlsenProductFastsearchCardComponent } from './components/otto-olsen-product-fastsearch-card/otto-olsen-product-fastsearch-card.component';
import { OttoOlsenCuttingFeeComponent } from './components/checkout/steps/otto-olsen-cutting-fee/otto-olsen-cutting-fee.component';
import { OttoOlsenRemoveBlockedProductsStepComponent } from './components/checkout/steps/otto-olsen-remove-blocked-products-step/otto-olsen-remove-blocked-products-step.component';
import { OttoOlsenFilterListSideFilterComponent } from './components/otto-olsen-filter-list-side-filter/otto-olsen-filter-list-side-filter.component';
import { OttoOlsenPaginationListComponent } from './components/otto-olsen-pagination-list/otto-olsen-pagination-list.component';
import { OttoOlsenProductPaginationListComponent } from './components/otto-olsen-product-pagination-list/otto-olsen-product-pagination-list.component';
import { OttoOlsenProductPaginationListConfigService } from './services/otto-olsen-product-pagination-list-config.service';
import { OttoOlsenCustomerUserCardListComponent } from './components/otto-olsen-customer-user-card-list/otto-olsen-customer-user-card-list.component';
import { OttoOlsenCustomerUserListConfigService } from './services/otto-olsen-customer-user-list-config.service';
import { OttoOlsenQuickSearchComponent } from './components/otto-olsen-quick-search/otto-olsen-quick-search.component';
import { OttoOlsenQuickorderScannerComponent } from './components/otto-olsen-quickorder-scanner/otto-olsen-quickorder-scanner.component';
import { OttoOlsenToastsComponent } from './components/otto-olsen-toasts/otto-olsen-toasts.component';
import { OttoOlsenBarcodeScannerService } from './services/otto-olsen-barcode-scanner.service';
import { OttoOlsenBarcodeScannerComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-barcode-scanner.component';
import { OttoOlsenProductsInCartComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-products-in-cart/otto-olsen-products-in-cart.component';
import {
  CommonModalService,
  DateUtilService,
  GungCommonModule,
  GungImageUrlService,
  GungLanguageConfigService
} from 'gung-common';
import { OttoOlsenMobileProductDetailsComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-product-details/otto-olsen-mobile-product-details.component';
import { OttoOlsenMobileStartPageComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-start-page/otto-olsen-mobile-start-page.component';
import { OttoOlsenOrderMobileCardListComponent } from './components/otto-olsen-order-mobile-card-list/otto-olsen-order-mobile-card-list.component';
import { OttoOlsenSavedCartConfigService } from './services/otto-olsen-saved-cart-config.service';
import { OttoOlsenSavedCartTableComponent } from './components/otto-olsen-saved-cart-table/otto-olsen-saved-cart-table.component';
import { OttoOlsenLoadSavedCartModalComponent } from './components/otto-olsen-load-saved-cart-modal/otto-olsen-load-saved-cart-modal.component';
import { OttoOlsenDeleteSavedCartModalComponent } from './components/otto-olsen-delete-saved-cart-modal/otto-olsen-delete-saved-cart-modal.component';
import { OttoOlsenAvailabilityModalComponent } from './components/otto-olsen-availability-modal/otto-olsen-availability-modal.component';
import { OttoOlsenModalService } from './services/otto-olsen-modal.service';
import { OttoOlsenOrderTermsJeevesWithCartComponent } from './components/checkout/steps/otto-olsen-order-terms-jeeves-with-cart/otto-olsen-order-terms-jeeves-with-cart.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OttoOlsenScannerComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-scanner/otto-olsen-scanner.component';
import { OttoOlsenScannerSearchComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-scanner-search/otto-olsen-scanner-search.component';
import { OttoOlsenMobileAssortmentTreeNavigationComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-assortment-tree-navigation/otto-olsen-mobile-assortment-tree-navigation.component';
import { OttoOlsenMobileProductDescriptionComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-product-description/otto-olsen-mobile-product-description.component';
import { OttoOlsenScannerFooterMenuComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-scanner-footer-menu/otto-olsen-scanner-footer-menu.component';
import { OttoOlsenProductInputQuantityConfigService } from './services/otto-olsen-product-input-quantity-config.service';
import { OttoOlsenMobileCheckoutComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-mobile-checkout/otto-olsen-mobile-checkout.component';
import { OttoOlsenScannerCheckoutConfirmComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-scanner-checkout-confirm/otto-olsen-scanner-checkout-confirm.component';
import { OttoOlsenViewer3dComponent } from './components/otto-olsen-viewer3d/otto-olsen-viewer3d.component';
import { OttoOlsenBaseViewConfigService } from './services/otto-olsen-base-view-config.service';
import { OttoOlsenScannerOptionsModalComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-scanner-options-modal/otto-olsen-scanner-options-modal.component';
import { OttoOlsenCartService } from './services/otto-olsen-cart.service';
import { OttoOlsenUsersListConfigService } from './services/otto-olsen-users-list-config.service';
import { OttoOlsenCustomerListConfigService } from './services/otto-olsen-customer-list-config.service';
import { OttoOlsenDeliveryLocationListConfigService } from './services/otto-olsen-delivery-location-list-config.service';
import { JeevesOpenOrdersListConfigService } from 'gung-standard-jeeves';
import { OttoOlsenOpenOrdersListConfigService } from './services/otto-olsen-open-orders-list-config.service';
import { OttoOlsenView3dModelModalComponent } from './components/otto-olsen-view3d-model-modal/otto-olsen-view3d-model-modal.component';
import { OttoOlsenSavedCartDetailsComponent } from './components/otto-olsen-saved-cart-details/otto-olsen-saved-cart-details.component';
import { OttoOlsenOrderConfirmationComponent } from './components/checkout/otto-olsen-order-confirmation/otto-olsen-order-confirmation.component';
import { OttoOlsenDeliveryAddressesService } from './services/otto-olsen-delivery-addresses.service';
/* import { NgxSliderModule } from 'ngx-slider-v2'; */
import { OttoOlsenBetaSalespersonSelectComponent } from './components/dashboard/otto-olsen-beta-salesperson-select/otto-olsen-beta-salesperson-select.component';
import { OttoOlsenBetaLastVsThisMonthMeetingsComponent } from './components/dashboard/otto-olsen-beta-last-vs-this-month-meetings/otto-olsen-beta-last-vs-this-month-meetings.component';
import { OttoOlsenBetaMeetingsConvertedSalesComponent } from './components/dashboard/otto-olsen-beta-meetings-converted-sales/otto-olsen-beta-meetings-converted-sales.component';
import { OttoOlsenBetaInvoiceStatisticsChartCardComponent } from './components/dashboard/otto-olsen-beta-invoice-statistics-chart-card/otto-olsen-beta-invoice-statistics-chart-card.component';
import { OttoOlsenBetaInvoiceStatisticsChartComponent } from './components/dashboard/otto-olsen-beta-invoice-statistics-chart/otto-olsen-beta-invoice-statistics-chart.component';
import { OttoOlsenPriceService } from './services/otto-olsen-price.service';
import { OttoOlsenPriceListDisplayComponent } from './components/otto-olsen-price-list-display/otto-olsen-price-list-display.component';
import { OttoOlsenUserTableComponent } from './components/otto-olsen-user-table/otto-olsen-user-table.component';
import { OttoOlsenLanguageConfigService } from './services/otto-olsen-language-config.service';
import { OttoOlsenHomeNavigationConfigService } from './services/otto-olsen-home-navigation-config.service';
import { OttoOlsenOrderNavigationButtonsComponent } from './components/otto-olsen-order-navigation-buttons/otto-olsen-order-navigation-buttons.component';
import { OttoOlsenOpenOrderTableComponent } from './components/otto-olsen-open-order-table/otto-olsen-open-order-table.component';
import { OttoOlsenDeliveryDatePickerComponent } from './components/checkout/otto-olsen-delivery-date-picker/otto-olsen-delivery-date-picker.component';
import { OttoOlsenFavouriteProductTreeTableViewComponent } from './components/otto-olsen-favourite-product-tree-table-view/otto-olsen-favourite-product-tree-table-view.component';
import { OttoOlsenFavouriteProductListComponent } from './components/otto-olsen-favourite-product-list/otto-olsen-favourite-product-list.component';
import { OttoOlsenProductExportActionConfigService } from './services/otto-olsen-product-export-action-config.service';
import { OttoOlsenFreeScannerComponent } from './components/otto-olsen-barcode-scanner/otto-olsen-free-scanner/otto-olsen-free-scanner.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { OttoOlsenNavbarcartComponent } from './components/otto-olsen-navbarcart/otto-olsen-navbarcart.component';
import { OttoOlsenColumnSortSelectionService } from './services/otto-olsen-column-sort-selection.service';
import { OttoOlsenFilterListService } from './services/otto-olsen-filter-list.service';
import { OttoOlsenDemoAccountComponent } from './components/otto-olsen-demo-account/otto-olsen-demo-account.component';
import { OttoOlsenCheckoutWrapperComponent } from './components/checkout/otto-olsen-checkout-wrapper/otto-olsen-checkout-wrapper.component';
import { OttoOlsenImageUrlService } from './services/otto-olsen-image-url.service';
import { OttoOlsenManageUsersWrapperComponent } from './components/otto-olsen-manage-users-wrapper/otto-olsen-manage-users-wrapper.component';
import { OttoOlsenUserActionConfigService } from './services/otto-olsen-user-action-config.service';
import { OttoOlsenManageUsersDetailsComponent } from './components/otto-olsen-manage-users-details/otto-olsen-manage-users-details.component';
import { OttoOlsenManageUserDetailsUserComponent } from './components/otto-olsen-manage-user-details-user/otto-olsen-manage-user-details-user.component';
import { OttoOlsenManageUserDetailsCustomersComponent } from './components/otto-olsen-manage-user-details-customers/otto-olsen-manage-user-details-customers.component';
import { OttoOlsenAvailabilityWarehousesComponent } from './components/otto-olsen-availability-warehouses/otto-olsen-availability-warehouses.component';
import { OttoOlsenAvailabilityWarehousesModalComponent } from './components/otto-olsen-availability-warehouses-modal/otto-olsen-availability-warehouses-modal.component';
import { OttoOlsenEdiOrderActionConfigService } from './services/otto-olsen-edi-order-action-config.service';

// Necessary to solve the problem of losing internet connection
LOAD_WASM().subscribe();

@NgModule({
  declarations: [
    OttoOlsenAppComponent,
    OttoOlsenHomeComponent,
    OttoOlsenProductCardComponent,
    OttoOlsenProductExportCardComponent,
    OttoOlsenItemSelectionButtonComponent,
    OttoOlsenProductDetailsModalComponent,
    OttoOlsenProductListComponent,
    OttoOlsenProductExportListComponent,
    OttoOlsenProductFastSearchListComponent,
    OttoOlsenProductPaginationListCardComponent,
    OttoOlsenDeliveryLocationsJeevesComponent,
    OttoOlsenNavbarComponent,
    OttoOlsenAppWrapperComponent,
    OttoOlsenProductDetailsComponent,
    OttoOlsenFavouriteProductIconComponent,
    OttoOlsenSalesCartListComponent,
    OttoOlsenAssortmentTreeNavigationComponent,
    OttoOlsenConceptDetailProductListViewComponent,
    OttoOlsenConceptDetailProductCardComponent,
    OttoOlsenConceptTreeListViewComponent,
    OttoOlsenOrderTermsJeevesComponent,
    OttoOlsenOrderCardListComponent,
    OttoOlsenAvailabilityComponent,
    OttoOlsenJeevesSalesCartListComponent,
    OttoOlsenOrderDetailsComponent,
    OttoOlsenBuyBtnComponent,
    OttoOlsenProductTreeListViewComponent,
    OttoOlsenProductTreeTableViewComponent,
    OttoOlsenImageCarouselComponent,
    OttoOlsenProductInfoPageComponent,
    OttoOlsenFilterListComponent,
    OttoOlsenConceptDetailComponent,
    OttoOlsenProductTreeListComponent,
    OttoOlsenFreightCostComponent,
    OttoOlsenAssignJeevesDefaultComponent,
    OttoOlsenOrderTermsJeevesWithCartListComponent,
    OttoOlsenFavouriteProductIconOnlyComponent,
    OttoOlsenProductGridViewComponent,
    OttoOlsenAssortmentTreeListViewComponent,
    OttoOlsenCustomerDetailsComponent,
    OttoOlsenCustomersSummaryCardComponent,
    OttoOlsenLastTenOrdersCardComponent,
    OttoOlsenCreateProductComponent,
    OttoOlsenLastFullYearVsThisYearSalesSummaryCardComponent,
    OttoOlsenLoginComponent,
    OttoOlsenRequestAccountComponent,
    OttoOlsenAccountRequestsCardListComponent,
    OttoOlsenLastYearToDateVsThisYearToDateSalesSummaryCardComponent,
    OttoOlsenInvoiceCardListComponent,
    OttoOlsenInvoiceListComponent,
    OttoOlsenCreateUserModalComponent,
    OttoOlsenFooterComponent,
    OttoOlsenProductListAssortmentComponent,
    OttoOlsenCheckoutButtonsComponent,
    OttoOlsenSummarizeOrderComponent,
    OttoOlsenFavouriteProductGridViewComponent,
    OttoOlsenProductInlineSearchCardComponent,
    OttoOlsenInlineSearchNavbarComponent,
    OttoOlsenProductFastsearchCardComponent,
    OttoOlsenCuttingFeeComponent,
    OttoOlsenRemoveBlockedProductsStepComponent,
    OttoOlsenFilterListSideFilterComponent,
    OttoOlsenPaginationListComponent,
    OttoOlsenProductPaginationListComponent,
    OttoOlsenCustomerUserCardListComponent,
    OttoOlsenQuickSearchComponent,
    OttoOlsenQuickorderScannerComponent,
    OttoOlsenToastsComponent,
    OttoOlsenBarcodeScannerComponent,
    OttoOlsenProductsInCartComponent,
    OttoOlsenMobileProductDetailsComponent,
    OttoOlsenMobileStartPageComponent,
    OttoOlsenOrderMobileCardListComponent,
    OttoOlsenSavedCartTableComponent,
    OttoOlsenLoadSavedCartModalComponent,
    OttoOlsenDeleteSavedCartModalComponent,
    OttoOlsenAvailabilityModalComponent,
    OttoOlsenOrderTermsJeevesWithCartComponent,
    OttoOlsenScannerComponent,
    OttoOlsenScannerSearchComponent,
    OttoOlsenMobileAssortmentTreeNavigationComponent,
    OttoOlsenMobileProductDescriptionComponent,
    OttoOlsenScannerFooterMenuComponent,
    OttoOlsenMobileCheckoutComponent,
    OttoOlsenScannerCheckoutConfirmComponent,
    OttoOlsenViewer3dComponent,
    OttoOlsenScannerOptionsModalComponent,
    OttoOlsenView3dModelModalComponent,
    OttoOlsenSavedCartDetailsComponent,
    OttoOlsenOrderConfirmationComponent,
    OttoOlsenBetaSalespersonSelectComponent,
    OttoOlsenBetaLastVsThisMonthMeetingsComponent,
    OttoOlsenBetaMeetingsConvertedSalesComponent,
    OttoOlsenBetaInvoiceStatisticsChartCardComponent,
    OttoOlsenBetaInvoiceStatisticsChartComponent,
    OttoOlsenPriceListDisplayComponent,
    OttoOlsenUserTableComponent,
    OttoOlsenOrderNavigationButtonsComponent,
    OttoOlsenOpenOrderTableComponent,
    OttoOlsenDeliveryDatePickerComponent,
    OttoOlsenFavouriteProductTreeTableViewComponent,
    OttoOlsenFavouriteProductListComponent,
    OttoOlsenFreeScannerComponent,
    OttoOlsenNavbarcartComponent,
    OttoOlsenDemoAccountComponent,
    OttoOlsenCheckoutWrapperComponent,
    OttoOlsenManageUsersWrapperComponent,
    OttoOlsenManageUsersDetailsComponent,
    OttoOlsenManageUserDetailsUserComponent,
    OttoOlsenManageUserDetailsCustomersComponent,
    OttoOlsenAvailabilityWarehousesComponent,
    OttoOlsenAvailabilityWarehousesModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    GungCommonModule,
    GungStandardModule,
    GungStandardJeevesModule,
    GungListModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgxScannerQrcodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLocalLoader,
        deps: [TranslationLocalService]
      }
    })
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OttoOlsenBackendInterceptorService,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: BackendInterceptor,
      useClass: OttoOlsenBackendInterceptorService
    },
    {
      provide: TranslationLocalService,
      useClass: OttoOlsenTranslationLocalService
    },
    {
      provide: ProductExportListConfigService,
      useClass: OttoOlsenProductExportListConfigService
    },
    {
      provide: ProductListConfigService,
      useClass: OttoOlsenProductListConfigService
    },
    {
      provide: ProductFastSearchListConfigService,
      useClass: OttoOlsenProductFastSearchListConfigService
    },
    {
      provide: NavbarConfigService,
      useClass: OttoOlsenNavbarConfigService
    },
    {
      provide: CheckoutConfigService,
      useClass: OttoOlsenCheckoutConfigService
    },
    {
      provide: PriceConfigService,
      useClass: OttoOlsenPriceConfigService
    },
    {
      provide: AssortmentTreeNavigationConfigService,
      useClass: OttoOlsenAssortmentTreeNavigationConfigService
    },
    {
      provide: ConceptDetailProductListConfigService,
      useClass: OttoOlsenConceptDetailProductListConfigService
    },
    {
      provide: AssortmentTreeImageSelectionService,
      useClass: OttoOlsenAssortmentTreeImageSelectionService
    },
    {
      provide: SalesCartConfigService,
      useClass: OttoOlsenSalesCartConfigService
    },
    {
      provide: ProductService,
      useClass: OttoOlsenProductService
    },
    {
      provide: OrderListConfigService,
      useClass: OttoOlsenOrderListConfigService
    },
    {
      provide: AvailabilityHelperService,
      useClass: OttoOlsenAvailabilityHelperService
    },
    {
      provide: ConceptTreeListConfigService,
      useClass: OttoOlsenConceptTreeListConfigService
    },
    {
      provide: CartConfigService,
      useClass: OttoOlsenCartConfigService
    },
    {
      provide: ProductTreeListConfigService,
      useClass: OttoOlsenProductTreeListConfigService
    },
    {
      provide: CheckoutQuantityValidationService,
      useClass: OttoCheckoutQuantityValidationService
    },
    {
      provide: DateUtilService,
      useClass: OttoOlsenDateUtilService
    },
    {
      provide: AssortmentTreeListConfigService,
      useClass: OttoOlsenAssortmentTreeListConfigService
    },
    {
      useClass: OttoOlsenDashboardCardService,
      provide: DashboardCardService
    },
    {
      provide: JeevesCustomerDeliveryLocationListConfigService,
      useClass: OttoOlsenJeevesCustomerDeliveryLocationService
    },
    {
      provide: AccountRequestListConfigService,
      useClass: OttoOlsenAccountRequestListConfigService
    },
    {
      provide: JeevesInvoiceListConfigService,
      useClass: OttoOlsenInvoiceListConfigService
    },
    {
      provide: JeevesInvoiceActionConfigService,
      useClass: OttoOlsenInvoiceActionConfigService
    },
    {
      provide: NewsActionConfigService,
      useClass: OttoOlsenNewsActionConfigService
    },
    {
      provide: JeevesCustomerActionConfigService,
      useClass: OttoOlsenCustomerActionConfigService
    },
    {
      provide: UsersService,
      useClass: OttoOlsenUsersService
    },
    {
      provide: JeevesOrderActionConfigService,
      useClass: OttoOlsenOrderActionConfigService
    },
    {
      provide: FavouriteProductListConfigService,
      useClass: OttoOlsenFavouriteProductListConfigService
    },
    {
      provide: ProductPaginationListConfigService,
      useClass: OttoOlsenProductPaginationListConfigService
    },
    {
      provide: CustomerUserListConfigService,
      useClass: OttoOlsenCustomerUserListConfigService
    },
    {
      provide: BarcodeScannerConfigService,
      useClass: OttoOlsenBarcodeScannerService
    },
    {
      provide: SavedCartConfigService,
      useClass: OttoOlsenSavedCartConfigService
    },
    {
      provide: GungModalService,
      useClass: OttoOlsenModalService
    },
    {
      provide: CommonModalService,
      useClass: OttoOlsenModalService
    },
    {
      provide: ProductInputQuantityConfigService,
      useClass: OttoOlsenProductInputQuantityConfigService
    },
    {
      provide: BaseViewConfigService,
      useClass: OttoOlsenBaseViewConfigService
    },
    {
      provide: UsersListConfigService,
      useClass: OttoOlsenUsersListConfigService
    },
    {
      provide: CustomerListConfigService,
      useClass: OttoOlsenCustomerListConfigService
    },
    {
      provide: DeliveryLocationListConfigService,
      useClass: OttoOlsenDeliveryLocationListConfigService
    },
    {
      provide: OpenOrdersConfigService,
      useClass: OttoOlsenOpenOrdersListConfigService
    },
    {
      provide: CartsService,
      useClass: OttoOlsenCartService
    },
    {
      provide: JeevesDeliveryAddressesService,
      useClass: OttoOlsenDeliveryAddressesService
    },
    {
      provide: ProductExportService,
      useClass: OttoOlsenProductExportService
    },
    {
      provide: PriceService,
      useClass: OttoOlsenPriceService
    },
    {
      provide: GungLanguageConfigService,
      useClass: OttoOlsenLanguageConfigService
    },
    {
      provide: LanguageConfigService,
      useClass: OttoOlsenLanguageConfigService
    },
    {
      provide: HomeNavigationConfigService,
      useClass: OttoOlsenHomeNavigationConfigService
    },
    {
      provide: ProductExportActionConfigService,
      useClass: OttoOlsenProductExportActionConfigService
    },
    {
      provide: ColumnSortSelectionService,
      useClass: OttoOlsenColumnSortSelectionService
    },
    {
      provide: FilterListService,
      useClass: OttoOlsenFilterListService
    },
    {
      provide: GungImageUrlService,
      useClass: OttoOlsenImageUrlService
    },
    {
      provide: GOOGLE_TAG_MANAGER_ID,
      useValue: 'GTM-5SSMKVZ'
    },
    {
      provide: UserActionConfigService,
      useClass: OttoOlsenUserActionConfigService
    },
    {
      provide: EdiOrderActionConfigService,
      useClass: OttoOlsenEdiOrderActionConfigService
    }
  ],
  bootstrap: [OttoOlsenAppComponent]
})
export class AppModule {}
