<div class="modal-header px-5 border-0 justify-content-center">
  <h4 class="modal-title mt-0 text-primary" id="modal-basic-title"><span translate>ADD_ITEMS_TO_CART</span>&nbsp;</h4>
  <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div class="modal-body px-5 border-0">
  <div class="row">
    <div class="col-3">
      <span class="font-weight-bold" translate>DATE</span>:
    </div>
    <div class="col-9">
      <span>{{ data.date | date: dateUtilService.dateFormat }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <span class="font-weight-bold" translate>SHOPPING_CART</span>:
    </div>
    <div class="col-9">
      <span>{{ data.name }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <span class="font-weight-bold" translate>CUSTOMER_NAME</span>:
    </div>
    <div class="col-9">
      <span>{{ data.customer }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <span class="font-weight-bold" translate>NUMBER_OF_SPECIES</span>:
    </div>
    <div class="col-9">
      <span>{{ data.qty }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <span class="font-weight-bold" translate>CART_SUBTOTAL</span>:
    </div>
    <div class="col-9">
      <span>{{ data.total | number }}</span>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 font-weight-bold text-center">
      <span translate>WHAT_DO_YOU_WANT_TO_DO</span>?
    </div>
  </div>
</div>

<div class="modal-footer d-block px-5 border-0">
  <div class="row">
    <div class="col-12 text-center">
      <button class="btn btn-sm btn-primary btn-default px-4 mr-2" (click)="activeModal.close('add')" translate>
        ADD_TO_EXISTING_CART
      </button>
      <button class="btn btn-sm btn-primary btn-default px-4 ml-2" (click)="activeModal.close('replace')" translate>
        REPLACE_EXISTING
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <button class="btn btn-sm btn-outline-primary px-5 m-2" (click)="activeModal.close(false)" translate>CANCEL</button>
    </div>
  </div>
</div>
