export function setColors(primary, secondary) {
    let cssToAdd = '';
    if(primary){
        document.documentElement.style.setProperty('--bs-primary', primary);
        cssToAdd += global(primary, 'primary');
        cssToAdd += buttons(primary, 'primary');
        cssToAdd += checkoutStepper(primary);
        cssToAdd += badge(primary);
    }

    if(secondary){
        document.documentElement.style.setProperty('--bs-secondary', secondary);
        document.documentElement.style.setProperty('--bs-link-color', secondary);
        document.documentElement.style.setProperty('--bs-link-hover-color', colorLuminance(secondary, -0.5));
        cssToAdd += global(secondary, 'secondary');
        cssToAdd += buttons(secondary, 'secondary');

    }

    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.appendChild(document.createTextNode(cssToAdd)); 
    document.head.appendChild(styleElement); 
}

function buttons(color, cssClass){
    const css = `
        .btn-${cssClass} {
            --bs-btn-color: ${pickTextColor(color)};
            --bs-btn-bg: ${color};
            --bs-btn-border-color: ${color} ;
            --bs-btn-hover-bg: ${colorLuminance(color, 0.15)} ;
            --bs-btn-hover-color: ${pickTextColor(color)};
            --bs-btn-hover-border-color: ${colorLuminance(color, 0.15)};
            --bs-btn-active-bg: ${colorLuminance(color, 0.3)}
            --bs-btn-disabled-bg: ${colorLuminance(color, 0.7)};
            --bs-btn-disabled-border-color: ${colorLuminance(color, 0.7)} ;
            --bs-btn-active-border-color: ${colorLuminance(color, 0.15)};
            --bs-btn-disabled-color:  ${pickTextColor(color)};
            --bs-btn-disabled-bg:  ${color};
            background:${color};
        }

        .btn-outline-${cssClass}{
            --bs-btn-color: ${color};
            --bs-btn-border-color: ${color};
            --bs-btn-hover-color: ${pickTextColor(color)};
            --bs-btn-hover-bg: ${color};
            --bs-btn-hover-border-color: ${color};
            --bs-btn-focus-shadow-rgb: 253, 0, 0;
            --bs-btn-active-color: ${pickTextColor(color)};
            --bs-btn-active-bg: ${color};
            --bs-btn-active-border-color: ${color}
            --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --bs-btn-disabled-color: ${color};
            --bs-btn-disabled-bg: transparent;
            --bs-btn-disabled-border-color: ${color};
        }
          
    `;
    

    return css;
}

function checkoutStepper(color){
    const css = `
        .stepper-indicator, .stepper-indicator:before, .stepper-indicator:last-child:after {
            background-color: ${colorLuminance(color, 1.3)} !important;
        }
    `;
    return css;
}

function global(color, cssClass){
    const css = `
        .bg-${cssClass}{
            background-color: ${color} !important;
            color: ${pickTextColor(color)};
        }
            
    `;
    return css;
}

function badge(color){
    const css = `
        .badge.badge-primary{
            background-color: ${color} !important;
            color: ${pickTextColor(color)} !important;
        }
            
    `;
    return css;
}


/**
 * 
 * @param hex  - a hex color value such as “#abc” or “#123456” (the hash is optional)
 * @param lum  -  the luminosity factor, i.e. -0.1 is 10% darker, 0.2 is 20% lighter, etc.
 * @returns - color in rgb
 */

function colorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}



function pickTextColor(backgroundColor: string): string {
    // Convert hex to RGB
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // Return black for light backgrounds and white for dark backgrounds
    return luminance > 0.5 ? 'black' : 'white';
  }