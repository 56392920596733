import { Injectable } from '@angular/core';
import { Availability, CustomerProductPrice, Product, ProductExtended, ProductInputQuantityConfigService, SimplifiedAvailability } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductInputQuantityConfigService extends ProductInputQuantityConfigService {
  getMinimumOrderQuantity(productId: string, product?: ProductExtended): number {
    let minimumOrderQuantity;
    if (product?.extra?.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
      minimumOrderQuantity = Number(product.extra.ar.q_salesbatchsize);
    }
    if (product?.extra?.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
      minimumOrderQuantity = Number(product.extra.ar.q_salesmoq);
    }

    return minimumOrderQuantity || this.getStepAmount(productId, product);
  }

  getStepAmount(productId: string, product?: ProductExtended): number {
    return product?.packageSize || 1;
  }

  getRecomendedInitOrderQuantity(productId: string, product?: ProductExtended): number {
    return 1;
  }

  showDecimals(productId?: string, product?: Product): number {
    return product?.extra?.ar?.antdec || 0;
  }

  getAllowManualQtyInputOutsideStepSizes(
    productId: string,
    product?: Product,
    price?: CustomerProductPrice,
    isAnonymous?: boolean,
    isUser?: boolean,
    isSales?: boolean,
    isAdmin?: boolean,
    isActuator?: boolean
  ): boolean {
    return product?.extra?.ar?.q_jis_fast_pakke_strl !== '1';
  }

  getAllowPurchaseOverAvailability(quantity: number, availability: Availability | SimplifiedAvailability, product: Product, roles: string[]): boolean {
    if (product?.extra.pim.arItemStatusCode.code === "8" && quantity > availability?.currentAvailability) {
      return false;
    }
    return true;
  }
}
