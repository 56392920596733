import { Component } from '@angular/core';
import { JeevesProductCardComponent } from 'gung-standard-jeeves';
import {
  PriceService,
  GungFlowService,
  AuthService,
  ProductExtended,
  Customer,
  SelectedCustomerService
} from 'gung-standard';
import { Location } from '@angular/common';
import { OttoOlsenModalService } from '../../services/otto-olsen-modal.service';
import { environment } from './../../../environments/environment';
import { first } from 'rxjs';

@Component({
  selector: 'otto-olsen-product-card',
  templateUrl: './otto-olsen-product-card.component.html',
  styleUrls: ['./otto-olsen-product-card.component.scss']
})
export class OttoOlsenProductCardComponent extends JeevesProductCardComponent {
  public requestPriceEmail = environment.requestPriceEmail;

  data: ProductExtended;
  isExport = false;
  public isAnonymous = true;
  selectedCustomer: Customer;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected modalService: OttoOlsenModalService,
    protected location: Location,
    protected authService: AuthService,
    private selectedCustomerService: SelectedCustomerService
  ) {
    super(priceService, gungFlowService, authService);
    this.authService
      .getHasRoles('ANONYMOUS')
      .pipe(first())
      .subscribe(isAnonymous => (this.isAnonymous = isAnonymous));
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
  }

  clickProduct(productId) {
    // this.router.navigate(['/products/p/'+productId]);
    this.location.go(this.location.path() + '/p/' + productId);
    this.modalService.openProductDetailsModal(productId);
  }
}
