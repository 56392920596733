import { Injectable } from '@angular/core';
import { ListLayoutMultipleComponent, ListLayout, PimTemplateProperties, ConfigBaseFilter } from 'gung-list';
import {
  ProductTreeListConfigService,
  ProductService,
  AuthService,
  ProductSortService,
  MetadataService,
  Product,
  GungFlowService,
  DynamicColumnsService,
  ColumnSortSelectionService,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { first, map, Observable } from 'rxjs';
import { ProductExtended } from '../components/otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenProductTreeListViewComponent } from '../components/otto-olsen-product-tree-list-view/otto-olsen-product-tree-list-view.component';
import { OttoOlsenProductTreeTableViewComponent } from '../components/otto-olsen-product-tree-table-view/otto-olsen-product-tree-table-view.component';
import { OttoOlsenAvailabilityFilter } from './otto-olsen-concept-detail-product-list-config.service';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductTreeListConfigService extends ProductTreeListConfigService {
  stockId;
  isAnonymous: boolean = true;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected dynamicColumnsService: DynamicColumnsService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(productService, authService, productSortService, gungFlowService, metadataService, dynamicColumnsService, cloudPimFiltersService, currentAssortmentService);
    this.authService.getCurrentUser().pipe(first()).subscribe(user => {
      this.isAnonymous = user.roles.includes('ANONYMOUS');
      this.stockId = user.managedMultistockIds[0];
    });
  }

  getLayouts(): ListLayout<Product>[] {
    if (this.pimOptions && this.pimOptions.showAsList) {
      return [
        {
          getIconClass: () => 'fa fa-list',
          getListItemComponent: () => OttoOlsenProductTreeTableViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductList'
        }
      ];
    }
    return [
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => OttoOlsenProductTreeListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getDynamicColumns(): PimTemplateProperties[] {
    const columns = super.getDynamicColumns();
    if (this.pimOptions && this.pimOptions.showAsList) {
      const filtered = columns.filter(c => c.isDisplayList || c.path === 'availabilities' || c.path === 'id');
      return filtered;
    }
    return columns;
  }

  getItems(): Observable<ProductExtended[]> {
    return super.getItems().pipe(
      map((products: ProductExtended[]) =>
        products.map(product => {
          // Product package size
          if (
            product.extra.ar?.q_jis_fast_pakke_strl &&
            product.extra.ar.artfsgforp &&
            Number(product.extra.ar.artfsgforp) > 0
          ) {
            product.packageSize = Number(product.extra.ar.artfsgforp);
          }
          // Product original size without cutting
          if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
            product.originalSize = Number(product.extra.ar.artfsgforp);
          }
          if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
            product.packageSize = Number(product.extra.ar.q_salesbatchsize);
          }
          if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
            product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
          }
          return product;
        })
      )
    );
  }

  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    const filters = this.filters;
    const indexStock = filters.findIndex(filter => filter.getName() === 'STOCK_FILTER');
    if (!this.isAnonymous && indexStock < 0) {
      filters.push(new OttoOlsenAvailabilityFilter(this.stockId));
    }

    return filters;
  }
}
