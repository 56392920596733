import { Injectable } from '@angular/core';
import { Language } from 'gung-common';
import { LanguageConfigService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenLanguageConfigService extends LanguageConfigService {
  protected defaultLanguage = 'no';

  public getLanguages(): Language[] {
    return [
      {
        language: 'norwegian',
        short: 'no',
        image: 'norway-flag-round-icon-256.png',
        enabled: true
      },
      {
        language: 'english',
        short: 'en',
        image: 'united-kingdom-flag-round-icon-256.png',
        dateFormat: 'MM/dd/yyyy',
        enabled: true
      },
    ];
  }
}
