import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { UsersService, BackendInterceptor, NavigationConfig } from 'gung-standard';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs';

export interface User {
  username: string;
  name: string;
  roles: string[];
  email: string;
  managedMultistockIds: string[];
  managedCompanyIds: string[];
  managedAssortmentIds: string[];
  assortment: string;
  password: string;
  activeGroups: { [s: string]: any };
  phone: string;
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenUsersService extends UsersService {
  constructor(protected http: HttpClient, 
    protected backendInterceptor: BackendInterceptor,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    super(http, backendInterceptor, environment);
  }

  getDefaultUserRoles() {
    return of([
      { selected: false, id: 'ACTUATOR' },
      { selected: false, id: 'ADMIN' },
      { selected: false, id: 'SALES' },
      { selected: false, id: 'USER' },
      { selected: false, id: 'VIEW' },
      { selected: false, id: 'SCANNER' },
      { selected: false, id: 'ASSORTMENT-MANAGER' },
      { selected: false, id: 'PRODUCT-MANAGER' },
      { selected: false, id: 'DEMO' },
    ]);
  }

  createUser(user: User): Observable<User> {
    if (!user.password) {
      user.password = '';
    }

    this.sendUserConfirmationMail(user);

    return this.http.post<User>('json/users', user).pipe(tap(() => this.updateUsersListSubject()));
  }

  protected mapParams(user: User) {
    return {
      username: user.username,
      email: user.email,
      customer: ''
    };
  }

  sendUserConfirmationMail(user: User): void {
    const params = this.mapParams(user);

    this.http.post<User>('json/otto-new-user', params).pipe(first()).subscribe();
  }
}

export interface EmailParams {
  username: string;
  email: string;
  customer: string;
}
