import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ButtonsComponent,
  NavbarCartService,
  GungModalService,
  SaveCartModalConfigService,
  CartService,
  AuthService,
  SelectedCustomerService,
  GungFlowService,
  CheckoutConfigService
} from 'gung-standard';
import { forkJoin, Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs';

@Component({
  selector: 'otto-olsen-checkout-buttons',
  templateUrl: './otto-olsen-checkout-buttons.component.html',
  styleUrls: ['./otto-olsen-checkout-buttons.component.scss']
})
export class OttoOlsenCheckoutButtonsComponent extends ButtonsComponent implements OnInit {
  public orderBlocked = false;
  isView = false;
  cartRows;
  isDemo = false;

  constructor(
    protected navbarCartService: NavbarCartService,
    protected gungModalService: GungModalService,
    protected saveCartModalConfigService: SaveCartModalConfigService,
    protected cartService: CartService,
    protected translationService: TranslateService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected http: HttpClient,
    protected checkoutConfig: CheckoutConfigService
  ) {
    super(
      navbarCartService,
      gungModalService,
      saveCartModalConfigService,
      cartService,
      translationService,
      authService,
      checkoutConfig
    );
  }

  ngOnInit() {
    this.updateData();
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.orderBlocked = roles.filter(role => role.toUpperCase() === 'ORDERBLOCKED').length > 0;
        this.isSales = roles.filter(role => role.toUpperCase() === 'SALES').length > 0;
        this.isView = roles.filter(role => role.toUpperCase() === 'VIEW').length > 0;
        this.isDemo = roles.findIndex(r => r === 'DEMO') > -1;
      });

    this.navbarCartService
      .getContents()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(contents => {
        this.cartTotalValue = contents.totalValue;
        this.cartCurrency = contents.currency;
        this.cartTotalRows = contents.rows.length;
        this.cartRows = contents.rows;
        this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
      });
  }

  saveCartToExcel() {
    const email = prompt('Please enter your email');
    if (!email) {
      return;
    }
    forkJoin({
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      flow: this.gungFlowService.getSelectedFlow().pipe(first())
    })
      .pipe(first())
      .subscribe(({ customer, flow }) => {
        const requestPayload = {
          definitionId: 'CART_EXPORT',
          extra: {
            customerId: customer.id,
            flowId: flow.id
          },
          filename: 'cart-data.xlsx',
          mailTitle: 'Cart information',
          recipient: email,
          rows: this.cartRows.filter(row => row.product?.extra?.ar?.webarttext !== 'configure')
        };

        this.requestExcelToEmail(requestPayload).subscribe(data => { });
      });
  }

  updateData() {
    super.updateData();
    this.isLastStep = this.allSteps.indexOf(this.currentStep) === this.allSteps.length - 2;
  }

  requestExcelToEmail(payload): Observable<any> {
    return this.http.post<any>('download/cart/excel', payload);
  }
}
