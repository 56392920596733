import { Injectable } from '@angular/core';
import { Invoice, InvoiceService, SelectedCustomerService } from 'gung-standard';
import { ConfigBaseFilter, ListLayout, ListLayoutSingleComponent, SimpleConfigBaseFilter } from 'gung-list';
import { InvoiceStatusFilter, JeevesInvoiceListConfigService } from 'gung-standard-jeeves';
import { TranslateService } from '@ngx-translate/core';
import { OttoOlsenInvoiceCardListComponent } from '../components/otto-olsen-invoice-card-list/otto-olsen-invoice-card-list.component';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenInvoiceListConfigService extends JeevesInvoiceListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected invoiceService: InvoiceService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    super(translationService, invoiceService, selectedCustomerService);
  }

  getLayouts(): ListLayout<Invoice>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenInvoiceCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Invoice grid'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<Invoice>[] {
    const filters = super.getFilters().filter(filter => {
      return !(filter instanceof InvoiceStatusFilter);
    });
    filters.push(new OttoOlsenOverdueInvoiceFilter());
    filters.push(new OttoOlsenInvoiceStatusFilter(this.translationService));

    return filters;
  }

  getSearchTerms(item: Invoice): string[] {
    const terms = super.getSearchTerms(item);

    if (item?.extra?.fh?.ordernr  ) {
      terms.push(item.extra.fh.ordernr + '');
    }
    if (item?.extra?.fh?.kundbetalarenr  ) {
      terms.push(item.extra.fh.kundbetalarenr);
    }
    if (item?.extra?.fh?.kundref2 ) {
      terms.push(item.extra.fh.kundref2);
    }
    
    return terms;
  }

  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }
}

export class OttoOlsenOverdueInvoiceFilter extends SimpleConfigBaseFilter<Invoice> {
  type = 'dateRangeFilter';

  getName(): string {
    return 'OVERDUE_INVOICES';
  }

  getOptionIds(item: Invoice): string[] {
    return [format(new Date(item.extra.fh.faktffdat), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return key;
  }
}


export class OttoOlsenInvoiceStatusFilter extends SimpleConfigBaseFilter<Invoice> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'FH_FAKTSTKOD';
  }

  getOptionIds(item: Invoice): string[] {
    let today = new Date();
    today.setHours(0,0,0,0);
    let overdueDate = new Date(item.extra.fh.faktffdat);
    //overdueDate.setHours(5,0,0,0);


    if (item.extra.fh.faktstkod == null) {
      return ['UNDEFINED'];
    }
    if (item.extra.fh.faktstkod == 0 && overdueDate < today) {
      return ['OVERDUE'];
    }
    return [`FH_FAKTSTKOD_${item.extra.fh.faktstkod}`];
  }
  
  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}
