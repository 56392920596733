import { Component, OnInit, Optional, OnDestroy } from '@angular/core';
import { ItemSelectionButtonComponent, AbstractSelectionService } from 'gung-list';

@Component({
  selector: 'otto-olsen-item-selection-button',
  templateUrl: './otto-olsen-item-selection-button.component.html',
  styleUrls: ['./otto-olsen-item-selection-button.component.scss']
})
export class OttoOlsenItemSelectionButtonComponent<T>
  extends ItemSelectionButtonComponent<T>
  implements OnInit, OnDestroy
{
  constructor(@Optional() protected selectionService: AbstractSelectionService<T>) {
    super(selectionService);
  }
}
