import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';
import { AssortmentService, AuthService, Cart, CartRow, CartService, CartsService, GungModalService, PriceService, Product, ProductInputQuantityConfigService, ProductService, SavedCart, SavedCartTableComponent } from 'gung-standard';
import { first } from 'rxjs';
import { OttoOlsenDeleteSavedCartModalComponent } from '../otto-olsen-delete-saved-cart-modal/otto-olsen-delete-saved-cart-modal.component';
import { OttoOlsenLoadSavedCartModalComponent } from '../otto-olsen-load-saved-cart-modal/otto-olsen-load-saved-cart-modal.component';
import { ProductExtended } from '../otto-olsen-product-details/otto-olsen-product-details.component';
import { User } from 'gung-standard/lib/state/auth/types';

interface SavedCartRow extends CartRow {
  product: ProductExtended;
}
@Component({
  selector: 'otto-olsen-saved-cart-table',
  templateUrl: './otto-olsen-saved-cart-table.component.html',
  styleUrls: ['./otto-olsen-saved-cart-table.component.scss']
})
export class OttoOlsenSavedCartTableComponent extends SavedCartTableComponent implements OnInit {
  _isCollapsedCart: { [s: string]: boolean } = {};

  isCartDetail = false;

  currentUser: User;

  constructor(
    protected translateService: TranslateService,
    protected cartsService: CartsService,
    protected productService: ProductService,
    protected cartService: CartService,
    protected modalService: GungModalService,
    public dateUtilService: DateUtilService,
    protected priceService: PriceService,
    protected ngbModal: NgbModal,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected assortmentService: AssortmentService, 
    protected route: ActivatedRoute,
    protected router: Router,
    protected authService: AuthService
  ) {
    super( translateService,
      cartsService,
      productService,
      cartService,
      modalService,
      dateUtilService,
      priceService,
      ngbModal,
      productInputQuantityConfigService,
      assortmentService
      );
  }

  ngOnInit(){
    const routeParams = this.route.snapshot.params;
    // this is only to saved cart details eg: cart/6239cfbd08ae973654078a8e
    if (routeParams.cartId != null) {
        this._isCollapsedCart[routeParams.cartId] = true;
        this.isCartDetail = true
        setTimeout(() => {
          this.fetchProductData(this.data[0]);
        }, 1000);
    }

    this.authService.getCurrentUser().pipe(first()).subscribe(user => this.currentUser = user);
  }

  openCartDetailTab(cart) {
    // Converts the route into a string that can be used 
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/cart/${cart.id}`])
    );
  
    window.open(url, '_blank');
  }

  onSave(cart: SavedCart) {
    cart.data.rows = cart.data.rows.filter(r => r.qty > 0);
    this.refreshQty(undefined, cart);
    delete cart.extra._isEditCart;
    const cartCopy = JSON.parse(JSON.stringify(cart));
    delete cartCopy.extra.customer;

    // Add updated last updated data
    cartCopy.extra.lastUpdated = {
      by: this.currentUser.name,
      at: new Date().getTime()
    }

    this.cartsService.updateSavedCart(cartCopy).subscribe(newCart => {
      this.mapData[cart.id] = undefined;
      this.fetchProductData(newCart);
    });
  }

  updateQuantity(qty, row: SavedCartRow, cart: SavedCart, event?) {
    const cartRow = cart.data.rows.find(r => r.productId === row.productId);
    const cartRow2 = this.mapData[cart.id].find(r => r.productId === row.productId);
    let { stepAmount, decimals, minimumOrderQuantity, recomendedInitOrderQuantity } = this.getProductValues(
      cartRow2.product as ProductExtended
    );
    if (typeof qty === 'string') {
      qty = Number(qty);
    }
    const checkModulus = this.checkModulus(qty, stepAmount);
    if (qty >= 0 && !checkModulus.multiple) {
      if ((cartRow2.qty - Math.floor(cartRow2.qty)) !== 0 && cartRow2.product?.extra?.ar?.q_jis_fast_pakke_strl !== '1') {
        qty = +qty.toFixed(decimals);
      } else {
        qty = checkModulus.remainder;
      }
    } else if (qty >= 0 && checkModulus.remainder >= stepAmount) {
      qty = checkModulus.remainder;
    }
    qty = +qty.toFixed(decimals);

    cartRow.qty = qty;
    cartRow2.qty = qty;
    event.value = qty;
  }

  fetchProductData(cart: Cart): void {
    this.fetchData(cart).subscribe(data => {
      this.setMappedData(cart.id, data);
      cart.extra._total = this.totalPrice;
      cart.extra._currencyCode = this.currencyCode;
    });
  }

  deleteRow(cartPosition: number, row: CartRow, cart: SavedCart) {
    cart.data.rows = cart.data.rows.filter(r => r.productId !== row.productId);
    this.updateMappedData(cart.id, cart.data.rows);
  }

  refreshQty(row: CartRow, cart: SavedCart) {
    this.totalPrice = 0;
    const mapDataCart = this.mapData[cart.id];
    for (const c of mapDataCart) {
      const price = c.product.extra.price.value;
      this.totalPrice += c.qty * price;
    }
    cart.extra._total = this.totalPrice;
  }

  /* private updateMappedData(cartId: string, data: { qty: number; product: Product; productId: string }[]): void {
    // include only all the row whose product is not excluded
    if (this.mapData[cartId]) {
      this.mapData[cartId] = data
        .filter(row => !!row.product)
        .map(row => {
          return {
            qty: row.qty,
            product: row.product,
            productId: row.productId
          };
        });
    }
  } */

  checkModulus(value: number, precision: number): { multiple: boolean; remainder: number } {
    value = Number(value);
    precision = Number(precision || 1);
    const result = { multiple: false, remainder: 0 };
    // result.multiple = Math.round(value / precision) / (1 / precision) === value;
    result.multiple = Number.isInteger(value / precision);
    let remainder = Math.round(value / precision) / (1 / precision);
    if (remainder < value) {
      remainder = remainder + precision;
    }
    result.remainder = remainder;
    return result;
  }

  protected mapDataProduct(qty, product, row) {
    // Product package size
    if (
      product?.extra.ar?.q_jis_fast_pakke_strl &&
      product?.extra.ar?.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 0
    ) {
      product.packageSize = Number(product.extra.ar.artfsgforp);
    }
    // Product original size without cutting
    if (product?.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
      (product as ProductExtended).originalSize = Number(product.extra.ar.artfsgforp);
    }
    if (product?.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
      product.packageSize = Number(product.extra.ar.q_salesbatchsize);
    }

    // Product package size
    if (
      product?.extra.ar &&
      product.extra.ar.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 0
    ) {
      product.packageSize = Number(product.extra.ar.artfsgforp);
    }
    if (product?.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
      (product as ProductExtended).firstStepAmount = Number(product.extra.ar.q_salesmoq);
    }
    return super.mapDataProduct(qty, product, row);
  }

  loadCart(cart: SavedCart) {
    this.fetchData(cart).subscribe(() => {
      const qtys = cart.data.rows.map(r => r.qty);

      const dataModal: { date: Date; total: number; qty: number; customer: string; name: string } = {
        date: cart.creationDate,
        total: this.totalPrice,
        qty: qtys.reduce((a, b) => a + b, 0),
        customer: cart.extra?.customer?.name ? cart.extra?.customer?.name + ' (' + cart.customerId + ')' : '',
        name: cart.name
      };

      const ref = this.ngbModal.open(OttoOlsenLoadSavedCartModalComponent, {
        size: 'md'
      });
      ref.componentInstance.delegate = this;
      ref.componentInstance.data = dataModal;
      ref.result.then(
        result => {
          if (result) {
            // if (!this.accordion.isExpanded(cart.id)) {
            //   this.accordion.expand(cart.id);
            // }
            if (result === 'replace') {
              this.loadProductToCart(cart, true);
            } else if (result === 'add') {
              this.loadProductToCart(cart, false);
            }
          } else {
            // this.accordion.collapse(cart.id);
          }
        },
        () => {
          // Keep panel closed
          // this.accordion.collapse(cart.id);
        }
      );
    });
  }

  deleteCart(cart: SavedCart) {
    const dataModal: { date: Date; customer: string; name: string } = {
      date: cart.creationDate,
      customer: cart.extra?.customer?.name ? cart.extra?.customer?.name + ' (' + cart.customerId + ')' : '',
      name: cart.name
    };

    const ref = this.ngbModal.open(OttoOlsenDeleteSavedCartModalComponent, {
      size: 'md'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.data = dataModal;
    ref.result.then(
      result => {
        if (result) {
          const loadedCardId = this.cartsService.getLoadedCardId();
          if (loadedCardId && loadedCardId === cart.id + '#' + cart.name) {
            this.cartsService.removeLoadedCardId();
          }
          this.cartsService
            .deleteCart(cart.id)
            .pipe()
            .subscribe(() => {
              this.cartsService.updateSavedCartsSubject();
            });
        }
      },
      () => undefined
    );
  }

  getProductValues(product: ProductExtended) {
    let stepAmount =
      (product as ProductExtended)?.packageSize ||
      this.productInputQuantityConfigService.getStepAmount(product.id, product);
    const decimals = product?.extra.ar.antdec || 0;
    if (decimals && !stepAmount) {
      let stepAmount2 = '0.0000000001';
      stepAmount2 = stepAmount2.substring(0, 2) + stepAmount2.substring(stepAmount2.length - decimals);
      stepAmount = +parseFloat(stepAmount2);
    }
    return {
      stepAmount,
      decimals,
      minimumOrderQuantity: this.productInputQuantityConfigService.getMinimumOrderQuantity(product.id, product),
      recomendedInitOrderQuantity: this.productInputQuantityConfigService.getRecomendedInitOrderQuantity(
        product.id,
        product
      )
    };
  }

  sub(cartPosition: number, row: SavedCartRow, cart: SavedCart, event?) {
    const cartRow2 = this.mapData[cart.id].find(r => r.productId === row.productId);
    let { stepAmount, decimals, minimumOrderQuantity, recomendedInitOrderQuantity } = this.getProductValues(
      cartRow2.product as ProductExtended
    );
    let qty = cartRow2.qty;
    if (qty - stepAmount >= minimumOrderQuantity) {
      qty -= stepAmount;
    } else {
      qty = 0;
    }
    this.updateQuantity(qty, row, cart, event);
  }

  add(cartPosition: number, row: SavedCartRow, cart: SavedCart, event?) {
    const cartRow = cart.data.rows.find(r => r.productId === row.productId);
    const cartRow2 = this.mapData[cart.id].find(r => r.productId === row.productId);
    let { stepAmount, decimals, minimumOrderQuantity, recomendedInitOrderQuantity } = this.getProductValues(
      cartRow2.product as ProductExtended
    );
    let qty = cartRow2.qty;
    if (!qty) {
      qty = Math.max(minimumOrderQuantity, stepAmount, recomendedInitOrderQuantity);
    } else {
      qty += stepAmount;
    }
    this.updateQuantity(qty, row, cart, event);
  }
}
