import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { parse } from 'date-fns';
import {
  AuthService,
  PriceService,
  AvailabilityService,
  Availability,
  TableRecord,
  Customer,
  SelectedCustomerService,
  MetadataService,
  DeliveryDateService,
  CalendarDate,
  CartService,
  CartRow
} from 'gung-standard';
import { OrderTermsJeevesComponent } from 'gung-standard-jeeves';
import {
  OttoOlsenFreightCostService,
  OttoOlsenFreightCondition
} from '../../../../services/otto-olsen-freight-cost.service';
import { forkJoin } from 'rxjs';
import { first, map } from 'rxjs';
import { OttoCheckoutStateServiceService } from '../../../../services/utils/otto-checkout-state-service.service';

export const getMinDate = (av: Availability, quantity: number): Date => {
  if (av.currentAvailability > 0) {
    return new Date();
  }
  if (av.maxFutureStock === 0) {
    return new Date();
  }
  const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] >= quantity);
  const dateFormat = 'yyMMdd';
  return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
};

const getMinDateSuper = (av: Availability): Date => {
  if (av.currentAvailability > 0) {
    return new Date();
  }
  if (av.maxFutureStock === 0) {
    return new Date();
  }
  const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] > 0);
  const dateFormat = 'yyMMdd';
  return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
};

@Component({
  selector: 'otto-olsen-order-terms-jeeves',
  templateUrl: './otto-olsen-order-terms-jeeves.component.html',
  styleUrls: ['./otto-olsen-order-terms-jeeves.component.scss']
})
export class OttoOlsenOrderTermsJeevesComponent extends OrderTermsJeevesComponent implements OnInit {
  partDeliveryEntries: { id: string; name: string }[] = [];
  currentCustomer: Customer;
  pushDeliveryDate = false;
  dates: CalendarDate[];
  isUser: boolean;

  freightRow: CartRow;
  isFreight = false;
  noFreight = false;
  cartRows: CartRow[];

  deliveryMethod22 = false;

  constructor(
    protected cartService: CartService,
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected datePipe: DatePipe,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    private selectedCustomerService: SelectedCustomerService,
    private metadataService: MetadataService,
    protected deliveryDateService: DeliveryDateService,
    private freightCostService: OttoOlsenFreightCostService,
    protected ottoCheckoutState: OttoCheckoutStateServiceService
  ) {
    super(authService, formBuilder, datePipe, availabilityService);
  }

  ngOnInit() {
    // super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked2());

    this.cartService
      .getCurrentCart()
      .pipe(
        map(cartRows => {
          this.cartRows = cartRows;
        })
      )
      .subscribe();

    this.initForm();
    this.initPartialDeliveries();

    this.freightCostService
      .calculateFreight()
      .pipe(first())
      .subscribe(freightCondition => {
        this.isFreight = freightCondition.customerApplyFreight && freightCondition.totalValue < 500;
        this.deliveryMethod22 = '' + this.checkout.extra.procargs?.levsattkod === '22';
        if ('' + this.checkout.extra.procargs?.levsattkod === '22') {
          // this.noFreight = true;
        }
      });

    this.initDeliveryDatesBasedOnDeliveryMethod();
  }

  public initDeliveryDatesBasedOnDeliveryMethod() {
    forkJoin([
      this.availabilityService
        .getAvailabilities(
          this.cartRows.map(r => r.productId),
          undefined,
          true
        )
        .pipe(first()),
      this.deliveryDateService.getDeliveryDates(this.form.get('levsattkod').value).pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.authService.getRoles().pipe(first())
    ]).subscribe(([avs, dates, customer, roles]) => {
      this.currentCustomer = customer;
      this.dates = dates;
      this.ottoCheckoutState.dates = dates;

      this.isUser = roles.length === 1 && roles.includes('USER');
      // Calculate delivery date when delivery method changes
      this.checkPushDeliveryDate(this.form.get('levsattkod').value);
      this.form.get('levsattkod').valueChanges.subscribe(value => {
        this.checkPushDeliveryDate(value);
        this.handlePartDeliveryChange();
      });
      if (this.checkout.extra.oh.dellevtillaten !== '10') {
        this.assignRowDates();
      }

      avs = avs.map(av => ({ ...av, availabilities: av.extra.availabilities }));
      const nowDate = new Date();
      let minDate = nowDate;
      for (const row of this.cartRows) {
        const av = avs.find(a => a.productId === row.productId);
        if (av) {
          const tempDate = getMinDateSuper(av);
          if (minDate < tempDate) {
            minDate = tempDate;
          }
        }
        // if (row.extra.orp.ordberlevdat) {
        //   const tempDate = new Date(row.extra.orp.ordberlevdat);
        //   if (minDate < tempDate) {
        //     minDate = tempDate;
        //   }
        // }
      }
      // If now date is today and q_jis_cutoff_hour of delivery method is already past
      if (this.pushDeliveryDate && nowDate.getTime() === minDate.getTime()) {
        minDate.setDate(minDate.getDate() + 1);
        //    minDate = this.setMinDate();
      }
      this.minDate = minDate;
      this.loadingComponent = false;
    });
  }

  private handleNextButtonClicked2(): void {
    if (this.form.invalid || this.loadingComponent) {
      console.log(this.form.invalid);
      return;
    }

    this.checkout.extra.applyFreight = this.isFreight && !this.noFreight;

    this.assignFormValues(this.form);
    this.stepDone.emit(this.checkout);
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      dellevtillaten: this.formBuilder.control(this.checkout.extra.oh.dellevtillaten || '0'),
      levsattkod: this.formBuilder.control('' + this.checkout.extra.procargs.levsattkod || ''),
      levvillkkod: this.formBuilder.control('' + this.checkout.extra.procargs.levvillkkod || ''),
      betkod: this.formBuilder.control(this.checkout.extra.oh.betkod || ''),
      saljare: this.formBuilder.control(this.checkout.extra.procargs.saljare || '')
    });

    this.ottoCheckoutState.selectedDeliveryMethod = this.checkout.extra.procargs.levsattkod;
  }

  checkPushDeliveryDate(levsattkod: string) {
    // CHECK IF CURRENT TIME AFTER 16H = ALWAYS PUSH NEXT DAY
    if (new Date().getHours() >= 16) {
      this.pushDeliveryDate = true;
      return;
    }
    let cutoffHour = this.metadataService.getMetadataValue('x2f', 'q_jis_cutoff_hour', levsattkod);
    /*
    The hardcoded value change below was made since we needed it for a demo within an hour. There was no time to change
    the table in jeeves, however it would be better to update in jeeves and then remove this hardcoded override of the
    cut off hour.
     */
    if (!cutoffHour && levsattkod === '22') {
      cutoffHour = '15';
    }
    if (cutoffHour && new Date().getHours() >= Number(cutoffHour)) {
      this.pushDeliveryDate = true;
    } else {
      this.pushDeliveryDate = false;
    }
  }

  setMinDate(): Date {
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() + 1);
    const firstDateAvailable = this.deliveryDateService.findFirstAvailableDate(this.dates, nowDate);
    this.minDate = firstDateAvailable.date;
    return firstDateAvailable.date;
  }

  private initPartialDeliveries(): void {
    this.partDeliveryEntries = [
      { id: '0', name: 'OH_DELLEVTILLATEN_0' },
      { id: '10', name: 'OH_DELLEVTILLATEN_10' }
    ];
  }

  handlePartDeliveryChange(): void {
    this.loadingComponent = true;
    const partDeliveryFormControlName = 'dellevtillaten';
    const selectedPArtDeliveryValue = this.form.controls[partDeliveryFormControlName].value || '';
    this.checkout.extra.oh.dellevtillaten = selectedPArtDeliveryValue;
    if (this.checkout.extra.oh.dellevtillaten === '0') {
      this.ottoCheckoutState.partDeliveriesForbidden = false;
      delete this.checkout.extra.oh.ordberlevdat;
      this.ottoCheckoutState.selectedDeliveryDate = null;
      this.assignRowDates();
    } else {
      this.ottoCheckoutState.partDeliveriesForbidden = true;
      delete this.minDate;
      this.availabilityService
        .getAvailabilities(
          this.cartRows.map(r => r.productId),
          undefined,
          true
        )
        .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
        .subscribe(avs => {
          const nowDate = new Date();
          let minDateFromAvailability = nowDate;

          // let maxAssignedDate = '1970-01-01';
          for (const row of this.cartRows) {
            const av = avs.find(a => a.productId === row.productId);
            if (av) {
              const tempDate = getMinDate(av, row.qty);
              if (minDateFromAvailability < tempDate) {
                minDateFromAvailability = tempDate;
              }
            }
            // if (row.extra._user_selected_ordberlevdat && row.extra._user_selected_ordberlevdat > maxAssignedDate) {
            //   maxAssignedDate = row.extra._user_selected_ordberlevdat;
            // }
          }
          // If now date is today and q_jis_cutoff_hour of delivery method is already past
          if (this.pushDeliveryDate && nowDate.getTime() === minDateFromAvailability.getTime()) {
            minDateFromAvailability.setDate(minDateFromAvailability.getDate() + 1);
            minDateFromAvailability = this.setMinDate();
          }
          this.minDate = minDateFromAvailability;

          for (const testDate of this.dates) {
            if (testDate.date >= this.minDate) {
              if (testDate.valid) {
                this.minDate = testDate.date;
                break;
              }
            }
          }

          const dString: string = this.datePipe.transform(this.minDate, 'yyyy-MM-dd');

          this.checkout.extra.oh.ordberlevdat = dString;
          this.ottoCheckoutState.selectedDeliveryDate = dString;

          for (const row of this.cartRows) {
            this.cartService.removeExtraField(
              row.productId,
              'orp.ordberlevdat',
              row.targetStockId,
              row.productPartialId
            );
          }
          // if (dString > maxAssignedDate) {
          //   this.checkout.extra.oh.ordberlevdat = dString;
          // } else {
          //   this.checkout.extra.oh.ordberlevdat = maxAssignedDate;
          //   this.minDate = parse(maxAssignedDate, 'yyyy-MM-dd', this.minDate);
          // }
          this.createFullDeliveryPlan();
          this.loadingComponent = false;
        });
    }
  }

  assignRowDates(): void {
    this.availabilityService
      .getAvailabilities(
        this.cartRows.map(r => r.productId),
        undefined,
        true
      )
      .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
      .subscribe(avs => {
        for (const row of this.cartRows) {
          const av = avs.find(a => a.productId === row.productId);
          row.extra._availability_reference = av;
          let mindate = null;
          if (av) {
            const tempDate = getMinDate(av, row.qty);
            const nowDate = new Date();
            let minDate = nowDate;
            if (minDate < tempDate) {
              minDate = tempDate;
            }

            for (const testDate of this.dates) {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const dString1: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const dString2: string = this.datePipe.transform(testDate.date, 'yyyy-MM-dd');

              // We cant include time when comparing
              if (dString2 >= dString1) {
                if (testDate.valid) {
                  minDate = testDate.date;
                  break;
                }
              }
            }
            // If now date is today and q_jis_cutoff_hour of delivery method is already past
            const dString1: string = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
            const dString2: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
            if (this.pushDeliveryDate && dString1 === dString2) {
              const dStringBefore: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
              minDate.setDate(minDate.getDate() + 1);
              const dStringAfter: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
              // minDate = this.setMinDate();
            }

            const dString: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
            mindate = dString;
          }

          row.extra._calc_min_date = mindate;
          row.extra.orp = row.extra.orp || {};
          row.extra.orp.ordberlevdat = row.extra._calc_min_date;
          if (row.extra._user_selected_ordberlevdat) {
            if (row.extra._user_selected_ordberlevdat > row.extra._calc_min_date) {
              row.extra.orp.ordberlevdat = row.extra._user_selected_ordberlevdat;
            }
          }

          const deliveryPlan = {};
          const dates = Object.keys(av.availabilities);
          let quantityRemaining = row.qty;
          for (const date of dates) {
            const qty = av.availabilities[date];
            if (qty > 0 && quantityRemaining > 0) {
              const parsedDate = parse(date, 'yyMMdd', new Date());
              const formattedDate = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');

              let assignedDate = formattedDate;
              if (row.extra._user_selected_ordberlevdat > assignedDate) {
                assignedDate = row.extra._user_selected_ordberlevdat;
              }
              const currentOnDate = deliveryPlan[assignedDate] || 0;
              const currentAllocation = Math.min(qty, quantityRemaining);

              deliveryPlan[assignedDate] = currentOnDate + currentAllocation;

              quantityRemaining = quantityRemaining - currentAllocation;
            }
          }
          if (quantityRemaining > 0) {
            // Handle edge case of last quantities of expiring product
            deliveryPlan['2099-12-31'] = quantityRemaining;
          }
          row.extra._preliminaryDeliveryPlan = deliveryPlan;
        }

        for (const row of this.cartRows) {
          this.cartService.removeExtraField(
            row.productId,
            'dummy_element_just_to_trigger',
            row.targetStockId,
            row.productPartialId
          );
          break;
        }

        this.loadingComponent = false;
      });
  }

  createFullDeliveryPlan() {
    for (const row of this.checkout.rows) {
      const deliveryPlan = {};
      deliveryPlan[this.checkout.extra.oh.ordberlevdat] = row.quantity;
      // This is important because used by backend to split lines
      row.extra._preliminaryDeliveryPlan = deliveryPlan;
    }
  }

  optionsFilterDeliveryMethod(customer: Customer, option: { key: string; value: string | TableRecord }): boolean {
    return (
      option.key === '2' ||
      option.key === '4' ||
      option.key === '22' ||
      option.key === '' + customer.extra.kus.levsattkod
    );
  }

  dateSelected(event) {
    super.dateSelected(event);
    const date = event.date;
    const dString: string = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.ottoCheckoutState.selectedDeliveryDate = dString;
    for (const row of this.checkout.rows) {
      row.extra.orp.ordberlevdat = dString;
    }
  }

  onDeliveryMethodChanged(event) {
    this.checkout.extra.procargs.levsattkod = event || this.currentCustomer.extra.kus.levsattkod;
    if (event === '' + this.currentCustomer.extra.kus.levsattkod) {
      this.form.get('levvillkkod').setValue(this.currentCustomer.extra.kus.levvillkkod);
      this.noFreight = false;
    } else {
      this.form.get('levvillkkod').setValue('0');
      this.noFreight = false;
    }
    this.deliveryMethod22 = event === '22';
    if (event === '22') {
      // this.noFreight = true;
    }

    this.ottoCheckoutState.selectedDeliveryMethod = event;

    this.initDeliveryDatesBasedOnDeliveryMethod();
  }
}
