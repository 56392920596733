import { Component, Input, OnInit } from '@angular/core';
import { DashboardKpiService, LastVsThisMonthSalesAmountSummaryCardComponent } from 'gung-dashboard';
import { ActivityInternal, ActivityInternalListConfigService } from 'gung-standard';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'otto-olsen-beta-meetings-converted-sales',
  templateUrl: './otto-olsen-beta-meetings-converted-sales.component.html',
  styleUrls: ['./otto-olsen-beta-meetings-converted-sales.component.scss']
})
export class OttoOlsenBetaMeetingsConvertedSalesComponent extends LastVsThisMonthSalesAmountSummaryCardComponent implements OnInit {
  protected unsubscribe: Subject<void> = new Subject();
  convertedCurrentMoth;
  convertedLastMoth;
  currentDate: Date;

  @Input()
  preConditions: any;

  constructor(
    protected dashboardKpiService: DashboardKpiService,
    public activityInternalListConfigService: ActivityInternalListConfigService
    ) {
    super(dashboardKpiService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dashboardKpiService
    .getActiveCustomersThisYearComparison(this.preConditions)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe();
    
    this.activityInternalListConfigService.getItems().subscribe(items => {
      const completeActivity = ['30'];
      this.currentDate = new Date();
      const currentMonth = this.currentDate.getMonth();
      const lastMonth = this.currentDate.getMonth() -1;

      this.convertedCurrentMoth = this.filterItemsByMonth(items, currentMonth, completeActivity);
      this.convertedLastMoth = this.filterItemsByMonth(items, lastMonth, completeActivity);
    })
  }

  filterItemsByMonth(items: ActivityInternal[], month: number, status?: string[]): any[] {
    const filteredItems = items.filter((act) => {
      const objDate = new Date(act.activityDate);
  
      const isMatchingMonth = (date: Date) => date.getMonth() === month;
      const isMatchingStatus = (status: string[]) => status.includes(act.status);
      const isMatchingOrderValue = () => act.extra.orderValue === '';
      const isMatchingSalesPerson = (salesPersonId: string) => act.customerResponsible === salesPersonId;
  
      if (this.preConditions) {
        return (
          isMatchingMonth(objDate) &&
          isMatchingStatus(status) &&
          isMatchingOrderValue() &&
          isMatchingSalesPerson(this.preConditions.salesPersonId)
        );
      }
  
      return (
        isMatchingMonth(objDate) &&
        isMatchingStatus(status) &&
        isMatchingOrderValue()
      );
    });
  
    return filteredItems;
  }
}
