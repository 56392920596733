<div class="form-group" *ngIf="loaded; else loading">
  <label *ngIf="label && !suppressLabel" for="dp" class="control-label" [ngClass]="{ 'font-weight-bold': labelBold }">
    {{ label | translate }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="input-group">
    <input
      class="form-control input-date invisible position-absolute w-0"
      placeholder="yyyy-mm-dd"
      name="dp"
      ngbDatepicker
      #d="ngbDatepicker"
      [markDisabled]="markDisabled"
      (dateSelect)="dateSelected($event)"
      [minDate]="firstDatePickerDate"
      [maxDate]="maxDatePickerDate"
      [startDate]="firstDatePickerDate"
      [(ngModel)]="ngModel"
      [showWeekNumbers]="showWeekNumbers"
      [readonly]="true"
      [disabled]="disabled"
      [firstDayOfWeek]="firstDayOfWeek"
    />
    <input
      class="form-control input-date"
      placeholder="yyyy-mm-dd"
      (click)="d.toggle()"
      [value]="dateUtilService.createDateFromNgbDate(ngModel) | date: dateUtilService.dateFormat"
      [readonly]="readonly || disabled"
      [disabled]="disabled"
    />
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" [disabled]="disabled">
        <i class="fal fa-calendar-alt"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  <div *ngIf="!anyError; else showError">LOADING...</div>
  <ng-template #showError>
    <button class="btn btn-sm btn-danger btn-default" (click)="openErrorModal()" translate>ERROR</button>
  </ng-template>
</ng-template>
