import { Component, OnInit } from '@angular/core';
import {
  ProductGridViewComponent,
  PriceService,
  GungFlowService,
  AuthService,
  Customer,
  SelectedCustomerService,
  GungAnonymousConfigService
} from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'otto-olsen-product-grid-view',
  templateUrl: './otto-olsen-product-grid-view.component.html',
  styleUrls: ['./otto-olsen-product-grid-view.component.scss']
})
export class OttoOlsenProductGridViewComponent extends ProductGridViewComponent {
  selectedCustomer: Customer;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(priceService, gungFlowService, authService, gungAnonymousConfigService);
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
  }
}
