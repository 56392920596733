import { Component, OnInit, OnDestroy } from '@angular/core';
import { JeevesProductTableComponent, ProductTableRow } from 'gung-standard-jeeves';
import {
  PriceService,
  GungFlowService,
  ProductService,
  MetadataService,
  AuthService,
  LocationConfigService,
  ProductExtended,
  CustomerProductPrice,
  Availability,
  SelectedCustomerService,
  Customer
} from 'gung-standard';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { first } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JeevesUpdateKuarModalComponent } from 'gung-standard-jeeves';

@Component({
  selector: 'otto-olsen-product-pagination-list-card',
  templateUrl: './otto-olsen-product-pagination-list-card.component.html',
  styleUrls: ['./otto-olsen-product-pagination-list-card.component.scss']
})
export class OttoOlsenProductPaginationListCardComponent
  extends JeevesProductTableComponent
  implements OnInit, OnDestroy
{
  public requestPriceEmail = environment.requestPriceEmail;
  public isAnonymous = true;
  public selectedCustomer: Customer;

  public Number = Number;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected productService: ProductService,
    protected metadataService: MetadataService,
    protected selectedCustomerService: SelectedCustomerService,
    authService: AuthService,
    router: Router,
    locationConfigService: LocationConfigService,
    protected modalService: NgbModal
  ) {
    super(priceService, gungFlowService, authService, router, locationConfigService);
    this.authService
      .getHasRoles('ANONYMOUS')
      .pipe(first())
      .subscribe(isAnonymous => (this.isAnonymous = isAnonymous));
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(selectedCustomer => {
        this.selectedCustomer = selectedCustomer;
      });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public openCrossReferenceEditor(product: ProductTableRowExtended) {
    const modal = this.modalService.open(JeevesUpdateKuarModalComponent, { size: 'lg' });
    modal.componentInstance.product = product.product;
  }

  protected mapItem(
    id: string,
    product: ProductExtended,
    price: CustomerProductPrice,
    availability: Availability
  ): ProductTableRowExtended {
    // Product package size
    let packageSize;
    if (
      product.extra.ar.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 0
    ) {
      packageSize = Number(product.extra.ar.artfsgforp);
    }
    // Product original size without cutting
    let originalSize;
    if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
      originalSize = Number(product.extra.ar.artfsgforp);
    }
    if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
      packageSize = Number(product.extra.ar.q_salesbatchsize);
    }
    let firstStepAmount;
    if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
      firstStepAmount = Number(product.extra.ar.q_salesmoq);
    }
    return {
      id,
      name: product.name,
      product,
      price,
      packageSize,
      originalSize,
      availability,
      replacementId: product.extra.ar.q_gung_filter,
      firstStepAmount
    };
  }
}

export interface ProductTableRowExtended extends ProductTableRow {
  originalSize?: number;
  firstStepAmount?: number;
}
