import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenSupplierArticleService {
  constructor(protected http: HttpClient) {}

  isExistingArticleNumber(articleNumber: string, supplier: string): Observable<boolean> {
    const url = `json/otto-olsen/create-product/is-existing-id?productId=${articleNumber}&supplierId=${supplier}`;
    return this.http.get<boolean>(url);
  }
}
