import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import {
  DashboardCard,
  DashboardCardService,
  InvoiceStatisticsChartCardComponent,
  LastSevenDaysOrdersSummaryCardComponent,
  LastVsThisMonthSalesAmountSummaryCardComponent,
  LastVsThisWeekSalesAmountSummaryCardComponent,
  LastVsThisYearSalesAmountSummaryCardComponent,
  SalesSummaryCardComponent
} from 'gung-dashboard';
import { AuthService, NavigationConfig } from 'gung-standard';
import { first, takeUntil } from 'rxjs/operators';
import { OttoOlsenCustomersSummaryCardComponent } from '../components/dashboard/otto-olsen-customers-summary-card/otto-olsen-customers-summary-card.component';
import { OttoOlsenLastFullYearVsThisYearSalesSummaryCardComponent } from '../components/dashboard/otto-olsen-last-full-year-vs-this-year-sales-summary-card/otto-olsen-last-full-year-vs-this-year-sales-summary-card.component';
import { OttoOlsenLastTenOrdersCardComponent } from '../components/dashboard/otto-olsen-last-ten-orders-card/otto-olsen-last-ten-orders-card.component';
import { OttoOlsenLastYearToDateVsThisYearToDateSalesSummaryCardComponent } from '../components/dashboard/otto-olsen-last-year-to-date-vs-this-year-to-date-sales-summary-card/otto-olsen-last-year-to-date-vs-this-year-to-date-sales-summary-card.component';
import { OttoOlsenDashboardDataService } from './otto-olsen-dashboard-data.service';
import { Subject } from 'rxjs';
import { OttoOlsenBetaSalespersonSelectComponent } from '../components/dashboard/otto-olsen-beta-salesperson-select/otto-olsen-beta-salesperson-select.component';
import { OttoOlsenBetaLastVsThisMonthMeetingsComponent } from '../components/dashboard/otto-olsen-beta-last-vs-this-month-meetings/otto-olsen-beta-last-vs-this-month-meetings.component';
import { OttoOlsenBetaMeetingsConvertedSalesComponent } from '../components/dashboard/otto-olsen-beta-meetings-converted-sales/otto-olsen-beta-meetings-converted-sales.component';
import { OttoOlsenBetaInvoiceStatisticsChartCardComponent } from '../components/dashboard/otto-olsen-beta-invoice-statistics-chart-card/otto-olsen-beta-invoice-statistics-chart-card.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenDashboardCardService extends DashboardCardService {
  isAdmin: boolean = false;
  isSales: boolean = false;

  constructor(
    protected authService: AuthService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected dashboardDataService: OttoOlsenDashboardDataService
  ) {
    super(authService, environment);
    this.clearCards();
    authService.getRoles().pipe(first()).subscribe((roles: string[]) => {
      this.isAdmin = roles.findIndex(r => r === 'ADMIN') > -1;
      this.isSales = roles.findIndex(r => r === 'SALES') > -1 && roles.findIndex(r => r === 'ADMIN') === -1;
      if ((this.isSales || this.isAdmin) && window.location.pathname.includes('sales-dashboard-beta')) {
        dashboardDataService.currentMessage.subscribe(preCondition => {
          this.clearCards();
          this.createBetaSalesDashboardCards(preCondition);
        });
      } else if (this.isSales) {
        this.createCardsSales();
      } else {
        this.createCardsUser();
      }
    });
  }

  createCardsSales() {
    this.addCard(new DashboardCard(LastVsThisYearSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    this.addCard(new DashboardCard(LastVsThisMonthSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    this.addCard(new DashboardCard(LastVsThisWeekSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    this.addCard(new DashboardCard(LastSevenDaysOrdersSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));

    /*
    // this.addCard(
    //   new DashboardCard(MonthlySalesSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    // this.addCard(
    //   new DashboardCard(MonthlySalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    // this.addCard(
    //   new DashboardCard(CustomersWhoPurchasedCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    // this.addCard(
    //   new DashboardCard(ActiveProductsCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    */

    this.addCard(new DashboardCard(InvoiceStatisticsChartCardComponent, 'col-xs-12 col-md-12 col-xl-12'));
    this.addCard(new DashboardCard(SalesSummaryCardComponent, 'col-xs-12 col-md-12 col-xl-12'));
  }

  createCardsUser() {
    this.addCard(
      new DashboardCard(OttoOlsenLastFullYearVsThisYearSalesSummaryCardComponent, 'col-xs-12 col-md-4 col-xl-4')
    );
    this.addCard(
      new DashboardCard(OttoOlsenLastYearToDateVsThisYearToDateSalesSummaryCardComponent, 'col-xs-12 col-md-4 col-xl-4')
    );
    this.addCard(new DashboardCard(LastVsThisMonthSalesAmountSummaryCardComponent, 'col-xs-12 col-md-4 col-xl-4'));
    // this.addCard(
    //   new DashboardCard(LastVsThisWeekSalesAmountSummaryCardComponent, 'col-xs-12 col-md-4 col-xl-4')
    // );
    // this.addCard(
    //   new DashboardCard(LastSevenDaysOrdersSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    this.addCard(new DashboardCard(OttoOlsenCustomersSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-6'));
    this.addCard(new DashboardCard(OttoOlsenLastTenOrdersCardComponent, 'col-xs-12 col-md-6 col-xl-6'));
  }

  createBetaSalesDashboardCards(preCondition: any) {
    if (this.isAdmin) {
      this.addCard(
        new DashboardCard(OttoOlsenBetaSalespersonSelectComponent, 'col-xs-12 col-md-12 col-xl-12', preCondition)
      );
    }
    
    this.addCard(new DashboardCard(OttoOlsenBetaLastVsThisMonthMeetingsComponent, 'col-xs-12 col-md-6', preCondition));
    this.addCard(new DashboardCard(OttoOlsenBetaMeetingsConvertedSalesComponent, 'col-xs-12 col-md-6', preCondition));
    
    this.addCard(new DashboardCard(LastVsThisYearSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-4', preCondition));
    this.addCard(new DashboardCard(LastVsThisMonthSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-4', preCondition));
    this.addCard(new DashboardCard(LastVsThisWeekSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-4', preCondition));

    this.addCard(new DashboardCard(InvoiceStatisticsChartCardComponent, 'col-12', preCondition));

    // Needs backend work like cult so we can search for product category
    //this.addCard(new DashboardCard(OttoOlsenBetaInvoiceStatisticsChartCardComponent, 'col-12', preCondition));
    this.addCard(new DashboardCard(SalesSummaryCardComponent, 'col-12', preCondition));
  }
}
