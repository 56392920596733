import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthGuard } from 'gung-standard';
import { Observable, first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenAuthGuardService extends AuthGuard {
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    const roles = next.data.rolesAllowed;
    return this.checkLogin(url, roles).then(async res => {
      const isAnonymous = await this.authService.getHasRoles('ANONYMOUS').pipe(first()).toPromise();
      if (isAnonymous && url === '/mobile') {
        this.router.navigate(['/login']);
        return false;
      }
      return res;
    });
  }
}
