<div class="container" *ngIf="!hideMenuRoutes.includes(router.url.split('/')[1])">
  <div class="float-left">
    <p>
      <a href="https://oo.no/personvernerklaering/" target="_blank">Personvernerklæring</a>&nbsp; /&nbsp;
      <a
        href="https://oo.no/wp-content/uploads/Salgs-og-leveringsbetingelser-Otto-Olsen-AS.pdf"
        target="_blank"
        rel="noopener"
        >Salgs-og leveringsbetingelser for Otto Olsen AS</a
      >&nbsp; /&nbsp;
      <a href="https://oo.no/generelle-vilkar/" target="_blank">Generelle vilkår</a>
    </p>
  </div>
  <div class="float-right social-icon mb-3">
    <span class="elementor-grid-item">
      <a
        class="elementor-icon elementor-social-icon elementor-social-icon-facebook"
        href="https://www.facebook.com/ottoolsenas/"
        target="_blank"
      >
        <i class="fab fa-facebook"></i>
      </a>
    </span>
    <span class="elementor-grid-item">
      <a
        class="elementor-icon elementor-social-icon elementor-social-icon-linkedin"
        href="https://www.linkedin.com/company/otto-olsen-as"
        target="_blank"
      >
        <i class="fab fa-linkedin"></i>
      </a>
    </span>
    <span class="elementor-grid-item">
      <a
        class="elementor-icon elementor-social-icon elementor-social-icon-instagram"
        href="https://www.instagram.com/ottoolsenas/"
        target="_blank"
      >
        <i class="fab fa-instagram"></i>
      </a>
    </span>
  </div>
</div>
