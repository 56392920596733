import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GungModalService, AccountRequestsCardListComponent, AccountRequestService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-account-requests-card-list',
  templateUrl: './otto-olsen-account-requests-card-list.component.html',
  styleUrls: ['./otto-olsen-account-requests-card-list.component.scss']
})
export class OttoOlsenAccountRequestsCardListComponent extends AccountRequestsCardListComponent {
  constructor(
    protected accountRequestService: AccountRequestService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected modalService: NgbModal,
    protected router: Router
  ) {
    super(accountRequestService, gungModalService, translationService, modalService, router);
  }
}
