import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PimTemplateProperties } from 'gung-list';
import {
  ConceptDetailProductListViewComponent,
  PriceService,
  GungFlowService,
  AuthService,
  AssortmentTreeImageSelectionService,
  Product,
  Customer,
  SelectedCustomerService,
  GridViewProductCard,
  CustomerProductPrice,
  Availability,
  GridViewProductCardExtended,
  PriceConfigService,
  ColumnSortSelectionService,
  GungAnonymousConfigService
} from 'gung-standard';
import { forkJoin, of, takeUntil, first } from 'rxjs';
import { environment } from './../../../environments/environment';
import { OttoOlsenPimEditConfigService } from '../../services/otto-olsen-pim-edit-config.service';

export interface ProductExtended extends Product {
  documents?: any[];
  extra: {
    [s: string]: any;
    productTemplateProperties?: PimTemplateProperties[];
  };
  packageSize?: number;
  originalSize?: number;
  firstStepAmount?: number;
}

@Component({
  selector: 'otto-olsen-concept-detail-product-list-view',
  templateUrl: './otto-olsen-concept-detail-product-list-view.component.html',
  styleUrls: ['./otto-olsen-concept-detail-product-list-view.component.scss']
})
export class OttoOlsenConceptDetailProductListViewComponent
  extends ConceptDetailProductListViewComponent
  implements OnInit, OnDestroy {
  public requestPriceEmail = environment.requestPriceEmail;

  public Number = Number;

  @Input()
  public allData?: Product[];
  selectedCustomer: Customer;

  public hidePrice: boolean;

  productPimUrl: string = environment.externalPimUrl + 'pim-item/';
  isAssortmentManager: boolean = false;
  isProductManager: boolean = false;
  pimEditUrlHide: boolean;

  protected firstLoad: boolean = true;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    protected columnSortSelectionService: ColumnSortSelectionService,
    protected selectedCustomerService: SelectedCustomerService,
    protected router: Router,
    protected priceConfigService: PriceConfigService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(priceService, gungFlowService, authService, assortmentTreeImageSelectionService, router, gungAnonymousConfigService);
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
    this.priceConfigService.hidePrice.pipe(takeUntil(this.unsubscribe)).subscribe(hidePrice => (this.hidePrice = hidePrice));
  }

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(userRoles => {
      this.isAssortmentManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'assortments');
      this.isProductManager = this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles, 'products-concepts');
    });
    this.ottoOlsenPimEditConfigService.pimEditUrlHide.pipe(takeUntil(this.unsubscribe)).subscribe(pimEditUrlHide => (this.pimEditUrlHide = pimEditUrlHide));
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.columnSortSelectionService.setSelectedColumnSort(null);
    this.clearSortColumns();
  }

  sortColumn(headerKey: PimTemplateProperties) {
    const ascSort = !headerKey.sort || headerKey.sort === 'asc' ? -1 : 1;
    this.clearSortColumns();
    headerKey.sort = ascSort === 1 ? 'asc' : 'desc';
    this.columnSortSelectionService.setSelectedColumnSort(headerKey);
  }

  clearSortColumns() {
    // clear all other columns sorts
    for (const c of this.dynamicColumns) {
      c.sort = undefined;
    }
  }

  protected subscribeToProducts() {
    if (!this.oldFlow || !this.currentFlow || this.oldFlow.id !== this.currentFlow.id) {
      // remove cached data;
      this.keyedMapData = {};
      this.oldFlow = this.currentFlow;
    }
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }

    const subscription = forkJoin([
      of(this.data),
      this.priceService.getCurrentCustomerPrices(newIds).pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ])
      .pipe(first())
      .subscribe(data => {
        const productData = data[0];
        const prices = data[1];
        let avs = [];
        this.includeAvailability = data[2].useAvailabilities || data[2].requireAvailability;
        if (this.includeAvailability) {
          avs = newIds.map(id => this.getProductAvailability(this.data.find(product => product.id === id)));
        }

        this.keyedMapData = {
          ...this.keyedMapData,
          ...newIds.reduce((acc, curr) => {
            const product = productData.filter(p => p && p.id === curr)[0];
            let price;
            let av;
            try {
              if (!product) {
                this.errorMessage += 'No product found. ';
                throw new Error('No product found');
              }

              price = prices.filter(p => p && p.productId === curr)[0];
              if (!price) {
                this.errorMessage += 'No price found.';
                throw new Error('No price found');
              }

              av = avs.filter(p => p && p.productId === curr)[0];
              if (!av && this.includeAvailability) {
                this.errorMessage += 'No availability found. ';
                throw new Error('No availability found');
              }
            } catch {
              this.findError = true;
              // throw new Error(this.errorMessage);
            } finally {
            }
            const item: GridViewProductCard = this.mapItem(curr, product, price, av);
            return {
              ...acc,
              [curr]: item
            };
          }, {})
        };
        this.readMappedDataFromCache();

        if (this.firstLoad) {
          this.firstLoad = false;
          this.sortDefault();
        }
      });
    this.subscriptions.push(subscription);
  }

  protected mapItem(
    id: string,
    product2: ProductExtended,
    price2: CustomerProductPrice,
    availability2: Availability
  ): GridViewProductCardExtended {
    const { product, price, availability } = super.mapItem(id, product2, price2, availability2);

    // Product package size
    if (
      product.extra.ar.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 0
    ) {
      product.packageSize = Number(product.extra.ar.artfsgforp);
    }
    // Product original size without cutting
    if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
      (product as ProductExtended).originalSize = Number(product.extra.ar.artfsgforp);
    }
    if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
      product.packageSize = Number(product.extra.ar.q_salesbatchsize);
    }

    // Product package size
    if (
      product.extra.ar &&
      product.extra.ar.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 0
    ) {
      product.packageSize = Number(product.extra.ar.artfsgforp);
    }
    if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
      (product as ProductExtended).firstStepAmount = Number(product.extra.ar.q_salesmoq);
    }
    return {
      product,
      price,
      availability
    };
  }

  protected sortDefault(): void {
    for (const col of (this.dynamicColumns as OttoOlsePimTemplateProperties[])) {
      if (col.defaultSortingOrder && col.isDefaultSort) {
        const ascSort = !col.defaultSortingOrder || col.defaultSortingOrder === 'asc' ? -1 : 1;
        this.clearSortColumns();
        col.sort = ascSort === 1 ? 'asc' : 'desc';
        this.columnSortSelectionService.setSelectedColumnSort(col);
        break;
      }
    }
  }

  public encodeURIComponent(s: string): string {
    return encodeURIComponent(s);
  }
}

export interface OttoOlsePimTemplateProperties extends PimTemplateProperties {
  defaultSortingOrder: string,
  isDefaultSort: boolean
}
