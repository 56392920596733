<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    <span translate>CREATE_USER_ASSOCIATE_TO</span> {{ createUserFrom.name }}
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class="form" [formGroup]="userForm" (ngSubmit)="onAdd()">
  <div class="modal-body" *ngIf="!isBlocked; else isBlockedInfo">
    <div class="row">
      <div class="col-12">
        <lib-gung-text-input
          gungTranslate="NAME"
          formControlName="name"
          [placeholder]="'NAME' | translate"
        ></lib-gung-text-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <lib-gung-text-input
          gungTranslate="EMAIL"
          formControlName="email"
          [placeholder]="'EMAIL' | translate"
        ></lib-gung-text-input>
      </div>
    </div>
    <div class="row" *ngIf="userFromCustomer">
      <div class="col-12">
        <lib-gung-text-input
          gungTranslate="PASSWORD"
          formControlName="password"
          [placeholder]="'PASSWORD' | translate"
        ></lib-gung-text-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <lib-gung-number-input
          gungTranslate="PHONE"
          formControlName="phone"
          [placeholder]="'PHONE' | translate"
        ></lib-gung-number-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <lib-gung-text-input
          gungTranslate="USERNAME"
          formControlName="username"
          [placeholder]="'USERNAME' | translate"
        ></lib-gung-text-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <lib-gung-text-input
          gungTranslate="ASSORTMENT"
          formControlName="assortment"
          [placeholder]="'ASSORTMENT' | translate"
        ></lib-gung-text-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <lib-gung-text-input
          gungTranslate="CUSTOMER_ID"
          formControlName="managedCompanyIds"
          [placeholder]="'CUSTOMER_ID' | translate"
        ></lib-gung-text-input>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ngb-alert class="mt-2" *ngIf="alertMessage" [type]="alertMessage.type" (close)="alertMessage = null">
          {{ alertMessage.message | translate }}
          <br />
          <span *ngFor="let field of alertMessage.invalidFields; let last = last">
            {{ field | translate }}<span *ngIf="!last">, </span>
          </span>
        </ngb-alert>
      </div>
    </div>
  </div>
  <ng-template #isBlockedInfo>
    <span class="d-block my-2 text-center" translate>CURRENT_CUSTOMER_BLOCKED</span>
  </ng-template>
  <div class="modal-footer">
    <div class="row w-100">
      <div class="col d-flex justify-content-end">
        <button type="button" class="btn btn-light btn-sm" (click)="activeModal.close(false)" translate>CANCEL</button>
        <button type="submit" class="btn btn-primary btn-sm ml-2" translate [disabled]="isBlocked">
          CREATE <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
        </button>
      </div>
    </div>
  </div>
</form>
