import { Injectable } from '@angular/core';
import { ListLayoutMultipleComponent, ListLayout } from 'gung-list';
import { ConceptTreeListConfigService, AssortmentRecursive, ConceptTreeTableViewComponent } from 'gung-standard';
import { forkJoin, Observable, first, map, mergeMap, of } from 'rxjs';
import { OttoOlsenConceptTreeListViewComponent } from '../components/otto-olsen-concept-tree-list-view/otto-olsen-concept-tree-list-view.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenConceptTreeListConfigService extends ConceptTreeListConfigService {
  getLayouts(): ListLayout<AssortmentRecursive>[] {
    if (this.pimOptions && this.pimOptions.showAsList) {
      return [
        {
          getIconClass: () => '',
          getListItemComponent: () => ConceptTreeTableViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        }
      ];
    }
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenConceptTreeListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getBatchSizes(): number[] {
    return [500];
  }

  getItems(): Observable<AssortmentRecursive[]> {
    return super.getItems().pipe(
      mergeMap(assorments => forkJoin([of(assorments), this.authService.getRoles().pipe(first())])),
      map(([assortments, roles]) => {
        if (!roles.includes('SALES') && !roles.includes('ADMIN')) {
          // Hide concept not active in B2B for users
          return assortments.filter(a => {
            return a.extra.pim?.activeInB2B;
          });
        }
        return assortments;
      })
    );
  }
}
