import { Injectable } from '@angular/core';
import { FastSearchLayout, InlineSearchLayoutComponent, SearchRequest, SearchResult } from 'gung-list';
import {
  AuthService,
  BaseViewConfigService,
  GungBase,
  PriceService,
  Product,
  ProductFastsearchDetailComponent,
  ProductInlineSearchCardComponent,
  ProductInlineSearchListConfigService,
  ProductService
} from 'gung-standard';
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, first, switchMap } from 'rxjs';
import { OttoOlsenProductInlineSearchCardComponent } from '../components/otto-olsen-product-inline-search-card/otto-olsen-product-inline-search-card.component';
import { OttoOlsenProductService } from './otto-olsen-product.service';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenInlineSearchListConfigService extends ProductInlineSearchListConfigService {
  constructor(
    protected productService: OttoOlsenProductService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected baseViewConfigService: BaseViewConfigService
  ) {
    super(productService, authService, priceService, baseViewConfigService);
  }

  getItems(terms: string[], skip: number, limit: number): Observable<SearchResult<any>> {
    return this.productService.getSearchAssortments(terms[0]).pipe(
      switchMap(result => {
        const assortments = result.assortments.map(a => {
          const _path = a.path.map(p => p.id);
          _path.splice(0, 1, '/articles');
          return { ...a.assortment, _path };
        });
        const concepts = result.concepts.map(c => {
          const _path = c.path.map(p => p.id);
          _path.splice(0, 1, '/articles');
          return { ...c.concept, _path };
        });
        const items = [...assortments, ...concepts];
        const searchResultProducts = {
          items,
          totalItems: items.length,
          skipped: null
        };
        return of(searchResultProducts);
      })
    );
  }

  getLayout(): FastSearchLayout<Product> {
    return {
      getLayout: () => InlineSearchLayoutComponent,
      getListItemComponent: () => OttoOlsenProductInlineSearchCardComponent,
      getDetailItemComponent: () => ProductFastsearchDetailComponent
    };
  }
}
