import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateUtilService } from 'gung-common';
import { Cart, CartsService, Product, ProductService, PriceService, gungComparatorHelper } from 'gung-standard';
import { first, forkJoin, map, mergeMap, Observable, of } from 'rxjs';
import { OttoOlsenSavedCartDetailConfigService } from '../../services/otto-olsen-saved-cart-detail-config.service';
import { ProductExtended } from '../otto-olsen-product-details/otto-olsen-product-details.component';
import { OttoOlsenSavedCartTableComponent } from '../otto-olsen-saved-cart-table/otto-olsen-saved-cart-table.component';

@Component({
  selector: 'otto-olsen-saved-cart-details',
  templateUrl: './otto-olsen-saved-cart-details.component.html',
  styleUrls: ['./otto-olsen-saved-cart-details.component.scss']
})
export class OttoOlsenSavedCartDetailsComponent implements OnInit {

  constructor(
    public config: OttoOlsenSavedCartDetailConfigService,
    protected route: ActivatedRoute,
    protected cartsService: CartsService
  ) {}

  ngOnInit(): void {
    const routeParams = this.route.snapshot.params;
    if (routeParams.cartId != null) {
      this.config.cart.next(routeParams.cartId);
    }
  }
}
