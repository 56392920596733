import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OttoOlsenInlineSearchListConfigService } from '../../../services/otto-olsen-inline-search-list-config.service';

@Component({
  selector: 'otto-olsen-scanner-search',
  templateUrl: './otto-olsen-scanner-search.component.html',
  styleUrls: ['./otto-olsen-scanner-search.component.scss']
})
export class OttoOlsenScannerSearchComponent implements OnInit {

  constructor(public configService: OttoOlsenInlineSearchListConfigService) { }

  ngOnInit(): void {
  }

}
