import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ProductTreeListViewComponent,
  PriceService,
  GungFlowService,
  AuthService,
  MetadataService,
  LocationConfigService,
  Customer,
  SelectedCustomerService,
  GungAnonymousConfigService
} from 'gung-standard';
import { first } from 'rxjs';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'otto-olsen-product-tree-list-view',
  templateUrl: './otto-olsen-product-tree-list-view.component.html',
  styleUrls: ['./otto-olsen-product-tree-list-view.component.scss']
})
export class OttoOlsenProductTreeListViewComponent extends ProductTreeListViewComponent {
  public requestPriceEmail = environment.requestPriceEmail;
  selectedCustomer: Customer;

  public Number = Number;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected metadataService: MetadataService,
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    private selectedCustomerService: SelectedCustomerService,
    protected route: ActivatedRoute,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(priceService, gungFlowService, authService, locationConfigService, router, route, gungAnonymousConfigService);
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
  }
}
