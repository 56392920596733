import { Injectable } from '@angular/core';
import { BackendPrice, Customer, CustomerProductPrice } from 'gung-standard';
import { JeevesPriceService } from 'gung-standard-jeeves';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenPriceService extends JeevesPriceService{

  protected mapCustomerProductPrice(backendPrice: BackendPrice, customer: Customer, qty?: number) {
    const priceLevel = this.getPriceLevel(backendPrice, qty);

    const customerDiscountPercent = priceLevel.discount;

    // priceLevel.price is with discounts for Jeeves, which is why we calculate gross price by dividing with the
    // discount percent.
    const customerGrossPrice = !!priceLevel.price ? Math.round((priceLevel.price / ((100 - customerDiscountPercent) / 100)) * 100) / 100 : 0;
    const customerNetPrice = priceLevel.price;
    const recommendedRetailPrice = backendPrice.rrp || priceLevel.extra.ApproximatePrice || 0;

    const customerProductPrice: CustomerProductPrice = {
      productId: backendPrice.productId,
      customerId: customer.id,
      customerDiscountPercent,
      customerGrossPrice: {
        value: customerGrossPrice,
        currencyCode: customer.extra.kus.valkod
      },
      customerNetPrice: {
        value: customerNetPrice,
        currencyCode: customer.extra.kus.valkod
      },
      customerDiscountAmount: {
        value: customerGrossPrice - customerNetPrice,
        currencyCode: customer.extra.kus.valkod
      },
      backendPriceLevel: priceLevel,
      priceFactor: backendPrice.priceFactor,
      backendPrice: backendPrice
    };

    customerProductPrice.recommendedRetailPrice = {
      value: recommendedRetailPrice,
      currencyCode: customer.extra.kus.valkod
    };

    return customerProductPrice;
  }
}
