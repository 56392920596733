import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DashboardKpiService, SalesSummaryCardComponent } from 'gung-dashboard';
import { ProductService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-customers-summary-card',
  templateUrl: './otto-olsen-customers-summary-card.component.html',
  styleUrls: ['./otto-olsen-customers-summary-card.component.scss']
})
export class OttoOlsenCustomersSummaryCardComponent
  extends SalesSummaryCardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected dashboardKpiService: DashboardKpiService,
    protected translateService: TranslateService,
    protected productService: ProductService
  ) {
    super(changeDetectorRef, dashboardKpiService, translateService, productService);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
