import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';
import {
  BackendInterceptor,
  Product,
  ProductListComponent,
  ProductListConfigService,
  ProductSelectionService
} from 'gung-standard';
import { OttoOlsenProductAssortmentListConfigService } from '../../services/otto-olsen-product-assortment-list-config.service';
import {OttoOlsenProductExportService} from "../../services/otto-olsen-product-export.service";

@Component({
  selector: 'otto-olsen-product-list-assortment',
  template:
    '<h2 class="text-center" translate>EXTRA_PRODUCTS</h2><div class="row"><div class="col-12"><button type="button" class="btn btn-primary float-right" (click)="exportToExcel()" translate>EXPORT_TO_EXCEL</button></div></div><lib-filter-list #productList [configService]="productListConfigService" [topFilter]="true"></lib-filter-list>',
  styleUrls: ['./otto-olsen-product-list-assortment.component.scss'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class OttoOlsenProductListAssortmentComponent extends ProductListComponent implements OnInit {
  @ViewChild('productList') productList: FilterListComponent<Product>;

  constructor(
    public productListConfigService: OttoOlsenProductAssortmentListConfigService,
    public route: ActivatedRoute,
    backendInterceptor: BackendInterceptor,
    private ottoOlsenProductExportService: OttoOlsenProductExportService
  ) {
    super(productListConfigService, route, backendInterceptor);
  }

  exportToExcel(){
    this.ottoOlsenProductExportService.exportExcel(this.productList.items,"CUSTOMER-SPECIFIC-ASSORTMENT-EXPORT",null).subscribe(res=>{

    })
  }
}
