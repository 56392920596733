import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  ProductDetailComponent,
  ProductService,
  AvailabilityService,
  AuthService,
  PriceService,
  AssortmentService,
  MetadataService,
  GungFlowService,
  BaseViewConfigService
} from 'gung-standard';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { map, switchMap, first } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'otto-olsen-product-details-modal',
  templateUrl: './otto-olsen-product-details-modal.component.html',
  styleUrls: ['./otto-olsen-product-details-modal.component.scss']
})
export class OttoOlsenProductDetailsModalComponent extends ProductDetailComponent implements OnInit {
  lang = localStorage.getItem('selectedLanguage');

  public imageIdString = 'productImage';
  @ViewChild(NgbCarousel)
  private myCarousel: NgbCarousel;
  @ViewChild('scrollContainer')
  scrollingImages: ElementRef;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected metadataService: MetadataService,
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    public activeModal: NgbActiveModal,
    protected gungFlowService: GungFlowService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      metadataService,
      translateService,
      assortmentService,
      gungFlowService
    );
  }

  ngOnInit(): void {
    const baseObservable = this.route.params.pipe(
      map(params => {
        return (params.productId as string) || this.productId;
      }),
      switchMap(productId =>
        forkJoin({
          productId: of(productId),
          multistock: this.authService.getCurrentUser().pipe(
            first(),
            map(user => user.managedMultistockIds[0])
          )
        })
      ),
      switchMap(({ productId, multistock }) =>
        forkJoin({
          product: this.productService.getProduct(productId),
          availability: of(null), // this.availabilityService.getAvailability(productId, multistock),
          price: of(null), // this.priceService.getCurrentCustomerPrice(productId).pipe(first()),
          user: this.authService.getCurrentUser().pipe(first()),
          flow: this.gungFlowService.getSelectedFlow().pipe(first())
        })
      )
    );
    this.handleObservable(baseObservable);
  }

  select(index: number): void {
    this.myCarousel.select(this.imageIdString + index);
  }

  scrollImagesRight(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.min(
      scroller.scrollLeft + scroller.clientWidth,
      scroller.scrollWidth - scroller.clientWidth
    );
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }

  scrollImagesLeft(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.max(scroller.scrollLeft - scroller.clientWidth, 0);
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }
}
